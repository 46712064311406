import axios from "axios";
import Api from "../../Api";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class TipoDocumentoService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/documento/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }
}
