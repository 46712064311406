import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";

//Servicion
import DepartamentoService from "./../../service/AppService/DepartamentoService";
import DireccionService from "./../../service/AppService/DireccionService";
import MunicipioService from "./../../service/AppService/MunicipioService";

export const NuevaDireccion = ({
  hideDialag,
  persona,
  direccione,
  toast,
  eliminar,
  setEliminar,
}) => {
  // const toast = useRef();
  const message = useRef();
  let direccionEmpty = {
    descripnDireccion: "",
    id: 0,
    inmId: null,
    munID: 0,
    municipio: {
      depId: 0,
      descripcion: "",
      id: 0,
    },
    persona: null,
    tipo: "",
  };

  let idDepartamentoEmpty = {
    descripcion: "",
    id: 0,
  };

  let municipios = {
    depId: 0,
    descripcion: "",
    id: 0,
  };

  const [direccion, setDireccion] = useState(direccionEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [appDepartamentos, setDepartamento] = useState();
  const [idDepartamento, setIdDepartamento] = useState(idDepartamentoEmpty);
  const [appMunicipio, setAppMunicipio] = useState(municipios);

  const exit = () => {
    hideDialag(), setSubmitted(false), setDireccion(direccionEmpty);
    setIdDepartamento(idDepartamentoEmpty), setAppMunicipio(municipios);
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  useEffect(() => {
    const departamentoService = new DepartamentoService();

    departamentoService.findAll().then((response) => {
      setDepartamento(response);
    });

    if (direccione?.munID !== 0) {
      const municipioService = new MunicipioService();
      departamentoService
        .findById(direccione.municipio.depId)
        .then((response) => {
          setIdDepartamento(response);

          municipioService.findById(response.id).then((mun) => {
            setAppMunicipio(mun);
          });
        });
    }

    setDireccion(direccione);
  }, []);

  const onInputChangeRegObject = (e) => {
    setIdDepartamento(e.value);

    const municipioService = new MunicipioService();
    municipioService.findById(e.value.id).then((response) => {
      setAppMunicipio(response);
    });
  };

  const onInputChangeRegObjectMun = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _direccion = { ...direccion };
    _direccion[`${name}`] = val;

    _direccion[`${key}`] = value;

    setDireccion(_direccion);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _direccion = { ...direccion };
    _direccion[`${name}`] = val;

    setDireccion(_direccion);
  };

  const save = () => {
    setSubmitted(true);
    let _direccion = { ...direccion };
    _direccion[`persona`] = persona?.id || null;
    if (
      direccion.descripnDireccion !== "" &&
      direccion.munID !== "" &&
      direccion.tipo !== ""
    ) {
      const direccionService = new DireccionService();
      direccionService.save(_direccion).then(() => {
        if (direccion.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nueva direccion creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Direccion actualizada",
            life: 4000,
          });
        }
        setSubmitted(false);
        setDireccion(direccionEmpty);

        hideDialag();
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const confirmDelete = () => {
    const direccionService = new DireccionService();
    direccionService.dele(direccion.id).then(() => {
      setDireccion(direccionEmpty);

      hideDialag();
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Direccion eliminada",
        life: 4000,
      });
    });
  };

  return (
    <>
      {/* Star Card y columna de datos  */}
      <div className="card p-fluid">
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="description">Departameto</label>

            <div className="field">
              <Dropdown
                disabled={eliminar === true ? "on" : ""}
                value={idDepartamento}
                options={appDepartamentos}
                onChange={(e) =>
                  onInputChangeRegObject(
                    e,
                    "idDepartamento",
                    e.value.id,
                    "idDep"
                  )
                }
                optionLabel="descripcion"
                placeholder="Seleccione un departamento"
                required
              />
            </div>
          </div>

          <div className="field col">
            <label htmlFor="description">Municipio</label>

            <Dropdown
              value={direccion.municipio}
              options={appMunicipio}
              onChange={(e) =>
                onInputChangeRegObjectMun(e, "municipio", e.value.id, "munID")
              }
              optionLabel="descripcion"
              placeholder="Seleccione un municipio"
              required
              className={classNames({
                "p-invalid": submitted && !direccion.munID,
              })}
              disabled={eliminar === true ? "on" : ""}
            />
          </div>
        </div>
        {/* Star Card y columna de datos  */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="description">Tipo de direccion</label>
            <div className="formgrid grid">
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="R"
                    checked={direccion?.tipo === "R"}
                    onChange={(e) => onInputChange(e, "tipo")}
                    className={classNames({
                      "p-invalid": submitted && !direccion.tipo,
                    })}
                    disabled={eliminar === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Residencia</label>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="T"
                    checked={direccion?.tipo === "T"}
                    onChange={(e) => onInputChange(e, "tipo")}
                    className={classNames({
                      "p-invalid": submitted && !direccion.tipo,
                    })}
                    disabled={eliminar === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Trabajo</label>
                </div>
              </div>

              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="I"
                    checked={direccion?.tipo === "I"}
                    onChange={(e) => onInputChange(e, "tipo")}
                    className={classNames({
                      "p-invalid": submitted && !direccion.tipo,
                    })}
                    disabled={eliminar === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Inmueble</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="description">Descripcion</label>

            <div className="field">
              <InputTextarea
                id="description"
                value={direccion.descripnDireccion}
                rows="6"
                placeholder="Ejemplo: Aldea Argueta, Avenida Europea "
                required
                onChange={(e) => onInputChange(e, "descripnDireccion")}
                className={classNames({
                  "p-invalid": submitted && !direccion.descripnDireccion,
                })}
                disabled={eliminar === true ? "on" : ""}
              />
              {submitted && !direccion.descripnDireccion && (
                <small className="p-invalid">
                  {" "}
                  Ingrese una descripcion a la direccion
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div
        className="card p-fluid"
        style={{ display: eliminar === true ? "none" : "" }}
      >
        <div className="formgrid grid">
          <div className="field col">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={exit}
            />
          </div>
          <div className="field col">
            <Button
              type="button"
              label="Guardar"
              icon="pi pi-upload "
              className="p-button-success"
              onClick={() => save()}
            />
          </div>
        </div>
      </div>

      <div
        className="card p-fluid"
        style={{ display: eliminar !== true ? "none" : "" }}
      >
        <div className="grid formgrid">
          <div className="col">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={exit}
            />
          </div>
          <div className="col">
            <Button
              type="button"
              label="Confirmar"
              icon="pi pi-trash"
              className="p-button-warning"
              onClick={() => confirmDelete()}
            />
          </div>
        </div>
      </div>
      {/* <Toast ref={toast} /> */}
    </>
  );
};
