import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { RadioButton } from "primereact/radiobutton";
//services
//import CorrelativoService from "../../services/CorrelativoService"

import CorrelativoService from "../../service/AppService/CorrelativoService";

export const Correlativo = () => {
  const toast = useRef();
  const message = useRef();

  let correlativoEmpty = {
    correlativo: 0,
    fechaFinal: "",
    fechaInicial: "",
    id: 0,
    estado: "",
  };

  let filtroEmpty = {
    fechaInicial: "",
    fechaFinal: "",
  };

  //Use State
  const [correlativo, setCorrelativo] = useState(correlativoEmpty);
  const [correlativos, setCorrelativos] = useState([]);
  const [nuevoCorrelativo, setNuevoCorrelativo] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);
  //use Effect
  useEffect(() => {
    const correlativoService = new CorrelativoService();
    correlativoService
      .finByFecha(filtro.fechaInicial, filtro.fechaFinal)
      .then((response) => {
        setCorrelativos(response);
      });
  }, [filtro.fechaInicial, filtro.fechaFinal]);

  const hideDialog = () => {
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " cambios descartados",
      life: 4000,
    });
    setSubmitted(false);
    onHide("nuevoCorrelativo");
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setCorrelativo(correlativoEmpty);
  };

  const dialogFuncMap = {
    nuevoCorrelativo: setNuevoCorrelativo,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onInputChangeReg = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _correlativo = { ...correlativo };
    _correlativo[`${name}`] = val;

    setCorrelativo(_correlativo);
  };
  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };
  const save = () => {
    setSubmitted(true);
    let _correlativo = { ...correlativo };
    if (correlativo.fechaFinal !== "" && _correlativo.fechaInicial !== "") {
      const correlativoService = new CorrelativoService();
      correlativoService.save(_correlativo).then(() => {
        if (correlativo.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Correlativo  creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " correlativo actualizado",
            life: 4000,
          });
        }
        setSubmitted(false);
        setNuevoCorrelativo(false);
        setCorrelativo(correlativoEmpty);
        correlativoService.findAll().then((reponse) => {
          setCorrelativos(reponse);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "No se aceptan campos vacios ",
        life: 4000,
      });
    }
  };
  const onEdit = (rowData) => {
    setCorrelativo(rowData);
    setNuevoCorrelativo(true);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar: "}
        />
      </>
    );
  };
  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaInicial);
  };
  const dateBodyTemplate2 = (rowData) => {
    return formatDate(rowData.fechaFinal);
  };
  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );


  return (
    <div className="grid-container">
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h4>Filtros</h4>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">Fecha Inicial</label>

              <div className="field">
                <InputText
                  id="filtroNombre"
                  type="date"
                  value={filtro.fechaInicial}
                  autoComplete="off"
                  onChange={(e) => chengFiltro(e, "fechaInicial")}
                />
              </div>
            </div>

            <div className="field col">
              <label htmlFor="nombre">Fecha Final</label>

              <div className="field">
                <InputText
                  id="filtroNombre"
                  autoComplete="off"
                  type="date"
                  value={filtro.fechaFinal}
                  onChange={(e) => chengFiltro(e, "fechaFinal")}
                />
              </div>
            </div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nuevo correleativo"
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick("nuevoCorrelativo")}
              />
            </div>
          </div>
        </div>
        <div className="card p-fluid">
          <h4>Correlativo</h4>

          {/* Start Row */}
          <DataTable
            value={correlativos}
            emptyMessage="No se encontro ningun correlativo"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="correlativo" header="Ultiimo utilizado "></Column>
            <Column
              body={dateBodyTemplate}
              field="fechaInicial"
              header="Fecha inicial "
            ></Column>
            <Column
              body={dateBodyTemplate2}
              field="estado"
              header="Fecha final "
            ></Column>
            <Column
              body={teplateBodyEstado}

              header="Estado "
            ></Column>

            {/* <Column header="Eestado" body={teplateBodyEstado}></Column> */}
            <Column header="Acciones  " body={accciones}></Column>
          </DataTable>
          {/* End Row */}
        </div>
        {/* Start puff nuevo correlativo */}

        <Dialog
          visible={nuevoCorrelativo}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          className="p-fluid"
          onHide={hideDialog}
          header={
            correlativo.id === 0 ? "Nuevo correlativo" : `Editar correlativo `
          }
          autoComplete="off"
        >
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="nombre">fecha Inicial</label>

                <div className="field">
                  <InputText
                    id="nombre"
                    type="date"
                    value={correlativo.fechaInicial}
                    onChange={(e) => onInputChangeReg(e, "fechaInicial")}
                    required
                    autoFocus
                    className={classNames({
                      "p-invalid": submitted && !correlativo.fechaInicial,
                    })}
                  />
                </div>
              </div>

              <div className="field col">
                <label htmlFor="apellido">Fecha final</label>

                <div className="field">
                  <InputText
                    id="apellido"
                    type="date"
                    value={correlativo.fechaFinal}
                    onChange={(e) => onInputChangeReg(e, "fechaFinal")}
                    required
                    className={classNames({
                      "p-invalid": submitted && !correlativo.fechaFinal,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="nombre">
                  {correlativo.id === 0
                    ? "Numero de contrato inicial"
                    : "Ultimo numero utilizado"}
                </label>

                <div className="field">
                  <InputText
                    id="nombre"
                    type="number"
                    value={correlativo.correlativo}
                    onChange={(e) => onInputChangeReg(e, "correlativo")}
                    required
                    disabled={correlativo.id === 0 ? "on" : ""}
                  />
                </div>
              </div>
              <div className="field col">
                <label htmlFor="nombre">Estado</label>

                <div className="formgrid grid">
                  <div className="field col">
                    <div className="field-radiobutton">
                      <RadioButton
                        value="A"
                        checked={correlativo?.estado === "A"}
                        onChange={(e) => onInputChangeReg(e, "estado")}
                        className={classNames({
                          "p-invalid": submitted && !correlativo.estado,
                        })}
                      />
                    </div>
                    <label htmlFor="option1">Activo</label>
                  </div>
                  <div className="field col">
                    <div className="field-radiobutton">
                      <RadioButton
                        value="I"
                        checked={correlativo?.estado === "I"}
                        onChange={(e) => onInputChangeReg(e, "estado")}
                        className={classNames({
                          "p-invalid": submitted && !correlativo.estado,
                        })}
                      />
                    </div>
                    <label htmlFor="option1">Inactivo</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Start button save and delete  */}
          <Messages ref={message} />
          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={hideDialog}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => save()}
                />
              </div>
            </div>
          </div>

          {/* finish button save and delete  */}
        </Dialog>
        <Toast ref={toast} />
      </div>
    </div>
  );
};
