import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { RadioButton } from "primereact/radiobutton";
//servicios
import GarantiaAhorroService from "./../../../../service/AppService/GarantiaAhorroService";
import FiadorService from "../../../../service/AppService/FiadorService";

export const Ahorro = ({ toast }) => {
  const message = useRef();
  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let ahorroEmpy = {
    ahoConId: 0,
    ahoMonto: 0,
    fecha: "",
    id: 0,
    lugarDeAhorro: "",
    nombrePerEncargada: "N",
    numCertificado: 0,
    numCueta: 0,
    fia: null,
    porcentaje: "",
  };
  const [ahorro, setAhorro] = useState(ahorroEmpy);
  const [ahorros, setAhorros] = useState([]);
  const [nuevoAhorro, setNuevoAhorro] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [fiadores, setFiadores] = useState([]);

  useEffect(() => {
    const fiadorService = new FiadorService();
    const garantiaAhorroService = new GarantiaAhorroService();
    garantiaAhorroService.findByConId(contrato1?.id || 0).then((response) => {
      setAhorros(response);
    });

    fiadorService.finByIdCon(contrato1?.id || 0).then((response) => {
      setFiadores(response);
    });
  }, []);

  const onHide = () => {
    setEliminar(false);
    setSubmitted(false);
    setNuevoAhorro(false);
    setAhorro(ahorroEmpy);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onClick = () => {
    setNuevoAhorro(true);
  };

  const changeAhorro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _ahorro = { ...ahorro };
    _ahorro[`${name}`] = val;
    setAhorro(_ahorro);
  };

  const save = () => {
    setSubmitted(true);
    let _ahorro = { ...ahorro };

    if (ahorro.ahoConId === 0) {
      _ahorro["ahoConId"] = contrato1.id;
    }
    if (
      ahorro.ahoMonto !== 0 &&
      ahorro.lugarDeAhorro !== 0 &&
      ahorro.numCueta !== 0 &&
      ahorro.numCertificado !== 0
    ) {
      const garantiaAhorroService = new GarantiaAhorroService();
      garantiaAhorroService.save(_ahorro).then(() => {
        if (ahorro.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia de ahorro añadida",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia de ahorro actualizado",
            life: 4000,
          });
        }
        setNuevoAhorro(false);
        setSubmitted(false);
        setAhorro(ahorroEmpy);

        garantiaAhorroService
          .findByConId(contrato1?.id || 0)
          .then((response) => {
            setAhorros(response);
          });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const onEdit = (rowData) => {
    setNuevoAhorro(true);
    setAhorro(rowData);
  };
  const eliminarT = (rowData) => {
    setNuevoAhorro(true);
    setAhorro(rowData);
    setEliminar(true);
  };

  const confirmDelete = () => {
    const garantiaAhorroService = new GarantiaAhorroService();
    garantiaAhorroService.dele(ahorro.id).then(() => {
      setNuevoAhorro(false);
      setAhorro(ahorroEmpy);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Garantia eliminada",
        life: 4000,
      });

      garantiaAhorroService.findByConId(contrato1?.id || 0).then((response) => {
        setAhorros(response);
      });
    });
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
          disabled={contrato1.imp == "S" ? "on" : ""}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp == "S" ? "on" : ""}
        />
      </>
    );
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _ahorro = { ...ahorro };
    _ahorro[`${name}`] = val;

    _ahorro[`${key}`] = value;

    setAhorro(_ahorro);
  };

  const pertenece = (rowData) => {
    return <>{rowData.nombrePerEncargada == "S" ? "Deudor" : "Fiador"}</>;
  };
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              {" "}
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                  disabled={contrato1.imp == "S" ? "on" : ""}
                />
              </div>
            </div>

            <DataTable
              value={ahorros}
              responsiveLayout="scroll"
              emptyMessage="No se encontro ninguna garantia de ahorro"
            >
              <Column field="lugarDeAhorro" header="Lugar de ahorro "></Column>
              <Column
                body={pertenece}
                header="Aquien le pertence la garantia"
              ></Column>
              <Column field="numCueta" header="Numero de certificado"></Column>
              <Column field="numCertificado" header="Numero de cuenta"></Column>

              <Column
                field="ahoMonto"
                header="Monto de la cuenta de ahorro"
              ></Column>
              <Column body={accciones} header="Acciones"></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        header={
          ahorro.id === 0
            ? "Nueva garantia de ahorro"
            : eliminar === true
            ? "Eliminar garantia de ahorro"
            : "Editar garantia de ahorro"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={nuevoAhorro}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label>Lugar de ahorro</label>
              <InputText
                id="Aquin le pertenece"
                type="text"
                placeholder="Ejmplo: Fondos R.L"
                disabled={eliminar === true ? "on" : ""}
                value={ahorro.lugarDeAhorro}
                autoComplete="off"
                onChange={(e) => changeAhorro(e, "lugarDeAhorro")}
                className={classNames({
                  "p-invalid": submitted && !ahorro.lugarDeAhorro,
                })}
              />

              {submitted && !ahorro.lugarDeAhorro && (
                <small className="p-invalid">
                  Se necesita un lugar de ahorro
                </small>
              )}
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="description">Fiador</label>

              <Dropdown
                value={ahorro.fiadorContrato}
                options={fiadores}
                onChange={(e) =>
                  onInputChangeRegObject(e, "fiadorContrato", e.value.id, "fia")
                }
                optionLabel="persona.nombreCompleto"
                placeholder="Seleccione un fiador"
                required
                className={classNames({
                  "p-invalid": submitted && !ahorro.fiadorContrato,
                })}
                disabled={
                  eliminar === true
                    ? "on"
                    : contrato1.fiador === "N"
                    ? "on"
                    : contrato1.fiadorGarantia === "N"
                    ? "on"
                    : ahorro.nombrePerEncargada == "S"
                    ? "on"
                    : ""
                }
              />
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label htmlFor=" Genero"> Garantia del deudor</label>
              <div className="formgrid grid">
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="S"
                      checked={ahorro.nombrePerEncargada == "S"}
                      onChange={(e) => changeAhorro(e, "nombrePerEncargada")}
                      disabled={eliminar === true ? "on" : ""}
                    />
                    <label htmlFor="option1">Si</label>
                  </div>
                </div>
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="N"
                      checked={ahorro.nombrePerEncargada == "N"}
                      onChange={(e) => changeAhorro(e, "nombrePerEncargada")}
                      disabled={eliminar === true ? "on" : ""}
                    />
                    <label htmlFor="option1">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="field col">
              <label>Numero de certificado</label>
              <InputText
                id="Numero de cuenta"
                type="number"
                disabled={eliminar === true ? "on" : ""}
                value={ahorro.numCueta}
                autoComplete="off"
                onChange={(e) => changeAhorro(e, "numCueta")}
                className={classNames({
                  "p-invalid": submitted && !ahorro.numCueta,
                })}
              />

              {submitted && !ahorro.numCueta && (
                <small className="p-invalid">Se necesita un certificado</small>
              )}
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label>Nmero de cuenta</label>
              <InputText
                id="Aquin le pertenece"
                type="number"
                disabled={eliminar === true ? "on" : ""}
                value={ahorro.numCertificado}
                autoComplete="off"
                onChange={(e) => changeAhorro(e, "numCertificado")}
                className={classNames({
                  "p-invalid": submitted && !ahorro.numCertificado,
                })}
              />

              {submitted && !ahorro.numCertificado && (
                <small className="p-invalid">
                  Se necesita el numero de cuenta
                </small>
              )}
            </div>

            <div className="field col">
              <label>Monto de la cuenta</label>
              <InputText
                id="Monto de la cuenta"
                type="number"
                disabled={eliminar === true ? "on" : ""}
                value={ahorro.ahoMonto}
                autoComplete="off"
                onChange={(e) => changeAhorro(e, "ahoMonto")}
                className={classNames({
                  "p-invalid": submitted && !ahorro.ahoMonto,
                })}
              />

              {submitted && !ahorro.ahoMonto && (
                <small className="p-invalid">
                  Se necesita el monto que tiene la cuenta
                </small>
              )}
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label>De fecha</label>
              <InputText
                id="Aquin le pertenece"
                type="date"
                disabled={eliminar === true ? "on" : ""}
                value={ahorro.fecha}
                autoComplete="off"
                onChange={(e) => changeAhorro(e, "fecha")}
                className={classNames({
                  "p-invalid": submitted && !ahorro.fecha,
                })}
              />
            </div>

            <div className="field col">
              <label>Porcentaje que cubre la garantia</label>
              <span className="p-input-icon-right">
                <i>%</i>
                <InputText
                  id="Monto de la cuenta"
                  type="number"
                  disabled={eliminar === true ? "on" : ""}
                  value={ahorro.porcentaje}
                  autoComplete="off"
                  onChange={(e) => changeAhorro(e, "porcentaje")}
                />
              </span>
            </div>
          </div>

          <Messages ref={message} />
          <div
            className="card p-fluid"
            style={{ display: eliminar === true ? "none" : "" }}
          >
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={onHide}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => save()}
                />
              </div>
            </div>
          </div>

          <div
            className="card p-fluid"
            style={{ display: eliminar !== true ? "none" : "" }}
          >
            <div className="grid formgrid">
              <div className="col">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={onHide}
                />
              </div>
              <div className="col">
                <Button
                  type="button"
                  label="Confirmar"
                  icon="pi pi-trash"
                  className="p-button-warning"
                  onClick={() => confirmDelete()}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
