import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import { ComponentToPrint } from "./pages/ComponentToPrint";
import AppWrapper from "./AppWrapper";
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <HashRouter>
    <AppWrapper></AppWrapper>
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
