import React, { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import font from "./ArialNarrow2.ttf";
import fontBold from "./ArialNarrowBold.ttf";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";

//services
import PersonaService from "../../service/AppService/PersonaService";
import AmortizacionService from "../../service/AppService/AmortizacionService";
import DesembolsoService from "../../service/AppService/DesembolsoService";
import AhorroService from "../../service/AppService/GarantiaAhorroService";
import SueldoService from "../../service/AppService/GarantiaSueldo";
import BienService from "../../service/AppService/GarantiaBienInmueble";
import FiadorService from "../../service/AppService/FiadorService";
import TestigoService from "../../service/AppService/TestigoService";

export const VisualizarDoc = () => {
  const toast = useRef();
  const [fonT, setFonT] = useState(12);
  const [interlineado, setInterlineado] = useState(2);
  Font.register({ family: "Arial Narrow", src: font });
  Font.register({ family: "Arial Narrow Bold", src: fontBold });

  const styles = StyleSheet.create({
    body: {
      paddingTop: "1.25cm",
      paddingBottom: "3.6cm",
      paddingLeft: "4.6cm",
      paddingRight: "1.4cm",
    },

    line: {
      background: "#fff",
      zIndex: 100,
      position: "relative",
      padding: "15px",
    },

    body2: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingLeft: 50,
      paddingRight: 150,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Arial Narrow",
    },

    text: {
      margin: 12,
      fontSize: fonT,
      textAlign: "justify",
      fontFamily: "Arial Narrow",
      lineHeight: interlineado,
    },

    text1: {
      fontSize: fonT,
      textAlign: "justify",
      fontFamily: "Arial Narrow",
      lineHeight: interlineado,
      margin: 2,
    },

    textL: {
      marginBottom: "5.5cm",
      fontSize: fonT,
      textAlign: "center",
      fontFamily: "Arial Narrow",
      lineHeight: "2",
    },
    header2: {
      marginBottom: 150,
      fontSize: 11,
      textAlign: "right",
      fontFamily: "Arial Narrow",
      lineHeight: "2",

      color: "#90B1DB",
    },
    header: {
      fontSize: 9.5,
      textAlign: "center",
      color: "black",
      fontFamily: "Arial Narrow Bold",
    },
    header1: {
      fontSize: fonT,
      textAlign: "center",
      color: "black",
      fontFamily: "Arial Narrow",
      color: "#90B1DB",
    },
    pageNumber: {
      bottom: 30,
      position: "absolute",
      fontSize: 12,

      left: 0,
      right: 50,
      textAlign: "right",
      color: "black",
      fontFamily: "Arial Narrow Bold",
    },

    negrita: {
      fontSize: fonT,
      lineHeight: interlineado,
      fontFamily: "Arial Narrow Bold",
    },

    negritaSub: {
      fontFamily: "Arial Narrow Bold",
      textDecoration: "underline",
    },
    firma: {
      fontFamily: "Arial Narrow Bold",
      fontSize: fonT,
      margin: 1,
    },

    negritaLinea: {
      fontFamily: "Arial Narrow Bold",
      fontSize: fonT,
      margin: 1,
    },
  });

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let perEmty = {
    apellidoCasada: "",
    deudor: "",
    emId: 0,
    empres: {
      estado: "",
      id: 0,
      nombre: "",
      nombreAbreviado: "",
      registroInacop: [
        {
          empId: 0,
          estad: "",
          fechaIns: "",
          folio: 0,
          id: 0,
          libro: 0,
          registro: 0,
        },
      ],
    },
    estado: "",
    estadoCivil: "",
    fechaNacimiento: "",
    id: 0,
    nacionalidad: "",
    nombreCompleto: "",
    notario: "",
    numeroDpi: "",
    otroNombre: "",
    primerApellido: "",
    primerNombre: "",
    profecion: "",
    representanteLegal: "",
    segundoApellido: "",
    segundoNombre: "",
    sexo: "",
    direccion: [
      {
        descripnDireccion: "",
        id: 0,
        inmId: 0,
        munID: 0,
        municipio: {
          depId: 0,
          departamento: {
            descripcion: "",
            id: 0,
          },
          descripcion: "",
          id: 0,
        },
        persona: 0,
        tipo: "",
      },
    ],
  };

  const [contrato, setScontrato] = useState(contrato1);
  const [representante, setRepresentante] = useState(perEmty);
  const [notario, setNotario] = useState(perEmty);
  const [persona, setPersona] = useState(perEmty);
  const [amortizacion, setAmortizacion] = useState([]);
  const [desembolso, setDesembolso] = useState([]);
  const [ahorro, setAhorro] = useState([]);
  const [sueldo, setSueldo] = useState([]);
  const [bien, setBien] = useState([]);

  const [fiador, setFiador] = useState([]);
  const [fiador1, setFiador1] = useState(perEmty);
  const [testigo, setTestigo] = useState([]);

  useEffect(() => {
    const personaSerive = new PersonaService();
    const amortizacion = new AmortizacionService();
    const desembolso = new DesembolsoService();
    const ahorroservice = new AhorroService();
    const sueldoService = new SueldoService();
    const bienService = new BienService();
    const fiadoresService = new FiadorService();
    const testigoService = new TestigoService();

    personaSerive.findById(contrato?.idRepresentante || 0).then((res) => {
      setRepresentante(res);
    });

    personaSerive.findById(contrato?.idNotario || 0).then((response) => {
      setNotario(response);
    });

    personaSerive.findById(contrato?.perId || 0).then((response) => {
      setPersona(response);
    });
    amortizacion.findByConId(contrato?.id).then((response) => {
      setAmortizacion(response);
    });

    desembolso.findByIdCon(contrato?.id).then((response) => {
      setDesembolso(response);
    });

    ahorroservice.findByConId(contrato?.id).then((response) => {
      setAhorro(response);
    });
    sueldoService.findByConId(contrato?.id).then((response) => {
      setSueldo(response);
    });

    bienService.findByConId(contrato1?.id || 0).then((response) => {
      setBien(response);
    });

    fiadoresService.finByIdCon(contrato1?.id || 0).then((response) => {
      setFiador(response);
      setFiador1(response[0]?.persona || perEmty);
    });
    testigoService.findByConId(contrato1?.id || 0).then((response) => {
      setTestigo(response);
    });
  }, []);

  function calcularEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    var edad1 = edadLetras(edad);
    return edad1;
  }

  function edadLetras(n) {
    var num = parseInt(n * 100 + "");

    var centavos = num % 100;
    var numero = parseInt(n + "");

    var respuesta = "";

    if (numero == 100) {
      respuesta = " cien";
    } else if (numero > 100) {
      var cen = parseInt(numero / 100);
      var dec = numero % 100;

      respuesta = " " + centenas(cen) + numeroALetras(dec);
    } else {
      var dec = numero % 100;

      if (dec < 20) {
        respuesta = " " + dias(dec);
      } else {
        var unis = dec % 10;
        var ddec = parseInt(dec / 10);

        if (unis != 0) {
          respuesta = " " + decenas(ddec) + " y " + dias(unis);
        } else {
          respuesta = " " + decenas(ddec);
        }
      }
    }

    return respuesta;
  }

  const onChangeF = (e, name) => {
    const val = (e.target && e.target.value) || "";

    /* 8=3
9=2.7
10=2.4
11=2.2
12=2
13 = 1.8
14=1.7
15=1.6
16=1.5
17=1.4
18=1.3
19=1.25
20=1.18*/

    if (val >= 8 && val <= 20) {
      switch (val) {
        case "8":
          setInterlineado(3);
          setFonT(val);
          console.log("dentro del case");
          break;
        case "9":
          setInterlineado(2.7);
          setFonT(val);
          break;
        case "10":
          setInterlineado(2.4);
          setFonT(val);
          break;
        case "11":
          setInterlineado(2.2);
          setFonT(val);
          break;
        case "12":
          setInterlineado(2);
          setFonT(val);
          break;
        case "13":
          setInterlineado(1.8);
          setFonT(val);
          break;
        case "14":
          setInterlineado(1.7);
          setFonT(val);
          break;
        case "15":
          setInterlineado(1.6);
          setFonT(val);
          break;
        case "16":
          setInterlineado(1.5);
          setFonT(val);
          break;
        case "17":
          setInterlineado(1.4);
          setFonT(val);
          break;
        case "18":
          setInterlineado(1.3);
          setFonT(val);
          break;
        case "19":
          setInterlineado(1.25);
          setFonT(val);
          break;
        case "20":
          setInterlineado(1.18);
          setFonT(val);
          break;
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "El tamaño disponible es de 8 a 20",
        life: 4000,
      });
    }
  };

  ///////////////////////////

  function dias(n) {
    if (n + "" == "1") {
      return "un";
    }
    if (n + "" == "2") {
      return "dos";
    }
    if (n + "" == "3") {
      return "tres";
    }
    if (n + "" == "4") {
      return "cuatro";
    }
    if (n + "" == "5") {
      return "cinco";
    }
    if (n + "" == "6") {
      return "seis";
    }
    if (n + "" == "7") {
      return "siete";
    }
    if (n + "" == "8") {
      return "ocho";
    }
    if (n + "" == "9") {
      return "nueve";
    }

    if (n + "" == "10") {
      return "diez";
    }
    if (n + "" == "11") {
      return "once";
    }
    if (n + "" == "12") {
      return "doce";
    }
    if (n + "" == "13") {
      return "trece";
    }
    if (n + "" == "14") {
      return "catorce";
    }
    if (n + "" == "15") {
      return "quince";
    }
    if (n + "" == "16") {
      return "dieciseis";
    }
    if (n + "" == "17") {
      return "diecisite";
    }
    if (n + "" == "18") {
      return "dieciocho";
    }
    if (n + "" == "19") {
      return "diecinueve";
    }

    return "";
  }

  function decenas(n) {
    if (n + "" == "1") {
      return "diez";
    }
    if (n + "" == "2") {
      return "veinte";
    }
    if (n + "" == "3") {
      return "treinta";
    }
    if (n + "" == "4") {
      return "cuartenta";
    }
    if (n + "" == "5") {
      return "cincuenta";
    }
    if (n + "" == "6") {
      return "sesenta";
    }
    if (n + "" == "7") {
      return "setenta";
    }
    if (n + "" == "8") {
      return "ochenta";
    }
    if (n + "" == "9") {
      return "noventa";
    }

    return "";
  }

  function centenas(n) {
    if (n + "" == "1") {
      return "ciento";
    }
    if (n + "" == "2") {
      return "docientos";
    }
    if (n + "" == "3") {
      return "trecientos";
    }
    if (n + "" == "4") {
      return "cuatrocientos";
    }
    if (n + "" == "5") {
      return "quinientos";
    }
    if (n + "" == "6") {
      return "seicientos";
    }
    if (n + "" == "7") {
      return "setencientos";
    }
    if (n + "" == "8") {
      return "ochocientos";
    }
    if (n + "" == "9") {
      return "novecientos";
    }

    return "";
  }

  function numeroALetras(n) {
    var num = parseInt(n * 100 + "");

    var centavos = num % 100;
    var numero = parseInt(n + "");

    var respuesta = "";

    if (numero > 999) {
      if ((numero + "").length > 6) {
        var residuo = parseInt(numero + "") % 1000000;
        var x = parseInt(numero / 1000000);

        if (x == 1) {
          respuesta = " un millon" + numeroALetras(residuo);
        } else {
          respuesta = numeroALetras(x) + " millones" + numeroALetras(residuo);
        }
      } else if ((numero + "").length > 3) {
        var residuo = parseInt(numero + "") % 1000;
        var x = parseInt(numero / 1000);

        if (x == 1) {
          respuesta = " mil" + numeroALetras(residuo);
        } else {
          respuesta = numeroALetras(x) + " mil" + numeroALetras(residuo);
        }
      }
    } else {
      if (numero == 100) {
        respuesta = " cien";
      } else if (numero > 100) {
        var cen = parseInt(numero / 100);
        var dec = numero % 100;

        respuesta = " " + centenas_nal(cen) + numeroALetras(dec);
      } else {
        var dec = numero % 100;

        if (dec < 30) {
          respuesta = " " + unidades_nal(dec);
        } else {
          var unis = dec % 10;
          var ddec = parseInt(dec / 10);

          if (unis != 0) {
            respuesta = " " + decenas_nal(ddec) + " y " + unidades_nal(unis);
          } else {
            respuesta = " " + decenas_nal(ddec);
          }
        }
      }
    }

    return respuesta;
  }
  function numeroALetrasDPI(n) {
    var num = parseInt(n * 100 + "");

    var centavos = num % 100;
    var numero = parseInt(n + "");

    var respuesta = "";

    if (numero > 999) {
      if ((numero + "").length > 6) {
        var residuo = parseInt(numero + "") % 1000000;
        var x = parseInt(numero / 1000000);

        if (x == 1) {
          respuesta = " un millon" + numeroALetrasDPI(residuo);
        } else {
          respuesta =
            numeroALetrasDPI(x) + " millones" + numeroALetrasDPI(residuo);
        }
      } else if ((numero + "").length > 3) {
        var residuo = parseInt(numero + "") % 1000;
        var x = parseInt(numero / 1000);

        if (x == 1) {
          respuesta = " un mil" + numeroALetrasDPI(residuo);
        } else {
          respuesta = numeroALetrasDPI(x) + " mil" + numeroALetrasDPI(residuo);
        }
      }
    } else {
      if (numero == 100) {
        respuesta = " cien";
      } else if (numero > 100) {
        var cen = parseInt(numero / 100);
        var dec = numero % 100;

        respuesta = " " + centenas_nal(cen) + numeroALetrasDPI(dec);
      } else {
        var dec = numero % 100;

        if (dec < 30) {
          respuesta = " " + unidades_nal(dec);
        } else {
          var unis = dec % 10;
          var ddec = parseInt(dec / 10);

          if (unis != 0) {
            respuesta = " " + decenas_nal(ddec) + " y " + unidades_nal(unis);
          } else {
            respuesta = " " + decenas_nal(ddec);
          }
        }
      }
    }

    return respuesta;
  }

  ///////////////////////////

  function unidades_nal(n) {
    if (n + "" == "1") {
      return "uno";
    }
    if (n + "" == "2") {
      return "dos";
    }
    if (n + "" == "3") {
      return "tres";
    }
    if (n + "" == "4") {
      return "cuatro";
    }
    if (n + "" == "5") {
      return "cinco";
    }
    if (n + "" == "6") {
      return "seis";
    }
    if (n + "" == "7") {
      return "siete";
    }
    if (n + "" == "8") {
      return "ocho";
    }
    if (n + "" == "9") {
      return "nueve";
    }

    if (n + "" == "10") {
      return "diez";
    }
    if (n + "" == "11") {
      return "once";
    }
    if (n + "" == "12") {
      return "doce";
    }
    if (n + "" == "13") {
      return "trece";
    }
    if (n + "" == "14") {
      return "catorce";
    }
    if (n + "" == "15") {
      return "quince";
    }
    if (n + "" == "16") {
      return "dieciseis";
    }
    if (n + "" == "17") {
      return "diecisite";
    }
    if (n + "" == "18") {
      return "dieciocho";
    }
    if (n + "" == "19") {
      return "diecinueve";
    }
    if (n + "" == "20") {
      return "veinte";
    }
    if (n + "" == "21") {
      return "veintiuno";
    }
    if (n + "" == "22") {
      return "veintidos";
    }
    if (n + "" == "23") {
      return "veintitres";
    }
    if (n + "" == "24") {
      return "veinticuatro";
    }
    if (n + "" == "25") {
      return "veinticinco";
    }
    if (n + "" == "26") {
      return "veintiseis";
    }
    if (n + "" == "27") {
      return "veintisiete";
    }
    if (n + "" == "28") {
      return "veintiocho";
    }
    if (n + "" == "29") {
      return "diecinueve";
    }

    return "";
  }

  function decenas_nal(n) {
    if (n + "" == "1") {
      return "diez";
    }
    if (n + "" == "2") {
      return "veinte";
    }
    if (n + "" == "3") {
      return "treinta";
    }
    if (n + "" == "4") {
      return "cuartenta";
    }
    if (n + "" == "5") {
      return "cincuenta";
    }
    if (n + "" == "6") {
      return "sesenta";
    }
    if (n + "" == "7") {
      return "setenta";
    }
    if (n + "" == "8") {
      return "ochenta";
    }
    if (n + "" == "9") {
      return "noventa";
    }

    return "";
  }

  function centenas_nal(n) {
    if (n + "" == "1") {
      return "ciento";
    }
    if (n + "" == "2") {
      return "docientos";
    }
    if (n + "" == "3") {
      return "trecientos";
    }
    if (n + "" == "4") {
      return "cuatrocientos";
    }
    if (n + "" == "5") {
      return "quinientos";
    }
    if (n + "" == "6") {
      return "seicientos";
    }
    if (n + "" == "7") {
      return "setencientos";
    }
    if (n + "" == "8") {
      return "ochocientos";
    }
    if (n + "" == "9") {
      return "novecientos";
    }

    return "";
  }

  /////////////////

  function numerosAMeses(n) {
    if (n + "" == "1") {
      return "enero";
    }
    if (n + "" == "2") {
      return "febrero";
    }
    if (n + "" == "3") {
      return "marzo";
    }
    if (n + "" == "4") {
      return "abril";
    }
    if (n + "" == "5") {
      return "mayo";
    }
    if (n + "" == "6") {
      return "junio";
    }
    if (n + "" == "7") {
      return "julio";
    }
    if (n + "" == "8") {
      return "agosto";
    }
    if (n + "" == "9") {
      return "septiembre";
    }
    if (n + "" == "10") {
      return "octubre";
    }
    if (n + "" == "11") {
      return "noviembre";
    }
    if (n + "" == "12") {
      return "diciembre";
    }

    return "";
  }

  function CDate(date1) {
    var date = date1;

    var arr1 = date.split("-");
    var arr2;

    if (arr1[2] < 10) {
      arr2 = arr1[2].split("0");

      arr1[2] = arr2[1];
    }

    if (arr1[1] < 10) {
      arr2 = arr1[1].split("0");

      arr1[1] = arr2[1];
    }

    var dia = numeroALetras(arr1[2]);
    var mes = numerosAMeses(arr1[1]);
    var ano = numeroALetras(arr1[0]);
    return ` el${dia} de ${mes} del año${ano}`;
  }

  function CDate1(date1) {
    var date = date1;

    var arr1 = date.split("-");
    var arr2;

    if (arr1[2] < 10) {
      arr2 = arr1[2].split("0");
      arr1[2] = arr2[1];
    }

    if (arr1[1] < 10) {
      arr2 = arr1[1].split("0");
      arr1[1] = arr2[1];
    }

    var dia = numeroALetras(arr1[2]);
    var mes = numerosAMeses(arr1[1]);
    var ano = numeroALetras(arr1[0]);
    return `${dia} de ${mes} del año${ano}`;
  }

  function CDate2(date1) {
    var date = date1;

    var arr1 = date.split("-");
    var arr2;

    if (arr1[2] < 10) {
      arr2 = arr1[2].split("0");
      arr1[2] = arr2[1];
    }

    if (arr1[1] < 10) {
      arr2 = arr1[1].split("0");
      arr1[1] = arr2[1];
    }

    var dia = numeroALetras(arr1[2]);
    var mes = numerosAMeses(arr1[1]);
    var ano = numeroALetras(arr1[0]);
    return ` a los ${dia} días del mes de ${mes} del año${ano}`;
  }

  function DPI(dpi) {
    var dp = dpi;
    var arr1 = dp.split(" ");
    var pri = numeroALetrasDPI(arr1[0]);
    var seg = numeroALetrasDPI(arr1[1]);
    var ter = numeroALetrasDPI(arr1[2]);

    if (arr1[2] < 999) {
      return `${capitalizarPrimeraLetra(pri)} espacio${seg} espacio cero${ter}`;
    } else {
      return `${capitalizarPrimeraLetra(pri)} espacio${seg} espacio${ter}`;
    }
  }

  function capitalizarPrimeraLetra(str) {
    return str.charAt(1).toUpperCase() + str.slice(2);
  }

  function residencia(tipo) {
    for (var i = 0; i < representante.direccion.length; i++) {
      if (representante.direccion[i].tipo == tipo) {
        return (
          representante.direccion[i].descripnDireccion +
          " del municipio de " +
          representante.direccion[i].municipio.descripcion +
          ", departamento de " +
          representante.direccion[i].municipio.departamento.descripcion
        );
      }
    }
  }
  function residenciaP(tipo) {
    for (var i = 0; i < persona.direccion.length; i++) {
      if (persona.direccion[i].tipo == tipo) {
        return (
          persona.direccion[i].descripnDireccion +
          " del municipio de " +
          persona.direccion[i].municipio.descripcion +
          ", departamento de " +
          persona.direccion[i].municipio.departamento.descripcion
        );
      }
    }
  }

  function residenciaF(tipo, per) {
    for (var i = 0; i < persona.direccion.length; i++) {
      if (fiador[per].persona.direccion[i].tipo == tipo) {
        return (
          fiador[per].persona.direccion[i].descripnDireccion +
          " del municipio de " +
          fiador[per].persona.direccion[i].municipio.descripcion +
          ", departamento de " +
          fiador[per].persona.direccion[i].municipio.departamento.descripcion
        );
      }
    }
  }

  function registro(condicion) {
    for (var i = 0; i < representante.empresa?.registroInacop.length; i++) {
      if (
        (representante.empresa.registroInacop[i].estado == "A") &
        (condicion == 1)
      ) {
        return numeroALetras(representante.empresa.registroInacop[i].registro);
      } else if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 0
      ) {
        return representante.empresa.registroInacop[i].registro;
      }
    }
  }

  function folio(condicion) {
    for (var i = 0; i < representante.empresa?.registroInacop.length; i++) {
      if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 1
      ) {
        return numeroALetras(representante.empresa.registroInacop[i].folio);
      } else if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 0
      ) {
        return representante.empresa.registroInacop[i].folio;
      }
    }
  }

  function libro(condicion) {
    for (var i = 0; i < representante.empresa?.registroInacop.length; i++) {
      if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 1
      ) {
        var arr1 = representante.empresa.registroInacop[i].libro.split(" ");

        var pri = numeroALetras(arr1[0].slice(1));
        var seg = numeroALetras(arr1[1]);

        return (
          representante.empresa.registroInacop[i].libro.charAt(0) +
          " " +
          capitalizarPrimeraLetra(pri) +
          seg
        );
      } else if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 0
      ) {
        return representante.empresa.registroInacop[i].libro;
      }
    }
  }

  function dateR(condicion) {
    for (var i = 0; i < representante.empresa?.registroInacop.length; i++) {
      if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 1
      ) {
        return CDate1(representante.empresa.registroInacop[i].fechaIns);
      } else if (
        representante.empresa.registroInacop[i].estado == "A" &&
        condicion == 0
      ) {
        return representante.empresa.registroInacop[i].libro;
      }
    }
  }

  function estadoSivil(es, sex) {
    if (es == "S" && sex == "M") {
      return "soltero";
    }
    if (es == "C" && sex == "M") {
      return "casado";
    }
    if (es == "S" && sex == "F") {
      return "soltera";
    }
    if (es == "C" && sex == "F") {
      return "casada";
    }
  }

  function resolucionCredito() {
    try {
      var arry = contrato.resolucionCredito.split("-");
      var arry2 = contrato.resolucionCredito.split("/");
      var arry3 = arry2[0].split("-");

      let seg = arry[1];
      var dia;
      var mes;
      var ano;
      var num;
      if (seg[2] == 0 && seg[0] == 0) {
        dia = numeroALetras(seg[1]);
        mes = numerosAMeses(seg[3]);
        ano = numeroALetras(arry2[1]);
      } else if (seg[2] == 0) {
        var dia1 = seg[0] + seg[1];
        dia = numeroALetras(dia1);
        mes = numerosAMeses(seg[3]);
        ano = numeroALetras(arry2[1]);
      } else if (seg[0] == 0) {
        var mes1 = seg[2] + seg[3];
        dia = numeroALetras(seg[1]);
        mes = numerosAMeses(mes1);
        ano = numeroALetras(arry2[1]);
      } else {
        var dia1 = seg[0] + seg[1];
        var mes1 = seg[2] + seg[3];
        dia = numeroALetras(dia1);
        mes = numerosAMeses(mes1);
        ano = numeroALetras(arry2[1]);
      }
      if (arry3[1] < 999) {
        num = " cero" + numeroALetras(arry3[1]);
      } else {
        num = numeroALetras(arry3[1]);
      }
      var proto = (
        <Text>
          número:{" "}
          <Text style={styles.negrita}>
            {arry[0]} GUION {num.toUpperCase()} DIAGONAL
            {numeroALetras(arry2[1]).toUpperCase()} (
            {contrato.resolucionCredito}
            ),
          </Text>
        </Text>
      );

      var fecha =
        "de fecha " +
        dia +
        " de " +
        mes +
        " del año " +
        ano +
        ", número " +
        arry[0] +
        " guion" +
        num +
        " diagonal" +
        numeroALetras(arry2[1]);

      if (contrato.tipoDocId == "1") {
        if (arry[0] == "RGAS") {
          return "resolución Gerente de Agencia Sololá " + fecha;
        }
        if (arry[0] == "RGAN") {
          return "resolución Gerente de Agencia Nauala " + fecha;
        }
        if (arry[0] == "RGAP") {
          return "resolución Gerente de Agencia Panajachel " + fecha;
        }
        if (arry[0] == "RGAR") {
          return "resolución Gerente de Agencia Roosevelt " + fecha;
        }
        if (arry[0] == "RGG") {
          return "resolución Gerente General " + fecha;
        }
        if (arry[0] == "RCC") {
          return "resolución Comite de Crédito " + fecha;
        }
        if (arry[0] == "RGA") {
          return "resolución Gerente de Agencia " + fecha;
        }
      } else {
        return proto;
      }
    } catch (error) {}
  }

  function montoL(monto) {
    if (Number.isInteger(monto) == true) {
      return numeroALetras(monto) + " quetzales exactos";
    } else {
      var monto1 = `${monto}`;
      var mon = monto1.split(".");
      var mon1 = numeroALetras(mon[0]);
      var mon2 = numeroALetras(mon[1]);
      return mon1 + " quetzales con" + mon2 + " centavos";
    }
  }

  function porcentaje(porcentaje) {
    if (Number.isInteger(porcentaje) == true) {
      return numeroALetras(porcentaje) + " por ciento";
    } else {
      var monto1 = `${porcentaje}`;
      var mon = monto1.split(".");
      var mon1 = numeroALetras(mon[0]);
      var mon2 = numeroALetras(mon[1]);
      return mon1 + " punto" + mon2 + " por ciento";
    }
  }

  function extencion1(medida) {
    if (Number.isInteger(medida)) {
      return `${numeroALetras(medida).toLowerCase()} metros (${formatMiles(
        medida
      )} Mts.)`;
    } else {
      var monto1 = `${medida}`;
      var arr1 = monto1.split(".");
      return `${numeroALetras(
        arr1[0]
      ).toLowerCase()} mectros con ${numeroALetras(
        arr1[1]
      ).toLowerCase()} centrimetros (${formatMiles(medida)} Mts.)`;
    }
  }

  function extencion(medida) {
    if (Number.isInteger(medida)) {
      return `${numeroALetras(
        medida
      ).toUpperCase()} METROS CUADRADOS (${formatMiles(medida)}  Mts.²)`;
    } else {
      var monto1 = `${medida}`;
      var arr1 = monto1.split(".");
      return `${numeroALetras(
        arr1[0]
      ).toUpperCase()} METROS CUADRADOS CON ${numeroALetras(
        arr1[1]
      ).toUpperCase()} CENTIMETROS (${formatMiles(medida)}  Mts.²)`;
    }
  }

  function vencimient(fecha, date) {
    var años = fecha / 12;
    var años1 = Math.round(años);
    var arr1 = date.split("-");
    var dia;
    var mes;
    var ano;
    if (años >= 1) {
      var años2 = Number(arr1[0]) + años1;
      var mes1 = Number(arr1[1]) + fecha - años1 * 12;
      dia = numeroALetras(arr1[2]);
      mes = numerosAMeses(mes1);
      ano = numeroALetras(años2);
    } else {
      var mes1 = Number(arr1[1]) + fecha;

      if (mes1 > 12) {
        var mes2 = Math.round(mes1 / 12);
        var años2 = Number(arr1[0]) + mes2;
        var mes3 = mes1 - mes2 * 12;

        dia = numeroALetras(arr1[2]);
        mes = numerosAMeses(mes3);
        ano = numeroALetras(años2);
      } else {
        dia = numeroALetras(arr1[2]);
        mes = numerosAMeses(mes1);
        ano = numeroALetras(arr1[0]);
      }
    }

    return dia + " de " + mes + " del año" + ano;
  }

  function amortizacionM() {
    try {
      if (amortizacion.length > 1) {
        var resMa = "";
        var resMe = "";
        for (var i = 0; i < amortizacion.length; i++) {
          if (amortizacion[i].cantidadAmorizaciones <= 1) {
            resMe =
              "una " +
              `(${amortizacion[i].cantidadAmorizaciones}) última amortizacion de: ` +
              montoL(amortizacion[i].monto).toUpperCase() +
              ` (Q. ${formatMiles(amortizacion[i].monto)})`;
          } else {
            resMa =
              numeroALetras(amortizacion[i].cantidadAmorizaciones) +
              ` (${
                amortizacion[i].cantidadAmorizaciones
              }) amortizaciones ${frecuenciaPago()} de` +
              montoL(amortizacion[i].monto).toUpperCase() +
              ` (Q. ${formatMiles(amortizacion[i].monto)}) cada una`;
          }
        }

        if (resMa !== "" && resMe !== "") {
          return resMa + " y " + resMe;
        }
      } else {
        var i = amortizacion[0]?.cantidadAmorizaciones || 0;
        var frecuencia;

        switch (contrato?.frecuenciaPago) {
          case 1:
            frecuencia = "semanal";
            break;
          case 2:
            frecuencia = "quincenal";
            break;
          case 3:
            frecuencia = "mensual";
            break;
          case 4:
            frecuencia = "trimestral";
            break;
          case 5:
            frecuencia = "semestral";
            break;
          case 6:
            frecuencia = "anaul";
            break;
        }

        if (i == 1) {
          return (
            "una " +
            `(${amortizacion[0].cantidadAmorizaciones}) amortizacion ${frecuencia} de: ` +
            montoL(amortizacion[0].monto).toUpperCase() +
            ` (Q. ${formatMiles(amortizacion[0].monto)})`
          );
        } else {
          return (
            numeroALetras(amortizacion[0].cantidadAmorizaciones) +
            ` (${
              amortizacion[0].cantidadAmorizaciones
            }) amortizaciones ${frecuenciaPago()} de` +
            montoL(amortizacion[0].monto).toUpperCase() +
            ` (Q. ${formatMiles(amortizacion[0].monto)}) cada una`
          );
        }
      }
    } catch (error) {}
  }

  function frecuenciaPago() {
    switch (contrato?.frecuenciaPago) {
      case 1:
        return "semanales";
        break;
      case 2:
        return "quincenales";
        break;
      case 3:
        return "mensuales";
        break;
      case 4:
        return "trimestrales";
        break;
      case 5:
        return "semestrales";
        break;
      case 6:
        return "anuales";
        break;
    }
  }

  function pago(date1) {
    var date = date1;
    var frecuencia;

    var arr1 = date.split("-");
    var arr2;

    if (arr1[2] < 10) {
      arr2 = arr1[2].split("0");
      arr1[2] = arr2[1];
    }

    if (arr1[1] < 10) {
      arr2 = arr1[1].split("0");
      arr1[1] = arr2[1];
    }

    var dia = numeroALetras(arr1[2]);
    var mes = numerosAMeses(arr1[1]);
    var ano = numeroALetras(arr1[0]);

    switch (contrato?.frecuenciaPago) {
      case 1:
        frecuencia = "semana";
        break;
      case 2:
        frecuencia = "quincena";
        break;
      case 3:
        frecuencia = "mes";
        break;
      case 4:
        frecuencia = "trimestre";
        break;
      case 5:
        frecuencia = "semestre";
        break;
      case 6:
        frecuencia = "año";
        break;
    }

    return `${dia.toUpperCase()} de daca ${frecuencia}, a partir del mes de ${mes} del año${ano}`;
  }
  function cantD() {
    try {
      if (desembolso.length > 1) {
        return `${numeroALetras(
          desembolso.length
        )} desembolsos de la forma siguiente: `;
      } else {
        return "un solo desembolso de: ";
      }
    } catch (error) {}
  }

  function desembolsos() {
    try {
      if (contrato.tipoDocId == "1") {
        if (desembolso.length > 1) {
          var resMa = "";
          var resMe = "";
          var resM1 = "";
          var resM2 = "";
          var resM3 = "";
          for (var i = 0; i < desembolso.length; i++) {
            var dato = "";
            dato =
              montoL(desembolso[i].monto).toUpperCase() +
              ` (Q. ${formatMiles(desembolso[i].monto)})`;

            switch (i) {
              case 0:
                resMa = dato;
                break;
              case 1:
                resMe = dato;
                break;
              case 2:
                resM1 = dato;
                break;
              case 3:
                resM2 = dato;
                break;
              case 4:
                resM3 = dato;
                break;
            }
          }

          return `a) un primer desembolso por el monto de:${resMa} b) el segundo desembolso por el monto de:${resMe}${
            resM1 !== ""
              ? " c) el tercer desembolso por el monto de:" + resM1
              : ""
          } ${
            resM2 !== ""
              ? " d) el cuarto desembolso por el monto de:" + resM2
              : ""
          } ${
            resM3 !== ""
              ? " e) el quinto desembolso por el monto de:" + resM3
              : ""
          }`;
        } else {
          return (
            montoL(desembolso[0]?.monto).toUpperCase() +
            ` (Q. ${formatMiles(desembolso[0]?.monto)})`
          );
        }
      } else {
        for (var i = 0; i < desembolso.length; i++) {
          if (desembolso.length > 1) {
            return `${numeroALetras(
              desembolso.length
            ).toLowerCase()} desembolsos`;
          } else {
            return " un solo desembolso";
          }
        }
      }
    } catch (error) {}
  }

  function ahorroLetra(cuenta) {
    var resultado = "";
    var cuentaIndividual = cuenta.split("");

    for (var i = 0; i < cuenta.length; i++) {
      resultado += numero(cuentaIndividual[i]) + " ";
    }

    return resultado;
  }

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  function garantia() {
    try {
      var retornar = "";

      var retornar1 = "";
      var retornar2 = "";
      var retornar3 = "";
      var retornar4 = "";
      var retornar5 = "";
      var retornar6 = "";
      var retornar7 = "";
      var retornar8 = "";
      var retornar9 = "";

      if (ahorro.length > 0) {
        for (var i = 0; i < ahorro.length; i++) {
          var dato = "";

          if (ahorro[i].nombrePerEncargada == "S") {
            dato = (
              <Text>
                el <Text style={styles.negrita}>AHORRO A PLAZO FIJO</Text> que
                poseo en {ahorro[i].lugarDeAhorro}, lo que demuestro con el{" "}
                <Text style={styles.negrita}>
                  CERTIFICADO DE DEPÓSITO DE AHORRO A PLAZO FIJO,
                </Text>{" "}
                Número: {numeroALetras(ahorro[i].numCueta)} (
                {ahorro[i].numCueta}) Cuenta número:{" "}
                {ahorroLetra(ahorro[i].numCertificado)} (
                {ahorro[i].numCertificado}) a mi nombre:
                <Text style={styles.negrita}>
                  {" "}
                  {persona.nombreCompleto.toUpperCase()}
                </Text>{" "}
                , por el monto de: {montoL(ahorro[i].ahoMonto).toUpperCase()}{" "}
                (Q. {formatMiles(ahorro[i].ahoMonto)}), de fecha:{" "}
                {CDate1(ahorro[i].fecha)} ({formatDate(ahorro[i].fecha)}),
                certificado que adjunto al presente contrato, así mismo
                manifiesto{" "}
                <Text style={styles.negrita}>
                  yo {persona.nombreCompleto.toUpperCase()}
                </Text>{" "}
                expresamente en dejarlo como garantía para el presente contrato
                de mutuo a favor de{" "}
                <Text style={styles.negrita}>
                  "{representante.empresa?.nombreAbreviado.toUpperCase()}",
                </Text>{" "}
                y que por éste contrato autorizo a la entidad acreedora a que se
                trabe embargo sobre el porcentaje legal de mi Ahorro A Plazo
                Fijo, en caso de incumplimiento del presente contrato. Queda
                legal constancia que en el momento que se ejecute el presente
                contrato por la vía legal correspondiente
                {ahorro[i].porcentaje !== 0
                  ? `, ésta garantía cubrirá el ${porcentaje(
                      ahorro[i].porcentaje
                    )} (${ahorro[i].porcentaje}%) del préstamo otorgado. `
                  : "."}
              </Text>
            );
          }

          switch (i) {
            case 0:
              retornar1 = dato;
              break;
            case 1:
              retornar2 = dato;
              break;
            case 2:
              retornar3 = dato;
              break;
          }
        }
      }

      if (sueldo.length > 0) {
        for (var i = 0; i < sueldo.length; i++) {
          var dato = "";
          if (sueldo[i].nombre == "S") {
            if (sueldo[i].codigoEmpleado !== null) {
              dato = (
                <Text>
                  el sueldo que devengo en {sueldo[i].dondeTrabaja},
                  {sueldo[i].especificamente !== ""
                    ? `especíoficamente ${sueldo[i].especificamente},`
                    : ""}
                  {sueldo[i].puesto !== "" ? `como ` : ""}{" "}
                  <Text style={styles.negrita}>
                    {sueldo[i].puesto !== ""
                      ? `${sueldo[i].puesto.toUpperCase()}, `
                      : ""}
                  </Text>
                  lo que demuestro con la constancia de pago mensual, Número de
                  empleado: {numeroALetras(sueldo[i].codigoEmpleado)} (
                  {sueldo[i].codigoEmpleado}),
                  {sueldo[i].cuenta !== ""
                    ? ` Cuenta Bancaria números: ${banco(sueldo[i].cuenta)} (${
                        sueldo[i].cuenta
                      }),`
                    : ""}
                  {sueldo[i].parPresupuestariaMensa !== ""
                    ? " a mi nombre: "
                    : ""}{" "}
                  <Text style={styles.negrita}>
                    {sueldo[i].parPresupuestariaMensa !== ""
                      ? `${persona.nombreCompleto.toUpperCase()}`
                      : ""}
                  </Text>
                  {sueldo[i].parPresupuestariaMensa !== ""
                    ? ` y con cargo a la partida presupuestaria número: ${numeroGuion(
                        sueldo[i]?.parPresupuestariaMensa || "1"
                      )} (${sueldo[i].parPresupuestariaMensa}) `
                    : ""}
                  lo cual acredito con la constancia de pago mensual que
                  corresponde al mes de {mesAño(sueldo[i].fechaNominaSueldo)},
                  extendida por{" "}
                  {sueldo[i].especificamente !== ""
                    ? `${sueldo[i].especificamente}`
                    : `${sueldo[i].dondeTrabaja}`}
                  , Constancia que adjunto al presente contrato, asimismo; yo{" "}
                  <Text style={styles.negrita}>
                    {persona.nombreCompleto.toUpperCase()}
                  </Text>{" "}
                  manifiesto expresamente en dejarlo como garantía para el
                  presente contrato de mutuo a favor de{" "}
                  <Text style={styles.negrita}>
                    “{representante.empresa?.nombreAbreviado.toUpperCase()}”,
                  </Text>{" "}
                  y que por éste mismo acto autorizo a la entidad acreedora a
                  que se trabe embargo sobre el porcentaje legal de mi sueldo,
                  en caso de incumplimiento del presente contrato.Queda legal
                  constancia que en el momento que se ejecute el presente
                  contrato por la vía legal correspondiente
                  {sueldo[i].porcentaje !== 0
                    ? `, ésta garantía cubrirá el ${porcentaje(
                        sueldo[i].porcentaje
                      )} (${sueldo[i].porcentaje}%) del préstamo otorgado. `
                    : "."}
                </Text>
              );
            } else {
              dato = (
                <Text>
                  {sueldo[i].dondeTrabaja}, tal como lo acredita con la
                  Declración Jurada de Ingeresos, de fecha{" "}
                  {CDate1(sueldo[i].fechaNominaSueldo)}, documento que dejo en
                  poder de la entidad acreedora, ingresos sobre los cuales se
                  podrán trabajar enbargo en caso de incumplimiento del presente
                  contrato
                  {sueldo[i].porcentaje !== 0
                    ? `, ésta garantía cubrirá el ${porcentaje(
                        sueldo[i].porcentaje
                      )} (${sueldo[i].porcentaje}%) del préstamo otorgado. `
                    : "."}
                </Text>
              );
            }
          }

          switch (i) {
            case 0:
              retornar4 = dato;
              break;
            case 1:
              retornar5 = dato;
              break;
            case 2:
              retornar6 = dato;
              break;
          }
        }
      }
      if (bien.length > 0) {
        for (var i = 0; i < bien.length; i++) {
          if (
            bien[i].fia == null ||
            bien[i].fia == "null" ||
            bien[i].fia == 0
          ) {
            var dato = "";
            var da1 = "";
            var da2 = "";
            var da3 = "";
            var da4 = "";
            var da5 = "";
            var da6 = "";
            var da7 = "";
            var da8 = "";
            var da9 = "";
            var da10 = "";
            var tes1 = "";
            var tes2 = "";
            var tes3 = "";
            var tes4 = "";
            var tes5 = "";

            if (bien[i].coordenada.length > 0) {
              for (var e = 0; e < bien[i].coordenada.length; e++) {
                var dato1 = "";
                dato1 = (
                  <Text>
                    {" "}
                    <Text style={styles.negrita}>
                      {bien[i].coordenada[
                        e
                      ].puntoCardinal.descripcion.toUpperCase()}
                      :{" "}
                    </Text>
                    {bien[i].coordenada[e].memida == null
                      ? ""
                      : bien[i].coordenada[e].memida == "null"
                      ? ""
                      : bien[i].coordenada[e].memida == "0"
                      ? ""
                      : `${extencion1(bien[i].coordenada[e].memida)} `}
                    {bien[i].coordenada[e].descripcion}
                  </Text>
                );

                switch (e) {
                  case 0:
                    da1 = dato1;
                    break;
                  case 1:
                    da2 = dato1;
                    break;
                  case 2:
                    da3 = dato1;
                    break;
                  case 3:
                    da4 = dato1;
                    break;
                  case 4:
                    da5 = dato1;
                    break;
                  case 5:
                    da6 = dato1;
                    break;
                  case 6:
                    da7 = dato1;
                    break;
                  case 7:
                    da8 = dato1;
                    break;
                  case 8:
                    da9 = dato1;
                    break;
                  case 9:
                    da10 = dato1;
                    break;
                }
              }
            } else {
              for (var e = 0; e < bien[i].medidasColidancias.length; e++) {
                var dato1 = "";
                dato1 = (
                  <Text>
                    {bien[i].medidasColidancias[e].descripcion}{" "}
                    {numeroALetras(
                      bien[i].medidasColidancias[e].cantidad
                    ).toLowerCase()}{" "}
                    ({bien[i].medidasColidancias[e].cantidad}
                    {bien[i].medidasColidancias[e].tipo !== null
                      ? `${bien[i].medidasColidancias[e].tipoMedida.signo}`
                      : ""}
                    )
                    {bien[i].medidasColidancias[e].tipo !== null
                      ? ` ${bien[i].medidasColidancias[e].tipoMedida.descripcion}`
                      : ""}
                  </Text>
                );

                switch (e) {
                  case 0:
                    da1 = dato1;
                    break;
                  case 1:
                    da2 = dato1;
                    break;
                  case 2:
                    da3 = dato1;
                    break;
                  case 3:
                    da4 = dato1;
                    break;
                  case 4:
                    da5 = dato1;
                    break;
                  case 5:
                    da6 = dato1;
                    break;
                  case 6:
                    da7 = dato1;
                    break;
                  case 7:
                    da8 = dato1;
                    break;
                  case 8:
                    da9 = dato1;
                    break;
                  case 9:
                    da10 = dato1;
                    break;
                }
              }
            }

            for (var e = 0; e < bien[i].testimonioInmueble.length; e++) {
              var dato2 = "";
              dato2 = (
                <Text>
                  <Text style={styles.negrita}>
                    {bien[i].testimonioInmueble[e].tipoTestimonio}
                  </Text>{" "}
                  de la escritura pública número{" "}
                  <Text style={styles.negrita}>
                    {numeroALetras(bien[i].testimonioInmueble[e].numEscritura)}{" "}
                    ({formatMiles(bien[i].testimonioInmueble[e].numEscritura)}),{" "}
                  </Text>
                  autorizada en {bien[i].testimonioInmueble[e].descripcion}, el{" "}
                  {CDate1(bien[i].testimonioInmueble[e].fechaAutorizacion)}, por
                  {bien[i].aFavor == "M" ? "el Notario" : "la Notaria"}{" "}
                  {bien[i].testimonioInmueble[e].notario.toUpperCase()}, a favor
                  de:{" "}
                  <Text style={styles.negrita}>
                    {persona.nombreCompleto.toUpperCase()}
                  </Text>
                </Text>
              );

              switch (i) {
                case 0:
                  tes1 = dato2;
                  break;
                case 1:
                  tes2 = dato2;
                  break;
                case 2:
                  tes3 = dato2;
                  break;
                case 3:
                  tes4 = dato2;
                  break;
                case 4:
                  tes5 = dato2;
                  break;
              }
            }

            if (
              bien[i].fia === null ||
              bien[i].fia == "null" ||
              bien[i].fia == ""
            ) {
              dato = (
                <Text>
                  {bien[i].conTitutlo == "N"
                    ? ` Gravamen Especial sobre los derechos posesorios que ejerzo sobre un bien inmueble de naturaleza ${bien[
                        i
                      ].tipoInmuble.toUpperCase()}, `
                    : `${
                        bien[i].hipoteca == "S"
                          ? " HIPOTECA "
                          : ` Gravamen Especial sobre los derechos posesorios que ejerzo sobre un bien inmueble de naturaleza  ${bien[
                              i
                            ].tipoInmuble.toUpperCase()}, `
                      }sin limitaciones de ninguna especie sobre ${
                        bien[i].consisteEn
                      } inscrita en el Segundo Registro de la Propiedad de ${
                        bien[i].propiedad
                      },bajo el número:`}
                  <Text style={styles.negrita}>
                    {bien[i].numero !== 0
                      ? `${numeroALetras(
                          bien[i].numero
                        ).toUpperCase()} (${formatMiles(bien[i].numero)}), `
                      : ""}
                  </Text>
                  {bien[i].folio !== 0 ? `folio:` : ``}
                  <Text style={styles.negrita}>
                    {bien[i].folio !== 0
                      ? `${numeroALetras(
                          bien[i].folio
                        ).toUpperCase()} (${formatMiles(bien[i].folio)}), `
                      : ""}
                  </Text>
                  {bien[i].libro !== "" ? `del libro:` : ``}
                  <Text style={styles.negrita}>
                    {bien[i].libro !== "" ? `${libroB(bien[i].libro)},` : ""}
                  </Text>
                  {bien[i].hipoteca == "S"
                    ? ` consiste en ${bien[i].consisteEn.toUpperCase()} ${bien[
                        i
                      ].tipoInmuble.toUpperCase()},`
                    : ""}
                  {bien[i].identificacion !== "" ? `identificado como ` : ""}
                  <Text style={styles.negrita}>
                    {bien[i].identificacion !== ""
                      ? `${bien[i].identificacion.toUpperCase()}, `
                      : ""}
                  </Text>
                  ubicado en el lugar denominado{" "}
                  <Text style={styles.negrita}>
                    {bien[i].direccion[0].descripnDireccion}, DEL MUNICIPIO DE{" "}
                    {bien[i].direccion[0].municipio.descripcion.toUpperCase()},{" "}
                    DEPARTAMENTO DE{" "}
                    {bien[
                      i
                    ].direccion[0].municipio.departamento.descripcion.toUpperCase()}
                    ,{" "}
                    {bien[i].refenrenciaEvaluo !== ""
                      ? `referencia según evaluó: ${bien[i].refenrenciaEvaluo}, `
                      : ""}
                  </Text>
                  {bien[i].conTitutlo == "N"
                    ? "el cual carece de título inscrito y matrícula fiscal, "
                    : ""}
                  {bien[i].descripcionInmueble !== ""
                    ? `${bien[i].descripcionInmueble} `
                    : bien[i].copropiedad == "S"
                    ? " que al ser medidio nuevamente por ambas partes en el sistema metrico decimal se hace constar que cuenta con una extencion superficial de"
                    : " inmueble que cuenta con una extensión superficial de"}
                  :
                  <Text style={styles.negrita}>
                    {extencion(bien[i].extencion)}
                  </Text>{" "}
                  con las medidas y colidancias siguientes {da1}
                  {da2 !== "" ? "; " : ""}
                  {da2}
                  {da3 !== "" ? "; " : ""}
                  {da3}
                  {da4 !== "" ? "; " : ""}
                  {da4}
                  {da5 !== "" ? "; " : ""}
                  {da5}
                  {da6 !== "" ? "; " : ""}
                  {da6}
                  {da7 !== "" ? "; " : ""}
                  {da7}
                  {da8 !== "" ? "; " : ""}
                  {da8}
                  {da9 !== "" ? "; " : ""}
                  {da9}
                  {da10 !== "" ? "; " : ""}
                  {da10}
                  {bien[i].disponibilidad !== "" ||
                  bien[i].disponibilidad !== null
                    ? `. ${bien[i].disponibilidad}`
                    : ""}
                  . Sobre el inmueble que otorgo en Garantia y sobre sus frutos
                  no pesan gravámenes, anotaciones, ni limitaciones que puedan
                  afectar los derechos de{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  {bien[i].testimonioInmueble.length > 0
                    ? "Estos derechos posesorios los amparo mediante "
                    : ""}
                  {tes1}
                  {tes2 !== "" ? ", " : ""}
                  {tes2}
                  {tes3 !== "" ? ", " : ""}
                  {tes3}
                  {tes4 !== "" ? ", " : ""}
                  {tes4}
                  {tes5 !== "" ? ", " : ""}
                  {tes5}. escritura que dejo en poder de la entidad acreedora,
                  asimismo yo{" "}
                  <Text style={styles.negrita}>
                    {persona.nombreCompleto.toUpperCase()}
                  </Text>{" "}
                  manifiesto expresamente en dejarlo como garantía para el
                  presente contrato de mutuo a favor de{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}",
                  </Text>{" "}
                  inmueble sobre el cual se podrá trabjar embargo en caso de
                  incumplimiento del deudor.
                  {bien[i].cubre !== 0
                    ? `Queda legal constancia que en el momento que se ejecute el presente contrato por la via legal correspondiente, ésta garantía cubrirá el ${porcentaje(
                        bien[i].cubre
                      )} (${bien[i].cubre}%) del préstamo otorgado. `
                    : ""}
                </Text>
              );
            }

            switch (i) {
              case 0:
                retornar7 = dato;
                break;
              case 1:
                retornar8 = dato;
                break;
              case 2:
                retornar9 = dato;
                break;
            }
          }
        }
      }

      return (
        <Text>
          <Text style={styles.negrita}>
            {retornar1 && retornar2 !== ""
              ? "PRIMERO. "
              : retornar1 && retornar4 !== ""
              ? "PRIMERO. "
              : retornar1 && retornar7 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>

          {retornar1}

          <Text style={styles.negrita}>
            {retornar2 && retornar1 !== "" ? "SEGUNDO. " : ""}
          </Text>
          {retornar2}
          <Text style={styles.negrita}>
            {retornar2 && retornar3 !== "" ? "TERCERO. " : ""}
          </Text>

          {retornar3}

          <Text style={styles.negrita}>
            {retornar3 && retornar4 !== ""
              ? "CUARTO. "
              : retornar2 && retornar4 !== ""
              ? "TERCERO. "
              : retornar4 && retornar1 !== ""
              ? "SEGUNDO. "
              : retornar4 && retornar5 !== ""
              ? "PRIMERO. "
              : retornar4 && retornar7 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>
          {retornar4}

          <Text style={styles.negrita}>
            {retornar4 && retornar3 && retornar5 !== ""
              ? "QUINTO. "
              : retornar2 && retornar4 && retornar5 !== ""
              ? "CUARTO. "
              : retornar1 && retornar4 && retornar5 !== ""
              ? "TERCERO. "
              : retornar4 && retornar5 !== ""
              ? "SEGUNDO. "
              : ""}
          </Text>
          {retornar5}

          <Text style={styles.negrita}>
            {retornar3 && retornar5 && retornar6 !== ""
              ? "SEXTO. "
              : retornar2 && retornar5 && retornar6 !== ""
              ? "QUINTO. "
              : retornar1 && retornar5 && retornar6 !== ""
              ? "CUARTO. "
              : retornar5 && retornar6 !== ""
              ? "TERCERO. "
              : ""}
          </Text>
          {retornar6}

          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar7 !== ""
              ? "SEPTIMO. "
              : retornar2 && retornar6 && retornar7 !== ""
              ? "SEXTO. "
              : retornar1 && retornar6 && retornar7 !== ""
              ? "Quinto. "
              : retornar6 && retornar7 !== ""
              ? "CUARTO. "
              : retornar5 && retornar7 !== ""
              ? "TERCERO. "
              : retornar4 && retornar7 !== ""
              ? "Segundo."
              : retornar1 && retornar7 !== ""
              ? "Segundo. "
              : retornar7 && retornar8 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>

          {retornar7}
          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar7 && retornar8 !== ""
              ? "OCTAVO. "
              : retornar2 && retornar6 && retornar7 && retornar8 !== ""
              ? "SEPTIMO. "
              : retornar1 && retornar6 && retornar7 && retornar8 !== ""
              ? "SEXTO. "
              : retornar6 && retornar7 && retornar8 !== ""
              ? "QUINTO. "
              : retornar5 && retornar7 && retornar8 !== ""
              ? "CUARTO. "
              : retornar4 && retornar7 && retornar8 !== ""
              ? "TERCERO. "
              : retornar7 && retornar1 !== ""
              ? "TERCERO. "
              : retornar7 && retornar8 !== ""
              ? "SEGUNDO. "
              : ""}
          </Text>
          {retornar8}
          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar8 && retornar9 !== ""
              ? "NOVENO. "
              : retornar2 && retornar6 && retornar8 && retornar9 !== ""
              ? "OCTAVO. "
              : retornar1 && retornar6 && retornar8 && retornar9 !== ""
              ? "SEPTIMO. "
              : retornar6 && retornar8 && retornar9 !== ""
              ? "SEXTO. "
              : retornar5 && retornar8 && retornar9 !== ""
              ? "QUINTO. "
              : retornar4 && retornar8 && retornar9 !== ""
              ? "CUARTO. "
              : retornar1 && retornar8 && retornar7 !== ""
              ? "CUARTO. "
              : retornar8 && retornar9 !== ""
              ? "TERCERO. "
              : ""}
          </Text>
          {retornar9}
        </Text>
      );
    } catch (error) {}
  }

  function libroB(dato) {
    try {
      var arr1 = dato.split(" ");
      var pri = numeroALetras(arr1[0]);

      return pri.toUpperCase() + " " + arr1[1] + ` (${arr1[0]}${arr1[1]})`;
    } catch (error) {}
  }
  let objetGarantiaAho = (id) => {
    return ahorro.reduce(
      (objet, garantia) => {
        if (garantia.fia == id) {
          objet.ahorro.push(garantia);
        }
        return objet;
      },

      { ahorro: [] }
    );
  };

  let objetGarantiaSue = (id) => {
    return sueldo.reduce(
      (objet, garantia) => {
        if (garantia.fia == id) {
          objet.sueldo.push(garantia);
        }
        return objet;
      },

      { sueldo: [] }
    );
  };

  let objetGarantiaBien = (id) => {
    return bien.reduce(
      (objet, garantia) => {
        if (garantia.fia == id) {
          objet.bien.push(garantia);
        }
        return objet;
      },

      { bien: [] }
    );
  };

  function garantiaFia(id) {
    try {
      var retornar = "";

      var retornar1 = "";
      var retornar2 = "";
      var retornar3 = "";
      var retornar4 = "";
      var retornar5 = "";
      var retornar6 = "";
      var retornar7 = "";
      var retornar8 = "";
      var retornar9 = "";

      if (objetGarantiaAho(id).ahorro.length > 0) {
        for (var i = 0; i < objetGarantiaAho(id).ahorro.length; i++) {
          var dato = "";

          dato = (
            <Text>
              el <Text style={styles.negrita}>AHORRO A PLAZO FIJO</Text> que
              poseo en {objetGarantiaAho(id).ahorro[i]?.lugarDeAhorro}, lo que
              demuestro con el{" "}
              <Text style={styles.negrita}>
                CERTIFICADO DE DEPÓSITO DE AHORRO A PLAZO FIJO,
              </Text>{" "}
              Número: {numeroALetras(objetGarantiaAho(id).ahorro[i]?.numCueta)}{" "}
              ({objetGarantiaAho(id).ahorro[i]?.numCueta}) Cuenta número:{" "}
              {numeroAahorroLetraLetras(
                objetGarantiaAho(id).ahorro[i]?.numCertificado
              )}{" "}
              ({objetGarantiaAho(id).ahorro[i]?.numCertificado}) a mi nombre:
              <Text style={styles.negrita}>
                {" "}
                {objetGarantiaAho(id).ahorro[
                  i
                ].fiadorContrato.persona.nombreCompleto.toUpperCase()}
              </Text>{" "}
              , por el monto de:{" "}
              {montoL(objetGarantiaAho(id).ahorro[i]?.ahoMonto).toUpperCase()}{" "}
              (Q. {objetGarantiaAho(id).ahorro[i]?.ahoMonto}), de fecha:{" "}
              {CDate1(objetGarantiaAho(id).ahorro[i]?.fecha)} (
              {formatDate(objetGarantiaAho(id).ahorro[i]?.fecha)}
              ), certificado que adjunto al presente contrato, así mismo
              manifiesto{" "}
              <Text style={styles.negrita}>
                yo{" "}
                {objetGarantiaAho(id).ahorro[
                  i
                ].fiadorContrato.persona.nombreCompleto.toUpperCase()}
              </Text>{" "}
              expresamente en dejarlo como garantía para el presente contrato de
              mutuo a favor de{" "}
              <Text style={styles.negrita}>
                "{representante.empresa?.nombreAbreviado.toUpperCase()}",
              </Text>{" "}
              y que por éste contrato autorizo a la entidad acreedora a que se
              trabe embargo sobre el porcentaje legal de mi Ahorro A Plazo Fijo,
              en caso de incumplimiento del presente contrato. Queda legal
              constancia que en el momento que se ejecute el presente contrato
              por la vía legal correspondiente
              {objetGarantiaAho(id).ahorro[i]?.porcentaje !== 0
                ? `, ésta garantía cubrirá el ${porcentaje(
                    ahorro[i].porcentaje
                  )} (${
                    objetGarantiaAho(id).ahorro[i]?.porcentaje
                  }%) del préstamo otorgado. `
                : "."}
            </Text>
          );

          switch (i) {
            case 0:
              retornar1 = dato;
              break;
            case 1:
              retornar2 = dato;
              break;
            case 2:
              retornar3 = dato;
              break;
          }
        }
      }

      if (objetGarantiaSue(id).sueldo.length > 0) {
        for (var i = 0; i < sueldo.length; i++) {
          var dato = "";

          if (objetGarantiaSue(id).sueldo[i]?.codigoEmpleado !== null) {
            dato = (
              <Text>
                el sueldo que devengo en{" "}
                {objetGarantiaSue(id).sueldo[i]?.dondeTrabaja},
                {objetGarantiaSue(id).sueldo[i]?.especificamente !== ""
                  ? `especíoficamente ${
                      objetGarantiaSue(id).sueldo[i]?.especificamente
                    },`
                  : ""}
                {objetGarantiaSue(id).sueldo[i]?.puesto !== "" ? `como ` : ""}{" "}
                <Text style={styles.negrita}>
                  {objetGarantiaSue(id).sueldo[i]?.puesto !== ""
                    ? `${objetGarantiaSue(id).sueldo[
                        i
                      ]?.puesto.toUpperCase()}, `
                    : ""}
                </Text>
                lo que demuestro con la constancia de pago mensual, Número de
                empleado:{" "}
                {numeroALetras(objetGarantiaSue(id).sueldo[i]?.codigoEmpleado)}{" "}
                ({objetGarantiaSue(id).sueldo[i]?.codigoEmpleado}),
                {objetGarantiaSue(id).sueldo[i]?.cuenta !== ""
                  ? ` Cuenta Bancaria números: ${banco(
                      objetGarantiaSue(id).sueldo[i]?.cuenta
                    )} (${objetGarantiaSue(id).sueldo[i]?.cuenta}),`
                  : ""}
                {objetGarantiaSue(id).sueldo[i]?.parPresupuestariaMensa !== ""
                  ? " a mi nombre: "
                  : ""}{" "}
                <Text style={styles.negrita}>
                  {objetGarantiaSue(id).sueldo[i]?.parPresupuestariaMensa !== ""
                    ? `${objetGarantiaSue(id).sueldo[
                        i
                      ]?.fiadorContrato.persona.nombreCompleto.toUpperCase()}`
                    : ""}
                </Text>
                {objetGarantiaSue(id).sueldo[i].parPresupuestariaMensa !== ""
                  ? ` y con cargo a la partida presupuestaria número: ${numeroGuion(
                      objetGarantiaSue(id).sueldo[i]?.parPresupuestariaMensa ||
                        "1"
                    )} (${
                      objetGarantiaSue(id).sueldo[i]?.parPresupuestariaMensa
                    }) `
                  : ""}
                lo cual acredito con la constancia de pago mensual que
                corresponde al mes de{" "}
                {mesAño(objetGarantiaSue(id).sueldo[i]?.fechaNominaSueldo)},
                extendida por{" "}
                {objetGarantiaSue(id).sueldo[i].especificamente !== ""
                  ? `${objetGarantiaSue(id).sueldo[i]?.especificamente}`
                  : `${objetGarantiaSue(id).sueldo[i]?.dondeTrabaja}`}
                , Constancia que adjunto al presente contrato, asimismo; yo{" "}
                <Text style={styles.negrita}>
                  {objetGarantiaSue(id).sueldo[
                    i
                  ]?.fiadorContrato.persona.nombreCompleto.toUpperCase()}
                </Text>{" "}
                manifiesto expresamente en dejarlo como garantía para el
                presente contrato de mutuo a favor de{" "}
                <Text style={styles.negrita}>
                  “{representante.empresa?.nombreAbreviado.toUpperCase()}”,
                </Text>{" "}
                y que por éste mismo acto autorizo a la entidad acreedora a que
                se trabe embargo sobre el porcentaje legal de mi sueldo, en caso
                de incumplimiento del presente contrato.Queda legal constancia
                que en el momento que se ejecute el presente contrato por la vía
                legal correspondiente
                {objetGarantiaSue(id).sueldo[i]?.porcentaje !== 0
                  ? `, ésta garantía cubrirá el ${porcentaje(
                      objetGarantiaSue(id).sueldo[i]?.porcentaje
                    )} (${
                      objetGarantiaSue(id).sueldo[i]?.porcentaje
                    }%) del préstamo otorgado. `
                  : "."}
              </Text>
            );
          } else {
            dato = (
              <Text>
                {objetGarantiaSue(id).sueldo[i]?.dondeTrabaja}, tal como lo
                acredita con la Declración Jurada de Ingeresos, de fecha{" "}
                {CDate1(objetGarantiaSue(id).sueldo[i]?.fechaNominaSueldo)},
                documento que dejo en poder de la entidad acreedora, ingresos
                sobre los cuales se podrán trabajar enbargo en caso de
                incumplimiento del presente contrato
                {objetGarantiaSue(id).sueldo[i]?.porcentaje !== 0
                  ? `, ésta garantía cubrirá el ${porcentaje(
                      objetGarantiaSue(id).sueldo[i]?.porcentaje
                    )} (${
                      objetGarantiaSue(id).sueldo[i]?.porcentaje
                    }%) del préstamo otorgado. `
                  : "."}
              </Text>
            );
          }

          switch (i) {
            case 0:
              retornar4 = dato;
              break;
            case 1:
              retornar5 = dato;
              break;
            case 2:
              retornar6 = dato;
              break;
          }
        }
      }
      if (objetGarantiaBien(id).bien.length > 0) {
        for (var i = 0; i < objetGarantiaBien(id).bien.length; i++) {
          var dato = "";
          var da1 = "";
          var da2 = "";
          var da3 = "";
          var da4 = "";
          var da5 = "";
          var da6 = "";
          var da7 = "";
          var da8 = "";
          var da9 = "";
          var da10 = "";
          var tes1 = "";
          var tes2 = "";
          var tes3 = "";
          var tes4 = "";
          var tes5 = "";

          if (objetGarantiaBien(id).bien[i].coordenada.length > 0) {
            for (
              var e = 0;
              e < objetGarantiaBien(id).bien[i].coordenada.length;
              e++
            ) {
              var dato1 = "";
              dato1 = (
                <Text>
                  {" "}
                  <Text style={styles.negrita}>
                    {objetGarantiaBien(id).bien[i].coordenada[
                      e
                    ].puntoCardinal.descripcion.toUpperCase()}
                    :{" "}
                  </Text>
                  {objetGarantiaBien(id).bien[i].coordenada[e].memida == null
                    ? ""
                    : objetGarantiaBien(id).bien[i].coordenada[e].memida ==
                      "null"
                    ? ""
                    : objetGarantiaBien(id).bien[i].coordenada[e].memida == "0"
                    ? ""
                    : `${extencion1(
                        objetGarantiaBien(id).bien[i].coordenada[e].memida
                      )} `}
                  {objetGarantiaBien(id).bien[i].coordenada[e].descripcion}
                </Text>
              );

              switch (e) {
                case 0:
                  da1 = dato1;
                  break;
                case 1:
                  da2 = dato1;
                  break;
                case 2:
                  da3 = dato1;
                  break;
                case 3:
                  da4 = dato1;
                  break;
                case 4:
                  da5 = dato1;
                  break;
                case 5:
                  da6 = dato1;
                  break;
                case 6:
                  da7 = dato1;
                  break;
                case 7:
                  da8 = dato1;
                  break;
                case 8:
                  da9 = dato1;
                  break;
                case 9:
                  da10 = dato1;
                  break;
              }
            }
          } else {
            for (
              var e = 0;
              e < objetGarantiaBien(id).bien[i].medidasColidancias.length;
              e++
            ) {
              var dato1 = "";
              dato1 = (
                <Text>
                  {
                    objetGarantiaBien(id).bien[i].medidasColidancias[e]
                      .descripcion
                  }{" "}
                  {numeroALetras(
                    objetGarantiaBien(id).bien[i].medidasColidancias[e].cantidad
                  ).toLowerCase()}{" "}
                  (
                  {objetGarantiaBien(id).bien[i].medidasColidancias[e].cantidad}
                  {objetGarantiaBien(id).bien[i].medidasColidancias[e].tipo !==
                  null
                    ? `${
                        objetGarantiaBien(id).bien[i].medidasColidancias[e]
                          .tipoMedida.signo
                      }`
                    : ""}
                  )
                  {objetGarantiaBien(id).bien[i].medidasColidancias[e].tipo !==
                  null
                    ? ` ${
                        objetGarantiaBien(id).bien[i].medidasColidancias[e]
                          .tipoMedida.descripcion
                      }`
                    : ""}
                </Text>
              );

              switch (e) {
                case 0:
                  da1 = dato1;
                  break;
                case 1:
                  da2 = dato1;
                  break;
                case 2:
                  da3 = dato1;
                  break;
                case 3:
                  da4 = dato1;
                  break;
                case 4:
                  da5 = dato1;
                  break;
                case 5:
                  da6 = dato1;
                  break;
                case 6:
                  da7 = dato1;
                  break;
                case 7:
                  da8 = dato1;
                  break;
                case 8:
                  da9 = dato1;
                  break;
                case 9:
                  da10 = dato1;
                  break;
              }
            }
          }

          for (
            var e = 0;
            e < objetGarantiaBien(id).bien[i].testimonioInmueble.length;
            e++
          ) {
            var dato2 = "";
            dato2 = (
              <Text>
                <Text style={styles.negrita}>
                  {
                    objetGarantiaBien(id).bien[i].testimonioInmueble[e]
                      .tipoTestimonio
                  }{" "}
                </Text>
                de la escritura pública número{" "}
                <Text style={styles.negrita}>
                  {numeroALetras(bien[i].testimonioInmueble[e].numEscritura)} (
                  {formatMiles(
                    objetGarantiaBien(id).bien[i].testimonioInmueble[e]
                      .numEscritura
                  )}
                  ),{" "}
                </Text>
                autorizada en{" "}
                {
                  objetGarantiaBien(id).bien[i].testimonioInmueble[e]
                    .descripcion
                }
                , el{" "}
                {CDate1(
                  objetGarantiaBien(id).bien[i].testimonioInmueble[e]
                    .fechaAutorizacion
                )}
                , por{" "}
                {objetGarantiaBien(id).bien[i].aFavor == "M"
                  ? "el Notario"
                  : "la Notaria"}{" "}
                {objetGarantiaBien(id).bien[i].testimonioInmueble[
                  e
                ].notario.toUpperCase()}
                , a favor de:{" "}
                <Text style={styles.negrita}>
                  {objetGarantiaBien(id).bien[
                    i
                  ]?.fiadorContrato.persona.nombreCompleto.toUpperCase()}
                </Text>
              </Text>
            );

            switch (i) {
              case 0:
                tes1 = dato2;
                break;
              case 1:
                tes2 = dato2;
                break;
              case 2:
                tes3 = dato2;
                break;
              case 3:
                tes4 = dato2;
                break;
              case 4:
                tes5 = dato2;
                break;
            }
          }

          dato = (
            <Text>
              {objetGarantiaBien(id).bien[i].conTitutlo == "N"
                ? ` Gravamen Especial sobre los derechos posesorios que ejerzo sobre un bien inmueble de naturaleza ${objetGarantiaBien(
                    id
                  ).bien[i].tipoInmuble.toUpperCase()}, `
                : `${
                    bien[i].hipoteca == "S"
                      ? " HIPOTECA "
                      : ` Gravamen Especial sobre los derechos posesorios que ejerzo sobre un bien inmueble de naturaleza  ${objetGarantiaBien(
                          id
                        ).bien[i].tipoInmuble.toUpperCase()}, `
                  }sin limitaciones de ninguna especie sobre ${
                    objetGarantiaBien(id).bien[i].consisteEn
                  } inscrita en el Segundo Registro de la Propiedad de ${formatMiles(
                    objetGarantiaBien(id).bien[i].propiedad
                  )},bajo el número:`}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[i].numero !== 0
                  ? `${numeroALetras(
                      objetGarantiaBien(id).bien[i].numero
                    ).toUpperCase()} (${
                      objetGarantiaBien(id).bien[i].numero
                    }), `
                  : ""}
              </Text>
              {objetGarantiaBien(id).bien[i].folio !== 0 ? `folio:` : ``}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[i].folio !== 0
                  ? `${numeroALetras(
                      objetGarantiaBien(id).bien[i].folio
                    ).toUpperCase()} (${formatMiles(
                      objetGarantiaBien(id).bien[i].folio
                    )}), `
                  : ""}
              </Text>
              {objetGarantiaBien(id).bien[i].libro !== "" ? `del libro:` : ``}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[i].libro !== ""
                  ? `${libroB(objetGarantiaBien(id).bien[i].libro)},`
                  : ""}
              </Text>
              {objetGarantiaBien(id).bien[i].hipoteca == "S"
                ? ` consiste en ${objetGarantiaBien(id).bien[
                    i
                  ].consisteEn.toUpperCase()} ${objetGarantiaBien(id).bien[
                    i
                  ].tipoInmuble.toUpperCase()},`
                : ""}
              {objetGarantiaBien(id).bien[i].identificacion !== ""
                ? `identificado como `
                : ""}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[i].identificacion !== ""
                  ? `${objetGarantiaBien(id).bien[
                      i
                    ].identificacion.toUpperCase()}, `
                  : ""}
              </Text>
              ubicado en el lugar denominado{" "}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[i].direccion[0].descripnDireccion},
                DEL MUNICIPIO DE{" "}
                {objetGarantiaBien(id).bien[
                  i
                ].direccion[0].municipio.descripcion.toUpperCase()}
                , DEPARTAMENTO DE{" "}
                {objetGarantiaBien(id).bien[
                  i
                ].direccion[0].municipio.departamento.descripcion.toUpperCase()}
                ,{" "}
                {objetGarantiaBien(id).bien[i].refenrenciaEvaluo !== ""
                  ? `referencia según evaluó: ${
                      objetGarantiaBien(id).bien[i].refenrenciaEvaluo
                    }, `
                  : ""}
              </Text>
              {objetGarantiaBien(id).bien[i].conTitutlo == "N"
                ? "el cual carece de título inscrito y matrícula fiscal, "
                : ""}
              {objetGarantiaBien(id).bien[i].descripcionInmueble !== ""
                ? `${objetGarantiaBien(id).bien[i].descripcionInmueble}, `
                : objetGarantiaBien(id).bien[i].copropiedad == "S"
                ? " que al ser medidio nuevamente por ambas partes en el sistema metrico decimal se hace constar que cuenta con una extencion superficial de"
                : " inmueble que cuenta con una extensión superficial de"}
              :
              <Text style={styles.negrita}>
                {extencion(objetGarantiaBien(id).bien[i].extencion)}
              </Text>{" "}
              con las medidas y colidancias siguientes {da1}
              {da2 !== "" ? "; " : ""}
              {da2}
              {da3 !== "" ? "; " : ""}
              {da3}
              {da4 !== "" ? "; " : ""}
              {da4}
              {da5 !== "" ? "; " : ""}
              {da5}
              {da6 !== "" ? "; " : ""}
              {da6}
              {da7 !== "" ? "; " : ""}
              {da7}
              {da8 !== "" ? "; " : ""}
              {da8}
              {da9 !== "" ? "; " : ""}
              {da9}
              {da10 !== "" ? "; " : ""}
              {da10}
              {objetGarantiaBien(id).bien[i].disponibilidad !== "" ||
              objetGarantiaBien(id).bien[i].disponibilidad !== null
                ? `. ${objetGarantiaBien(id).bien[i].disponibilidad}`
                : ""}
              . Sobre el inmueble que otorgo en Garantia y sobre sus frutos no
              pesan gravámenes, anotaciones, ni limitaciones que puedan afectar
              los derechos de{" "}
              <Text style={styles.negrita}>
                "{representante.empresa?.nombreAbreviado.toUpperCase()}"
              </Text>{" "}
              {objetGarantiaBien(id).bien[i].testimonioInmueble.length > 0
                ? "Estos derechos posesorios los amparo mediante "
                : ""}
              {tes1}
              {tes2 !== "" ? ", " : ""}
              {tes2}
              {tes3 !== "" ? ", " : ""}
              {tes3}
              {tes4 !== "" ? ", " : ""}
              {tes4}
              {tes5 !== "" ? ", " : ""}
              {tes5}. escritura que dejo en poder de la entidad acreedora, asi
              mismo yo{" "}
              <Text style={styles.negrita}>
                {objetGarantiaBien(id).bien[
                  i
                ]?.fiadorContrato.persona.nombreCompleto.toUpperCase()}
              </Text>{" "}
              manifiesto expresamente en dejarlo como garantía para el presente
              contrato de mutuo a favor de{" "}
              <Text style={styles.negrita}>
                "{representante.empresa?.nombreAbreviado.toUpperCase()}",
              </Text>{" "}
              inmueble sobre el cual se podrá trabjar embargo en caso de
              incumplimiento del deudor.
              {objetGarantiaBien(id).bien[i].cubre !== 0
                ? `Queda legal constancia que en el momento que se ejecute el presente contrato por la via legal correspondiente, ésta garantía cubrirá el ${porcentaje(
                    objetGarantiaBien(id).bien[i].cubre
                  )} (${
                    objetGarantiaBien(id).bien[i].cubre
                  }%) del préstamo otorgado. `
                : ""}
            </Text>
          );

          switch (i) {
            case 0:
              retornar7 = dato;
              break;
            case 1:
              retornar8 = dato;
              break;
            case 2:
              retornar9 = dato;
              break;
          }
        }
      }

      return (
        <Text>
          {retornar1 || retornar4 || retornar7 !== ""
            ? " Especialmente constituyo a favor de  "
            : ""}

          <Text style={styles.negrita}>
            {retornar1 || retornar4 || retornar7 !== ""
              ? `${representante.empresa?.nombreAbreviado.toUpperCase()} `
              : ""}
            {retornar1 && retornar2 !== ""
              ? "PRIMERO. "
              : retornar1 && retornar4 !== ""
              ? "PRIMERO. "
              : retornar1 && retornar7 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>
          {retornar1}

          <Text style={styles.negrita}>
            {retornar2 && retornar1 !== "" ? "SEGUNDO. " : ""}
          </Text>
          {retornar2}
          <Text style={styles.negrita}>
            {retornar2 && retornar3 !== "" ? "TERCERO. " : ""}
          </Text>

          {retornar3}

          <Text style={styles.negrita}>
            {retornar3 && retornar4 !== ""
              ? "CUARTO. "
              : retornar2 && retornar4 !== ""
              ? "TERCERO. "
              : retornar4 && retornar1 !== ""
              ? "SEGUNDO. "
              : retornar4 && retornar5 !== ""
              ? "PRIMERO. "
              : retornar4 && retornar7 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>
          {retornar4}

          <Text style={styles.negrita}>
            {retornar4 && retornar3 && retornar5 !== ""
              ? "QUINTO. "
              : retornar2 && retornar4 && retornar5 !== ""
              ? "CUARTO. "
              : retornar1 && retornar4 && retornar5 !== ""
              ? "TERCERO. "
              : retornar4 && retornar5 !== ""
              ? "SEGUNDO. "
              : ""}
          </Text>
          {retornar5}

          <Text style={styles.negrita}>
            {retornar3 && retornar5 && retornar6 !== ""
              ? "SEXTO. "
              : retornar2 && retornar5 && retornar6 !== ""
              ? "QUINTO. "
              : retornar1 && retornar5 && retornar6 !== ""
              ? "CUARTO. "
              : retornar5 && retornar6 !== ""
              ? "TERCERO. "
              : ""}
          </Text>
          {retornar6}

          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar7 !== ""
              ? "SEPTIMO. "
              : retornar2 && retornar6 && retornar7 !== ""
              ? "SEXTO. "
              : retornar1 && retornar6 && retornar7 !== ""
              ? "Quinto. "
              : retornar6 && retornar7 !== ""
              ? "CUARTO. "
              : retornar5 && retornar7 !== ""
              ? "TERCERO. "
              : retornar4 && retornar7 !== ""
              ? "Segundo."
              : retornar1 && retornar7 !== ""
              ? "Segundo. "
              : retornar7 && retornar8 !== ""
              ? "PRIMERO. "
              : ""}
          </Text>

          {retornar7}
          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar7 && retornar8 !== ""
              ? "OCTAVO. "
              : retornar2 && retornar6 && retornar7 && retornar8 !== ""
              ? "SEPTIMO. "
              : retornar1 && retornar6 && retornar7 && retornar8 !== ""
              ? "SEXTO. "
              : retornar6 && retornar7 && retornar8 !== ""
              ? "QUINTO. "
              : retornar5 && retornar7 && retornar8 !== ""
              ? "CUARTO. "
              : retornar4 && retornar7 && retornar8 !== ""
              ? "TERCERO. "
              : retornar7 && retornar1 !== ""
              ? "TERCERO. "
              : retornar7 && retornar8 !== ""
              ? "SEGUNDO. "
              : ""}
          </Text>
          {retornar8}
          <Text style={styles.negrita}>
            {retornar3 && retornar6 && retornar8 && retornar9 !== ""
              ? "NOVENO. "
              : retornar2 && retornar6 && retornar8 && retornar9 !== ""
              ? "OCTAVO. "
              : retornar1 && retornar6 && retornar8 && retornar9 !== ""
              ? "SEPTIMO. "
              : retornar6 && retornar8 && retornar9 !== ""
              ? "SEXTO. "
              : retornar5 && retornar8 && retornar9 !== ""
              ? "QUINTO. "
              : retornar4 && retornar8 && retornar9 !== ""
              ? "CUARTO. "
              : retornar1 && retornar8 && retornar7 !== ""
              ? "CUARTO. "
              : retornar8 && retornar9 !== ""
              ? "TERCERO. "
              : ""}
          </Text>
          {retornar9}
        </Text>
      );
    } catch (error) {}
  }

  function mesAño(date1) {
    var date = date1;

    var arr1 = date.split("-");
    var arr2;

    if (arr1[2] < 10) {
      arr2 = arr1[2].split("0");
      arr1[2] = arr2[1];
    }

    if (arr1[1] < 10) {
      arr2 = arr1[1].split("0");
      arr1[1] = arr2[1];
    }

    var mes = numerosAMeses(arr1[1]);
    var ano = numeroALetras(arr1[0]);
    return `${mes} del año${ano}`;
  }

  function banco(num) {
    var arr1 = num;
    var retornar = "";
    for (var i = 0; i < arr1.length; i++) {
      var dato = numeroALetras(arr1[i]);
      dato += "";
      retornar += dato;
    }
    return retornar;
  }

  function numeroGuion(num) {
    var retornar = "";
    var arr1 = num.split("-");

    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] <= 9 && arr1[i] >= 1) {
        var long = arr1[i];
        var mon = "9";
        var tot = long.length - mon.length;

        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(arr1[i]);

        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 99 && arr1[i] > 9) {
        var long = arr1[i];
        var mon = "99";
        var tot = long.length - mon.length;
        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;

        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 999 && arr1[i] > 99) {
        var long = arr1[i];
        var mon = "999";
        var tot = long.length - mon.length;
        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 9999 && arr1[i] > 999) {
        var long = arr1[i];
        var mon = "9999";
        var tot = long.length - mon.length;
        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 99999 && arr1[i] > 9999) {
        var long = arr1[i];
        var mon = "99999";
        var tot = long.length - mon.length;
        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 999999 && arr1[i] > 99999) {
        var long = arr1[i];
        var mon = "999999";
        var tot = mon.length - long.length;
        for (var e = 0; e < tot; e++) {
          retornar += "cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else if (arr1[i] < 9999999 && arr1[i] > 999999) {
        var long = arr1[i] + "";
        var mon = "9999999";
        var tot = mon.length - long.length;
        for (var e = 0; e < tot; e++) {
          retornar += " cero";
        }
        var dato1 = numeroALetras(long);
        dato1 += "";
        retornar += dato1;
        if (arr1.length !== i + 1) {
          retornar += " guion";
        }
      } else {
        var long = arr1[i] + "";
        for (var e = 0; e < long.length; e++) {
          retornar += " cero";
          if (arr1.length !== i + 1) {
            retornar += " guion";
          }
        }
      }
    }

    return retornar;
  }

  function fiadores() {
    try {
      if (fiador.length > 0) {
        var retornar0 = "";
        var retornar1 = "";
        var retornar2 = "";
        var retornar3 = "";
        var retornar4 = "";
        var retornar5 = "";
        var retornar6 = "";
        var retornar7 = "";
        var retornar8 = "";
        var retornar9 = "";
        for (var i = 0; i < fiador.length; i++) {
          var des = "";
          des = (
            <Text>
              {" "}
              <Text style={styles.negrita}>
                {" "}
                {i > 0 ? "Y " : fiador.length > 1 ? "Y NOSTOROS: " : "Y YO "}
                {fiador[i].persona.nombreCompleto.toUpperCase()},{" "}
              </Text>
              de
              {calcularEdad(fiador[i].persona.fechaNacimiento)} años de edad,{" "}
              {estadoSivil(
                fiador[i].persona.estadoCivil,
                fiador[i].persona.sexo
              )}
              , {fiador[i].persona.nacionalidad.toLowerCase()},
              {fiador[i].persona.profecion.toLowerCase()}, con residencia y
              domicilio en la {residenciaF("R", i)}, me identifico con el
              Documento Personal de Identificación{" "}
              <Text style={styles.negrita}>–DPI- </Text> con el Código Único de
              Identificación <Text style={styles.negrita}>–CUI-</Text> número:{" "}
              {DPI(fiador[i].persona.numeroDpi)} ({fiador[i].persona.numeroDpi}
              ), extendido por el Registro Nacional de las Personas de la
              República de Guatemala.
            </Text>
          );
          switch (i) {
            case 0:
              retornar0 = des;
              break;
            case 1:
              retornar1 = des;
              break;
            case 2:
              retornar2 = des;
              break;
            case 3:
              retornar3 = des;
              break;
            case 4:
              retornar4 = des;
              break;
            case 5:
              retornar5 = des;
              break;
            case 6:
              retornar6 = des;
              break;
            case 6:
              retornar7 = des;
              break;
            case 8:
              retornar8 = des;
              break;
            case 9:
              retornar9 = des;
              break;
          }
        }

        if (retornar0 !== "") {
          return (
            <Text>
              {retornar0}
              {retornar1}
              {retornar2}
              {retornar3}
              {retornar4}
              {retornar5}
              {retornar6}
              {retornar7}
              {retornar8}
              {retornar9}{" "}
            </Text>
          );
        }
      } else {
        return "";
      }
    } catch (err) {}
  }

  function fidorContrato2() {
    try {
      if (fiador.length > 0) {
        var retornar0 = "";
        var retornar1 = "";
        var retornar2 = "";
        var retornar3 = "";
        var retornar4 = "";
        var retornar5 = "";
        var retornar6 = "";
        var retornar7 = "";
        var retornar8 = "";
        var retornar9 = "";
        for (var i = 0; i < fiador.length; i++) {
          var des = "";

          des = (
            <Text>
              {fiador.length == i + 1 && fiador.length > 1 ? " y " : " "}
              {fiador[i].persona.sexo == "M" ? "el señor " : "la señora "}
              <Text style={styles.negrita}>
                {fiador[i].persona.nombreCompleto.toUpperCase()},
              </Text>{" "}
              como{" "}
              {fiador[i].persona.sexo == "M"
                ? "el fiador, solidario y mancomunado "
                : "la fiadora, solidaria y mancomunada "}{" "}
              de {persona.sexo == "M" ? "el deudor" : "la deudora"}
              principal, quien se manifiesta ser de{" "}
              {calcularEdad(fiador[i].persona.fechaNacimiento)} años de edad,
              {estadoSivil(
                fiador[i].persona.estadoCivil,
                fiador[i].persona.sexo
              )}
              , {fiador[i].persona.profecion.toLowerCase()},con residencia y
              domiciliio en {residenciaP("R")} se identifica con el Documento
              Personal de Identificación, con Código Único de Identificacion
              número: {DPI(fiador[i]?.persona.numeroDpi || "9999 99999 9999")} (
              {fiador[i]?.persona.numeroDpi || "Ingrese un fiador"}), extendido
              por el Regristro Nacional de las Personas de la República de
              Guatemala.
            </Text>
          );

          switch (i) {
            case 0:
              retornar0 = des;
              break;
            case 1:
              retornar1 = des;
              break;
            case 2:
              retornar2 = des;
              break;
            case 3:
              retornar3 = des;
              break;
            case 4:
              retornar4 = des;
              break;
            case 5:
              retornar5 = des;
              break;
            case 6:
              retornar6 = des;
              break;
            case 6:
              retornar7 = des;
              break;
            case 8:
              retornar8 = des;
              break;
            case 9:
              retornar9 = des;
              break;
          }
        }

        if (retornar0 !== "") {
          return (
            <Text>
              {retornar0}
              {retornar1}
              {retornar2}
              {retornar3}
              {retornar4}
              {retornar5}
              {retornar6}
              {retornar7}
              {retornar8}
              {retornar9}
            </Text>
          );
        }
      } else if (fiador.length == 0) {
        return "";
      }
    } catch (error) {}
  }

  function segundoReglon() {
    try {
      if (fiador.length > 0) {
        var retornar0 = "";
        var retornar1 = "";
        var retornar2 = "";
        var retornar3 = "";
        var retornar4 = "";
        var retornar5 = "";
        var retornar6 = "";
        var retornar7 = "";
        var retornar8 = "";
        var retornar9 = "";
        for (var i = 0; i < fiador.length; i++) {
          var des = "";

          des = (
            <Text>
              {" "}
              <Text style={styles.negrita}>
                {i > 0 ? "Y " : fiador.length > 1 ? "NOSTOROS: " : "YO "}
                {fiador[i].persona.nombreCompleto.toUpperCase()},{" "}
              </Text>
              por el presente acto me constituyo expresamente como FIADORA
              SOLIDARIA Y MANCOMUNADA del deudor principal{" "}
              <Text style={styles.negrita}>
                {persona.nombreCompleto.toUpperCase()},{" "}
              </Text>{" "}
              en toda la extensión de la obligación que contrae y de las
              prórrogas si se llegaren a causar, renuncio expresamente al fuero
              de mi domicilio y me someto a los tribunales que{" "}
              <Text style={styles.negrita}>
                {" "}
                {representante.empresa?.nombreAbreviado.toUpperCase()}
              </Text>
              , elija en caso de incumplimiento de la obligación contraída y
              garantizo en mi carácter u obligación de fiadora solidaria y
              mancomunada con mis bienes presentes y futuros.
              {garantiaFia(fiador[i].id)}
            </Text>
          );

          switch (i) {
            case 0:
              retornar0 = des;
              break;
            case 1:
              retornar1 = des;
              break;
            case 2:
              retornar2 = des;
              break;
            case 3:
              retornar3 = des;
              break;
            case 4:
              retornar4 = des;
              break;
            case 5:
              retornar5 = des;
              break;
            case 6:
              retornar6 = des;
              break;
            case 6:
              retornar7 = des;
              break;
            case 8:
              retornar8 = des;
              break;
            case 9:
              retornar9 = des;
              break;
          }
        }

        if (retornar0 !== "") {
          if (contrato.tipoDocId == "1") {
            return (
              <Text>
                <Text style={styles.negritaSub}>Segunda:</Text>
                {fiador.length > 1 ? " por nuestra parte," : " por mi parte."}
                {retornar0}
                {retornar1}
                {retornar2}
                {retornar3}
                {retornar4}
                {retornar5}
                {retornar6}
                {retornar7}
                {retornar8}
                {retornar9} <Text style={styles.negritaSub}>Tercera:</Text>
                {sabeFirmar()}
              </Text>
            );
          } else {
            return (
              <Text>
                <Text style={styles.negritaSub}>SEXTA:</Text>
                {fiador.length > 1 ? "por nuestra parte," : "por mi parte."}
                {retornar0}
                {retornar1}
                {retornar2}
                {retornar3}
                {retornar4}
                {retornar5}
                {retornar6}
                {retornar7}
                {retornar8}
                {retornar9}{" "}
                <Text style={styles.negritaSub}>SEPTIMA: ACEPTACÍON</Text> El
                Representante Legal de{" "}
                <Text style={styles.negrita}>
                  "{representante.empresa.nombreAbreviado}"
                </Text>{" "}
                indica que en los términos consignados{" "}
                <Text style={styles.negrita}>ACEPTA EXPRESAMENTE</Text>
                las garantías constituidas a favor de su presentada. Los
                otorgantes manifiestan su conformidad y aceptacion con las
                cláusalas de este contrato, YO el Notario,
                <Text style={styles.negrita}> DOY FE: </Text> a) de todo lo
                expuesto, B) de haber tenido a la vista los documentos de
                identificaciòn con que se identificaron los otorgantes, así como
                eltítulo que acredita la propiedad del bien otorgado en
                adjudicación, asi como el contrato de mutuo antes aludido; c)
                Que advertí a los otorgantes de los derechos y obligaciones
                provenientes de la celebración de este acto. Finalmente, por
                designación de los otorgantes doy lectura de todo lo escrito e
                impuesto de su contenido, objeto, validez y obligaciones que
                contraen, lo ratifican, aceptan
                {sabeFirmar()} Juntamentecon el infrascrito Notario Autorizante.
                (f) ilegible e impresión dactilar, impresión dactilar y (f)
                ilegible, (f) ilegible e impresión dactilar, Ante mí: (f)
                ilegible, está el sello del Notario
              </Text>
            );
          }
        }
      } else if (fiador.length == 0) {
        if (contrato.tipoDocId == "1") {
          return (
            <Text>
              {" "}
              <Text style={styles.negritaSub}>Segunda:</Text>
              <Text style={styles.negrita}>
                YO: {representante.nombreCompleto.toUpperCase()},
              </Text>
              , expresamente acepto la garantía constituida por el deudor{" "}
              <Text style={styles.negrita}>
                {" "}
                {persona.nombreCompleto.toUpperCase()},{" "}
              </Text>
              , a favor de mi representada. Nosotros los comparecientes
              manifestamos cada uno nuestra conformidad y aceptación con las
              cláusulas de este contrato; finalmente, cada uno de los
              comparecientes damos por sí lectura integra a todo lo escrito e
              impuesto de su contenido, validez y obligaciones que contraemos lo
              ratificamos, {sabeFirmar()}
            </Text>
          );
        } else {
          return (
            <Text>
              <Text>
                <Text style={styles.negritaSub}>SEXTA: ACEPTACÍON</Text> El
                Representante Legal de{" "}
                <Text style={styles.negrita}>
                  "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                </Text>{" "}
                indica que en los términos consignados{" "}
                <Text style={styles.negrita}>ACEPTA EXPRESAMENTE</Text>
                las garantías constituidas a favor de su presentada. Los
                otorgantes manifiestan su conformidad y aceptacion con las
                cláusalas de este contrato, YO el Notario,
                <Text style={styles.negrita}> DOY FE: </Text> a) de todo lo
                expuesto, B) de haber tenido a la vista los documentos de
                identificaciòn con que se identificaron los otorgantes, así como
                eltítulo que acredita la propiedad del bien otorgado en
                adjudicación, asi como el contrato de mutuo antes aludido; c)
                Que advertí a los otorgantes de los derechos y obligaciones
                provenientes de la celebración de este acto. Finalmente, por
                designación de los otorgantes doy lectura de todo lo escrito e
                impuesto de su contenido, objeto, validez y obligaciones que
                contraen, lo ratifican, aceptan {}
                {sabeFirmar()} Juntamentecon el infrascrito Notario Autorizante.
                (f) ilegible e impresión dactilar, impresión dactilar y (f)
                ilegible, (f) ilegible e impresión dactilar, Ante mí: (f)
                ilegible, está el sello del Notario.
              </Text>
            </Text>
          );
        }
      }
    } catch (error) {}
  }

  let cantFirmas = () => {
    return fiador.reduce(
      (objet, fiador) => {
        if (fiador.sabeFirmar != "1") {
          objet.fiadorN.push(fiador);
        }
        return objet;
      },

      { fiadorN: [] }
    );
  };

  function sabeFirmar() {
    try {
      if (fiador.length > 0) {
        var retornar0 = "";
        var retornar1 = "";
        var retornar2 = "";
        var retornar3 = "";
        var retornar4 = "";
        var retornar5 = "";
        var retornar6 = "";
        var retornar7 = "";
        var retornar8 = "";
        var retornar9 = "";
        var retornarf0 = "";
        var retornarf1 = "";
        var retornarf2 = "";
        var retornarf3 = "";
        var retornarf4 = "";
        var retornarf5 = "";
        var retornarf6 = "";
        var retornarf7 = "";
        var retornarf8 = "";
        var retornarf9 = "";
        var nom0 = "";
        var nom1 = "";
        var nom2 = "";
        var nom3 = "";
        var nom4 = "";
        var nom5 = "";
        var nom6 = "";
        var nom7 = "";
        var nom8 = "";
        var nom9 = "";
        var Nof = "";
        var AddF = "";

        for (var i = 0; i < fiador.length; i++) {
          var des = "";
          var desf = "";
          var desS = "";
          des = (
            <Text>
              {fiador.length == i + 1 && fiador.length > 1 ? " y " : " "}

              <Text style={styles.negrita}>
                {fiador[i].persona.nombreCompleto.toUpperCase()},
              </Text>
            </Text>
          );

          if (fiador[i].sabeFirmar !== 1) {
            desf = (
              <Text>
                {fiador[i].persona.sexo == "M" ? "el fiador " : "la fiadora "}

                <Text style={styles.negrita}>
                  {fiador[i].persona.nombreCompleto.toUpperCase()},
                </Text>
              </Text>
            );
          } else {
            desS = (
              <Text>
                <Text style={styles.negrita}>
                  {" "}
                  {fiador[i].persona.nombreCompleto.toUpperCase()},
                </Text>
              </Text>
            );
          }

          switch (i) {
            case 0:
              retornar0 = des;
              retornarf0 = desf;
              nom0 = desS;

              break;
            case 1:
              retornar1 = des;
              retornarf1 = desf;
              nom1 = desS;
              break;
            case 2:
              retornar2 = des;
              retornarf2 = desf;
              nom2 = desS;
              break;
            case 3:
              retornar3 = des;
              retornarf3 = desf;
              nom3 = desS;
              break;
            case 4:
              retornar4 = des;
              retornarf4 = desf;
              nom4 = desS;
              break;
            case 5:
              retornar5 = des;
              retornarf5 = desf;
              nom5 = desS;
              break;
            case 6:
              retornar6 = des;
              retornarf6 = desf;
              nom6 = desS;
              break;
            case 7:
              retornar7 = des;
              retornarf7 = desf;
              nom7 = desS;
              break;
            case 8:
              retornar8 = des;
              retornarf8 = desf;
              nom8 = desS;
              break;
            case 9:
              retornar9 = des;
              retornarf9 = desf;
              nom9 = desS;
              break;
          }
        }

        Nof = (
          <Text>
            , no asi{" "}
            <Text style={styles.negrita}>
              {contrato.firma != "S"
                ? `YO ${persona.nombreCompleto.toUpperCase()},`
                : ""}
            </Text>
            {retornarf0}
            {retornarf1}
            {retornarf2}
            {retornarf3}
            {retornarf4}
            {retornarf5}
            {retornarf6}
            {retornarf7}
            {retornarf8}
            {retornarf9}
            {contrato.firma !== "S" && retornarf0 !== ""
              ? " quienes"
              : contrato.firma !== "S" && retornarf1 !== ""
              ? " quienes"
              : contrato.firma !== "S" && retornarf2 !== ""
              ? " quienes"
              : contrato.firma !== "S" && retornarf3 !== ""
              ? " quienes"
              : contrato.firma !== "S" && retornarf4 !== ""
              ? " quienes"
              : " quien"}{" "}
            por ignorar hacerlo{" "}
            {contrato.firma !== "S" && retornarf0 !== ""
              ? "dejamos "
              : contrato.firma !== "S" && retornarf1 !== ""
              ? "dejamos "
              : contrato.firma !== "S" && retornarf2 !== ""
              ? "dejamos "
              : contrato.firma !== "S" && retornarf3 !== ""
              ? "dejamos "
              : contrato.firma !== "S" && retornarf4 !== ""
              ? "dejamos "
              : contrato.firma !== "S" && retornarf4 !== ""
              ? "dejamos "
              : retornarf0 !== "S" && retornarf1 !== ""
              ? "dejamos "
              : retornarf1 !== "S" && retornarf2 !== ""
              ? "dejamos "
              : retornarf2 !== "S" && retornarf3 !== ""
              ? "dejamos "
              : retornarf3 !== "S" && retornarf4 !== ""
              ? "dejamos "
              : "dejo "}
            la impresión dactilar del dedo pulgar de la mano derecha,
            {cantFirmas().length > 1 ||
            (cantFirmas.length > 1 && contrato.firma == "N")
              ? " siendo de la misma parte firma a sus ruegos "
              : " firma a mi ruego "}{" "}
            {testigo[0]?.persona.sexo == "F"
              ? "la testigo hábil y capaz la señora: "
              : "el testigo hábil y capaz el señor: "}{" "}
            <Text style={styles.negrita}>
              {testigo[0]?.persona.nombreCompleto.toUpperCase()},
            </Text>{" "}
            quien se identifica el Documento Personal de Identificación
            <Text style={styles.negrita}> –DPI- </Text> con el Código Único de
            Identificación <Text style={styles.negrita}> -CUI- </Text> número:
            {DPI(testigo[0]?.persona.numeroDpi || "10-1")} (
            {testigo[0]?.persona.numeroDpi}
            ), extendido por el Registro Nacional de las Personas de la
            República de Guatemala
          </Text>
        );

        AddF = (
          <Text>
            , así mismo,
            <Text style={styles.negrita}>
              {" "}
              {contrato.firma !== "N"
                ? `YO ${persona.nombreCompleto.toUpperCase()},`
                : ""}
            </Text>
            {nom0}
            {nom1}
            {nom2}
            {nom3}
            {nom4}
            {nom5}
            {nom6}
            {nom7}
            {nom8}
            {nom9} además de firmar para mayor seguridad de{" "}
            <Text style={styles.negrita}>
              {representante.empresa?.nombreAbreviado.toUpperCase()},
            </Text>{" "}
            {nom0 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom1 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom2 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom3 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom4 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom5 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom6 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom7 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom8 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom9 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom0 !== "" && nom1 == "S"
              ? "dejamos"
              : nom1 !== "" && nom2 == "S"
              ? "dejamos"
              : nom2 !== "" && nom3 == "S"
              ? "dejamos"
              : nom3 !== "" && nom4 == "S"
              ? "dejamos"
              : nom4 !== "" && nom5 == "S"
              ? "dejamos"
              : nom5 !== "" && nom6 == "S"
              ? "dejamos"
              : nom6 !== "" && nom7 == "S"
              ? "dejamos"
              : nom7 !== "" && nom8 == "S"
              ? "dejamos"
              : nom8 !== "" && nom8 == "S"
              ? "dejamos"
              : nom9 !== "" && nom9 == "S"
              ? "dejamos"
              : "dejo"}{" "}
            la impresión dactilar de mi dedo pulgar de la mano derecha{" "}
          </Text>
        );

        if (retornar0 !== "") {
          return (
            <Text>
              <Text style={styles.negrita}>
                {" "}
                YO: {representante.nombreCompleto.toUpperCase()},
              </Text>{" "}
              espresamente acepto las garantás constituidas por el deudor{" "}
              <Text style={styles.negrita}>
                {" "}
                YO: {persona.nombreCompleto.toUpperCase()}
              </Text>
              {fiador.length > 1
                ? " y por sus fiadores y solidarios y mancumunados "
                : " y por su fiador y solidaria y mancumunada"}
              {retornar0}
              {retornar1}
              {retornar2}
              {retornar3}
              {retornar4}
              {retornar5}
              {retornar6}
              {retornar7}
              {retornar8}
              {retornar9} a favor de mi representada. Nosotros los
              comparecientes manifestamos cada uno nuestra conformidad y
              aceptacion con las cláusualas de este contrato; finalmente, cada
              uno de los comparecientes damos por sí lectura integra a todo lo
              escrito e impuesto de su contenido, validez y obligaciones que
              contraemos lo ratificamos, aceptamos y
              {contrato.firma !== "N" && fiador.length == cantFirmas().length
                ? "firmamos"
                : contrato.firma !== "S" && fiador.length > cantFirmas().length
                ? "firmamos"
                : contrato.firma !== "S" && fiador.length < cantFirmas().length
                ? "firma únicamente el acreedor"
                : nom0 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom1 !== "" &&
                  contrato.firma == "N" &&
                  fiador.length > 0 &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom2 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom3 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom4 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom5 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom6 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : nom7 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y el fiador"
                : nom8 !== "" &&
                  fiador.length > 0 &&
                  contrato.firma == "N" &&
                  fiador.length > cantFirmas().length
                ? "firman únicamente el acredor y fiador"
                : contrato.firma !== "S" && fiador.length == 0
                ? "firma únicamente el acreador"
                : contrato.firma !== "S" && fiador.length > 0
                ? "firmamos"
                : "firmamos"}
              {retornarf0 !== ""
                ? Nof
                : retornarf1 !== ""
                ? Nof
                : retornarf2 !== ""
                ? Nof
                : retornarf3 !== ""
                ? Nof
                : retornarf4 !== ""
                ? Nof
                : retornarf5 !== ""
                ? Nof
                : retornarf6 !== ""
                ? Nof
                : retornarf7 !== ""
                ? Nof
                : retornarf8 !== ""
                ? Nof
                : retornarf9 !== ""
                ? Nof
                : contrato.firma !== "S"
                ? Nof
                : ""}
              {nom0 !== ""
                ? AddF
                : nom1 !== ""
                ? AddF
                : nom2 !== ""
                ? AddF
                : nom3 !== ""
                ? AddF
                : nom4 !== ""
                ? AddF
                : nom5 !== ""
                ? AddF
                : nom6 !== ""
                ? AddF
                : nom7 !== ""
                ? AddF
                : nom8 !== ""
                ? AddF
                : contrato.firma == "S"
                ? AddF
                : ""}
              .
            </Text>
          );
        }
      } else {
        if (contrato.firma == "S") {
          return (
            <Text>
              aceptamos y firmamos, así mismo,{" "}
              <Text style={styles.negrita}>
                {" "}
                {contrato.firma !== "N"
                  ? `YO ${persona.nombreCompleto.toUpperCase()},`
                  : ""}{" "}
              </Text>
              además de firmar para mayor seguridad de{" "}
              <Text style={styles.negrita}>
                {" "}
                {representante.empresa?.nombreAbreviado.toUpperCase()} ,{" "}
              </Text>{" "}
              dejo la impresión dactilar de mi dedo pulgar de la mano derecha.{" "}
            </Text>
          );
        } else {
          var retornar = "";
          retornar = (
            <Text>
              aceptamos y firman únicamente el acredor, no asi{" "}
              <Text style={styles.negrita}>
                {" "}
                {contrato.firma != "S"
                  ? `YO ${persona.nombreCompleto.toUpperCase()}, `
                  : ""}
              </Text>
              quien por ignorar hacerlo deja la impresión dactilar del dedo
              pulgar de la mano derecha, siendo de la misma parte firma a sus
              ruego{" "}
              {testigo[0]?.persona.sexo == "F"
                ? "la testigo hábil y capaz la señora: "
                : "el testigo hábil y capaz el señor: "}{" "}
              <Text style={styles.negrita}>
                {testigo[0]?.persona.nombreCompleto.toUpperCase() || ""},
              </Text>{" "}
              quien se identifica el Documento Personal de Identificación
              <Text style={styles.negrita}>–DPI-</Text> con el Código Único de
              Identificación <Text style={styles.negrita}> -CUI- </Text> número:
              {DPI(testigo[0]?.persona.numeroDpi || "9999 99999 9999")} (
              {testigo[0]?.persona.numeroDpi || "Ingrese un testigo"}
              ), extendido por el Registro Nacional de las Personas de la
              República de Guatemala.{" "}
            </Text>
          );
          return retornar;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  function firmas() {
    try {
      var retornar0 = "";
      var retornar1 = "";
      var retornar2 = "";
      var retornar3 = "";
      var retornarS0 = "";
      var retornarS1 = "";
      var retornarS2 = "";
      var retornarS3 = "";

      for (var i = 0; i < fiador.length; i++) {
        var des = "";
        var sex = "";

        des = fiador[i].persona.nombreCompleto.toUpperCase();

        if (fiador[i].persona.sexo == "M") {
          sex = "Fiador solidario y mancomunado";
        } else {
          sex = "Fiadora solidaria y mancomunada";
        }
        switch (i) {
          case 0:
            retornar0 = des;
            retornarS0 = sex;
            break;
          case 1:
            retornar1 = des;
            retornarS1 = sex;
            break;
          case 2:
            retornar2 = des;
            retornarS2 = sex;
            break;
          case 3:
            retornar3 = des;
            retornarS3 = sex;
            break;
        }
      }

      if (fiador.length == "0" && testigo.length == "0") {
        return (
          <View style={{ margin: 1 }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                paddingTop: 75,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.firma}>
                  <Text style={styles.negritaLinea}>
                    {" ______________________________________ "}
                  </Text>
                  {representante.nombreCompleto.toUpperCase()}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {" ______________________________________ "}
                </Text>
                <Text style={styles.firma}>
                  {persona.nombreCompleto.toUpperCase()}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",

                paddingBottom: 20,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{}}>Representante Legal</Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text>Dudor</Text>
              </Text>
            </View>
          </View>
        );
      } else if (
        (fiador.length >= 1 && fiador.length < 3) ||
        testigo.length > 0
      ) {
        return (
          <View style={{ margin: 1 }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                paddingTop: 75,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {" ______________________________________ "}
                </Text>
                <Text style={styles.firma}>
                  {representante.nombreCompleto.toUpperCase()}.
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {" ______________________________________ "}
                </Text>
                <Text style={styles.firma}>
                  {persona.nombreCompleto.toUpperCase()}.
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",

                lineHeight: 1.75,
                height: "40px",
              }}
            >
              <Text style={{ flex: 1 }}>Representante Legal</Text>
              <Text style={{ flex: 1 }}>Dudor</Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                marginTop: 60,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar0 !== ""
                    ? " ______________________________________ "
                    : testigo.length > 0
                    ? " ______________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar0 !== ""
                    ? retornar0
                    : `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar1 !== ""
                    ? " ______________________________________ "
                    : testigo.length > 0
                    ? " ______________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar1 !== ""
                    ? retornar1
                    : retornar0 !== ""
                    ? `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`
                    : ""}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",

                lineHeight: 1.75,

                paddingBottom: 20,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{}}>
                  {retornar0 !== ""
                    ? retornarS0
                    : testigo.length > 0
                    ? "Testigo"
                    : ""}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text>
                  {retornar1 !== ""
                    ? retornarS1
                    : retornar0 !== ""
                    ? testigo.length > 0
                      ? "Testigo"
                      : ""
                    : ""}
                </Text>
              </Text>
            </View>
          </View>
        );
      } else if (fiador.length >= 3 && fiador.length < 5) {
        return (
          <View style={{ margin: 1 }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                paddingTop: 75,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {"     __________________________________ "}
                </Text>
                <Text style={styles.firma}>
                  {representante.nombreCompleto.toUpperCase()}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {"     __________________________________ "}
                </Text>
                <Text style={styles.firma}>
                  {persona.nombreCompleto.toUpperCase()}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",

                lineHeight: 1.75,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{}}>Representante Legal</Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text>Dudor</Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                paddingTop: 60,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar0 !== ""
                    ? "     __________________________________ "
                    : testigo.length > 0
                    ? "     __________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar0 !== ""
                    ? retornar0
                    : `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar1 !== ""
                    ? "     __________________________________ "
                    : testigo.length > 0
                    ? "     __________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar1 !== ""
                    ? retornar1
                    : retornar0 !== ""
                    ? `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`
                    : ""}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",
                lineHeight: 1.75,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{}}>
                  {retornar0 !== ""
                    ? retornarS0
                    : testigo.length > 0
                    ? "Testigo"
                    : ""}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text>
                  {retornar1 !== ""
                    ? retornarS1
                    : retornar0 !== ""
                    ? testigo.length > 0
                      ? "Testigo"
                      : ""
                    : ""}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                lineHeight: 1.75,
                textAlign: "center",
                paddingTop: 60,
                margin: 2,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar3 !== ""
                    ? "     __________________________________ "
                    : testigo.length > 0
                    ? "     __________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar2 !== ""
                    ? retornar2
                    : retornar1 !== ""
                    ? `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`
                    : ""}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text style={styles.negritaLinea}>
                  {retornar3 !== ""
                    ? "     __________________________________ "
                    : testigo.length > 0
                    ? "     __________________________________ "
                    : ""}
                </Text>
                <Text style={styles.firma}>
                  {retornar3 !== ""
                    ? retornar3
                    : retornar2 !== ""
                    ? `${
                        testigo[0]?.persona.nombreCompleto.toUpperCase() || ""
                      }`
                    : ""}
                </Text>
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: fonT - 1,
                textAlign: "center",

                lineHeight: 1.75,
                paddingBottom: 20,
              }}
            >
              <Text style={{ flex: 1 }}>
                <Text style={{}}>
                  {" "}
                  {retornar2 !== ""
                    ? retornarS2
                    : retornar1 !== ""
                    ? testigo.length > 0
                      ? "Testigo"
                      : ""
                    : ""}
                </Text>
              </Text>
              <Text style={{ flex: 1 }}>
                <Text>
                  {" "}
                  {retornar3 !== ""
                    ? retornarS3
                    : retornar2 !== ""
                    ? testigo.length > 0
                      ? "Testigo"
                      : ""
                    : ""}
                </Text>
              </Text>
            </View>
          </View>
        );
      } else {
        toast.current.show({
          severity: "error",
          summary: "Errror",
          detail:
            " Solo estan permitidos 3 fiadores y un testigo o 4 fiadores y ningun testigo",
          life: 5000,
        });
      }
    } catch (error) {}
  }

  function actafirma() {
    try {
      if (fiador.length > 0) {
        var retornar0 = "";
        var retornar1 = "";
        var retornar2 = "";
        var retornar3 = "";
        var retornarf0 = "";
        var retornarf1 = "";
        var retornarf2 = "";
        var retornarf3 = "";
        var retornarSf0 = "";
        var retornarSf1 = "";
        var retornarSf2 = "";
        var retornarSf3 = "";

        var nom0 = "";
        var nom1 = "";
        var nom2 = "";
        var nom3 = "";

        var Nof = "";
        var AddF = "";
        var SiF = "";

        for (var i = 0; i < fiador.length; i++) {
          var des = "";
          var desf = "";
          var desS = "";
          var desSF = "";
          des = (
            <Text>
              {fiador.length == i + 1 ? " y " : " "}

              <Text style={styles.negrita}>
                {fiador[i].persona.nombreCompleto.toUpperCase()},
              </Text>
            </Text>
          );

          if (fiador[i].sabeFirmar !== 1) {
            desf = (
              <Text>
                {fiador[i].persona.sexo == "M" ? "el señor: " : "la señora: "}
                <Text style={styles.negrita}>
                  {fiador[i].persona.nombreCompleto.toUpperCase()},
                </Text>{" "}
                quien se identifica con el Documento Personal de Identificación{" "}
                <Text style={styles.negrita}>-DPI- </Text>
                con el Código Único de Identificación{" "}
                <Text style={styles.negrita}> -CUI- </Text>número:{" "}
                {DPI(fiador[i]?.persona.numeroDpi || "9999 99999 9999")} (
                {fiador[i]?.persona.numeroDpi || "Ingrese un Testigo"}),
                extendido por el Regristro Nacional de las Personas de la
                República de Guatemala;
              </Text>
            );
          } else {
            desS = (
              <Text>
                {" "}
                {fiador[i].persona.sexo == "M" ? " el señor " : " la señora "}
                <Text style={styles.negrita}>
                  {fiador[i].persona.nombreCompleto.toUpperCase()},
                </Text>
              </Text>
            );

            desSF = (
              <Text>
                {fiador[i].persona.sexo == "M" ? "el señor " : "la señora "}
                <Text style={styles.negrita}>
                  {fiador[i].persona.nombreCompleto.toUpperCase()},
                </Text>{" "}
                quien se identifica con el Documento Personal de Identificación{" "}
                <Text style={styles.negrita}>-DPI- </Text>
                con el Código Único de Identificación{" "}
                <Text style={styles.negrita}> -CUI- </Text>número:{" "}
                {DPI(fiador[i]?.persona.numeroDpi || "9999 99999 9999")} (
                {fiador[i]?.persona.numeroDpi || "Ingrese un Testigo"}),
                extendido por el Regristro Nacional de las Personas de la
                República de Guatemala;
              </Text>
            );
          }

          switch (i) {
            case 0:
              retornar0 = des;
              retornarf0 = desf;
              retornarSf0 = desSF;
              nom0 = desS;

              break;
            case 1:
              retornar1 = des;
              retornarf1 = desf;
              retornarSf1 = desSF;
              nom1 = desS;
              break;
            case 2:
              retornar2 = des;
              retornarf2 = desf;
              retornarSf2 = desSF;
              nom2 = desS;
              break;
            case 3:
              retornar3 = des;
              retornarf3 = desf;
              retornarSf3 = desSF;
              nom3 = desS;
              break;
          }
        }

        var deudorF = "";

        deudorF = (
          <Text>
            {persona.sexo == "M" ? " del señor " : " de la señora "}
            <Text style={styles.negrita}>
              {persona.nombreCompleto.toUpperCase()},
            </Text>{" "}
            quien se identifica con el Documento Personal de Identificación{" "}
            <Text style={styles.negrita}>-DPI- </Text>
            con el Código Único de Identificación{" "}
            <Text style={styles.negrita}> -CUI- </Text>número:{" "}
            {DPI(persona?.numeroDpi || "9999 99999 9999")} (
            {persona.numeroDpi || "Ingrese un Testigo"}), extendido por el
            Regristro Nacional de las Personas de la República de Guatemala;
          </Text>
        );

        SiF = (
          <Text>
            {contrato.firma !== "N" ? deudorF : ""}

            {retornarSf0}
            {retornarSf1}
            {retornarSf2}
            {retornarSf3}
          </Text>
        );
        Nof = (
          <Text>
            {testigo[0]?.persona.sexo == "F"
              ? " y por la señora: "
              : " y por el señor: "}{" "}
            <Text style={styles.negrita}>
              {testigo[0]?.persona.nombreCompleto.toUpperCase()},
            </Text>{" "}
            quien se identifica el Documento Personal de Identificación
            <Text style={styles.negrita}>–DPI-</Text> con el Código Único de
            Identificación <Text style={styles.negrita}> -CUI- </Text> número:
            {DPI(testigo[0]?.persona.numeroDpi || "9999 99999 9999")} (
            {testigo[0]?.persona.numeroDpi}
            ), extendido por el Registro Nacional de las Personas de la
            República de Guatemala; quien firma como testigo{" "}
            {contrato.firma !== "S" && retornarf0 !== ""
              ? " de los:"
              : contrato.firma !== "S" && retornarf1 !== ""
              ? " de los:"
              : contrato.firma !== "S" && retornarf2 !== ""
              ? " de los:"
              : contrato.firma !== "S" && retornarf3 !== ""
              ? " de los:"
              : retornarf0 !== "" && retornarf1 !== ""
              ? " de los:"
              : retornarf1 !== "" && retornarf2 !== ""
              ? " de los:"
              : retornarf2 !== "" && retornarf3 !== ""
              ? " de los:"
              : " de:"}{" "}
            {contrato.firma !== "S" ? deudorF : ""}
            {retornarf0}
            {retornarf1}
            {retornarf2}
            {retornarf3}
            {contrato.firma !== "S" && retornarf0 !== ""
              ? " quienes ignoran firmar y dejan "
              : contrato.firma !== "S" && retornarf1 !== ""
              ? " quienes ignoran firmar y dejan "
              : contrato.firma !== "S" && retornarf2 !== ""
              ? " quienes ignoran firmar y dejan "
              : contrato.firma !== "S" && retornarf3 !== ""
              ? " quienes ignoran firmar y dejan "
              : retornarf0 !== "" && retornarf1 !== ""
              ? " quienes ignoran firmar y dejan "
              : retornarf1 !== "" && retornarf2 !== ""
              ? " quienes ignoran firmar y dejan "
              : retornarf2 !== "" && retornarf3 !== ""
              ? " quienes ignoran firmar y dejan "
              : " quien ignora firmar y deja "}{" "}
            la impresión dactilar del dedo pulgar de la mano derecha;{" "}
          </Text>
        );

        AddF = (
          <Text>
            {nom0 !== "" && contrato.firma == "S"
              ? "quienes"
              : nom1 !== "" && contrato.firma == "S"
              ? "quienes"
              : nom2 !== "" && contrato.firma == "S"
              ? "quienes"
              : nom3 !== "" && contrato.firma == "S"
              ? "quienes"
              : nom0 !== "" && nom1 == "S"
              ? "quienes"
              : nom1 !== "" && nom2 == "S"
              ? "quienes"
              : nom2 !== "" && nom3 == "S"
              ? "quienes"
              : nom3 !== "" && nom4 == "S"
              ? "quienes"
              : "quien"}{" "}
            nuevamente firman la presente acta de legalización; además de la
            firma
            {contrato.firma !== "N"
              ? ` ${persona.sexo == "F" ? " de la señora " : " del señor "}`
              : ""}
            <Text style={styles.negrita}>
              {contrato.firma !== "N"
                ? `${persona.nombreCompleto.toUpperCase()},`
                : ""}
            </Text>
            {nom0}
            {nom1}
            {nom2}
            {nom3} para mayor seguridad de{" "}
            <Text style={styles.negrita}>
              {representante.empresa?.nombreAbreviado.toUpperCase()},
            </Text>{" "}
            {nom0 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom1 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom2 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom3 !== "" && contrato.firma == "S"
              ? "dejamos"
              : nom0 !== "" && nom1 == "S"
              ? "dejamos"
              : nom1 !== "" && nom2 == "S"
              ? "dejamos"
              : nom2 !== "" && nom3 == "S"
              ? "dejamos"
              : nom3 !== "" && nom4 == "S"
              ? "dejamos"
              : "deja"}{" "}
            la impresión dactilar del dedo pulgar de la mano derecha, juntamente
            con el Notario, que de todo lo actuado doy fe
          </Text>
        );

        if (retornar0 !== "") {
          return (
            <Text>
              {retornarSf0 !== ""
                ? SiF
                : retornarSf1 !== ""
                ? SiF
                : retornarSf2 !== ""
                ? SiF
                : retornarSf3 !== ""
                ? SiF
                : contrato.firma !== "n"
                ? SiF
                : ""}
              {retornarf0 !== ""
                ? Nof
                : retornarf1 !== ""
                ? Nof
                : retornarf2 !== ""
                ? Nof
                : retornarf3 !== ""
                ? Nof
                : contrato.firma !== "S"
                ? Nof
                : ""}
              {nom0 !== ""
                ? AddF
                : nom1 !== ""
                ? AddF
                : nom2 !== ""
                ? AddF
                : nom3 !== ""
                ? AddF
                : contrato.firma == "S"
                ? AddF
                : ""}
              .
            </Text>
          );
        }
      } else {
        if (contrato.firma == "S") {
          return (
            <Text>
              {persona.sexo == "M" ? "el señor: " : "la señora "}
              <Text style={styles.negrita}>
                {persona.nombreCompleto.toUpperCase()},
              </Text>{" "}
              quien se identifica con el Documento Personal de Identificación{" "}
              <Text style={styles.negrita}>-DPI- </Text>
              con el Código Único de Identificación{" "}
              <Text style={styles.negrita}> -CUI- </Text>número:{" "}
              {DPI(persona?.numeroDpi || "9999 99999 9999")} (
              {persona.numeroDpi || "Ingrese un Testigo"}), extendido por el
              Regristro Nacional de las Personas de la República de Guatemala;
              quien nuevamente firma la presente acta de legalización; además de
              la firma{persona.sexo == "M" ? " del señor " : " de la señora "}
              <Text style={styles.negrita}>
                {persona.nombreCompleto.toUpperCase()},
              </Text>{" "}
              para mayor seguridad de{" "}
              <Text style={styles.negrita}>
                {" "}
                {representante.empresa?.nombreAbreviado.toUpperCase()} ,{" "}
              </Text>{" "}
              deja la impresión dactilar del dedo pulgar de la mano derecha,
              juntamente con el Notario, que de todo lo acuado doy fe.{" "}
            </Text>
          );
        } else {
          var retornar = "";
          retornar = (
            <Text>
              {testigo[0]?.persona.sexo == "F"
                ? " y por la señora: "
                : " y por el señor: "}{" "}
              <Text style={styles.negrita}>
                {testigo[0]?.persona.nombreCompleto.toUpperCase()},
              </Text>{" "}
              quien se identifica el Documento Personal de Identificación
              <Text style={styles.negrita}>–DPI-</Text> con el Código Único de
              Identificación <Text style={styles.negrita}> -CUI- </Text> número:
              {DPI(testigo[0]?.persona.numeroDpi || "9999 99999 9999")} (
              {testigo[0]?.persona.numeroDpi}
              ), extendido por el Registro Nacional de las Personas de la
              República de Guatemala; quien firma como testigo{" "}
              {persona.sexo == "M" ? " del señor: " : "de la señor: "}
              <Text style={styles.negrita}>
                {persona.nombreCompleto.toUpperCase()},
              </Text>{" "}
              quien se identifica con el Documento Personal de Identificación{" "}
              <Text style={styles.negrita}>-DPI- </Text>
              con el Código Único de Identificación{" "}
              <Text style={styles.negrita}> -CUI- </Text>número:{" "}
              {DPI(persona?.numeroDpi || "9999 99999 9999")} (
              {persona.numeroDpi || "Ingrese un Testigo"}), extendido por el
              Regristro Nacional de las Personas de la República de Guatemala,
              quien ignora firmar y deja la impresón dactilar del dedo pulgar de
              la mano derecha,-= juntamente con el Notario, que de todo lo
              acuado doy fe.
            </Text>
          );
          return retornar;
        }
      }
    } catch (error) {}
  }

  const formatMiles = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";

    if (number % 1 == 0) {
      return number.toString().replace(exp, rep) + ".00";
    } else {
      return number.toString().replace(exp, rep);
    }
  };

  const conGarantias = () => {
    if (bien.length > 0) {
      for (var i = 0; i < bien.length; i++) {
        if (bien[i].fia == null || bien[i].fia == "null" || bien[i].fia == 0) {
          return true;
        }
      }
    } else if (sueldo.length > 0) {
      for (var i = 0; i < sueldo.length; i++) {
        if (sueldo[i].nombre == "S") {
          return true;
        }
      }
    } else if (ahorro.length > 0) {
      for (var i = 0; i < ahorro.length; i++) {
        if (ahorro[i].nombrePerEncargada == "S") {
          return true;
        }
      }
    }
    return false;
  };

  function numero(n) {
    if (n + "" == "0") {
      return "cero";
    }
    if (n + "" == "1") {
      return "uno";
    }
    if (n + "" == "2") {
      return "dos";
    }
    if (n + "" == "3") {
      return "tres";
    }
    if (n + "" == "4") {
      return "cuatro";
    }
    if (n + "" == "5") {
      return "cinco";
    }
    if (n + "" == "6") {
      return "seis";
    }
    if (n + "" == "7") {
      return "siete";
    }
    if (n + "" == "8") {
      return "ocho";
    }
    if (n + "" == "9") {
      return "nueve";
    }

    return "";
  }

  if (contrato.tipoDocId == "1") {
    return (
      <div>
        <div className="field ">
          <div className="formgrid grid">
            <div
              className="field col"
              style={{ alignItems: "right", textAlign: "right" }}
            >
              <h6>Tamañon de fuente</h6>
            </div>
            <div className="field col">
              <InputText
                id="fontSize"
                type="number"
                value={fonT}
                onChange={(e) => onChangeF(e, "fechaInicial")}
              />
            </div>
          </div>
        </div>
        <font face="Arial Narrow,arial,sans-serif">
          <>
            <PDFViewer style={{ width: "100%", height: "78vh" }}>
              <Document title={`contrato ${persona.nombreCompleto}`}>
                <Page size={[611.28, 933.9]} style={styles.body} dpi={75}>
                  <Text style={styles.header} fixed>
                    Cooperativa Integral de Ahorro y Crédito FONDOS DE
                    AMERICA,Responsabilidad Limitada. FONDOS, R. L.
                  </Text>
                  <Text style={styles.header} fixed>
                    CONTRATO DE MUTUO-VISUALIZACION
                  </Text>
                  <Text style={styles.textL} fixed>
                    ___________________________________________________________________________
                  </Text>
                  <Text style={styles.text}>
                    {contrato?.garantia !== ""
                      ? `En ${contrato?.garantia}, del `
                      : "En el"}
                    municipio de {contrato.municipio.descripcion}, departamento
                    de {contrato.municipio.departamento.descripcion},
                    {CDate(contrato.fechaContrato)}; comparecemos por una parte:{" "}
                    <Text style={styles.negrita}>
                      YO: {representante.nombreCompleto.toUpperCase()},
                    </Text>{" "}
                    {}
                    de {calcularEdad(representante.fechaNacimiento)} años de
                    edad, unido de hecho,{" "}
                    {representante.nacionalidad.toLowerCase()},
                    {representante.profecion.toLowerCase()}, con residencia y
                    domicilio en la {residencia("R")}, me identifico con el
                    Documento Personal de Identificación
                    <Text style={styles.negrita}> –DPI-</Text> con el Código
                    Único de Identificación{" "}
                    <Text style={styles.negrita}> -CUI- </Text> número:{" "}
                    {DPI(representante.numeroDpi)} ({representante.numeroDpi}
                    ), extendido por el Registro Nacional de las Personas de la
                    República de Guatemala, actúo en mi calidad de Representante
                    Legal De La {representante.empresa?.nombre},{" "}
                    {representante.empresa?.nombreAbreviado}. Calidad que
                    acredito con la certificación de inscripción extendida por
                    el Registro de Cooperativas del Instituto Nacional de
                    Cooperativas (INACOP), con número de Registro {registro(1)}{" "}
                    ({registro(0)}
                    ), folio número {folio(1)} ({folio(0)}), del libro número{" "}
                    {libro(1)} ({libro(0)}), de inscripciones de Representantes
                    Legales de las Cooperativas, legalmente inscritas, de fecha
                    {dateR(1)}; siendo tal representación suficiente de
                    conformidad con la ley Para la celebración Del presente
                    contrato de mutuo. A la {representante.empresa?.nombre},
                    podrá llamarse simplemente en este contrato como “
                    {representante.empresa?.nombreAbreviado.toUpperCase()}” o la
                    entidad acreedora. Por la otra parte comparezco:{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      YO {persona.nombreCompleto.toUpperCase()},{" "}
                    </Text>
                    de
                    {calcularEdad(persona.fechaNacimiento)} años de edad,{" "}
                    {estadoSivil(persona.estadoCivil, persona.sexo)},{" "}
                    {persona.nacionalidad.toLowerCase()},
                    {persona.profecion.toLowerCase()}, con residencia y
                    domicilio en {residenciaP("R")}, me identifico con el
                    Documento Personal de Identificación{" "}
                    <Text style={styles.negrita}> –DPI- </Text> con el Código
                    Único de Identificación{" "}
                    <Text style={styles.negrita}> –CUI- </Text> número:{" "}
                    {DPI(persona.numeroDpi)} ({persona.numeroDpi}), extendido
                    por el Registro Nacional de las Personas de la República de
                    Guatemala, a quien en el presente contrato se me denominará:
                    <Text style={styles.negrita}>
                      {" "}
                      “{persona.sexo == "M" ? "el deudor " : "la deudora "}”.
                    </Text>{" "}
                    {fiadores()}
                    {fiador.length >= 1 ? "Todos " : "Ambos "}
                    aseguramos ser de los datos anteriormente relacionados y
                    encontrándonos en el libre ejercicio de nuestros derechos
                    civiles y por este acto celebramos el presente{" "}
                    <Text style={styles.negritaSub}>
                      {" "}
                      CONTRATO DE MUTUO{" "}
                      {contrato?.garId !== 1
                        ? `${contrato?.tipoGarantia.descripcion.toUpperCase()} `
                        : ``}
                      CON LEGALIZACION DE FIRMAS{" "}
                    </Text>{" "}
                    conforme a las cláusulas siguientes:{" "}
                    <Text style={styles.negrita}> PRIMERA:</Text> YO,{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      {representante.nombreCompleto.toUpperCase()},
                    </Text>{" "}
                    declaro que en la calidad con la que actúo, según{" "}
                    {resolucionCredito()} ({contrato.resolucionCredito}). Por
                    este acto otorgo al deudor{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      {persona.nombreCompleto.toUpperCase()},
                    </Text>{" "}
                    un préstamo{" "}
                    {contrato?.garId !== 1
                      ? `${contrato?.tipoGarantia.descripcion.toLowerCase()} `
                      : ``}
                    , bajo las siguientes condiciones:{" "}
                    <Text style={styles.negritaSub}>a) MONTO:</Text> por la
                    cantidad de:{" "}
                    <Text style={styles.negrita}>
                      {montoL(contrato.monto).toUpperCase()} (Q.{" "}
                      {formatMiles(contrato.monto)}
                      ).
                    </Text>
                    <Text style={styles.negritaSub}> b) DESTINO:</Text> fondos
                    que se invertirán exclusivamente para:{" "}
                    <Text style={styles.negrita}>
                      PRÉSTAMO {contrato.destino.toUpperCase()},
                    </Text>{" "}
                    en caso contrario por si cambiare el destino de este
                    contrato, sin previa autorización de{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , tendrá la facultad de incrementar de forma inmediata el{" "}
                    {porcentaje(contrato.tasaIncumplimiento)} (
                    {contrato.tasaIncumplimiento}%) sobre la tasa de interés
                    anual pactado en este contrato.{" "}
                    <Text style={styles.negritaSub}>c) PLAZO: </Text>el plazo
                    convenido para el presente contrato mutuo es de{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      {numeroALetras(contrato.palazMeses)} (
                      {contrato.palazMeses}) MESES{" "}
                    </Text>
                    a partir del{CDate1(contrato.fechaContrato)} con vencimiento
                    el
                    {vencimient(contrato.palazMeses, contrato.fechaContrato)}.
                    <Text style={styles.negritaSub}> d) FORMA DE PAGO:</Text> la
                    parte deudora pagará este préstamo en{" "}
                    <Text style={styles.negrita}>{amortizacionM()}</Text>, más
                    intereses sobre saldos de capital, mismos que harán
                    efectivos en las cajas de{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , las que el deudor conoce perfectamente sin necesidad de
                    cobro ni requerimiento alguno.{" "}
                    <Text style={styles.negritaSub}>e) INTERESES:</Text> la
                    cantidad mutuada devengará a partir de esta fecha el
                    {numeroALetras(contrato.tasaInteger).toLowerCase()} por
                    ciento ({contrato.tasaInteger}%) de intereses anual sobre
                    saldos, tasa de interés que podrá variar en más o menos si
                    así lo dispone el Consejo de Administración de{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , asimismo tendrá un incremento inmediato del{" "}
                    {numeroALetras(contrato.tasaMora / 12)} por ciento (
                    {contrato.tasaMora / 12}%) mensual sobre saldo en caso de
                    que incurriera en mora a partir de los cinco días de atraso
                    del pago de capital{" "}
                    {contrato.frecuenciaPago == "1"
                      ? "semanales"
                      : contrato.frecuenciaPago == "2"
                      ? "quincenales"
                      : contrato.frecuenciaPago == "3"
                      ? "mensuales"
                      : contrato.frecuenciaPago == "1"
                      ? "trimestrales"
                      : contrato.frecuenciaPago == "1"
                      ? "semestral"
                      : contrato.frecuenciaPago == "1"
                      ? "anual"
                      : ""}
                    .{" "}
                    <Text style={styles.negritaSub}>
                      {" "}
                      f) MOROSIDAD: incumplimiento de los pagos de las
                      amortizaciones en las fechas convenidas:
                    </Text>{" "}
                    en caso de atraso en el pago de los abonos y de intereses,
                    el deudor pagará a{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    ., el {numeroALetras(contrato.tasaMora)} por ciento (
                    {contrato.tasaMora}%) anual de recargo por mora, sobre el
                    capital e intereses atrasados.{" "}
                    <Text style={styles.negritaSub}>
                      g) FECHA DE PAGO DE LAS AMORTIZACIONES
                    </Text>
                    : las cuotas de capital más intereses devengados se pagarán
                    los días
                    {pago(contrato.fechaPrimerPago)}.
                    <Text style={styles.negritaSub}> h) DESEMBOLSOS: </Text>Los
                    recursos de este préstamo los entrega{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , al deudor, mediante {cantD()}
                    <Text style={styles.negrita}>
                      {desembolsos()} i){" "}
                      {representante.empresa?.nombreAbreviado.toUpperCase()},
                    </Text>{" "}
                    dará por vencido el plazo y suspenderá las entregas
                    pendientes de capital, si las hubiere, si el asociado cambia
                    el destino del capital mutuado, al que señaló en la
                    resolución y si se incumple cualquiera de las obligaciones
                    asumidas por la parte deudora en este contrato, y pudiéndose
                    exigir el cumplimiento de las obligaciones en juicio
                    ejecutivo por la totalidad de lo adeudado, es decir,
                    capital, más intereses, recargos por mora, costas judiciales
                    y otros gastos que se llegara a ocasionar, por lo que para
                    el caso de la demanda, como parte deudora desde ya acepto
                    como buenas, exactas, liquidas y exigibles las cantidades
                    que se reclamen a consecuencia de este mutuo el cual se
                    constituye en título ejecutivo. j) El asociado mientras
                    tenga un préstamo vigente con{" "}
                    <Text style={styles.negrita}>
                      "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                    </Text>{" "}
                    no podrá de ninguna manera retirar su cuenta de aportaciones
                    hasta la total cancelación del préstamo, excepto cuando se
                    trata de cancelar el préstamo a través de las aportaciones o
                    cuando existe un exceso, según el Reglamento de Crédito.
                    <Text style={styles.negrita}>k) PARA LOS EFECTOS:</Text>de
                    este contrato y su eventual incumplimiento y demanda
                    judicial,{" "}
                    <Text style={styles.negrita}>
                      {" "}
                      YO {persona.nombreCompleto.toUpperCase()},{" "}
                    </Text>
                    renuncio al fuero de mi domicilio y me someto a los
                    tribunales que{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , elija, señalando como lugar para recibir notificaciones,
                    el lugar de mi residencia señalada en la parte introductoria
                    de este contrato.
                    <Text style={styles.negritaSub}>l) EN GARANTÍA:</Text> de la
                    cantidad recibida en calidad de mutuo como capital,
                    intereses, recargos y costas judiciales si fuera el caso,
                    por este acto{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>{" "}
                    otorgo mis bienes presentes y futuros,
                    {conGarantias() == true ? "especialmente constituto" : ""} a
                    favor de{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>{" "}
                    {garantia()}{" "}
                    <Text>
                      Asimismo; yo
                      <Text style={styles.negrita}>
                        {" "}
                        {persona.nombreCompleto.toUpperCase()}
                      </Text>
                      otorgo todas las facultades a{" "}
                      <Text style={styles.negrita}>
                        {" "}
                        {representante.empresa?.nombreAbreviado.toUpperCase()},
                      </Text>{" "}
                      para que pueda transferir en forma automática el valor de
                      mis ahorros a plazo y corriente, también mis aportaciones
                      para abonar capital, intereses y otros costos de mutuo que
                      he recibido el día de hoy, cuando incurra en morosidad o
                      pagos atrasados a partir de un mese de retraso, quedo
                      debidamente enterado de que el monto del préstamo vigente
                      tendrá carácter de cedible, negociable o transmisibe a
                      cualquier titulo sin necesidad, de previo ni posterior
                      aviso a la parte deudora.
                    </Text>
                    <Text style={styles.negrita}>
                      m) YO {persona.nombreCompleto.toUpperCase()}{" "}
                    </Text>{" "}
                    me reconozco liso y llano deudor de{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , asimismo me comprometo formalmente a usar la suma recibida
                    en la forma solicitada, según el plan de inversión, el cual
                    reconozco como parte del contrato, y en forma expresa acepto
                    que, en caso de falta de pago de esta obligación, pueden
                    trasladar los fondos que tenga en{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , en pago del mutuo y sus intereses, de acuerdo con la ley,
                    los estatutos, y las normas reglamentarias legalmente
                    emitidas.{" "}
                    <Text style={styles.negrita}>
                      n) SEGUROS Y COSTOS ADMINISTRATIVOS: yo{" "}
                      {persona.nombreCompleto.toUpperCase()},
                    </Text>{" "}
                    autorizo a{" "}
                    <Text style={styles.negrita}>
                      {representante.empresa?.nombreAbreviado.toUpperCase()}
                    </Text>
                    , para que proceda a cargar en el saldo del préstamo el
                    valor del seguro de protección del préstamo, el seguro
                    médico, el seguro de vida, y otros cargos relacionados; ya
                    sea en forma mensual o anual, según las políticas de seguros
                    y cargos administrativos vigentes en la cooperativa, con el
                    entendido que el seguro de préstamo solo es aplicable cuando
                    al deudor fallezca y el préstamo se encuentra al día con los
                    pagos; caso contrario el préstamo pierde el beneficio del
                    seguro.
                    <Text style={styles.negrita}>
                      {" "}
                      ñ) CLAUSULA RESOLUTORIA:{" "}
                    </Text>
                    se dará por vencido el plazo establecido en el presente
                    contrato por incumplimiento de pago a partir de una
                    amortización de capital e intereses, como consecuencia se
                    procederá a exigir su cumplimiento en juicio ejecutivo por
                    considerarse cantidad de dinero de plazo vencido, liquido y
                    exigible, lo que la parte deudora acepta en forma consciente
                    y voluntaria tal circunstancia.{segundoReglon()}
                  </Text>
                  {firmas()}
                  <Text style={styles.text}>
                    <Text style={styles.negrita}>
                      {" "}
                      ACTA DE LEGALIZACIÓN DE FIRMAS:
                    </Text>
                    En el municipio de {contrato.municipio.descripcion},
                    departamento de{" "}
                    {contrato.municipio.departamento.descripcion},
                    {CDate2(contrato.fechaContrato)}. YO, el infrascrito
                    Notario, DOY FE: que las firmas que anteceden son{" "}
                    <Text style={styles.negrita}>AUTÉNTICAS,</Text> en virtud de
                    haber sido puestas en mi presencia por{" "}
                    {representante.sexo != "M" ? "la señora" : "el señor"}:{" "}
                    <Text style={styles.negrita}>
                      {representante.nombreCompleto.toUpperCase()}
                    </Text>{" "}
                    quien se identifica con el Documento Personal de
                    Identificación <Text style={styles.negrita}>-DPI- </Text>
                    con el Código Único de Identificación{" "}
                    <Text style={styles.negrita}> -CUI- </Text>número:{" "}
                    {DPI(representante?.numeroDpi || "9999 99999 9999")} (
                    {representante?.numeroDpi || "Ingrese un Testigo"}),
                    extendido por el Regristro Nacional de las Personas de la
                    República de Guatemala; {actafirma()}
                  </Text>
                  {firmas()}
                  <Text style={styles.text1}> ANTE MÍ: </Text>
                  <Text
                    style={styles.pageNumber}
                    render={({ pageNumber }) =>
                      "__________________________________ " +
                      "Página " +
                      `${pageNumber}` +
                      "__________________________________  "
                    }
                    fixed
                  />
                </Page>
              </Document>
            </PDFViewer>
          </>
        </font>
        <Toast ref={toast} />
      </div>
    );
  } else {
    return (
      <div>
        <>
          <PDFViewer style={{ width: "100%", height: "78vh" }}>
            <Document title={`contrato ${persona.nombreCompleto}`}>
              <Page size="Legal" style={styles.body} dpi={75}>
                <Text style={styles.header1} fixed>
                  {" "}
                  C-353
                </Text>
                <Text style={styles.header2} fixed>
                  Hoja______de______ hojas-visualizar
                </Text>
                <Text style={styles.text1}>
                  <Text style={styles.negrita}>
                    NÚMERO {numeroALetras(contrato.corId).toUpperCase()} (
                    {contrato.corId}).
                  </Text>{" "}
                  {contrato?.garantia !== ""
                    ? `En ${contrato?.garantia}, del `
                    : "En el "}
                  municipio de {contrato.municipio.descripcion}, departamento de{" "}
                  {contrato.municipio.departamento.descripcion},
                  {CDate(contrato.fechaContrato)}.{" "}
                  <Text style={styles.negrita}>
                    ANTE MI: {notario.nombreCompleto.toUpperCase()}, Notario,
                  </Text>{" "}
                  comparece por parte:
                  <Text style={styles.negrita}>
                    {" "}
                    {representante.nombreCompleto.toUpperCase()},
                  </Text>{" "}
                  de {calcularEdad(representante.fechaNacimiento)} años de edad,
                  unido de hecho, {representante.nacionalidad.toLowerCase()},{" "}
                  {representante.profecion.toLowerCase()}, con residencia y
                  domicilio en la {residencia("R")}, me identifico con el
                  Documento Personal de Identificación con el Código Único de
                  Identificación número:{DPI(representante.numeroDpi)} (
                  {representante.numeroDpi}), extendido por el Registro Nacional
                  de las Personas de la República de Guatemala,comparece en
                  calidad de{" "}
                  <Text style={styles.negrita}>
                    REPRESENTANTE LEGAL DE LA{" "}
                    {representante.empresa?.nombre.toUpperCase()},
                  </Text>{" "}
                  institución que en el cuerpo de este instrumento y para
                  efectos de interpretación se denominará
                  <Text style={styles.negrita}>
                    {" "}
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  acredita su Personería con la certificación de instripcin
                  extendida por el Registro de la Cooperativas del Intituto
                  Nacional de Cooperativas
                  <Text style={styles.negrita}> (INACOP), </Text>
                  con número de Registro {registro(1)} ({registro(0)}
                  ), folio número {folio(1)} ({folio(0)}), del libro número{" "}
                  {libro(1)} ({libro(0)}), de inscripciones de Representantes
                  Legales de las Cooperativas, legalmente inscritas, de fecha
                  {dateR(1)}; y por la otra parte:{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    {persona.nombreCompleto.toUpperCase()},{" "}
                  </Text>
                  de {calcularEdad(persona.fechaNacimiento)} años de edad,{" "}
                  {estadoSivil(persona.estadoCivil, persona.sexo)},{" "}
                  {persona.nacionalidad.toLowerCase()},
                  {persona.profecion.toLowerCase()}, con residencia y domicilio
                  en la {residenciaP("R")}, se identifica con el Documento
                  Personal de Identificación, con el Código Único de
                  Identificación número: {DPI(persona.numeroDpi)} (
                  {persona.numeroDpi}), extendido por el Registro Nacional de
                  las Personas de la República de Guatemala; a quien en este
                  instrumento Público se le donominará{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA".</Text>
                  {fiador.length > 0 ? " Así tambien" : ""}
                  {fidorContrato2()}
                  Yo el Infrascrito Notario,{" "}
                  <Text style={styles.negrita}>DOY FE: </Text> a) Que las
                  personas antes nombras se identificaron con los documentos
                  indicados, y quienes me aseguran hallarse en el libre
                  ejercicio de sus derechos civiles; b) De tener a la vista la
                  documentación relacionada; c) Que la representación que se
                  ejercita es suficiente conforme a la ley y a mi juicio para el
                  presente acto; y d) Que los otorgantes manifiestan que por
                  medio del presente instrumento público celebran{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    CONTRATO DE MUTUO{" "}
                    {contrato?.garId !== 1
                      ? `${contrato?.tipoGarantia.descripcion.toUpperCase()}`
                      : ``}
                    ,{" "}
                  </Text>
                  conteniendo en las siguientes cláusulas:
                  <Text style={styles.negritaSub}> PRIMERA: </Text>El
                  Prepresentante Legal de{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  manifiesta que según resolucion de crédito{" "}
                  {resolucionCredito()} se acordó a{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> un
                  préstamo a mutuo por la suma de{" "}
                  <Text style={styles.negrita}>
                    {montoL(contrato.monto).toUpperCase()} (Q.{" "}
                    {formatMiles(contrato.monto)}
                    ),
                  </Text>{" "}
                  el cual le hará efectivo{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>
                  cuando se hallan cumplido los requerimientos administrativos
                  exigidos por{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  en
                  {desembolsos()}.{" "}
                  <Text style={styles.negritaSub}> SEGUNDA: </Text>{" "}
                  {persona.sexo == "F" ? "la señora" : "el señor"}{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    {persona.nombreCompleto.toUpperCase()},{" "}
                  </Text>
                  se delcara{" "}
                  {persona.sexo == "F"
                    ? "lisa y llana deudora"
                    : "liso y llano deudor"}{" "}
                  de la
                  <Text style={styles.negrita}>
                    {" "}
                    {representante.empresa?.nombre.toUpperCase()},
                  </Text>{" "}
                  por la suma de préstamo a mutuo por la suma de{" "}
                  <Text style={styles.negrita}>
                    {montoL(contrato.monto).toUpperCase()} (Q.{" "}
                    {formatMiles(contrato.monto)}
                    ),
                  </Text>{" "}
                  {persona.sexo == "F" ? "la señora" : "el señor"}{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    {persona.nombreCompleto.toUpperCase()},{" "}
                  </Text>
                  declara recibir la suma mutuada, en moneda de curso legal, en
                  las circunstancias apuntadas y se obliga a devolverla de
                  conformidad con las estipulaciones a continuación expresadas:{" "}
                  <Text style={styles.negrita}> a)DESTINO:</Text> Este préstamo
                  lo destinapara:{" "}
                  <Text style={styles.negrita}>
                    PRÉSTAMO {contrato.destino.toUpperCase()}, "
                    {representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>
                  , queda falcultad para comprobar la correcta inversión o
                  empleo del mismo y en caso contrario la tasa de interés
                  variará a un {porcentaje(contrato.tasaIncumplimiento)} (
                  {contrato.tasaIncumplimiento}%) de incremento sobree la base
                  establecida en este contrato;{" "}
                  <Text style={styles.negrita}>b) PLAZO: </Text>El pazo para la
                  devolución del capitual es de
                  <Text style={styles.negrita}>
                    {numeroALetras(contrato.palazMeses)} ({contrato.palazMeses})
                    MESES{" "}
                  </Text>
                  contados a partir del{CDate1(contrato.fechaContrato)} con
                  vencimiento el
                  {vencimient(contrato.palazMeses, contrato.fechaContrato)}.
                  debiendo efectuar{" "}
                  <Text style={styles.negrita}>{amortizacionM()}, </Text>más
                  intereses sobre saldos;
                  <Text style={styles.negrita}> d) TASA DE INTERÉS: </Text>Ambas
                  partes convienen expresamente que el préstamo está afectado al
                  pago de interéses del
                  <Text style={styles.negrita}>
                    {porcentaje(contrato.tasaInteger)} ({contrato.tasaInteger}%)
                  </Text>{" "}
                  anual sobre saldos, aceptando{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> que la
                  tasa de interés podrá variar en más o en menos si asi lo
                  dispone el Consejo de Administración o Junta Directiva de{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  y acepta{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> la
                  advertencia que la falta de un pago de una cuota será motivo
                  suficiente para dar por vencido el plazo del préstamo y
                  proceder a su cobro judicial;{" "}
                  <Text style={styles.negrita}>
                    e) TASA DE RECARGO POR MORA:
                  </Text>{" "}
                  En caso de que las amortizaciones{" "}
                  {contrato.frecuenciaPago == "1"
                    ? "semanales"
                    : contrato.frecuenciaPago == "2"
                    ? "quincenales"
                    : contrato.frecuenciaPago == "3"
                    ? "mensuales"
                    : contrato.frecuenciaPago == "1"
                    ? "trimestrales"
                    : contrato.frecuenciaPago == "1"
                    ? "semestral"
                    : contrato.frecuenciaPago == "1"
                    ? "anual"
                    : ""}{" "}
                  no sean canceladas en la forma y fechas convenidas,{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> acepta
                  que el <Text style={styles.negrita}>RECARGO POR MORA </Text>
                  será del {porcentaje(contrato.tasaMora)} ({contrato.tasaMora}
                  %) anual sobre el capital;{" "}
                  <Text style={styles.negrita}>f)</Text> Todo pago del capital
                  más intereses se efectuará sin necesidad de cobro o
                  requerimiento alguno en efectivo, en quetzales, en las
                  oficinas o agencias de{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"; g)
                    "LA PARTE DEUDORA"
                  </Text>{" "}
                  faculta a{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  para ceder, gravar o negociar este préstamo, sin necesidad de
                  previo aviso ni posterior notificación;{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> se
                  obliga a pagar o facultar a{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  para que se le cargue a su cuenta todos aquellos gastos que
                  por cualquier causa se ocacionen con motivo del presente
                  contrato, antes o después de su celebración y las escrituras
                  aclaratorias, de modificación, adición o ampliación y su
                  cancelación más las primas de seguros y gastos que ocasione
                  este negocio;{" "}
                  <Text style={styles.negrita}>j) "LA PARTE DEUDORA"</Text> se
                  obiga a mantener una buena comunicación con{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  en la contratación de servicios y productos financieros que
                  cuenta{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  , la cual al momento de evaluarse podrá incidir en una
                  modificación en la tasa de interés;{" "}
                  <Text style={styles.negrita}>k) "LA PARTE DEUDORA"</Text>{" "}
                  acepta que{" "}
                  {desembolso.length > 1 ? "los desembolsos" : "el desembolso"}{" "}
                  de fondos del presente préstamo se hará de conformidad con la
                  disponibilidad de los recursos de{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"; I)
                    "LA PARTE DEUDORA"
                  </Text>{" "}
                  mientras tenga un préstamo vigente con la Cooperativa{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  no podrá de ninguna manera retirar su cuenta de aportaciones
                  hasta la total cancelación del préstamo, excepto cuando se
                  trata de cancelar el préstamo a través de las aportaciones o
                  cuando existe un exceso, según el Reglamento de Crédito;
                  <Text style={styles.negrita}> m)</Text> En caso de
                  incumplimiento del pago del préstamo, la parte deudora
                  autoriza a{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  a transeferir el monto de sus ahorros corrientes, ahorros a
                  plazo y la cuenta de ahorro de aportaciones para pagar parcial
                  o totalmente este préstamo, y{" "}
                  <Text style={styles.negrita}>n) "LA PARTE DEUDORA"</Text>{" "}
                  renuncia al fuero de su domicilio, y se somete a los
                  tribunales que elija{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  {persona.direccion.length > 1
                    ? "a) residencia: "
                    : " y señala como lugar para recibir notificaciones: "}
                  <Text style={styles.negrita}> {residenciaP("R")}</Text>
                  {persona.direccion.length > 1
                    ? " b) dirección de trabajo:"
                    : ""}
                  <Text style={styles.negrita}> {residenciaP("T")}</Text>,
                  obligándose a comunicar por escrito a{" "}
                  <Text style={styles.negrita}>
                    "{representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>{" "}
                  cualquier cambio de ella hiciera, en el entendido de que a
                  falta de tal aviso se tendrá por válidadas y bien hechas las
                  notifiaciones, citaciones y emplazamientos que se le haga en
                  el lugar indicado.
                  <Text style={styles.negrita}>
                    {" "}
                    TERCERA "
                    {representante.empresa?.nombreAbreviado.toUpperCase()}"
                  </Text>
                  podrá dar por vencido anticipadamente el plazo de esta
                  obligación y exigir ejecutivamente el pago si: a){" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> faltara
                  el pago puntual de una de las cuotas periòdicas y
                  consecutivas; b) Si{" "}
                  <Text style={styles.negrita}>"LA PARTE DEUDORA"</Text> faltare
                  al cumplimiento de cualesquiera de las obligacines por este
                  contrato celebrado.
                  <Text style={styles.negritaSub}>CUARTA:</Text>
                  <Text style={styles.negrita}>
                    {" "}
                    VARIABILIDAD DE LAS CUOTAS:{" "}
                  </Text>{" "}
                  {contrato.frecuenciaPago == "1"
                    ? "semanales"
                    : contrato.frecuenciaPago == "2"
                    ? "quincenales"
                    : contrato.frecuenciaPago == "3"
                    ? "mensuales"
                    : contrato.frecuenciaPago == "1"
                    ? "trimestrales"
                    : contrato.frecuenciaPago == "1"
                    ? "semestral"
                    : contrato.frecuenciaPago == "1"
                    ? "anual"
                    : ""}{" "}
                  convenidas son variables si se aumentaren o disminuyeren en
                  capital y tasa de intéres en base a contigencias, sin que ello
                  constituya novación de crédito.
                  <Text style={styles.negritaSub}>QUINTA:</Text>
                  En garantía del pago de capital adeudado, interes, costas y
                  además obligaciones que por medio de esta escritura la parte
                  deudora:
                  <Text style={styles.negrita}>
                    {" "}
                    {persona.nombreCompleto.toUpperCase()}
                  </Text>{" "}
                  manifiesta <Text style={styles.negrita}> EXPRESAMENTE</Text>{" "}
                  que constituya a favor de la{" "}
                  <Text style={styles.negrita}>
                    {" "}
                    {representante.empresa?.nombre.toUpperCase()}{" "}
                  </Text>
                  {garantia()}
                  {sueldo.length == "0" &&
                  ahorro.length == "0" &&
                  bien.length == "0"
                    ? "sus bienes presentes y futuros sobre los cuales se podrá trabajar embargo en caso de incumplimiento del presente contrato."
                    : ""}
                  <Text style={styles.negrita}>
                    {" "}
                    {representante.empresa?.nombreAbreviado.toUpperCase()},
                  </Text>{" "}
                  {segundoReglon()}
                </Text>
                {/* <Text
                  style={styles.text1}
                  render={({ pageNumber }) => (
                    <Text style={styles.text1}>
                      <Text style={styles.negrita}>
                        ES TESTIMONIO ESPECIAL:{" "}
                      </Text>
                      De la Escritura Pública número{" "}
                      {numeroALetras(contrato.corId).toLowerCase()} , de fecha{" "}
                      {CDate1(contrato.fechaContrato)}, autorizada por mí en el
                      municipio de {contrato.municipio.descripcion},
                      departamento de{" "}
                      {contrato.municipio.departamento.descripcion}. Que, para
                      remitir al{" "}
                      <Text style={styles.negrita}>
                        ARCHIVO GENERAL DE PROTOCOLOS,{" "}
                      </Text>{" "}
                      extendido, numero, firmo y sello, en{" "}
                      {numeroALetras(pageNumber)} hojas de papel bond tamaño
                      oficio, debidamente confrontada con su original, a las que
                      se les adhieren los timpres de ley. En el municipio de{" "}
                      {contrato.municipio.descripcion}, departamento de{" "}
                      {contrato.municipio.departamento.descripcion}.{" "}
                    </Text>
                  )}
                ></Text> */}
              </Page>
            </Document>
          </PDFViewer>
        </>
        <Toast ref={toast} />
      </div>
    );
  }
};
