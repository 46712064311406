import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//services
import MunicipioService from "../../../service/AppService/MunicipioService";
export const Municipio = () => {
  const message = useRef();
  const toast = useRef();
  const location = useLocation();

  let departamentoEmpty = {
    descripcion: "",
    id: 0,
    municipio: [
      {
        depId: 0,
        descripcion: "",
        id: 0,
      },
    ],
  };

  let municipioEmpty = {
    depId: 0,
    descripcion: "",
    id: 0,
  };

  let filtroEmpty = {
    description: "",
  };

  const [departamento, setDepartamento] = useState(departamentoEmpty);
  const [municipio, setMunicipio] = useState(municipioEmpty);
  const [municipios, setMunicipios] = useState([]);
  const [nuevoMunicipio, setNumeoMunicipio] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);

  useEffect(() => {
    setDepartamento(location?.state?.departamento || departamentoEmpty);
  }, []);

  useEffect(() => {
    const municipioService = new MunicipioService();

    municipioService
      .findById(
        departamento.id || location?.state?.departamento?.id,
        filtro.description
      )
      .then((response) => {
        setMunicipios(response);
      });
  }, [filtro.description]);

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;
    setFiltro(_filtro);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _municipio = { ...municipio };
    _municipio[`${name}`] = val;

    setMunicipio(_municipio);
  };

  const dialogFuncMap = {
    nuevoMunicipio: setNumeoMunicipio,
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setMunicipio(municipioEmpty);
  };
  const hideDialog = () => {
    setSubmitted(false);
    onHide("nuevoMunicipio");
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };
  const onSave = () => {
    setSubmitted(true);
    let _municipio = { ...municipio };
    if (municipio.depId === 0) {
      _municipio[`${"depId"}`] = departamento.id;
    }

    if (municipio.descripcion !== "") {
      const municipioService = new MunicipioService();
      municipioService.save(_municipio).then((response) => {
        setNumeoMunicipio(false);
        setMunicipio(municipioEmpty);
        setSubmitted(false);
        if (municipio.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo municipio creado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Municipio actualizado",
            life: 4000,
          });
        }
        // setMunicipios(response);

        municipioService
          .findById(departamento.id, filtro.description)
          .then((response) => {
            setMunicipios(response);
          });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const onEdit = (rowData) => {
    setMunicipio(rowData);
    setNumeoMunicipio(true);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar: "}
        />
      </>
    );
  };

  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h4>Datos del departamento</h4>
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Nombre del departamento</label>
              <InputText
                id="name2"
                type="text"
                value={departamento.descripcion}
                disabled
              />
            </div>
          </div>
          {/* End Row */}
        </div>
        <div className="card p-fluid">
          <h4>Filtro</h4>
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Municipio</label>
              <InputText
                id="name2"
                type="text"
                value={filtro.description}
                onChange={(e) => chengFiltro(e, "description")}
              />
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nuevo municipio"
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick("nuevoMunicipio")}
              />
            </div>
          </div>
          {/* End Row */}
        </div>

        <div className="card p-fluid">
          <h5>Municipios</h5>

          {/* Start Row */}
          <DataTable
            value={municipios}
            emptyMessage="No se encontro ningun municipio"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="descripcion" header="Municipio "></Column>
            <Column header="Acciones" body={accciones}></Column>
          </DataTable>
          {/* End Row */}

          {/* Start Button nuevo Usuario */}

          {/* End Button nuevo Usuario */}
        </div>

        <Dialog
          visible={nuevoMunicipio}
          header={
            municipio.id === 0 ? "Nuevo Municipio" : "Editar  departamento"
          }
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          className="p-fluid"
          onHide={hideDialog}
          autoComplete="off"
        >
          {/* Star Card y columna de datos  */}
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="description">Departamento</label>

                <div className="field">
                  <InputText
                    id="description"
                    value={municipio.descripcion}
                    onChange={(e) => onInputChange(e, "descripcion")}
                    // className={classNames({
                    //   "p-invalid": submitted && !usuario.nombre,
                    // })}
                    autoFocus
                    required
                    autoComplete="off"
                    className={classNames({
                      "p-invalid": submitted && !municipio.descripcion,
                    })}
                  />
                  {submitted && !municipio.descripcion && (
                    <small className="p-invalid">
                      {" "}
                      Ingrese un departamento
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Star Card y columna de datos  */}
          {/* Start button save and delete  */}
          <Messages ref={message} />
          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={hideDialog}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => onSave()}
                />
              </div>
            </div>
          </div>
          {/* finish button save and delete  */}
        </Dialog>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
