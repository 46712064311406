import * as React from "react";
import ReactToPrint from "react-to-print";

export const Contratos = () => {
  const componentRef = React.useRef(null);

  // function imprimirElemento(elemento) {
  //   var ventana = window.open("", "PRINT", "height=400,width=600");
  //   ventana.document.write("<html><head><title>" + document.title + "</title>");
  //   ventana.document.write('<link rel="stylesheet" href="imprimir.css">'); //Cargamos otra hoja, no la normal
  //   ventana.document.write("</head><body >");
  //   ventana.document.write(elemento.innerHTML);
  //   ventana.document.write("</body></html>");
  //   ventana.document.close();
  //   document
  //     .querySelector("#btnImprimirDiv")
  //     .addEventListener("click", function () {
  //       var div = document.querySelector("#imprimible");
  //       imprimirElemento(div);
  //     });
  //   ventana.focus();
  //   ventana.onload = function () {
  //     ventana.print();
  //     ventana.close();
  //   };
  //   return true;
  // }

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {}, []);

  const handleBeforePrint = React.useCallback(() => {}, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button>Print using a Functional Component</button>;
  }, []);

  return (
    <div>
      <div>
        <p
          id="isPasted"
          style={{
            margin: 0,
            fontSize: "16px",
            fontFamily: "Times New Roman",
            marginBottom: "10.0pt",
            textAlign: "justify",
            lineHeight: "24.0pt",
          }}
        >
          <span
          // style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'
          >
            En el municipio de Solol&aacute;, departamento de Solol&aacute;, el
            uno de junio del a&ntilde;o dos mil veintid&oacute;s; comparecemos
            por una parte:{" "}
            <strong>YO PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ</strong>, de
            sesenta a&ntilde;os de edad, unido de hecho, guatemalteco,
            Administrador de Empresas Cooperativas, con residencia y domicilio
            en la Aldea San Juan Argueta, del municipio y departamento de
            Solol&aacute;, me identifico con el Documento Personal de
            Identificaci&oacute;n <strong>&ndash;DPI-&nbsp;</strong>con el
            C&oacute;digo &Uacute;nico de Identificaci&oacute;n{" "}
            <strong>&ndash;CUI-</strong> n&uacute;mero: Dos mil cuatrocientos
            cuarenta y tres espacio ochenta y nueve mil trescientos ochenta y
            uno espacio cero setecientos uno (2443 89381 0701), extendido por el
            Registro Nacional de las Personas de la Rep&uacute;blica de
            Guatemala, act&uacute;o en mi calidad de Representante Legal de la
            Cooperativa Integral de Ahorro y Cr&eacute;dito &ldquo;Fondos de
            Am&eacute;rica&rdquo; Responsabilidad Limitada, Fondos R.L.. Calidad
            que acredito con la certificaci&oacute;n de inscripci&oacute;n
            extendida por el Registro de Cooperativas del Instituto Nacional de
            Cooperativas (INACOP), con&nbsp;
          </span>
        </p>
        {/* <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            n&uacute;mero de Registro ciento veintis&eacute;is (126), folio
            n&uacute;mero ciento veintisiete (127), del libro n&uacute;mero L
            Dos cincuenta y dos mil quinientos ochenta y ocho (L2 52588), de
            inscripciones de Representantes Legales de las Cooperativas,
            legalmente inscritas, de fecha veintid&oacute;s de octubre del
            a&ntilde;o dos mil veintiuno
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            ; siendo tal representaci&oacute;n suficiente de conformidad con la
            ley para la celebraci&oacute;n del presente contrato de mutuo. A la
            Cooperativa Integral de Ahorro y Cr&eacute;dito &ldquo;Fondos de
            Am&eacute;rica, Responsabilidad Limitada, podr&aacute; llamarse
            simplemente en este contrato como &ldquo;FONDOS, R.L.&rdquo; o la
            entidad acreedora. Por la otra parte comparezco:{" "}
            <strong>YO LUCAS HOM TZORIN,&nbsp;</strong>de cuarenta a&ntilde;os
            de edad, casado, guatemalteco, mec&aacute;nico, con residencia y
            domicilio en el Caser&iacute;o Panca,&nbsp;a ciento veinte metros
            del Taller Mec&aacute;nico&nbsp;de Enderezado y Pintura,&nbsp;del
            municipio de Solol&aacute;, departamento de Solol&aacute;,&nbsp;me
            identifico con el Documento Personal de Identificaci&oacute;n{" "}
            <strong>&ndash;DPI-</strong> con el C&oacute;digo &Uacute;nico de
            Identificaci&oacute;n <strong>&ndash;CUI-</strong> n&uacute;mero: Un
            mil setecientos cuarenta y ocho espacio treinta y cinco mil ciento
            treinta y dos espacio cero setecientos uno (1748 35132 0701),
            extendido por el Registro Nacional de las Personas de la
            Rep&uacute;blica de Guatemala, a<strong>&nbsp;</strong>quien en el
            presente contrato se me denominar&aacute;:{" "}
            <strong>&ldquo;el deudor&rdquo;.</strong> Ambos aseguramos ser de
            los datos anteriormente relacionados y encontr&aacute;ndonos en el
            libre ejercicio de nuestros derechos civiles y por este acto
            celebramos el presente{" "}
            <strong>
              <u>
                CONTRATO DE MUTUO CON GARANT&Iacute;A SOBRE BIEN INMUEBLE DE
                DERECHOS POSESORIOS EN DOCUMENTO PRIVADO CON LEGALIZACI&Oacute;N
                DE FIRMAS
              </u>
            </strong>{" "}
            conforme a las cl&aacute;usulas siguientes:
            <strong>
              &nbsp;<u>PRIMERA</u>
            </strong>
            <u>:</u> YO,{" "}
            <strong>PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ,&nbsp;</strong>
            declaro que en la calidad con la que act&uacute;o, seg&uacute;n
            resoluci&oacute;n Gerente de Agencia Solol&aacute; de fecha uno de
            junio del a&ntilde;o dos mil veintid&oacute;s, n&uacute;mero RGAS
            guion cero ciento seis diagonal dos mil veintid&oacute;s
            (RGAS-0106/2022). Por este acto otorgo al deudor{" "}
            <strong>LUCAS HOM TZORIN,&nbsp;</strong>un pr&eacute;stamo con
            garant&iacute;a sobre bien inmueble de derechos posesorios, bajo las
            siguientes condiciones:
            <strong>
              &nbsp;a) <u>MONTO:</u>
            </strong>{" "}
            por la cantidad de:
            <strong>
              &nbsp;DIEZ MIL QUETZALES EXACTOS (Q. 10,000.00). b){" "}
              <u>DESTINO:</u>
            </strong>{" "}
            fondos que se invertir&aacute;n exclusivamente para:
            <strong>
              &nbsp;PR&Eacute;STAMO VIVIENDA (COMPRA DE MATERIALES DE
              CONSTRUCCI&Oacute;N),&nbsp;
            </strong>
            en caso contrario por si cambiare el destino de este contrato, sin
            previa autorizaci&oacute;n de <strong>FONDOS, R.L.,</strong>{" "}
            tendr&aacute; la facultad de incrementar de forma inmediata el seis
            por ciento (6%) sobre la tasa de inter&eacute;s anual pactado en
            este contrato.
            <strong>
              &nbsp;c) <u>PLAZO:</u>&nbsp;
            </strong>
            el plazo convenido para el presente contrato mutuo es de{" "}
            <strong>TREINTA Y SEIS (36) MESES</strong> a partir del uno de junio
            del a&ntilde;o dos mil veintid&oacute;s con vencimiento el uno de
            junio del a&ntilde;o dos mil veinticinco.{" "}
            <strong>
              d) <u>FORMA DE PAGO</u>:&nbsp;
            </strong>
            la parte deudora pagar&aacute; este pr&eacute;stamo en{" "}
            <strong>
              treinta y cinco (35) amortizaciones mensuales de: DOSCIENTOS
              SETENTA Y OCHO QUETZALES (Q. 278.00) cada una, y una (1)
              &uacute;ltima amortizaci&oacute;n de: DOSCIENTOS SETENTA QUETZALES
              (Q. 270.00),&nbsp;
            </strong>
            m&aacute;s intereses sobre saldos de capital, mismos que
            har&aacute;n efectivos en las cajas de
            <strong>&nbsp;FONDOS, R.L.,&nbsp;</strong>las que el deudor conoce
            perfectamente sin necesidad de cobro ni requerimiento alguno.{" "}
            <strong>
              e) <u>INTERESES</u>
            </strong>
            : la cantidad mutuada devengar&aacute; a partir de esta fecha el
            veinticuatro por ciento (24%) de intereses anual sobre saldos, tasa
            de inter&eacute;s que podr&aacute; variar en m&aacute;s o menos si
            as&iacute; lo dispone el Consejo de Administraci&oacute;n de{" "}
            <strong>FONDOS, R.L.,&nbsp;</strong>asimismo tendr&aacute; un
            incremento inmediato del dos por ciento (2%) mensual sobre saldo en
            caso de que incurriera en mora a partir de los cinco d&iacute;as de
            atraso del pago de capital mensual. <strong>f)</strong>{" "}
            <strong>
              <u>
                MOROSIDAD: incumplimiento de los pagos de las amortizaciones en
                las fechas convenidas
              </u>
            </strong>
            : en caso de atraso en el pago de los abonos y de intereses, el
            deudor pagar&aacute; a<strong>&nbsp;FONDOS, R.L.,</strong> el&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            veinticuatro por ciento (2
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            4%) anual de recargo por mora, sobre el capital e intereses
            atrasados. <strong>g)</strong>{" "}
            <strong>
              <u>FECHA DE PAGO DE LAS AMORTIZACIONES:</u>
            </strong>{" "}
            las cuotas de capital m&aacute;s intereses devengados se
            pagar&aacute;n los d&iacute;as&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:red;'>
            UNO&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            de cada mes, a partir del mes de&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:red;'>
            julio&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            del a&ntilde;o dos mil veintid&oacute;s.{" "}
            <strong>
              h) <u>DESEMBOLSOS</u>
            </strong>
            <u>:</u> Los recursos de este pr&eacute;stamo los entrega
            <strong>&nbsp;FONDOS, R.L.,</strong> al deudor,
            <strong>&nbsp;</strong>mediante un solo desembolso de:
            <strong>
              &nbsp;DIEZ MIL QUETZALES EXACTOS (Q. 10,000.00). i)
            </strong>{" "}
            <strong>FONDOS, R.L.,&nbsp;</strong>dar&aacute; por vencido el plazo
            y suspender&aacute; las entregas pendientes de capital, si las
            hubiere, si el asociado cambia el destino del capital mutuado, al
            que se&ntilde;al&oacute; en la resoluci&oacute;n y si se incumple
            cualquiera de las obligaciones asumidas por la parte deudora en este
            contrato, y pudi&eacute;ndose exigir el cumplimiento de las
            obligaciones en juicio ejecutivo por la totalidad de lo adeudado, es
            decir, capital, m&aacute;s intereses, recargos por mora, costas
            judiciales y otros gastos que se llegara a ocasionar, por lo que
            para el caso de la demanda, como parte deudora desde ya acepto como
            buenas, exactas, liquidas y exigibles las cantidades que se reclamen
            a consecuencia de este mutuo el cual &nbsp;se constituye en
            t&iacute;tulo ejecutivo.&nbsp;
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              j)&nbsp;
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            El asociado mientras tenga un pr&eacute;stamo vigente con la
            Cooperativa <strong>&ldquo;FONDOS, R.L.&rdquo;&nbsp;</strong>
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            no podr&aacute; de ninguna manera retirar su cuenta de aportaciones
            hasta la total cancelaci&oacute;n del pr&eacute;stamo, excepto
            cuando se trata de cancelar el pr&eacute;stamo a trav&eacute;s de
            las aportaciones o cuando existe un exceso, seg&uacute;n el
            Reglamento de Cr&eacute;dito.&nbsp;
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              k)
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
            <strong>
              <u>PARA LOS EFECTOS:</u>
            </strong>{" "}
            de este contrato y su eventual incumplimiento y demanda judicial, yo{" "}
            <strong>LUCAS HOM TZORIN,&nbsp;</strong>renuncio al fuero de mi
            domicilio y me someto a los tribunales que{" "}
            <strong>FONDOS, R.L.,&nbsp;</strong>elija, se&ntilde;alando como
            lugar para recibir notificaciones, el lugar de mi residencia
            se&ntilde;alada en la parte introductoria de este contrato.
            <strong>
              &nbsp;l) <u>EN GARANT&Iacute;A</u>
            </strong>
            : de la cantidad recibida en calidad de mutuo como capital,
            intereses, recargos y costas judiciales si fuera el caso, por este
            acto yo <strong>LUCAS HOM TZORIN,&nbsp;</strong>otorgo mis bienes
            presentes y futuros, <strong>especialmente&nbsp;</strong>constituyo
            a favor de <strong>FONDOS, R.L.</strong> Gravamen Especial sobre los
            derechos posesorios que ejerzo sobre un bien inmueble de naturaleza
            URBANA, ubicado en el lugar denominado{" "}
            <strong>
              CASER&Iacute;O PANCA, A CIENTO VEINTE METROS DEL TALLER
              MEC&Aacute;NICO DE ENDEREZADO Y PINTURA, DEL MUNICIPIO DE
              SOLOL&Aacute;
            </strong>
            <strong>, DEPARTAMENTO DE SOLOL&Aacute;,&nbsp;</strong>que carece de
            t&iacute;tulo inscrito y matr&iacute;cula fiscal,&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            inmueble&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            que cuenta con una extensi&oacute;n superficial&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            de
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            :
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
            <strong>
              TRESCIENTOS SETENTA Y NUEVE PUNTO DIECIS&Eacute;IS METROS
              CUADRADOS (379.16 Mts.<sup>2</sup>)
            </strong>
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              ,
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;el cual se encuentra dentro de las medidas y colindancias
            siguientes:&nbsp;
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              NORTE:
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;nueve metros, setenta y dos cent&iacute;metros con Agustin Hom
            Tzor&iacute;n; <strong>SUR:</strong> diecisiete metros, veinte
            cent&iacute;metros con Isabel Tzor&iacute;n Martin;{" "}
            <strong>ORIENTE:</strong> veintis&eacute;is metros, setenta
            cent&iacute;metros con Domingo Par V&aacute;squez;{" "}
            <strong>PONIENTE:</strong> veintinueve metros con Pedro
            Tzor&iacute;n Par. Actualmente sobre el inmueble se encuentran
            construidas dos casas de habitaci&oacute;n, la primera de dos pisos,
            de paredes de block, de cuatro ambientes, con techos de terraza, la
            segunda de paredes de block y adobe, de dos ambientes, con techo de
            l&aacute;mina de zinc, cuentan con los servicios b&aacute;sicos de
            agua potable y energ&iacute;a el&eacute;ctrica
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            ,&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            topogr&aacute;ficamente inclinado.
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            Sobre el inmueble que otorgo en Garant&iacute;a y sobre sus frutos
            no pesan grav&aacute;menes, anotaciones, ni limitaciones que puedan
            afectar los derechos de <strong>&ldquo;FONDOS, R. L.&rdquo;</strong>{" "}
            Estos derechos posesorios los amparo mediante{" "}
            <strong>PRIMERA COPIA LEGALIZADA&nbsp;</strong>de la escritura
            p&uacute;blica n&uacute;mero <strong>NOVENTA Y DOS (92),</strong>{" "}
            autorizada en la Ciudad de Solol&aacute;, Departamento de
            Solol&aacute;, el veintis&eacute;is de febrero del a&ntilde;o dos
            mil veintid&oacute;s, por el Notario BALVINO OBED GARC&Iacute;A
            MONZ&Oacute;N, a favor de: <strong>LUCAS HOM TZORIN,&nbsp;</strong>
            escritura que dejo en poder de la entidad acreedora, inmueble sobre
            el cual se podr&aacute; trabar embargo en caso de incumplimiento del
            presente contrato.<strong>&nbsp;</strong>Asimismo; yo{" "}
            <strong>LUCAS HOM TZORIN,&nbsp;</strong>otorgo todas las facultades
            a la cooperativa <strong>FONDOS, R.L.,</strong> para que pueda
            transferir en forma autom&aacute;tica el valor de mis ahorros a
            plazo y corriente, tambi&eacute;n mis aportaciones para abonar
            capital, intereses y otros costos de mutuo que he recibido el
            d&iacute;a de hoy, cuando incurra en morosidad o pagos atrasados a
            partir de un mes de retraso, quedo debidamente enterado de que el
            monto del pr&eacute;stamo vigente tendr&aacute; car&aacute;cter de
            cedible, negociable o transmisible a cualquier titulo sin necesidad,
            de previo ni posterior aviso a la parte deudora.{" "}
            <strong>m) YO LUCAS HOM TZORIN,</strong> me reconozco liso y llano
            deudor de <strong>FONDOS, R.L.,&nbsp;</strong>asimismo me comprometo
            formalmente a usar la suma recibida en la forma solicitada,
            seg&uacute;n el plan de inversi&oacute;n, el cual reconozco como
            parte del contrato, y en forma expresa acepto que, en caso de falta
            de pago de esta obligaci&oacute;n, pueden trasladar los fondos que
            tenga en<strong>&nbsp;FONDOS, R.L.,</strong> en pago del mutuo y sus
            intereses, de acuerdo con la ley, los estatutos, y las normas
            reglamentarias legalmente emitidas. <strong>n)</strong>{" "}
            <strong>SEGUROS Y COSTOS ADMINISTRATIVOS</strong>: yo{" "}
            <strong>LUCAS HOM TZORIN,</strong> autorizo a{" "}
            <strong>FONDOS, R.L.,&nbsp;</strong>para que proceda a cargar en el
            saldo del pr&eacute;stamo el valor del seguro de protecci&oacute;n
            del pr&eacute;stamo, el seguro m&eacute;dico, el seguro de vida, y
            otros cargos relacionados; ya sea en forma mensual o anual,
            seg&uacute;n las pol&iacute;ticas de seguros y cargos
            administrativos vigentes en la cooperativa, con el entendido que el
            seguro de pr&eacute;stamo solo es aplicable cuando al deudor
            fallezca y el pr&eacute;stamo se encuentra al d&iacute;a con los
            pagos; caso contrario el pr&eacute;stamo pierde el beneficio del
            seguro. <strong>&ntilde;) CLAUSULA RESOLUTORIA:&nbsp;</strong>se
            <strong>&nbsp;</strong>dar&aacute; por vencido el plazo establecido
            en el presente contrato por incumplimiento de pago a partir de una
            amortizaci&oacute;n de capital e intereses, como consecuencia se
            proceder&aacute; a exigir su cumplimiento en juicio ejecutivo por
            considerarse cantidad de dinero de plazo vencido, liquido y
            exigible, lo que la parte deudora acepta en forma consciente y
            voluntaria tal circunstancia.{" "}
            <strong>
              <u>SEGUNDA:</u> YO
            </strong>{" "}
            <strong>PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ</strong>,
            expresamente acepto la garant&iacute;a constituida por el deudor
            <strong>&nbsp;LUCAS HOM TZORIN,&nbsp;</strong>a favor de mi
            representada. Nosotros los comparecientes manifestamos cada uno
            nuestra conformidad y aceptaci&oacute;n con las cl&aacute;usulas de
            este contrato; finalmente, cada uno de los comparecientes damos por
            s&iacute; lectura integra a todo lo escrito e impuesto de su
            contenido, validez y obligaciones que contraemos lo ratificamos,
            aceptamos y firmamos; as&iacute; mismo, yo{" "}
            <strong>LUCAS HOM TZORIN,&nbsp;</strong>como deudor, adem&aacute;s
            de mi firma para mayor seguridad de <strong>FONDOS, R.L.,</strong>{" "}
            dejo la impresi&oacute;n dactilar de mi dedo pulgar de la mano
            derecha.
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp;&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            __________________________________ &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp;______________________________
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &nbsp;
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp;
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              LUCAS HOM TZORIN
            </span>
          </strong>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Representante
            Legal &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; Deudor &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:justify;line-height:24.0pt;'>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              ACTA DE LEGALIZACI&Oacute;N DE FIRMAS:
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;En el municipio de Solol&aacute;, departamento de
            Solol&aacute;, el uno de junio del a&ntilde;o dos mil
            veintid&oacute;s. YO, el infrascrito Notario, DOY FE: que las firmas
            que anteceden son <strong>AUT&Eacute;NTICAS,</strong> en virtud de
            haber sido puestas en mi presencia por el se&ntilde;or:{" "}
            <strong>PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ,</strong> quien
            se identifica con el Documento Personal de
            Identificaci&oacute;n&nbsp;
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &ndash;
            </span>
          </strong>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              DPI-
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;con el C&oacute;digo &Uacute;nico de
            Identificaci&oacute;n&nbsp;
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &ndash;
            </span>
          </strong>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              CUI-
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;n&uacute;mero: Dos mil cuatrocientos cuarenta y tres espacio
            ochenta y nueve mil trescientos ochenta y uno espacio cero
            setecientos uno (2443 89381 0701), extendido por el Registro
            Nacional de las Personas de la Rep&uacute;blica de Guatemala; y por
            el se&ntilde;or: <strong>LUCAS HOM TZORIN</strong>
          </span>
          <strong>
            <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
              ,&nbsp;
            </span>
          </strong>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            quien se identifica con el Documento Personal de
            Identificaci&oacute;n <strong>&ndash;DPI-</strong> con el
            C&oacute;digo &Uacute;nico de Identificaci&oacute;n{" "}
            <strong>&ndash;CUI-</strong> n&uacute;mero:&nbsp;
          </span>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            Un mil setecientos cuarenta y ocho espacio treinta y cinco mil
            ciento treinta y dos espacio cero setecientos uno (1748 35132 0701),
            extendido por el Registro Nacional de las Personas de la
            Rep&uacute;blica de Guatemala; quienes nuevamente firman la presente
            acta de legalizaci&oacute;n; as&iacute; mismo, adem&aacute;s de la
            firma del se&ntilde;or <strong>LUCAS HOM TZORIN,&nbsp;</strong>para
            mayor seguridad de<strong>&nbsp;FONDOS, R.L.,&nbsp;</strong>deja la
            impresi&oacute;n dactilar del dedo pulgar de la mano derecha,
            juntamente con el Notario, que de todo lo actuado doy fe.
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          </span>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            __________________________________ &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp;______________________________
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &nbsp;
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp;
            </span>
          </strong>
          <strong>
            <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
              LUCAS HOM TZORIN
            </span>
          </strong>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Representante
            Legal &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; Deudor
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:15px;font-family:"Calibri",sans-serif;'>
          <span style='font-family:"Arial Narrow",sans-serif;color:#171717;'>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;
          </span>
        </p>

        <p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;margin-bottom:10.0pt;text-align:justify;line-height:24.0pt;'>
          <span style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'>
            ANTE M&Iacute;:
          </span>
        </p> */}
      </div>
    </div>
  );
};
