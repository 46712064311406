import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";

import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { Messages } from "primereact/messages";

//Servicios
import EmpresaService from "../../service/AppService/EmpresaService";

export const Empresa = () => {
  const toast = useRef();
  const message = useRef();
  let empresaEmpty = {
    estado: "A",
    id: 0,
    nombre: "",
    nombreAbreviado: "",
  };

  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState(empresaEmpty);

  const [nuevaEmpresa, setNuevaEmpresa] = useState(false);

  const onInputChangeReg = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _empresa = { ...empresa };
    _empresa[`${name}`] = val;

    setEmpresa(_empresa);
  };

  //para checksbox
  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _empresa = { ...empresa };
    _empresa[`${name}`] = val;

    setEmpresa(_empresa);
  };

  useEffect(() => {
    const empresaService = new EmpresaService();
    empresaService.findAll().then((response) => {
      setEmpresas(response);
    });
  }, []);

  const dialogFuncMap = {
    nuevaEmpresa: setNuevaEmpresa,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setEmpresa(empresaEmpty);
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onEdit = (rowData) => {
    setEmpresa(rowData);
    setNuevaEmpresa(true);
  };

  const edit = (rowData) => {
    return (
      <Button
        className="p-button-rounded p-button-info mr-2 p-mr-2"
        icon="pi pi-pencil"
        onClick={() => onEdit(rowData)}
        tooltip={"Editar"}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Link
          to={{
            pathname: "/editarempresa",
            state: {
              empresa: rowData,
            },
          }}
        >
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-success  mr-2 p-mr-2"
            tooltip={"Ver registro inacop"}
          />
        </Link>
        {edit(rowData)}
      </div>
    );
  };

  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );
  const save = () => {
    let _empresa = { ...empresa };

    //instancio la empresa
    const empresaServie = new EmpresaService();

    //llamo la clase guardar
    if (empresa.nombre && empresa.nombreAbreviado !== "") {
      empresaServie.save(_empresa).then((response) => {
        setNuevaEmpresa(false);
        const empresaService1 = new EmpresaService();
        empresaService1.findAll().then((response) => {
          setEmpresas(response);
          setEmpresa(empresaEmpty);
          if (empresa.id === 0) {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Nueva empresa creada",
              life: 4000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Empresa actualizada",
              life: 4000,
            });
          }
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 10,
            }}
          >
            {" "}
            <div
              style={{
                flex: 1,
              }}
            >
              {" "}
              <h5>Datos de las Instituciones</h5>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 45,
              }}
            >
              {" "}
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nuevo Institución"
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick("nuevaEmpresa")}
              />
            </div>
          </div>

          {/* Start Row */}
          <DataTable
            value={empresas}
            emptyMessage="No se encontro ninguna empresa"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="nombre" header="Nombre de la institución"></Column>
            <Column field="nombreAbreviado" header="Nombre Abreviado"></Column>
            <Column header="Estado" body={teplateBodyEstado}></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>

          {/* End Row */}
        </div>
      </div>

      <Dialog
        visible={nuevaEmpresa}
        onHide={() => onHide("nuevaEmpresa")}
        header="Nueva empresa"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
      >
        <div className="card p-fluid">
          {/* Start Row */}

          <div className="formgrid grid">
            <div className="field col-12">
              <label htmlFor="email2">Nombre</label>
              <InputText
                id="name2"
                type="text"
                value={empresa.nombre}
                onChange={(e) => onInputChangeReg(e, "nombre")}
              />
            </div>

            <div className="field col">
              <label htmlFor="email2">Nombre Abreviado</label>
              <InputText
                id="email2"
                type="text"
                value={empresa.nombreAbreviado}
                onChange={(e) => onInputChangeReg(e, "nombreAbreviado")}
              />
            </div>
            <div
              className="col"
              style={{
                margin: "auto",
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
              }}
            >
              <div className="field-checkbox">
                <Checkbox
                  inputId="checkOption1"
                  name="option"
                  //   className=" ml-2 mr-2 mb-2"
                  checked={empresa.estado === "I" ? false : true}
                  onChange={(e) =>
                    onInputChangeRecCheckbox(
                      e.target.checked === true ? "A" : "I",
                      "estado"
                    )
                  }
                />
                <label htmlFor="checkOption1">
                  {empresa.estado === "A" ? "Activo" : "Inactivo"}
                </label>
              </div>
            </div>
          </div>
          {/* End Row */}

          {/* Start Row */}

          {/* End Row */}

          {/* Start Row */}
        </div>
        <Messages ref={message} />
        {/* Start button save and delete  */}
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={() => onHide("nuevaEmpresa")}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                //  onClick={toggleDataTable}
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
        {/* finish button save and delete  */}
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
