//services
import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";

import { Toast } from "primereact/toast";

//services
import DireccionService from "../../service/AppService/DireccionService";

//components
import { NuevaDireccion } from "../NuevaDireccion";

export const Direccion = ({ cliente }) => {
  const toast = useRef();
  const message = useRef();
  let direccionEmpty = {
    descripnDireccion: "",
    id: 0,
    inmId: null,
    munID: 0,
    municipio: {
      depId: 0,
      descripcion: "",
      id: 0,
    },
    persona: null,
    tipo: "",
  };

  const [direccion, setDireccion] = useState(direccionEmpty);
  const [direciones, setDirecciones] = useState([]);
  const [eliminar, setEliminar] = useState(false);
  const [direccionesA, setDireccionesA] = useState(false);

  useEffect(() => {
    const direccionService = new DireccionService();

    direccionService.findByIdPer(cliente?.id || 0).then((response) => {
      setDirecciones(response);
    });
  }, []);

  const dialogFuncMap = {
    direccionesA: setDireccionesA,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setDireccion(direccionEmpty);
  };

  const hideDialog = () => {
    onHide("direccionesA");

    const direccionService = new DireccionService();
    direccionService.findByIdPer(cliente?.id || 0).then((response) => {
      setDirecciones(response);
    });
  };
  const exit = () => {
    setDireccionesA(false);
    toast.current.show({
      severity: "error",
      summary: "error",
      detail: "Cambios descartados ",
      life: 4000,
    });

    const direccionService = new DireccionService();
    direccionService.findByIdPer(cliente?.id || 0).then((response) => {
      setDirecciones(response);
    });
  };

  const teplateBodyEstado = (rowData) => {
    return (
      <div>
        {rowData.tipo === "R"
          ? "Residencia"
          : rowData.tipo === "T"
          ? "Trabajo"
          : rowData.tipo === "I"
          ? "Inmueble"
          : "otro"}
      </div>
    );
  };

  const onEdit = (rowData) => {
    setDireccion(rowData);
    setDireccionesA(true);
  };
  const elminar = (rowData) => {
    setDireccion(rowData);
    setDireccionesA(true);
    setEliminar(true);
  };

  const accciones = (rowData) => {
    return (
      <div>
        <Button
          className="p-button-rounded p-button-info  mr-2 p-mr-2"
          icon="pi pi-pencil"
          tooltip={"Editar"}
          onClick={() => onEdit(rowData)}
        />
        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => elminar(rowData)}
          tooltip={"Eliminar"}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div
              className="formgrid grid
          "
            >
              <div className="field col-4">
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick("direccionesA")}
                />
              </div>
            </div>
            <DataTable
              value={direciones}
              emptyMessage="No se encontro ninguna direccion"
            >
              <Column
                field="municipio.departamento.descripcion"
                header="Departamento "
              ></Column>
              <Column
                field="municipio.descripcion"
                header="Municipio "
              ></Column>
              <Column field="descripnDireccion" header="Descripcion "></Column>

              <Column
                body={teplateBodyEstado}
                header="Tipo de direccion "
              ></Column>
              <Column body={accciones} header="Tipo de direccion "></Column>
            </DataTable>
          </div>

          <Dialog
            header={
              direccion.id === 0 ? "Nueva de direccion" : "Editar direccion"
            }
            visible={direccionesA}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            style={{ width: "50vw" }}
            modal
            className="p-fluid"
            onHide={exit}
          >
            <NuevaDireccion
              hideDialag={hideDialog}
              persona={cliente}
              direccione={direccion}
              toast={toast}
              eliminar={eliminar}
              setEliminar={setEliminar}
            ></NuevaDireccion>
          </Dialog>
          <Toast ref={toast} />
        </div>
      </div>
    </div>
  );
};
