import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";

//servicios
import UsuarioService from "../../service/AppService/UsuarioService";
import SucursalService from "../../service/AppService/SucursalNoatarioService";

//Components
import { Contraseña } from "./Contraseña";

export const Usuario = () => {
  const toast = useRef();
  const message = useRef();
  //Json a utilizar
  let usaurioEmpty = {
    apellido: "",
    correo: "",
    estado: "A",
    fechaIngreso: "",
    id: 0,
    nombre: "",
    password: "",
    rol: "",
    sucId: 0,
    usuario: "",
    sucursal: {
      enmid: 0,
      estado: "",
      id: 0,
      munid: 0,
      nombre: "",
      ubicacion: "",
    },
  };

  let sucursalesEmpty = {
    enmid: 0,
    estado: "",
    id: 0,
    munid: 0,
    nombre: "",
    ubicacion: "",
  };

  let password = {
    password: "",
    confirmPassword: "",
  };

  let filtroEmpty = {
    usuario: "",
    nombre: "",
    apellido: "",
  };
  //uses State
  const [usuarios, setUsuarios] = useState([]);
  const [contraseña, setContraseña] = useState(password);
  const [usuario, setUsuario] = useState(usaurioEmpty);
  const [appSucursales, setAppSucursales] = useState(sucursalesEmpty);
  const [nuevoUsuario, setNuevoUsuario] = useState(false);
  const [cambiarContraseña, setCambiarContraseña] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);

  const hideDialog = () => {
    setSubmitted(false);
    onHide("nuevoUsuario");
    setCambiarContraseña(false);
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  //capturar cambios
  const onInputChangeReg = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _usuario = { ...usuario };
    _usuario[`${name}`] = val;

    setUsuario(_usuario);
  };

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };

  //capturar cambios Dropdown's
  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _usuario = { ...usuario };
    _usuario[`${name}`] = val;

    _usuario[`${key}`] = value;

    setUsuario(_usuario);
  };

  const onInputChangeCon = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _contraseña = { ...contraseña };
    _contraseña[`${name}`] = val;

    let _usuario = { ...usuario };
    _usuario[`${name}`] = val;

    setUsuario(_usuario);
    setContraseña(_contraseña);
  };

  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _usuario = { ...usuario };
    _usuario[`${name}`] = val;

    setUsuario(_usuario);
  };

  //mostrar puff
  const dialogFuncMap = {
    nuevoUsuario: setNuevoUsuario,
    cambiarContraseña: setCambiarContraseña,
  };

  //accion para los botones y el puff
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setUsuario(usaurioEmpty);
  };

  const onEdit = (rowData) => {
    setUsuario(rowData);
    setNuevoUsuario(true);
  };

  const onPassword = (rowData) => {
    setUsuario(rowData);
    setCambiarContraseña(true);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar: "}
        />
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-key"
          onClick={() => onPassword(rowData)}
          tooltip={"Contraseña"}
        />
      </>
    );
  };

  //servicios para las acciones
  const save = () => {
    setSubmitted(true);

    let _usuario = { ...usuario };
    if (usuario.id === 0) {
      if (
        contraseña.password === contraseña.confirmPassword &&
        contraseña.password !== ""
      ) {
        //instancio la empresa
        const usuarioService = new UsuarioService();

        //llamo la clase guardar
        usuarioService.save(_usuario).then(() => {
          setSubmitted(false);
          setNuevoUsuario(false);
          setUsuario(usaurioEmpty);
          usuarioService.findAll().then((response) => {
            setUsuarios(response);
          });
        });
        if (usuario.id == 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: "Nuevo usuario creado",
            life: 4000,
          });
        }
      } else {
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Contraseñas no coinciden",
          life: 4000,
        });
        setContraseña(password);
      }
    } else {
      const usuarioService = new UsuarioService();
      //llamo la clase guardar
      usuarioService.save(_usuario).then(() => {
        setSubmitted(false);
        setNuevoUsuario(false);
        setUsuario(usaurioEmpty);
        usuarioService.findAll().then((response) => {
          setUsuarios(response);
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: "Usuario actualizado",
            life: 4000,
          });
        });
      });
    }
  };

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaIngreso);
  };

  useEffect(() => {
    const usuarioService = new UsuarioService();

    usuarioService
      .findByUsuNomApe(filtro.usuario, filtro.nombre, filtro.apellido)
      .then((response) => {
        setUsuarios(response);
      });

    const sucursalService = new SucursalService();
    sucursalService.findAll().then((response) => {
      setAppSucursales(response);
    });
  }, [filtro.usuario, filtro.nombre, filtro.apellido]);

  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );

  return (
    <div className="grid">
      <Messages ref={message} />
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 10,
            }}
          >
            {" "}
            <div
              style={{
                flex: 1,
              }}
            >
              {" "}
              <h4>Filtros</h4>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 10,
              }}
            >
              {" "}
              <Button
                style={{
                  width: "200px",
                  height: "30px",
                }}
                label="Nuevo Usuario"
                className="p-button-success mr-2 mb-2"
                icon="pi pi-external-link"
                onClick={() => onClick("nuevoUsuario")}
              />
            </div>
          </div>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col-2">
              <label htmlFor="nombre">Usuario</label>

              <div className="field">
                <InputText
                  id="filtroUsuario"
                  value={filtro.usuario}
                  autoComplete="off"
                  onChange={(e) => chengFiltro(e, "usuario")}
                />
              </div>
            </div>

            <div className="field col">
              <label htmlFor="nombre">Nombre</label>

              <div className="field">
                <InputText
                  id="filtroNombre"
                  autoComplete="off"
                  value={filtro.nombre}
                  onChange={(e) => chengFiltro(e, "nombre")}
                />
              </div>
            </div>

            <div className="field col">
              <label htmlFor="apellido">Apellido</label>

              <div className="field">
                <InputText
                  id="filtroApellido"
                  autoComplete="off"
                  type="text"
                  value={filtro.apellido}
                  onChange={(e) => chengFiltro(e, "apellido")}
                />
              </div>
            </div>
          </div>
          {/* End fila nuevo Usuario */}
        </div>
        <div className="card p-fluid">
          <h4>Usuarios</h4>

          {/* Start Row */}
          <DataTable
            value={usuarios}
            emptyMessage="No se encontro ningun usuario"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="usuario" header="usuario "></Column>
            <Column field="nombre" header="Nombre "></Column>
            <Column field="apellido" header="Apellido "></Column>

            <Column field="correo" header="correo "></Column>
            <Column body={dateBodyTemplate} header="Fecha nacimiento "></Column>
            <Column header="Estado" body={teplateBodyEstado}></Column>
            <Column header="Acciones  " body={accciones}></Column>
          </DataTable>
          {/* End Row */}
          {/* Start Button nuevo Usuario */}

          {/* End Button nuevo Usuario */}
        </div>
      </div>

      {/* Start Puff nuevo Usuario */}
      <Dialog
        visible={nuevoUsuario}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        className="p-fluid"
        onHide={hideDialog}
        header={
          usuario.id === 0
            ? "Nuevo usuario"
            : `Editar usuario de ${usuario.nombre}`
        }
        autoComplete="off"
      >
        {/* <div className="card p-fluid"> */}

        <div className="card p-fluid">
          {/* Start fila nuevo Usuario */}

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="nombre">Nombre</label>

              <div className="field">
                <InputText
                  id="nombre"
                  value={usuario.nombre}
                  onChange={(e) => onInputChangeReg(e, "nombre")}
                  required
                  // className={classNames({
                  //   "p-invalid": submitted && !usuario.nombre,
                  // })}
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !usuario.nombre,
                  })}
                />
                {submitted && !usuario.name && (
                  <small className="p-invalid"> Nombre requerido.</small>
                )}
              </div>
            </div>

            <div className="field col">
              <label htmlFor="apellido">Apellido</label>

              <div className="field">
                <InputText
                  id="apellido"
                  type="text"
                  value={usuario.apellido}
                  onChange={(e) => onInputChangeReg(e, "apellido")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !usuario.apellido,
                  })}
                />
                {submitted && !usuario.apellido && (
                  <small className="p-invalid">apellido requirido</small>
                )}
              </div>
            </div>
          </div>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="correo">Correo</label>
              <InputText
                id="correo"
                type="text"
                value={usuario.correo}
                onChange={(e) => onInputChangeReg(e, "correo")}
                required
                className={classNames({
                  "p-invalid": submitted && !usuario.correo,
                })}
              />
              {submitted && !usuario.correo && (
                <small className="p-invalid">Correo requirido</small>
              )}
            </div>

            <div className="field col">
              <label htmlFor="sucursal">Sucursal</label>
              <Dropdown
                value={usuario.sucursal}
                options={appSucursales}
                onChange={(e) =>
                  onInputChangeRegObject(e, "sucursal", e.value.id, "sucId")
                }
                optionLabel="nombre"
                placeholder="Seleccione una sucursal"
                required
                className={classNames({
                  "p-invalid": submitted && !usuario.sucursal,
                })}
              />
              {submitted && !usuario.sucursal && (
                <small className="p-invalid">Sucursal requirido</small>
              )}
            </div>
          </div>

          {/* End fila nuevo Usuario */}
          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid">
            <div className="field col-4">
              <label htmlFor="usuario">Usuario</label>
              <InputText
                id="usuario"
                type="text"
                value={usuario.usuario}
                onChange={(e) => onInputChangeReg(e, "usuario")}
                required
                className={classNames({
                  "p-invalid": submitted && !usuario.usuario,
                })}
              />
              {submitted && !usuario.usuario && (
                <small className="p-invalid">Usuario requirido</small>
              )}
            </div>
            <div className="field col-4">
              <label htmlFor="email2">Fecha de nacimiento</label>
              <InputText
                id="name2"
                type="date"
                value={usuario.fechaIngreso}
                onChange={(e) => onInputChangeReg(e, "fechaIngreso")}
              />
            </div>
            <div className="field col-4">
              <label htmlFor="email2">Estado</label>
              <div className="field-checkbox">
                <Checkbox
                  header="Estado"
                  inputId="checkOption1"
                  name="option"
                  className=" ml-3 mr-2 mb-1"
                  checked={usuario.estado === "I" ? false : true}
                  onChange={(e) =>
                    onInputChangeRecCheckbox(
                      e.target.checked === true ? "A" : "I",
                      "estado"
                    )
                  }
                />
                <label htmlFor="checkOption1">
                  {usuario.estado === "A" ? "Activo" : "Inactivo"}
                </label>
              </div>
            </div>
          </div>
          {/* <Messages ref={message} /> */}
          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid">
            <div
              className="field col"
              style={{ display: usuario.id === 0 ? "" : "none" }}
            >
              <label htmlFor="cotraseña">Contraseña</label>
              <InputText
                id="password"
                type="password"
                value={contraseña.password}
                onChange={(e) => onInputChangeCon(e, "password")}
                autoComplete="nope"
                required
                className={classNames({
                  "p-invalid": submitted && !contraseña.password,
                })}
              />
              {submitted && !contraseña.password && (
                <small className="p-invalid">Contraseña requirida</small>
              )}
            </div>
            <div
              className="field col"
              style={{ display: usuario.id === 0 ? "" : "none" }}
            >
              <label htmlFor="confimarContraseña">Confirmar Contraseña</label>
              <InputText
                id="email2"
                type="password"
                value={contraseña.confirmPassword}
                onChange={(e) => onInputChangeCon(e, "confirmPassword")}
                required
                autoComplete="nope"
                className={classNames({
                  "p-invalid": submitted && !contraseña.confirmPassword,
                })}
              />
              {submitted && !contraseña.confirmPassword && (
                <small className="p-invalid">Confirme su contraseña</small>
              )}
            </div>
          </div>

          {/* End fila nuevo Usuario */}
          {/* End fila nuevo Usuario */}
        </div>

        {/* End formulario nuevo Usuario */}
        {/* Start button save and delete  */}
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={hideDialog}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
        {/* finish button save and delete  */}
        <Messages ref={message} />
      </Dialog>
      {/* End Puff nuevo Usuario */}
      {/* Start Dialog cambiar contraseña */}
      <Dialog
        visible={cambiarContraseña}
        header={`Cambiar contraseña de ${usuario.nombre}`}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        className="p-fluid"
        onHide={hideDialog}
      >
        <Contraseña
          usuario={usuario}
          submitted={submitted}
          hideDialag={hideDialog}
          setSubmitted={setSubmitted}
          setCambiarContraseña={setCambiarContraseña}
          toast={toast}
        ></Contraseña>
      </Dialog>
      {/* End Dialog cambiar contraseña */}
      <Toast ref={toast} />
    </div>
  );
};
