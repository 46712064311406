import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Badge } from "primereact/badge";
import { Tag } from "primereact/tag";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

//Servicios
import DesembolsoService from "./../../../service/AppService/DesembolsoService";

export const DesembolsoContrato = ({ setTotalM }) => {
  const toast = useRef();
  const message = useRef();

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let desembolsoEmty = {
    conId: 0,
    id: 0,
    monto: 0,
    num: 0,
  };

  let totalEmty = {
    total1: 0,
  };

  const [desembolsos, setDesembolsos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [desembolso, setDesembolso] = useState(desembolsoEmty);
  const [nuevoDesembolso, setNuevoDesembolso] = useState(false);
  const [total, setTotal] = useState(totalEmty);
  const [eliminar, setEliminar] = useState(false);

  useEffect(() => {
    const desembolsoService = new DesembolsoService();
    desembolsoService.findByIdCon(contrato1.id).then((response) => {
      var num = 0;
      for (var i = 0; i < response.length; i++) {
        let _total = { ...total };
        num += response[i].monto;
        _total["total1"] = num;

        setTotal(_total);
        setTotalM(_total);

        setDesembolsos(response);
      }
    });
  }, []);

  const dialogFuncMap = {
    nuevoDesembolso: setNuevoDesembolso,
  };

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
    setDesembolso(desembolsoEmty);
  };
  const onHide1 = () => {
    toast.current.show({
      severity: "error",
      summary: "error",
      detail: "Cambios descartados ",
      life: 4000,
    });
    setEliminar(false);
    setDesembolso(desembolsoEmty);
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setSubmitted(false);
    setDesembolso(desembolsoEmty);
  };

  const hideDialog = () => {
    toast.current.show({
      severity: "error",
      summary: "error",
      detail: "Cambios descartados ",
      life: 4000,
    });
    onHide("nuevoDesembolso");
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _desembolso = { ...desembolso };
    _desembolso[`${name}`] = val;

    setDesembolso(_desembolso);
  };

  const save = () => {
    setSubmitted(true);
    const desembolsoService = new DesembolsoService();
    let _desembolso = { ...desembolso };
    _desembolso["conId"] = contrato1?.id;

    if (desembolso.monto !== 0 && desembolso.num !== 0) {
      desembolsoService.save(_desembolso).then(() => {
        if (desembolso.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo desembolso añadido",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Desembolso actualizado",
            life: 4000,
          });
        }

        setNuevoDesembolso(false);
        setSubmitted(false);
        desembolsoService.findByIdCon(contrato1.id).then((response) => {
          setDesembolsos(response);
          var num = 0;
          for (var i = 0; i < response.length; i++) {
            let _total = { ...total };
            num += response[i].monto;
            _total["total1"] = num;
            setTotalM(_total);
            setTotal(_total);

            setDesembolsos(response);
          }
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };
  const onEdit = (rowData) => {
    setDesembolso(rowData);
    setNuevoDesembolso(true);
  };

  const eliminarT = (rowData) => {
    setDesembolso(rowData);
    setEliminar(true);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"Editar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
      </>
    );
  };

  const eliminarTes = () => {
    const desembolsoService = new DesembolsoService();
    desembolsoService.dele(desembolso.id).then(() => {
      setEliminar(false);
      setDesembolso(desembolsoEmty);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Testigo eliminado",
        life: 4000,
      });
      desembolsoService.findByIdCon(contrato1.id).then((response) => {
        setDesembolsos(response);
        var num = 0;
        for (var i = 0; i < response.length; i++) {
          let _total = { ...total };
          num += response[i].monto;
          _total["total1"] = num;
          setTotalM(_total);
          setTotal(_total);

          setDesembolsos(response);
        }
      });
    });
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              {" "}
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick("nuevoDesembolso")}
                  disabled={
                    contrato1.cantDesembolsos <= desembolsos.length ? "on" : contrato1.imp =="S" ? "on" : "" 
                  }
                />
              </div>
              <div className="field col">
                <Badge
                  value={
                    "Cantidad de desembolsos a realizar: " +
                    `${contrato1.cantDesembolsos}`
                  }
                  severity="info"
                >
                  {" "}
                </Badge>
              </div>
              <div className="field col">
                <Badge
                  value={"Monto de contrato: Q." + `${contrato1.monto}`}
                  severity="warning"
                >
                  {" "}
                </Badge>
              </div>
            </div>

            <DataTable
              value={desembolsos}
              responsiveLayout="scroll"
              emptyMessage="No se encontro ningun desembolso a realizar"
            >
              <Column field="num" header="Numero de desembolso"></Column>
              <Column field="monto" header="Monto a desembolsar  Q "></Column>
              <Column header="Acciones" body={accciones}></Column>
            </DataTable>
          </div>

          <Tag
            style={{
              display: contrato1.monto - total.total1 !== 0 ? "" : "none",
            }}
            value={
              "Error: La suma de los desemboloso no coinciden. La diferencia es de:  Q. " +
              `${total.total1 - contrato1.monto}`
            }
            severity="danger"
            icon="pi pi-times"
          ></Tag>

          <Tag
            style={{
              display: total.total1 - contrato1.monto === 0 ? "" : "none",
            }}
            value={
              "Exito: La suma de los desemboloso coincide con el monto total"
            }
            severity="success"
            icon="pi pi-check"
          ></Tag>
        </div>
      </div>

      <Dialog
        header={desembolso.id === 0 ? "Nuevo desembolso" : "Editar desembolso"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={nuevoDesembolso}
        className="p-fluid"
        onHide={hideDialog}
      >
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <label htmlFor=" Genero"> Numero de desembolso</label>

              <InputText
                id="NumeroContrato"
                type="number"
                value={desembolso.num}
                mode="decimal"
                autoComplete="off"
                onChange={(e) => onChange(e, "num")}
                className={classNames({
                  "p-invalid": submitted && !desembolso.num,
                })}
              />
            </div>
            <div className="field col">
              <label htmlFor=" Genero"> Monto a desembolsar</label>
              <span className="p-input-icon-left">
                <i>Q </i>
                <InputText
                  id="NumeroContrato"
                  type="number"
                  value={desembolso.monto}
                  mode="decimal"
                  autoComplete="off"
                  onChange={(e) => onChange(e, "monto")}
                  className={classNames({
                    "p-invalid": submitted && !desembolso.monto,
                  })}
                />
              </span>
            </div>
          </div>
        </div>
        <Messages ref={message} />

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={hideDialog}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={
          desembolso.id !== 0
            ? `Seguero que quiere eliminar al Fiador:  `
            : "Editar"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={eliminar}
        className="p-fluid"
        onHide={onHide1}
      >
        <div className="card p-fluid">
          {" "}
          <div className="grid formgrid">
            <div className="field col">
              <InputText
                id="filtroNombre"
                value={desembolso.monto}
                disabled="on"
              />
            </div>
            <div className="field col">
              <InputText
                id="filtroNombre"
                value={desembolso.num}
                disabled="on"
              />
            </div>
          </div>
        </div>

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide1}
                disabled={contrato1.imp =="S" ? "on" : ""}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-user-minus "
                className="p-button-warning"
                onClick={() => eliminarTes()}
                disabled={contrato1.imp =="S" ? "on" : ""}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
