import React, { useState, useEffect, useRef } from "react";

import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
//servicios
import GarantiaBienInmuebleService from "./../../../../../../service/AppService/GarantiaBienInmueble";
import FiadorService from "./../../../../../../service/AppService/FiadorService";
export const NuevaGarantiaInmueble = ({
  bienInmueble1,
  setBienInmuebles,
  setBienInmueble1,
}) => {
  const toast = useRef();
  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }
  const message = useRef();
  let BienInmuebleLet = {
    conId: 0,
    disponibilidad: "",
    propiedad: "",
    cubre: "",
    hipoteca: "",
    conTitutlo: "N",
    consisteEn: "",
    copropiedad: "",
    descripcionInmueble: "",
    extencion: "",
    fiador: "",
    folio: "",
    id: 0,
    identificacion: "",
    libro: "",
    mismaUbicacion: "",
    numero: "",
    refenrenciaEvaluo: "",
    tipoInmuble: "",
    fia: 0,
    fiadorContrato: {
      conId: 0,
      id: 0,
      perId: 0,
      persona: {
        apellidoCasada: "",
        deudor: "",
        emId: 0,
        empresa: {
          estado: "",
          id: 0,
          nombre: "",
          nombreAbreviado: "",
          registroInacop: [
            {
              empId: 0,
              estado: "",
              fechaIns: "",
              folio: 0,
              id: 0,
              libro: 0,
              registro: 0,
            },
          ],
        },
        estado: "",
        estadoCivil: "",
        fechaNacimiento: "",
        id: 0,
        nacionalidad: "",
        notario: "",
        numeroDpi: "",
        otroNombre: "",
        primerApellido: "",
        primerNombre: "",
        profecion: "",
        representanteLegal: "",
        segundoApellido: "",
        segundoNombre: "",
        sexo: "",
        nombreCompleto: "",
      },
      sabeFirmar: 0,
    },
  };

  const [bienInmueble, setBienInmueble] = useState(
    bienInmueble1 || BienInmuebleLet
  );
  const [eliminar, setEliminar] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [block, setBlock] = useState(false);
  const [fiadores, setFiadores] = useState([]);
  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _bienInmueble = { ...bienInmueble };
    _bienInmueble[`${name}`] = val;

    setBienInmueble(_bienInmueble);
  };

  useEffect(() => {
    if (bienInmueble1?.id !== 0) {
      setBlock(true);
    }

    const fiadorService = new FiadorService();
    fiadorService.finByIdCon(contrato1?.id || 0).then((response) => {
      setFiadores(response);
    });
  }, []);

  const save = () => {
    setSubmitted(true);
    let _bienInmueble = { ...bienInmueble };
    if (bienInmueble.conId === 0) {
      _bienInmueble["conId"] = contrato1.id;
      _bienInmueble["folio"] = 0;
      _bienInmueble["numero"] = 0;
    }
    if (
      bienInmueble.cubre != 0 &&
      bienInmueble.tipoInmuble !== "" &&
      bienInmueble.extencion !== "" &&
      bienInmueble.extencion !== 0 &&
      bienInmueble.copropiedad !== "" &&
      bienInmueble.cubre !== "" &&
      bienInmueble.descripcionInmueble !== ""
    ) {
      const bienService = new GarantiaBienInmuebleService();
      bienService.save(_bienInmueble).then((res) => {
        if (bienInmueble.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia añadida",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia  actualizado",
            life: 4000,
          });
        }

        setSubmitted(false);
        setBienInmueble(res);
        setBienInmueble1(res);
        setBlock(true);
        bienService.findByConId(contrato1?.id || 0).then((response) => {
          setBienInmuebles(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const cancelar = () => {
    setBienInmueble(bienInmueble1 || BienInmuebleLet);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _bienInmueble = { ...bienInmueble };
    _bienInmueble[`${name}`] = val;

    _bienInmueble[`${key}`] = value;

    setBienInmueble(_bienInmueble);
  };

  return (
    <div className="card p-fluid">
      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Porcentaje que cubre la garantia</label>
          <span className="p-input-icon-right">
            <i>%</i>
            <InputText
              type="number"
              placeholder="100"
              value={bienInmueble.cubre}
              onChange={(e) => onChange(e, "cubre")}
              disabled={block === true ? "on" : ""}
            />
          </span>
          {submitted && !bienInmueble.cubre && (
            <small className="p-invalid">
              {" "}
              Porcentaje que cubre la garantia
            </small>
          )}
        </div>
        <div className="field col">
          <label htmlFor=" Genero"> Es hipoteca</label>
          <div className="formgrid grid">
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="S"
                  checked={bienInmueble.hipoteca === "S"}
                  onChange={(e) => onChange(e, "hipoteca")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.hipoteca,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">Si</label>
              </div>
            </div>
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="N"
                  checked={bienInmueble.hipoteca === "N"}
                  onChange={(e) => onChange(e, "hipoteca")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.hipoteca,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="field col">
          <label htmlFor=" Genero"> Con titutlo</label>
          <div className="formgrid grid">
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="S"
                  checked={bienInmueble.conTitutlo === "S"}
                  onChange={(e) => onChange(e, "conTitutlo")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.conTitutlo,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">Si</label>
              </div>
            </div>
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="N"
                  checked={bienInmueble.conTitutlo === "N"}
                  onChange={(e) => onChange(e, "conTitutlo")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.conTitutlo,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">No</label>
              </div>
            </div>
          </div>
        </div>

        <div className="field col-2">
          <InputSwitch checked={block} onChange={(e) => setBlock(e.value)} />
          <label htmlFor="Editar datos" className="ml-3">
            {block == true ? "Editar" : "Bloquear"}
          </label>
        </div>
      </div>

      <div className="formgrid grid">
        <div className="field col">
          <label htmlFor="description">Fiador</label>

          <Dropdown
            value={bienInmueble.fiadorContrato}
            options={fiadores}
            onChange={(e) =>
              onInputChangeRegObject(e, "fiadorContrato", e.value.id, "fia")
            }
            optionLabel="persona.nombreCompleto"
            placeholder="Seleccione un fiador"
            required
            className={classNames({
              "p-invalid": submitted && !bienInmueble.fiadorContrato,
            })}
            disabled={
              eliminar === true
                ? "on"
                : contrato1.fiador === "N"
                ? "on"
                : contrato1.fiadorGarantia === "N"
                ? "on"
                : ""
            }
          />
        </div>
        <div className="field col">
          <label>Extencion</label>
          <span className="p-input-icon-right">
            <i>Mts </i>
            <InputText
              type="number"
              placeholder="256"
              value={bienInmueble.extencion}
              onChange={(e) => onChange(e, "extencion")}
              disabled={block === true ? "on" : ""}
            />
          </span>
          {submitted && !bienInmueble.extencion && (
            <small className="p-invalid">
              {" "}
              Se necesita una extecion de la garantia
            </small>
          )}
        </div>

        <div className="field col">
          <label htmlFor=" Genero"> Fue medido nuevamente</label>
          <div className="formgrid grid">
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="S"
                  checked={bienInmueble.copropiedad === "S"}
                  onChange={(e) => onChange(e, "copropiedad")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.copropiedad,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">Si</label>
              </div>
            </div>
            <div className="field col">
              <div className="field-radiobutton">
                <RadioButton
                  inputId="option1"
                  value="N"
                  checked={bienInmueble.copropiedad === "N"}
                  onChange={(e) => onChange(e, "copropiedad")}
                  className={classNames({
                    "p-invalid": submitted && !bienInmueble.copropiedad,
                  })}
                  disabled={block === true ? "on" : ""}
                />
                <label htmlFor="option1">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="formgrid grid" aria-activedescendant="off">
        <div className="field col">
          <label>Naturaleza</label>
          <InputText
            id="TipoBienInmuble"
            type="text"
            autoComplete="off"
            placeholder="RUSTICA"
            value={bienInmueble.tipoInmuble}
            onChange={(e) => onChange(e, "tipoInmuble")}
            disabled={block === true ? "on" : ""}
          />
          {submitted && !bienInmueble.tipoInmuble && (
            <small className="p-invalid">
              {" "}
              Se necesita un tipo de bien in mueble
            </small>
          )}
        </div>
        <div className="field col">
          <label>Segundo Registro de la Propiedad de</label>
          <InputText
            id="propiedad"
            type="text"
            placeholder="Quetzaltenango"
            value={bienInmueble.propiedad}
            onChange={(e) => onChange(e, "propiedad")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
      </div>
      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Numero</label>
          <InputText
            type="number"
            placeholder="4642"
            value={bienInmueble.numero}
            onChange={(e) => onChange(e, "numero")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
        <div className="field col">
          <label>Folio</label>
          <InputText
            type="number"
            placeholder="124"
            value={bienInmueble.folio}
            onChange={(e) => onChange(e, "folio")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
      </div>
      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Libro</label>
          <InputText
            type="text"
            placeholder="30E"
            value={bienInmueble.libro}
            onChange={(e) => onChange(e, "libro")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
        <div className="field col">
          <label>Consiente en:</label>
          <InputText
            type="text"
            placeholder="FINCA RUSTICA"
            value={bienInmueble.consisteEn}
            onChange={(e) => onChange(e, "consisteEn")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
      </div>

      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Identificado como</label>
          <InputTextarea
            maxLength={250}
            rows={2}
            type="text"
            placeholder="LOTE QUINCE MANZANA C"
            value={bienInmueble.identificacion}
            onChange={(e) => onChange(e, "identificacion")}
            disabled={
              bienInmueble.conTitutlo === "N"
                ? "on"
                : block === true
                ? "on"
                : ""
            }
          />
        </div>
      </div>
      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Segun evaluo</label>
          <InputTextarea
            rows={2}
            maxLength={250}
            type="text"
            placeholder="A cincuenta metros del edificio (Segun sea el caso, de lo contrario dejarlo bacio)"
            value={bienInmueble.refenrenciaEvaluo}
            onChange={(e) => onChange(e, "refenrenciaEvaluo")}
            disabled={block === true ? "on" : ""}
          />
        </div>
      </div>
      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Descripcion</label>
          <InputTextarea
            rows={4}
            maxLength={250}
            type="text"
            placeholder="declaro que el inmuble ha sufrido una desmembracios y el resto del inmueble que al ser medido nuevamente por ambas al sitema metrico decimal se ha constar que actualmente cuenta con una extencion superficial de:
                (Segun sea el caso, de lo contrario dejarlo vacio)"
            value={bienInmueble.descripcionInmueble}
            onChange={(e) => onChange(e, "descripcionInmueble")}
            disabled={block === true ? "on" : ""}
          />
        </div>
      </div>

      <div className="formgrid grid" aria-autocomplete="off">
        <div className="field col">
          <label>Como se encuentra el inmueble</label>
          <InputTextarea
            rows={4}
            maxLength={500}
            type="text"
            placeholder="Actualmente sobre el inmueble se encuentra construidada una casa de habitacion,de paredes de block, de cuatro ambientes, con techo de lámina de zinc
            , Topográficamente plano"
            value={bienInmueble?.disponibilidad}
            onChange={(e) => onChange(e, "disponibilidad")}
            disabled={block === true ? "on" : ""}
          />
        </div>
      </div>

      <Messages ref={message} />
      <div
        className="card p-fluid"
        style={{ display: eliminar === true ? "none" : "" }}
      >
        <div className="grid formgrid">
          <div className="col-6">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={() => cancelar()}
              disabled={block === true ? "on" : ""}
            />
          </div>
          <div className="col-6">
            <Button
              type="button"
              label="Guardar"
              icon="pi pi-upload "
              className="p-button-success"
              onClick={() => save()}
              disabled={block === true ? "on" : ""}
            />
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
