import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { RadioButton } from "primereact/radiobutton";

//Servicios

import FiadorService from "./../../../service/AppService/FiadorService";

//paginas a utilizar

import { Persona } from "./Persona";
export const Fiador = () => {
  const message = useRef();
  const toast = useRef();
  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let fiadorEmty = {
    conId: 0,
    id: 0,
    perId: 0,
    nombre: "",
    persona: {
      apellidoCasada: "",
      deudor: "",
      emId: 0,
      empresa: {
        estado: "",
        id: 0,
        nombre: "",
        nombreAbreviado: "",
        registroInacop: [
          {
            empId: 0,
            estado: "",
            fechaIns: "",
            folio: 0,
            id: 0,
            libro: 0,
            registro: 0,
          },
        ],
      },
      estado: "",
      estadoCivil: "",
      fechaNacimiento: "",
      id: 0,
      nacionalidad: "",
      notario: "",
      numeroDpi: "",
      otroNombre: "",
      primerApellido: "",
      primerNombre: "",
      profecion: "",
      representanteLegal: "",
      segundoApellido: "",
      segundoNombre: "",
      sexo: "",
    },
    sabeFirmar: 0,
  };

  const [fidores, setfiadores] = useState([]);
  const [nuevoFiador, setNuevofiador] = useState(false);
  const [editar, setEditar] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [fiador, setfiador] = useState(fiadorEmty);

  useEffect(() => {
    const fiadorService = new FiadorService();
    fiadorService.finByIdCon(contrato1.id).then((response) => {
      setfiadores(response);
    });
  }, []);

  const nombreA = (rowData) => {
    return (
      <>
        {rowData.persona.primerNombre +
          "  " +
          rowData.persona.segundoNombre +
          "  " +
          rowData.persona.otroNombre +
          "  " +
          rowData.persona.primerApellido +
          "  " +
          rowData.persona.segundoApellido +
          "  " +
          rowData.persona.apellidoCasada}
      </>
    );
  };

  const onClick = () => {
    setNuevofiador(true);
  };

  const onHide = () => {
    setEliminar(false);
    setfiador(fiadorEmty);
    setEditar(false);
    setNuevofiador(false);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };
  const onEdit = (rowData) => {
    if (fiador.nombre === "") {
      let _rowData = { ...rowData };
      _rowData["nombre"] =
        rowData.persona.primerNombre +
        "  " +
        rowData.persona.segundoNombre +
        "  " +
        rowData.persona.otroNombre +
        "  " +
        rowData.persona.primerApellido +
        "  " +
        rowData.persona.segundoApellido +
        "  " +
        rowData.persona.apellidoCasada;

      setfiador(_rowData);
    }

    setEditar(true);
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _fiador = { ...fiador };
    _fiador[`${name}`] = val;
    setfiador(_fiador);
  };

  const sexoD = (rowData) => {
    return <>{rowData.persona.sexo === "M" ? "Masculino" : "Femenino"}</>;
  };
  const firma = (rowData) => {
    return <>{rowData.sabeFirmar === 1 ? "Si" : "No"}</>;
  };
  const eliminarT = (rowData) => {
    if (fiador.nombre === "") {
      let _rowData = { ...rowData };
      _rowData["nombre"] =
        rowData.persona.primerNombre +
        "  " +
        rowData.persona.segundoNombre +
        "  " +
        rowData.persona.otroNombre +
        "  " +
        rowData.persona.primerApellido +
        "  " +
        rowData.persona.segundoApellido +
        "  " +
        rowData.persona.apellidoCasada;

      setfiador(_rowData);
    }

    setEliminar(true);
  };
  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
      </>
    );
  };

  const save = () => {
    setSubmitted(true);
    if (fiador.sabeFirmar !== 0) {
      const fiadorService = new FiadorService();
      fiadorService.save(fiador).then(() => {
        if (fiador.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo fiador añadido",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " fiador actualizado",
            life: 4000,
          });
        }
        setfiador(fiadorEmty);
        setSubmitted(false);
        setEditar(false);

        fiadorService.finByIdCon(contrato1.id).then((response) => {
          setfiadores(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const eliminarTes = () => {
    const fiadorService = new FiadorService();
    fiadorService.dele(fiador.id).then(() => {
      setEliminar(false);
      setfiador(fiadorEmty);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Testigo eliminado",
        life: 4000,
      });
      fiadorService.finByIdCon(contrato1.id).then((response) => {
        setfiadores(response);
      });
    });
  };

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.persona.fechaNacimiento);
  };
  return (
    <div>
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <div className="formgrid grid">
            {" "}
            <div className="field col-6">
              {" "}
              <Button
                icon="pi pi-plus"
                className="p-button-success mr-4 md-2"
                onClick={() => onClick()}
                disabled={contrato1.imp =="S" ? "on" : ""}
              />
            </div>
          </div>
          <DataTable
            value={fidores}
            emptyMessage="No se encontro ningun fiador"
            responsiveLayout="scroll"
          >
            <Column body={nombreA} header="Nombre completo"></Column>

            <Column header="Numero de DPI" field="persona.numeroDpi"></Column>

            <Column
              body={dateBodyTemplate}
              field="persona.fechaNacimiento"
              header="Fecha de nacimiento"
            ></Column>

            <Column body={sexoD} header="Sexo"></Column>

            <Column field="persona.profecion" header="Profesion"></Column>
            <Column
              field="sabeFirmar"
              body={firma}
              header="Sabe firmar"
            ></Column>
            <Column header="Acciones" body={accciones}></Column>
          </DataTable>
        </div>
      </div>

      <Dialog
        header={fiador.id === 0 ? "Seleccione un fiador" : ""}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "70vw" }}
        modal
        visible={nuevoFiador}
        className="p-fluid"
        onHide={onHide}
      >
        <Persona
          onHide={setNuevofiador}
          contrato={contrato1}
          setfiadores={setfiadores}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header={fiador.id !== 0 ? `Editar:  ${fiador.nombre}` : "Editar"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={editar}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor=" Genero"> Fiador sabe firmar</label>
              <div className="formgrid grid">
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="1"
                      checked={fiador.sabeFirmar == 1}
                      onChange={(e) => onChange(e, "sabeFirmar")}
                      className={classNames({
                        "p-invalid": submitted && !fiador.sabeFirmar,
                      })}
                    />
                    <label htmlFor="option1">Si</label>
                  </div>
                </div>
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="2"
                      checked={fiador.sabeFirmar == 2}
                      onChange={(e) => onChange(e, "sabeFirmar")}
                      className={classNames({
                        "p-invalid": submitted && !fiador.sabeFirmar,
                      })}
                    />
                    <label htmlFor="option1">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Messages ref={message} />

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Agregar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        header={
          fiador.id !== 0
            ? `Seguero que quiere eliminar al Fiador:  `
            : "Editar"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={eliminar}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          {" "}
          <div className="grid formgrid">
            <div className="field col">
              <InputText
                id="nombre"
                className="p-inputtext-lg block"
                value={fiador.nombre}
                disabled="on"
              />
            </div>
          </div>
        </div>

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-user-minus "
                className="p-button-warning"
                onClick={() => eliminarTes()}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
