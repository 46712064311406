import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";

//Servicios
import EmpresaNotarioService from "../../service/AppService/EmpesaNotarioService";

export const EmpresaNotario = () => {
  const toast = useRef();
  const message = useRef();
  let empresaNotarioEmty = {
    estado: "A",
    id: 0,
    nombre: "",
    representante: "",
    ubicacioin: "",
  };

  let filtroEmpty = {
    nombre: "",
    representante: "",
  };

  //useEstate
  const [empresasNotarios, setEmpresasNotarios] = useState([]);
  const [empresaNotario, setEmpresaNotario] = useState(empresaNotarioEmty);
  const [nuevaEmpresaNotario, setNuevaEmpresaNotario] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);

  //UseEfeects
  useEffect(() => {
    const empresaNotario = new EmpresaNotarioService();
    empresaNotario
      .findFilter(filtro.nombre, filtro.representante)
      .then((response) => {
        setEmpresasNotarios(response);
      });
  }, [filtro.nombre, filtro.representante]);

  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );

  const hideDialog = () => {
    setSubmitted(false);
    onHide("nuevaEmpresaNotario");
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setEmpresaNotario(empresaNotarioEmty);
  };

  const dialogFuncMap = {
    nuevaEmpresaNotario: setNuevaEmpresaNotario,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onInputChangeReg = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _empresaNotario = { ...empresaNotario };
    _empresaNotario[`${name}`] = val;

    setEmpresaNotario(_empresaNotario);
  };

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };

  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _empresaNotario = { ...empresaNotario };
    _empresaNotario[`${name}`] = val;

    setEmpresaNotario(_empresaNotario);
  };
  const onSave = () => {
    setSubmitted(true);
    let _empresaNotario = { ...empresaNotario };
    if (
      empresaNotario.ubicacioin !== "" &&
      empresaNotario.nombre !== "" &&
      empresaNotario.representante !== ""
    ) {
      const empresaNotarioService = new EmpresaNotarioService();
      empresaNotarioService.save(_empresaNotario).then(() => {
        if (empresaNotario.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " empresa  creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " empresa  actualizada",
            life: 4000,
          });
        }
        setSubmitted(false);
        onHide("nuevaEmpresaNotario");
        empresaNotarioService.findAll().then((response) => {
          setEmpresasNotarios(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "No se aceptan campos vacios",
        life: 4000,
      });
    }
  };

  const edit = (rowData) => {
    return (
      <Button
        className="p-button-rounded p-button-info mr-2 p-mr-2"
        icon="pi pi-pencil"
        onClick={() => onEdit(rowData)}
        tooltip={"editar: "}
      />
    );
  };
  const accciones = (rowData) => {
    return (
      <>
        <div className="actions">
          <Link
            to={{
              pathname: "/empresaNotary/sucusal",
              state: {
                empresaNotario: rowData,
              },
            }}
          >
            <Button
              className="p-button-rounded p-button-warning mr-2 p-mr-2"
              icon="pi pi-eye"
              tooltip={"Ver sucursales"}
            />
          </Link>
          {edit(rowData)}
        </div>
      </>
    );
  };

  const onEdit = (rowData) => {
    setEmpresaNotario(rowData);
    setNuevaEmpresaNotario(true);
  };

  return (
    <div className="grid">
      <Messages ref={message} />
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h4>Filtros</h4>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">Nombre</label>

              <div className="field">
                <InputText
                  id="filtroNombre"
                  value={filtro.nombre}
                  autoComplete="off"
                  onChange={(e) => chengFiltro(e, "nombre")}
                />
              </div>
            </div>

            <div className="field col">
              <label htmlFor="nombre">Representante</label>

              <div className="field">
                <InputText
                  id="filtroNombre"
                  autoComplete="off"
                  value={filtro.representante}
                  onChange={(e) => chengFiltro(e, "representante")}
                />
              </div>
            </div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                label="Nueva Empresa"
                onClick={() => onClick("nuevaEmpresaNotario")}
              />
            </div>
          </div>
        </div>
        <div className="card p-fluid">
          <h4>Empresa del Notario</h4>

          {/* Start Row */}
          <DataTable
            value={empresasNotarios}
            emptyMessage="No se encontro ninguna empresa"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="nombre" header="Nombre "></Column>
            <Column field="representante" header="Representante "></Column>
            <Column
              field="ubicacioin"
              header="Ubicacion de la empresa "
            ></Column>
            <Column header="Eestado" body={teplateBodyEstado}></Column>
            <Column header="Acciones  " body={accciones}></Column>
          </DataTable>
        </div>
      </div>
      {/* Start Puff nueva empresa */}
      <Dialog
        visible={nuevaEmpresaNotario}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        className="p-fluid"
        onHide={hideDialog}
        header={
          empresaNotario.id === 0
            ? "Nueva empresa"
            : `Editar empresa de ${empresaNotario.nombre}`
        }
        autoComplete="off"
      >
        {/* <div className="card p-fluid"> */}

        <div className="card p-fluid">
          {/* Start fila nuevo Usuario */}

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="nombre">Nombre de la empresa</label>

              <div className="field">
                <InputText
                  id="nombre"
                  autoComplete="off"
                  value={empresaNotario.nombre}
                  onChange={(e) => onInputChangeReg(e, "nombre")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !empresaNotario.nombre,
                  })}
                />
                {submitted && !empresaNotario.nombre && (
                  <small className="p-invalid"> Nombre requerido.</small>
                )}
              </div>
            </div>
          </div>

          {/* Start fila nuevo empresaNotario */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="apellido">Nombre del representante</label>

              <div className="field">
                <InputText
                  id="representante"
                  type="text"
                  autoComplete="off"
                  value={empresaNotario.representante}
                  onChange={(e) => onInputChangeReg(e, "representante")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !empresaNotario.representante,
                  })}
                />
                {submitted && !empresaNotario.representante && (
                  <small className="p-invalid">
                    nombre del representante requirido
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="correo">Ubicacion de la empresa</label>
              <InputText
                id="correo"
                type="text"
                autoComplete="off"
                value={empresaNotario.ubicacioin}
                onChange={(e) => onInputChangeReg(e, "ubicacioin")}
                required
                className={classNames({
                  "p-invalid": submitted && !empresaNotario.ubicacioin,
                })}
              />
              {submitted && !empresaNotario.ubicacioin && (
                <small className="p-invalid">
                  ubicacion de la empresa requerida requirida
                </small>
              )}
            </div>
          </div>

          {/* End fila nuevo empresaNotario */}
          {/* Start fila nuevo empresaNotario */}
          <div className="formgrid grid">
            <div className="field col-4">
              <label htmlFor="email2">Estado</label>
              <div className="field-checkbox">
                <Checkbox
                  header="Estado"
                  inputId="checkOption1"
                  name="option"
                  className=" ml-3 mr-2 mb-1"
                  checked={empresaNotario.estado === "I" ? false : true}
                  onChange={(e) =>
                    onInputChangeRecCheckbox(
                      e.target.checked === true ? "A" : "I",
                      "estado"
                    )
                  }
                />
                <label htmlFor="checkOption1">
                  {empresaNotario.estado === "A" ? "Activo" : "Inactivo"}
                </label>
              </div>
            </div>
          </div>
          {/* <Messages ref={message} /> */}

          {/* End fila nuevo Usuario */}
          {/* End fila nuevo Usuario */}
        </div>

        {/* End formulario nuevo Usuario */}
        {/* Start button save and delete  */}
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={hideDialog}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => onSave()}
              />
            </div>
          </div>
        </div>
        {/* finish button save and delete  */}
        <Messages ref={message} />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
