import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

//Servicios
import GarantiaBienInmuebleService from "./../../../../service/AppService/GarantiaBienInmueble";

//comonentes
import { MenuBienInmuble } from "./MenuBienInmuble";

export const GarantiaBienInmueble = ({ toast }) => {
  const message = useRef();

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }
  let BienInmuebleLet = {
    conId: 0,
    propiedad: "",
    cubre: "",
    hipoteca: "N",
    conTitutlo: "N",
    consisteEn: "",
    copropiedad: "N",
    descripcionInmueble: "",
    extencion: "",
    fiador: "",
    folio: "",
    id: 0,
    identificacion: "",
    libro: "",
    mismaUbicacion: "",
    numero: "",
    refenrenciaEvaluo: "",
    tipoInmuble: "",
  };

  const [bienInmueble, setBienInmueble] = useState(BienInmuebleLet);
  const [bienInmuebles, setBienInmuebles] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [nuevoEliminar, setNuevoEliminar] = useState(false);
  const [nuevoBienInmueble, setNuevoBienInmueble] = useState(false);

  useEffect(() => {
    const bienService = new GarantiaBienInmuebleService();
    bienService.findByConId(contrato1?.id || 0).then((response) => {
      setBienInmuebles(response);
    });
  }, []);

  const onClick = () => {
    setNuevoBienInmueble(true);
  };
  const onHide = () => {
    setEliminar(false);
    setSubmitted(false);
    setNuevoBienInmueble(false);
    setBienInmueble(BienInmuebleLet);
  };

  const onHide1 = () => {
    setNuevoEliminar(false);
  };
  const onEdit = (rowData) => {
    setBienInmueble(rowData);
    setNuevoBienInmueble(true);
  };
  const eliminarT = (rowData) => {
    setNuevoEliminar(true);
    setBienInmueble(rowData);
    setEliminar(true);
  };

  const accciones = (rowData) => {
    return (
      <div>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          tooltip={"Editar"}
          onClick={() => onEdit(rowData)}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
      </div>
    );
  };

  const siNoC = (rowData) => {
    return <>{rowData.copropiedad === "S" ? "Si" : "No"}</>;
  };
  const siNoT = (rowData) => {
    return <>{rowData.conTitutlo === "S" ? "Si" : "No"}</>;
  };

  const confirmDelete = () => {
    const bienService = new GarantiaBienInmuebleService();

    bienService.dele(bienInmueble.id).then(() => {
      setNuevoEliminar(false);
      setBienInmueble(BienInmuebleLet);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Garantia eliminada",
        life: 4000,
      });
      bienService.findByConId(contrato1?.id || 0).then((response) => {
        setBienInmuebles(response);
      });
    });
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col-6">
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                  disabled={contrato1.imp =="S" ? "on" : ""}
                />
              </div>
            </div>

            <DataTable
              value={bienInmuebles}
              responsiveLayout="scroll"
              emptyMessage="No se encontro ninguna garantia"
            >
              <Column field="tipoInmuble" header="Tipo de inmueble" />
              {/* <Column
                field="descripcionInmueble"
                header="Descripcion bien inmueble"
              /> */}
              {/* <Column field="consisteEn" header="Consiente en" /> */}
              <Column field="extencion" header="Extencion en metros" />
              <Column field="cubre" header="Monto que cubre" />
              <Column body={siNoC} header="Copropiedad" />

              <Column body={siNoT} header="Con titulo" />
              {/* <Column field="refenrenciaEvaluo" header="Evaluo" /> */}
              <Column header="Acciones" body={accciones} />
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        header={
          bienInmueble.id === 0
            ? "Nueva garantia"
            : eliminar === true
            ? "Eliminar garantia"
            : "Editar garantia "
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        modal
        visible={nuevoBienInmueble}
        className="p-fluid"
        onHide={onHide}
      >
        <MenuBienInmuble
          bienInmueble={bienInmueble}
          setBienInmuebles={setBienInmuebles}
        />
      </Dialog>

      <Dialog
        header={
          bienInmueble.id === 0
            ? "Nueva garantia"
            : eliminar === true
            ? "Eliminar garantia"
            : "Editar garantia "
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "70vw" }}
        modal
        visible={nuevoEliminar}
        className="p-fluid"
        onHide={onHide1}
      >
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <label>Tipo de inmueble</label>
              <InputText
                disabled={eliminar === true ? "on" : ""}
                value={bienInmueble.tipoInmuble}
              />
            </div>
            <div className="field col">
              <label>Extencion de inmueble</label>
              <span className="p-input-icon-right">
                <i>mts</i>
                <InputText
                  disabled={eliminar === true ? "on" : ""}
                  value={bienInmueble.extencion}
                />{" "}
              </span>
            </div>
            <div className="field col">
              <label>Porcent que cubre la garantia</label>
              <span className="p-input-icon-right">
                <i>%</i>
                <InputText
                  disabled={eliminar === true ? "on" : ""}
                  value={bienInmueble.cubre}
                />{" "}
              </span>
            </div>
          </div>
        </div>
        <div
          className="card p-fluid"
          style={{ display: eliminar !== true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-trash"
                className="p-button-warning"
                onClick={() => confirmDelete()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
