import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import AuthService from "../service/Auth/AuthService";

export const Login = () => {
  const history = useHistory();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let emptyUsr = {
    username: null,
    password: null,
  };

  const [usr, setUsr] = useState(emptyUsr);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);

  const iniciarSesion = () => {
    setSubmitted(true);
    const authService = new AuthService();

    authService.login(usr).then((response) => {
      if (response === 403) {
        setTimeout(() => {
          setSubmitted(false);
        }, 3000);
        setEstado(false);
      } else if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(response.data));
        authService
          .getUser(usr.username, JSON.stringify(response.data))
          .then((response) => {
            localStorage.setItem("user", JSON.stringify(response));
            history.push("/");
            history.go();
            //  window.location.reload();
            setEstado(true);
            setSubmitted(false);
          });
      }
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _usr = { ...usr };
    _usr[`${name}`] = val;
    setUsr(_usr);
  };

  const handleLogin = () => {
    iniciarSesion();
  };

  return (
    <div className="login-body">
      <div className="login-wrapper">
        <div className="login-panel">
          <img
            style={{
              width: "200px",
              height: "200px",
            }}
            src="assets/demo/images/Principal.png"
            alt="Notary"
          />

          <InputText
            placeholder="Usuario"
            value={usr.username || ""}
            onChange={(e) => onInputChange(e, "username")}
            required
            className={classNames({
              "p-invalid": submitted && !usr.username,
            })}
          />

          <InputText
            placeholder="Contraseña"
            type="password"
            value={usr.password || ""}
            onChange={(e) => onInputChange(e, "password")}
            required
            className={classNames({
              "p-invalid": submitted && !usr.password,
            })}
          />
          <Button
            label="LOGIN"
            type="button"
            onClick={handleLogin}
            className={classNames({
              "p-invalid": submitted && !estado,
            })}
          ></Button>
          {submitted && !estado && (
            <Message
              severity="error"
              text="* Por favor revisa tu usuario y contraseña."
            />
          )}
        </div>
        <div className="login-footer">
          <h4>Notary</h4>
          <h6>Ⓒ Fénix Computer Inc. 2022. v0.9.1-SNAPSHOT. Alex Say</h6>
        </div>
      </div>
    </div>
  );
};
