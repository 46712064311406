import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";

//servicios
import MedidasColideciaService from "../../../../../../service/AppService/MedidasColidenciaService";
import TipoMedidaService from "../../../../../../service/AppService/TipoMedidaService";
export const MedidasColidedenica = ({ bienInmueble1, toast }) => {
  const message = useRef();
  let MedidasLet = {
    cantidad: "",
    descripcion: "",
    id: 0,
    inmId: 0,
  };

  const [medida, setMedia] = useState(MedidasLet);
  const [medidas, setMedidas] = useState([]);
  const [eliminar, setEliminar] = useState(false);
  const [nuevaCoordenada, setNuevaCoordenada] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [tipoMedias, setTipoMedidas] = useState([]);
  useEffect(() => {
    const medidasService = new MedidasColideciaService();
    medidasService.findIdInmb(bienInmueble1.id).then((response) => {
      setMedidas(response);
    });

    const tipoMediaas = new TipoMedidaService();
    tipoMediaas.findAll().then((response) => {
      setTipoMedidas(response);
    });
  }, []);

  const onClick = () => {
    setNuevaCoordenada(true);
  };
  const onHide = () => {
    setEliminar(false);
    setSubmitted(false);
    setNuevaCoordenada(false);
    setMedia(MedidasLet);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _medida = { ...medida };
    _medida[`${name}`] = val;

    _medida[`${key}`] = value;

    setMedia(_medida);
  };
  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _medida = { ...medida };
    _medida[`${name}`] = val;

    setMedia(_medida);
  };

  const save = () => {
    setSubmitted(true);
    let _medida = { ...medida };
    if (medida.inmId === 0) {
      _medida["inmId"] = bienInmueble1.id;
    }
    if (_medida.inmId !== 0) {
      if (medida.descripcion !== "" && medida.cantidad !== "") {
        const medidasService = new MedidasColideciaService();
        medidasService.save(_medida).then(() => {
          if (medida.id === 0) {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Coordenada agregada",
              life: 4000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Coordenada  actualizada",
              life: 4000,
            });
          }

          setSubmitted(false);
          setNuevaCoordenada(false);
          setMedia(MedidasLet);
          medidasService.findIdInmb(bienInmueble1.id).then((response) => {
            setMedidas(response);
          });
        });
      } else {
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que tenga llenos los campos necesarios ",
          life: 4000,
        });
      }
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Ingrese un inmueble primero",
        life: 4000,
      });
    }
  };

  const onEdit = (rowData) => {
    setNuevaCoordenada(true);
    setMedia(rowData);
  };
  const eliminarT = (rowData) => {
    setNuevaCoordenada(true);
    setMedia(rowData);
    setEliminar(true);
  };
  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
        />
      </>
    );
  };
  const confirmDelete = () => {
    const medidasService = new MedidasColideciaService();
    medidasService.dele(medida.id).then(() => {
      setEliminar(false);

      setNuevaCoordenada(false);
      setMedia(MedidasLet);

      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Coordenada eliminada",
        life: 4000,
      });

      medidasService.findIdInmb(bienInmueble1.id).then((response) => {
        setMedidas(response);
      });
    });
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                />
              </div>
            </div>
            <DataTable
              value={medidas}
              responsiveLayout="scroll"
              emptyMessage="No tiene ninguna medida de colidancia por coordenada"
            >
              <Column header="Descripcion" field="descripcion" />
              <Column header="Cantidad" field="cantidad" />
              <Column header="Tipo de medida" field="tipoMedida.descripcion" />
              <Column header="Acciones" body={accciones} />
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        header={
          medida.id === 0
            ? "Nueva coordenada"
            : eliminar === true
            ? "Eliminar coordenada"
            : "Editar coordenada"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={nuevaCoordenada}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label>Descripcion</label>
              <InputTextarea
                maxLength={250}
                rows={3}
                type="text"
                placeholder="ejemplos: Linea del, azimut, distancia de"
                value={medida.descripcion}
                onChange={(e) => onChange(e, "descripcion")}
                disabled={eliminar === true ? "on" : ""}
                required
                className={classNames({
                  "p-invalid": submitted && !medida.descripcion,
                })}
              />
            </div>
          </div>

          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label>Cantidas</label>
              <InputTextarea
                maxLength={250}
                rows={1}
                type="text"
                placeholder="ejemplos: 82 , 08 , 3"
                value={medida.cantidad}
                onChange={(e) => onChange(e, "cantidad")}
                disabled={eliminar === true ? "on" : ""}
                required
                className={classNames({
                  "p-invalid": submitted && !medida.cantidad,
                })}
              />
            </div>
            <div className="field col">
              <label>Tipo de medida</label>
              <Dropdown
                value={medida.tipoMedida}
                options={tipoMedias}
                onChange={(e) =>
                  onInputChangeRegObject(e, "tipoMedida", e.value.id, "tipo")
                }
                disabled={eliminar === true ? "on" : ""}
                optionLabel="descripcion"
                placeholder="Sin medida"
                required
                className={classNames({
                  "p-invalid": submitted && !medida.tipoMedida,
                })}
              />
            </div>
          </div>
        </div>

        <Messages ref={message} />
        <div
          className="card p-fluid"
          style={{ display: eliminar === true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>

        <div
          className="card p-fluid"
          style={{ display: eliminar !== true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-trash"
                className="p-button-warning"
                onClick={() => confirmDelete()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
