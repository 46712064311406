import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
//Service
import SueldoSerivice from "./../../../../service/AppService/GarantiaSueldo";
import FiadorService from "../../../../service/AppService/FiadorService";

export const Sueldo = ({ toast }) => {
  const message = useRef();

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let SueldoEmty = {
    codigoEmpleado: "",
    conId: 0,
    cuenta: "",
    dondeTrabaja: "",
    especificamente: "",
    fechaNominaSueldo: "",
    fia: null,
    id: 0,
    nombre: "N",
    parPresupuestariaMensa: "",
    puesto: "",
    porcentaje: 0,
  };

  const [sueldo, setSueldo] = useState(SueldoEmty);
  const [sueldos, setSueldos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [nuevoSueldo, setNuevoSueldo] = useState(false);
  const [fiadores, setFiadores] = useState([]);
  useEffect(() => {
    const sueldoSerice = new SueldoSerivice();
    const fiadorService = new FiadorService();
    sueldoSerice.findByConId(contrato1?.id || 0).then((response) => {
      setSueldos(response);
    });

    fiadorService.finByIdCon(contrato1?.id || 0).then((response) => {
      setFiadores(response);
    });
  }, []);

  const onHide = () => {
    setEliminar(false);
    setSubmitted(false);
    setNuevoSueldo(false);
    setSueldo(SueldoEmty);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onClick = () => {
    setNuevoSueldo(true);
  };

  const accciones = (rowData) => {
    return (
      <div className="formgrid grid">
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
      </div>
    );
  };

  const pertenece = (rowData) => {
    return <>{rowData.nombre == "S" ? "Deudor" : "Fiador"}</>;
  };
  const onEdit = (rowData) => {
    setNuevoSueldo(true);
    setSueldo(rowData);
  };

  const eliminarT = (rowData) => {
    setNuevoSueldo(true);
    setSueldo(rowData);
    setEliminar(true);
  };

  const change = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _sueldo = { ...sueldo };
    _sueldo[`${name}`] = val;
    setSueldo(_sueldo);
  };

  const save = () => {
    setSubmitted(true);
    let _sueldo = { ...sueldo };
    if (sueldo.conId === 0) {
      _sueldo["conId"] = contrato1.id;
    }
    if (sueldo.dondeTrabaja !== "" && sueldo.fechaNominaSueldo !== "") {
      const sueldoService = new SueldoSerivice();
      sueldoService.save(_sueldo).then(() => {
        if (sueldo.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia de sueldo añadida",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia de sueldo actualizado",
            life: 4000,
          });
        }

        setNuevoSueldo(false);
        setSubmitted(false);
        setSueldo(SueldoEmty);

        sueldoService.findByConId(contrato1?.id || 0).then((response) => {
          setSueldos(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const confirDelete = () => {
    const sueldoService = new SueldoSerivice();
    sueldoService.dele(sueldo.id).then(() => {
      setNuevoSueldo(false);

      setEliminar(false);
      setSueldo(SueldoEmty);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Garantia eliminada",
        life: 4000,
      });

      sueldoService.findByConId(contrato1?.id || 0).then((response) => {
        setSueldos(response);
      });
    });
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _sueldo = { ...sueldo };
    _sueldo[`${name}`] = val;

    _sueldo[`${key}`] = value;

    setSueldo(_sueldo);
  };

  const formatDate = (value) => {
    const event = new Date(value);
    return event.toLocaleDateString("en-US");
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaNominaSueldo);
  };
  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              {" "}
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                  disabled={contrato1.imp =="S" ? "on" : ""}
                />
              </div>
            </div>

            <DataTable
              value={sueldos}
              responsiveLayout="scroll"
              emptyMessage="No se encontro ninguna garantia de sueldo"
            >
              <Column
                body={pertenece}
                header="Aquien le pertence la garantia"
              ></Column>
              <Column
                field="dondeTrabaja"
                header="Lugar de trabajo o a que se dedica"
              ></Column>

              <Column field="puesto" header="Puesto que ejerce"></Column>

              <Column
                body={dateBodyTemplate}
                field="fechaNominaSueldo"
                header="Fecha de la nomina de sueldo"
              ></Column>
              <Column
                field="codigoEmpleado"
                header="Codigo de empleado"
              ></Column>
              <Column
                field="parPresupuestariaMensa"
                header="Numero de partida presupuestaria"
              ></Column>
              <Column body={accciones} header="Acciones"></Column>
            </DataTable>
          </div>
        </div>
      </div>

      <Dialog
        header={
          sueldo.id === 0
            ? "Nueva garantia de sueldo"
            : eliminar === true
            ? "Eliminar garantia de sueldo"
            : "Editar garantia de sueldo"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "60vw" }}
        modal
        visible={nuevoSueldo}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label htmlFor=" Genero"> Garantia del deudor</label>
              <div className="formgrid grid">
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="S"
                      checked={sueldo.nombre == "S"}
                      onChange={(e) => change(e, "nombre")}
                      disabled={eliminar === true ? "on" : ""}
                    />
                    <label htmlFor="option1">Si</label>
                  </div>
                </div>
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="N"
                      checked={sueldo.nombre == "N"}
                      onChange={(e) => change(e, "nombre")}
                      disabled={eliminar === true ? "on" : ""}
                    />
                    <label htmlFor="option1">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="field col">
              <label htmlFor="description">Fiador</label>

              <Dropdown
                value={sueldo.fiadorContrato}
                options={fiadores}
                onChange={(e) =>
                  onInputChangeRegObject(e, "fiadorContrato", e.value.id, "fia")
                }
                optionLabel="persona.nombreCompleto"
                placeholder="Seleccione un fiador"
                required
                disabled={
                  eliminar === true
                    ? "on"
                    : contrato1.fiador === "N"
                    ? "on"
                    : contrato1.fiadorGarantia === "N"
                    ? "on"
                    : sueldo.nombre == "S"
                    ? "on"
                    : ""
                }
              />
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label> Lugar donde trabaja</label>
              <InputTextarea
                rows={4}
                id="Nombre de la empresa donde trabaja o a que se dedica"
                type="text"
                placeholder="Gobierno de la República de Guatemala o a que se dedica Ejemplo: Mis ingresos mensuales que genero en mi trabajo como agricultor."
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.dondeTrabaja}
                autoComplete="off"
                onChange={(e) => change(e, "dondeTrabaja")}
                className={classNames({
                  "p-invalid": submitted && !sueldo.dondeTrabaja,
                })}
              />

              {submitted && !sueldo.dondeTrabaja && (
                <small className="p-invalid">
                  Se necesita el lugar donde trabaja
                </small>
              )}
            </div>
            <div className="field col">
              <label> Especificamente donde trabaja</label>
              <InputTextarea
                rows={4}
                id="Nombre de la empresa donde trabaja"
                type="text"
                placeholder="el Ministerio de Trabajo y Previsión Social"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.especificamente}
                autoComplete="off"
                onChange={(e) => change(e, "especificamente")}
              />
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label> Cuenta Bancaria</label>
              <InputText
                id="codigoEmpleado"
                type="number"
                placeholder="990134005151"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.cuenta}
                autoComplete="off"
                onChange={(e) => change(e, "cuenta")}
              />
            </div>
            <div className="field col">
              <label> Puesto que desempeña</label>
              <InputText
                id="puesto"
                type="text"
                placeholder="CAPORAL"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.puesto}
                autoComplete="off"
                onChange={(e) => change(e, "puesto")}
              />
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label> Codigo de empleado</label>
              <InputText
                id="codigoEmpleado"
                type="number"
                placeholder="990134"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.codigoEmpleado}
                autoComplete="off"
                onChange={(e) => change(e, "codigoEmpleado")}
              />
            </div>
            <div className="field col">
              <label> Cargo de la partida presupuestaria</label>
              <InputText
                id="puesto"
                type="text"
                placeholder="1130010-000-00"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.parPresupuestariaMensa}
                autoComplete="off"
                onChange={(e) => change(e, "parPresupuestariaMensa")}
              />
            </div>
          </div>

          <div className="formgrid grid" aria-autocomplete="off">
            <div className="field col">
              <label> Fecha de la Nómina de sueldos </label>
              <InputText
                id="fechaNomina"
                type="date"
                disabled={eliminar === true ? "on" : ""}
                value={sueldo.fechaNominaSueldo}
                autoComplete="off"
                onChange={(e) => change(e, "fechaNominaSueldo")}
                className={classNames({
                  "p-invalid": submitted && !sueldo.fechaNominaSueldo,
                })}
              />
            </div>
            <div className="field col">
              <label> Porcentaje que cubre la garantia </label>
              <span className="p-input-icon-right">
                <i>%</i>
                <InputText
                  id="fechaNomina"
                  type="number"
                  disabled={eliminar === true ? "on" : ""}
                  value={sueldo.porcentaje}
                  autoComplete="off"
                  onChange={(e) => change(e, "porcentaje")}
                />
              </span>
            </div>
          </div>

          <Messages ref={message} />
          <div
            className="card p-fluid"
            style={{ display: eliminar === true ? "none" : "" }}
          >
            <div className="grid formgrid">
              <div className="field col">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={onHide}
                />
              </div>
              <div className="field col">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => save()}
                />
              </div>
            </div>
          </div>

          <div
            className="card p-fluid"
            style={{ display: eliminar !== true ? "none" : "" }}
          >
            <div className="formgrid grid">
              <div className="field col">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={onHide}
                />
              </div>
              <div className="col">
                <Button
                  type="button"
                  label="Confirmar"
                  icon="pi pi-trash"
                  className="p-button-warning"
                  onClick={() => confirDelete()}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
