import React, { useState } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { useLocation } from "react-router-dom";

//paginas a utilizar
import { ContratoNuevo } from "./../CotratoNuevo";
import { DesembolsoContrato } from "../DesembolsoContrato";
import { Fiador } from "../Fiador";
import { Testigo } from "../Testigo";
import { Amortizaciones } from "../Amortizaciones";
import { MenuGarantia } from "../MenuGarantia";

export const MenuContrato = () => {
  var persona;

  var storList = localStorage.getItem("persona");
  persona = JSON.parse(storList);

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }
  let totalEmty = {
    total1: 0,
  };
  let montoEmpty = 0;

  const [cliente, setCliente] = useState(persona);
  const [contrato, setContrato] = useState(contrato1);
  const [totalM, setTotalM] = useState(totalEmty);
  const [totalA, setTotalA] = useState(montoEmpty);

  return (
    <div className="card card-w-title">
      <h5>
        Nuevo contrato de:{" "}
        {cliente === undefined
          ? "Ingrese primero un cliente "
          : cliente.id === 0
          ? "Ingrese un nuevo cliente porfavor"
          : cliente.primerNombre +
            " " +
            cliente.segundoNombre +
            "  " +
            cliente.otroNombre +
            "  " +
            cliente.primerApellido +
            "  " +
            cliente.segundoApellido +
            "  " +
            cliente.apellidoCasada}
      </h5>
      <TabView>
        <TabPanel header="Datos del contrato">
          {" "}
          <ContratoNuevo cliente={cliente} />
        </TabPanel>
        <TabPanel header="Distribucion de desembolsos">
          <DesembolsoContrato setTotalM={setTotalM} />
          {/* Aqui componenetes */}
        </TabPanel>

        <TabPanel header="Amortizaciones">
          <Amortizaciones setTotalA={setTotalA} />
          {/* Aqui componenetes */}
        </TabPanel>

        <TabPanel header="Fiadores">
          <Fiador />
          {/* Aqui componenetes */}
        </TabPanel>
        <TabPanel header="Testigos">
          <Testigo />
          {/* Aqui componenetes */}
        </TabPanel>

        <TabPanel header="Garantias">
          {" "}
          <MenuGarantia></MenuGarantia>
        </TabPanel>
      </TabView>
    </div>
  );
};
