import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";

import { Link } from "react-router-dom";
import PersonaService from "../../service/AppService/PersonaService";
import { getIsRtlScrollbarOnLeft } from "@fullcalendar/react";

export const Persona = () => {
  let personaEmpty = {
    apellidoCasada: "",
    deudor: "S",
    emId: 0,
    estado: "",
    estadoCivil: "C",
    fechaNacimiento: "",
    id: 0,
    nacionalidad: "",
    notario: "N",
    numeroDpi: "",
    otroNombre: "",
    primerApellido: "",
    primerNombre: "",
    profecion: "",
    representanteLegal: "N",
    segundoApellido: "",
    segundoNombre: "",
    sexo: "M",
  };
  let filtroEmpty = {
    numeroDpi: "",
    primerNombre: "",
    segundoNombre: "",
    otroNombre: "",
    primerApellido: "",
    segundoApellido: "",
    apellidoCasada: "",
  };

  const [personas, setPersonas] = useState([]);
  const [filtro, setFiltro] = useState(filtroEmpty);

  //States para la paginacion
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    const personaService = new PersonaService();
    personaService
      .findByDpiNomApe(
        filtro.numeroDpi,
        filtro.primerNombre,
        filtro.segundoNombre,
        filtro.otroNombre,
        filtro.primerApellido,
        filtro.segundoApellido,
        filtro.apellidoCasada,
        pageNumber
      )
      .then((response) => {
        if (response !== undefined) {
          setPersonas(response.content);
          setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
          setFirstPage(response.first);
          setLastPage(response.last);
          if (
            filtro.numeroDpi != "" ||
            filtro.primerNombre != "" ||
            filtro.segundoNombre != "" ||
            filtro.otroNombre != "" ||
            filtro.primerApellido != "" ||
            filtro.segundoApellido != ""
          ) {
            setPageNumber(0);
          }
        } else {
        }
      });
  }, [pageNumber]);

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };

  const fil = (evt) => {
    const personaService = new PersonaService();
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode == 9) {
      personaService
        .findByDpiNomApe(
          filtro.numeroDpi,
          filtro.primerNombre,
          filtro.segundoNombre,
          filtro.otroNombre,
          filtro.primerApellido,
          filtro.segundoApellido,
          filtro.apellidoCasada,
          pageNumber
        )
        .then((response) => {
          if (response !== undefined) {
            setPersonas(response.content);
            setTotalElements(response.totalElements);
            setTotalPages(response.totalPages);
            setFirstPage(response.first);
            setLastPage(response.last);
            if (
              filtro.numeroDpi != "" ||
              filtro.primerNombre != "" ||
              filtro.segundoNombre != "" ||
              filtro.otroNombre != "" ||
              filtro.primerApellido != "" ||
              filtro.segundoApellido != ""
            ) {
              setPageNumber(0);
            }
          } else {
          }
        });
    }
  };

  const nombreA = (rowData) => {
    return (
      <>
        {rowData.primerNombre +
          "  " +
          rowData.segundoNombre +
          "  " +
          rowData.otroNombre +
          "  " +
          rowData.primerApellido +
          "  " +
          rowData.segundoApellido +
          "  " +
          rowData.apellidoCasada}
      </>
    );
  };

  const sexoD = (rowData) => {
    return <>{rowData.sexo === "M" ? "Masculino" : "Femenino"}</>;
  };

  const accciones = (rowData) => {
    return (
      <>
        <Link
          to={{
            pathname: "/clientes/menuCliente",
          }}
        >
          <Button
            className="p-button-rounded p-button-warning mr-2 p-mr-2"
            icon="pi pi-eye"
            onClick={() =>
              localStorage.setItem("persona", JSON.stringify(rowData))
            }
            tooltip={"ver"}
          />
        </Link>
      </>
    );
  };

  const renderPaginacion = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-double-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(0)}
        />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        />

        <InputText
          type="text"
          className="p-inputtext p-component"
          placeholder={pageNumber + 1 + " de " + totalPages}
          style={{
            width: "100px",
            height: "30px",
            borderColor: "#5499C7",
            textAlign: "center",
            fontSize: "15px",
            background: "transparent",
          }}
          readOnly
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-right"
          style={{
            background: "transparent; color: #5499C7",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(pageNumber + 1)}
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-double-right"
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(totalPages - 1)}
        />
      </div>
    );
  };
  return (
    <div className="grid">
      <div className="col-12 p-fluid">
        <div className="card p-fluid" onKeyDown={(e) => fil(e)}>
          {/* Start fila nuevo Usuario */}

          <h4>Filtros</h4>

          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">DPI</label>

              <InputMask
                mask="9999 99999 9999"
                value={filtro.numeroDpi}
                onChange={(e) => chengFiltro(e, "numeroDpi")}
              ></InputMask>
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 10,
              }}
            >
              <Link
                to={{
                  pathname: "/clientes/menuCliente",
                }}
              >
                <Button
                  style={{
                    width: "200px",
                    height: "35px",
                  }}
                  label="Nuevo Cliente"
                  icon="pi pi-plus"
                  className="p-button-success mr-2"
                  onClick={() =>
                    localStorage.setItem(
                      "persona",
                      JSON.stringify(personaEmpty)
                    )
                  }
                />
              </Link>
            </div>
          </div>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid " autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">primer nombre</label>

              <InputText
                id="filtroUsuario"
                value={filtro.primerNombre}
                autoComplete="off"
                onChange={(e) => chengFiltro(e, "primerNombre")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Segundo nombre</label>
              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.segundoNombre}
                onChange={(e) => chengFiltro(e, "segundoNombre")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Otro nombre</label>

              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.otroNombre}
                onChange={(e) => chengFiltro(e, "otroNombre")}
              />
            </div>
          </div>

          {/* Start fila nuevo filtro */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="apellido">primer apellido</label>

              <InputText
                id="filtroApellido"
                autoComplete="off"
                type="text"
                value={filtro.primerApellido}
                onChange={(e) => chengFiltro(e, "primerApellido")}
              />
            </div>

            <div className="field col">
              <label htmlFor="apellido">segundo apellido</label>

              <InputText
                id="filtroApellido"
                autoComplete="off"
                type="text"
                value={filtro.segundoApellido}
                onChange={(e) => chengFiltro(e, "segundoApellido")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Apellido de casada</label>

              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.apellidoCasada}
                onChange={(e) => chengFiltro(e, "apellidoCasada")}
              />
            </div>
          </div>
          {/* End fila nuevo Usuario */}
          {/* Boton  nueva persona */}
        </div>

        <div className=" card p-fluid">
          <h5> Clientes </h5>
          <DataTable
            value={personas}
            emptyMessage="No se encontro ningun usuario"
            responsiveLayout="scroll"
            footer={renderPaginacion}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column header="Nombre  " body={nombreA}></Column>
            <Column field="numeroDpi" header="DPI "></Column>
            <Column field="nacionalidad" header="Nacionalidad "></Column>
            <Column field="profecion" header="Profesion "></Column>
            <Column header="Genero " body={sexoD}></Column>
            <Column header="Acciones " body={accciones}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};
