import { WindowScrollController } from "@fullcalendar/react";
import axios from "axios";
import Api from "../../Api";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class ContratoService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      s;
      const response = await axios.get(api.baseUrl() + `/contrato/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findByPerId(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/contrato/persona/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );

      return response.data;
    } catch (err) {
      console.log(err);
      if (err.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/contrato/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      console.log(err);
      window.location = "#/error";
    }
  }
}
