import React, { useState, useEffect, useRef } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";

//componlentes
import { NuevaGarantiaInmueble } from "./NuevaGarantiaInmueble";
import { DireccionGarantia } from "./DireccionGarantia";
import { MedidaPorCoordenada } from "./MedidaPorCoordenada";
import { MedidasColidedenica } from "./MedidasColidedenica";
import { Testmonio } from "./Testimonio";
export const MenuBienInmuble = ({ bienInmueble, setBienInmuebles }) => {
  const toast = useRef();

  let BienInmuebleLet = {
    conId: 0,
    propiedad: "",
    cubre: "",
    hipoteca: "",
    conTitutlo: "N",
    consisteEn: "",
    copropiedad: "",
    descripcionInmueble: "",
    extencion: "",
    fiador: "",
    folio: "",
    id: 0,
    identificacion: "",
    libro: "",
    mismaUbicacion: "",
    numero: "",
    refenrenciaEvaluo: "",
    tipoInmuble: "",
  };

  const [bienInmueble1, setBienInmueble1] = useState(
    bienInmueble || BienInmuebleLet
  );

  return (
    <div className="card card-w-title">
      <TabView>
        <TabPanel header="Datos Generales">
          <NuevaGarantiaInmueble
            bienInmueble1={bienInmueble1}
            setBienInmuebles={setBienInmuebles}
            setBienInmueble1={setBienInmueble1}
          />
        </TabPanel>
        <TabPanel header={"Direccion de Garantia "}>
          <DireccionGarantia bienInmueble1={bienInmueble1} />
        </TabPanel>
        <TabPanel header={"Puntos cardinales"}>
          <MedidaPorCoordenada bienInmueble1={bienInmueble1} toast={toast} />
        </TabPanel>
        <TabPanel header="Coordendas ">
          <MedidasColidedenica bienInmueble1={bienInmueble1} toast={toast} />
        </TabPanel>
        <TabPanel header="Testimonios">
          <Testmonio bienInmueble1={bienInmueble1} toast={toast} />
        </TabPanel>
      </TabView>
      <Toast ref={toast} />
    </div>
  );
};
