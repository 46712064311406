import axios from "axios";
import Api from "../../Api";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class PersonaService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/persona/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findByEmpId(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/persona/emp/${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findByEsNotario() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/persona/notario/S`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findByEsRepresentante() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/persona/representante/S`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findById(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/persona/${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async findByDpiNomApe(
    dpi,
    pNombre,
    sNombre,
    oNombre,
    pApellido,
    sApelldio,
    aCasad,
    pageNumber
  ) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/persona/filter/${dpi || 0}/${pNombre || 0}/${sNombre || 0}/${
            oNombre || 0
          }/${pApellido || 0}/${sApelldio || 0}/${aCasad || 0}?page=${
            pageNumber || 0
          }`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      console.error(err.response.status);
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/persona/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      if (err.response.status === 401) {
        window.location = "#/login";
      } else if (err.response.status == 403) {
        window.location = "#/login";
      } else if (err.response.status === 404) {
        window.location = "#/notfound";
      } else {
        window.location = "#/access";
      }
    }
  }
}
