import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
//servicios
import DepartamentoService from "../../service/AppService/DepartamentoService";

export const Departamento = () => {
  const toast = useRef();
  const message = useRef();
  let departamentoEmpty = {
    descripcion: "",
    id: 0,
  };
  let filtroEmpty = {
    depertamento: "",
  };
  const [departamentos, setDepartamentos] = useState([]);
  const [departamento, setDepartamento] = useState(departamentoEmpty);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [nuevoDepartamento, setNuevoDepartamento] = useState(false);

  const hideDialog = () => {
    setSubmitted(false);
    onHide("nuevoDepartamento");
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const dialogFuncMap = {
    nuevoDepartamento: setNuevoDepartamento,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setDepartamento(departamentoEmpty);
  };

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;
    setFiltro(_filtro);
  };
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _departamento = { ...departamento };
    _departamento[`${name}`] = val;
    setDepartamento(_departamento);
  };

  useEffect(() => {
    const departamentoService = new DepartamentoService();
    departamentoService
      .findByDescription(filtro.depertamento)
      .then((response) => {
        setDepartamentos(response);
      });
  }, [filtro.depertamento]);

  const onSave = () => {
    setSubmitted(true);
    let _departamento = { ...departamento };

    if (departamento.descripcion !== "") {
      const departamentoService = new DepartamentoService();
      departamentoService.save(_departamento).then(() => {
        setNuevoDepartamento(false);
        setSubmitted(false);
        departamentoService.findAll().then((response) => {
          setDepartamentos(response);
          setDepartamento(departamentoEmpty);
        });
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: "Cambios guardados correctamente",
          life: 4000,
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "No se admite el campo vacio",
        life: 4000,
      });
    }
  };

  const onEdit = (rowData) => {
    setDepartamento(rowData);
    setNuevoDepartamento(true);
  };

  const edit = (rowData) => {
    return (
      <Button
        className="p-button-rounded p-button-info mr-2 p-mr-2"
        icon="pi pi-pencil"
        onClick={() => onEdit(rowData)}
        tooltip={"editar"}
      />
    );
  };

  const accciones = (rowData) => {
    return (
      <div className="actions">
        <Link
          to={{
            pathname: "/municipios",
            state: {
              departamento: rowData,
            },
          }}
        >
          <Button
            className="p-button-rounded p-button-warning mr-2 p-mr-2"
            icon="pi pi-eye"
            tooltip={"Ver municipios"}
          />
        </Link>
        {edit(rowData)}
      </div>
    );
  };

  return (
    <div className="grid">
      <Messages ref={message} />
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h4>Filtros</h4>

          {/* Start fila Filtros */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">Departamento</label>

              <InputText
                id="filtroNombre"
                value={filtro.depertamento}
                autoComplete="off"
                onChange={(e) => chengFiltro(e, "depertamento")}
              />
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                label="Nuevo departamento"
                onClick={() => onClick("nuevoDepartamento")}
              />
            </div>
          </div>
          {/* End fila nuevo Usuario */}
        </div>
        <div className="card p-fluid">
          <h4>Departamentos</h4>

          {/* Start Row */}
          <DataTable
            value={departamentos}
            emptyMessage="No se encontro ningun departamento"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column header="Pais" body="Guatemala"></Column>
            <Column field="descripcion" header="Departamento "></Column>
            <Column header="Acciones" body={accciones}></Column>
          </DataTable>
          {/* End Row */}
          {/* Start Button nuevo Usuario */}

          {/* End Button nuevo Usuario */}
        </div>
        <Dialog
          visible={nuevoDepartamento}
          header={
            departamento.id === 0 ? "Nuevo departamento" : "Editar departamento"
          }
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          className="p-fluid"
          onHide={hideDialog}
          autoComplete="off"
        >
          {/* Star Card y columna de datos  */}
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="description">Departamento</label>

                <div className="field">
                  <InputText
                    id="description"
                    value={departamento.descripcion}
                    onChange={(e) => onInputChange(e, "descripcion")}
                    // className={classNames({
                    //   "p-invalid": submitted && !usuario.nombre,
                    // })}
                    autoFocus
                    required
                    className={classNames({
                      "p-invalid": submitted && !departamento.descripcion,
                    })}
                  />
                  {submitted && !departamento.descripcion && (
                    <small className="p-invalid">
                      {" "}
                      Ingrese un departamento
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Star Card y columna de datos  */}
          {/* Start button save and delete  */}
          <Messages ref={message} />
          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={hideDialog}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => onSave()}
                />
              </div>
            </div>
          </div>
          {/* finish button save and delete  */}
        </Dialog>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
