import React, { useState, useEffect, useRef } from "react";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";

import { Accordion, AccordionTab } from "primereact/accordion";

//components
import { Ahorro } from "./Ahorro";
import { Sueldo } from "./Sueldo";
import { GarantiaBienInmueble } from "./GarantiaBienInmueble";

export const MenuGarantia = () => {
  const message = useRef();
  const toast = useRef();
  return (
    <div className="card">
      <Accordion className="accordion-custom">
        <AccordionTab
          header={
            <React.Fragment>
              <i className="pi pi-file"></i>
              <span> Garantia por: Cuenta de ahorro</span>
            </React.Fragment>
          }
        >
          <Ahorro toast={toast} />
        </AccordionTab>

        <AccordionTab
          header={
            <React.Fragment>
              <i className="pi pi-briefcase"></i>
              <span> Garantia por:Sueldo </span>
            </React.Fragment>
          }
        >
          <Sueldo toast={toast} />
        </AccordionTab>
        <AccordionTab
          header={
            <React.Fragment>
              <i className="pi pi-building"></i>
              <span> Garantia por: Bien inmueble</span>
            </React.Fragment>
          }
        >
          <GarantiaBienInmueble toast={toast} />
        </AccordionTab>
      </Accordion>
      <Toast ref={toast} />
    </div>
  );
};
