import * as React from "react";

export class ComponentToPrint extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { checked: false };
  }

  canvasEl;

  componentDidMount() {
    const ctx = this.canvasEl.getContext("2d");
    if (ctx) {
      ctx.beginPath();
      ctx.arc(95, 50, 40, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillStyle = "rgb(200, 0, 0)";
      ctx.fillRect(85, 40, 20, 20);
      ctx.save();
    }
  }

  handleCheckboxOnChange = () =>
    this.setState({ checked: !this.state.checked });

  setRef = (ref) => (this.canvasEl = ref);

  render() {
    const { text } = this.props;

    return (
      <div className="relativeCSS">
        <style type="text/css" media="print">
          {"\
   @page { size: landscape; }\
"}
        </style>
        <div className="flash" />
        <canvas ref={this.setRef}>
          Your browser does not support the HTML5 canvas tag.
        </canvas>
        <div>
          <p
            id="isPasted"
            style={{
              margin: 0,
              fontSize: "16px",
              fontFamily: "Times New Roman",
              marginBottom: "10.0pt",
              textAlign: "justify",
              lineHeight: "24.0pt",
            }}
          >
            <span
            // style='font-size:15px;font-family:"Arial Narrow",sans-serif;color:#171717;'
            >
              En el municipio de Solol&aacute;, departamento de Solol&aacute;,
              el uno de junio del a&ntilde;o dos mil veintid&oacute;s;
              comparecemos por una parte:{" "}
              <strong>YO PEDRO FELICIANO MENCH&Uacute; V&Aacute;SQUEZ</strong>,
              de sesenta a&ntilde;os de edad, unido de hecho, guatemalteco,
              Administrador de Empresas Cooperativas, con residencia y domicilio
              en la Aldea San Juan Argueta, del municipio y departamento de
              Solol&aacute;, me identifico con el Documento Personal de
              Identificaci&oacute;n <strong>&ndash;DPI-&nbsp;</strong>con el
              C&oacute;digo &Uacute;nico de Identificaci&oacute;n{" "}
              <strong>&ndash;CUI-</strong> n&uacute;mero: Dos mil cuatrocientos
              cuarenta y tres espacio ochenta y nueve mil trescientos ochenta y
              uno espacio cero setecientos uno (2443 89381 0701), extendido por
              el Registro Nacional de las Personas de la Rep&uacute;blica de
              Guatemala, act&uacute;o en mi calidad de Representante Legal de la
              Cooperativa Integral de Ahorro y Cr&eacute;dito &ldquo;Fondos de
              Am&eacute;rica&rdquo; Responsabilidad Limitada, Fondos R.L..
              Calidad que acredito con la certificaci&oacute;n de
              inscripci&oacute;n extendida por el Registro de Cooperativas del
              Instituto Nacional de Cooperativas (INACOP), con&nbsp;
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return <ComponentToPrint ref={ref} text={props.text} />;
});
