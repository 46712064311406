import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";

import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";
import { Badge } from "primereact/badge";
import UsuarioService from "../../service/AppService/UsuarioService";

export const PerfilUsuario = () => {
  const messages = useRef(null);
  const toast = useRef(null);

  try {
    var usuario;

    var conList = localStorage.getItem("user");

    if (conList !== "null") {
      usuario = JSON?.parse(conList);
    } else {
      window.location = "#/login";
    }
  } catch (err) {
    window.location = "#/login";
  }

  const [appUsuario, setAppUsuario] = useState(usuario);
  const [submitted, setSubmitted] = useState(false);

  const [cambiarPassword, setCambiarPassword] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const usuarioService = new UsuarioService();
    usuarioService.findById(usuario.id).then((response) => {
      console.log(response)
      setAppUsuario(response);
    });
  }, []);

  const onUpdatePassword = () => {
    setSubmitted(true);

    if (password.password !== "" && password.confirmPassword !== "") {
      console.log(password);
      if (password.password === password.confirmPassword) {
        let _usuario = { ...appUsuario };
        _usuario[`password`] = password.password;
        const usuarioService = new UsuarioService();
        usuarioService.save(_usuario).then((response) => {
          console.log(response);
          if (response !== undefined) {
            localStorage.setItem("user", JSON.stringify(response));
            setSubmitted(false);
            setTimeout(function () {
              setCambiarPassword(false);
              setPassword({
                password: "",
                confirmPassword: "",
              });
            }, 2000);

            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: "Contraseña actualizada",
              life: 4000,
            });
          } else {
            setTimeout(function () {
              setCambiarPassword(false);
            }, 2000);
            messages.current.show({
              severity: "warn",
              content: "Ocurrio un problema",
              life: 3000,
            });
          }
        });
      } else {
        messages.current.show({
          severity: "warn",
          content: "Contraseñas no coinciden",
          life: 3000,
        });
        setPassword({
          password: "",
          confirmPassword: "",
        });
      }
    } else {
      messages.current.show({
        severity: "warn",
        detail: "Debe ingresar la contraseña",
        life: 3000,
      });
    }
  };

  return (
    <div
      className="card p-fluid  grid"
      style={{
        display: "flex",
        flexDirection: "row",
        maxWidth: "1200px",
        margin: "auto",
      }}
    >
      <Toast ref={toast}></Toast>

      {/* Start Seccion Foto t Contraseña */}
      <div
        className=" p-fluid field "
        style={{
          flex: 1,
          margin: 15,
        }}
      >
        {/* <div className="card p-fluid"> */}
        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
          Fotografia y contraseña
        </h3>

        <div
          style={{
            width: "100px",
            height: "100px",
            textAlign: "center",
            borderRadius: "50%",
            backgroundColor: "#5499C7",
            margin: "auto",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <b
            style={{
              fontSize: "50px",
              fontWeight: "bold",
              margin: "0px auto",
              color: "#FDFEFE ",
            }}
          >
            {appUsuario?.nombre.substring(0, 1)}
          </b>
        </div>

        <Divider align="center">
          <div className="p-fluid">
            <b>Contraseña</b>
          </div>
        </Divider>

        <Button
          icon="pi pi-key"
          style={{
            display: cambiarPassword ? "none" : "block",
            background: "#5499C7",
            fontSize: "14px",
          }}
          label="Cambiar contraseña"
          className="p-button-sm p-button-rounded"
          onClick={() => setCambiarPassword(true)}
        />

        <div
          style={{
            display: cambiarPassword === true ? "block" : "none",
          }}
        >
          <div className=" col-12 md:col-12">
            <div className="field ">
              <label htmlFor="usuPwd">Contraseña</label>
              <InputText
                type="password"
                className="p-inputtext p-component"
                id="newUsuPwd"
                name="usuPwd"
                value={password.password}
                onChange={(e) =>
                  setPassword({ ...password, password: e.target.value })
                }
                required
              />
            </div>
            <div className="field ">
              <label htmlFor="usuPwdConfirm">Confirmar Contraseña</label>
              <InputText
                type="password"
                className="p-inputtext p-component"
                id="usuPwdConfirm"
                name="usuPwdConfirm"
                value={password.confirmPassword}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    confirmPassword: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="grid formgrid  p-fluid">
              <div className="field col">
                <Button
                  onClick={() => setCambiarPassword(false)}
                  icon="pi pi-times"
                  label="Cancelar"
                  className="p-button-danger"
                ></Button>
              </div>
              <div className="field col">
                <Button
                  onClick={() => onUpdatePassword()}
                  icon="pi pi-check"
                  label="Guardar"
                  className="p-button-success"
                ></Button>
              </div>
            </div>
          </div>
        </div>
        <Messages ref={messages}></Messages>
        {/* </div> */}
      </div>
      {/* End Seccion Foto t Contraseña */}

      {/* Start Seccion Tus Datos */}
      <div
        className="p-fluid"
        style={{ borderLeft: "1px solid #F2F2F2", flex: 2 }}
      >
        {/* <div className="card p-fluid"> */}{" "}
        <h3 style={{ fontWeight: "bold", margin: "50px", textAlign: "center" }}>
          Tus datos{" "}
        </h3>
        <Divider align="center"></Divider>
        <div style={{ margin: 20, marginTop: "75px", marginBottom: "50px" }}>
          <div className="formgrid grid field" style={{ margin: 1 }}>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Nombres
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuNombre"
                type="text"
                value={appUsuario?.nombre}
                // onChange={(e) => {
                //   setAppUsuario({ ...appUsuario, usuNombre: e.target.value })
                // }}
                readOnly
              />
            </div>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Apellidos
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuApellido"
                type="text"
                value={appUsuario?.apellido}
                readOnly
              />
            </div>
          </div>
          <div className="formgrid grid" style={{ margin: 1 }}>
            <div className=" field col ">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Usuario
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuUsuario"
                type="text"
                value={appUsuario?.usuario}
                readOnly
              />
            </div>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                correo
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuEmail"
                type="text"
                value={appUsuario?.correo}
                readOnly
              />
            </div>
          </div>
          <div className="formgrid grid" style={{ margin: 1 }}>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Fecha Cumpleaños
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuFechaNacimiento"
                type="date"
                value={appUsuario?.fechaIngreso}
                readOnly
              />
            </div>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Sucursal
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuSuc"
                type="text"
                value={appUsuario?.sucursal.nombre}
                readOnly
              />
            </div>
          </div>
          <div className="formgrid grid" style={{ margin: 1 }}>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Municipio
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuMun"
                type="text"
                value={appUsuario?.sucursal.municipios.descripcion}
                readOnly
              />
            </div>
            <div className="field col">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Departamento
              </label>
              <InputText
                style={{ fontWeight: "bold" }}
                id="usuDep"
                type="text"
                value={appUsuario?.sucursal.municipios.departamento.descripcion}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};