import React, { useState, useEffect, useRef } from "react";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { useLocation } from "react-router-dom";
//paginas a utilizar
import { NuevaPersona } from "./../NuevaPersona";
import { Direccion } from "./../../../pages/Direccion";
import { Contrato } from "./../../Contrato";

export const MenuPersona = () => {
  var cliente1;
  var storList = localStorage.getItem("persona");
  cliente1 = JSON.parse(storList);

  const location = useLocation();
  const [cliente, setCliente] = useState(cliente1);

  const rendernewEditUser = () => (
    <NuevaPersona cliente={cliente} setCliente={setCliente} />
  );

  return (
    <div className="card card-w-title">
      <h5>
        Cliente:{" "}
        {cliente === undefined
          ? "Ingrese un nuevo Usuario"
          : cliente.id === 0
          ? "Ingrese un nuevo Usuario"
          : cliente.primerNombre +
            " " +
            cliente.segundoNombre +
            "  " +
            cliente.otroNombre +
            "  " +
            cliente.primerApellido +
            "  " +
            cliente.segundoApellido +
            "  " +
            cliente.apellidoCasada}
      </h5>
      {/* <div className="grid">
        <div className="col-12 md:col-12">
          <InputText id="OtroNombre" type="text" disabled="on" />
        </div>
      </div> */}

      <TabView>
        <TabPanel header="Datos del cliente">{rendernewEditUser()}</TabPanel>
        <TabPanel header="Direcciones">
          <Direccion cliente={cliente} />
        </TabPanel>
        <TabPanel header="Contratos">
          <Contrato cliente={cliente} />
        </TabPanel>
      </TabView>
    </div>
  );
};
