import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
//services

import GarantiaService from "../../service/AppService/GarantiaService";
export const Garantias = () => {
  const toast = useRef();
  const message = useRef();

  let GarantiaEmtpy = {
    descripcion: "",
    id: 0,
  };

  let filtroEmpty = {
    descripcion: "",
  };
  const [garantia, setGarantia] = useState(GarantiaEmtpy);
  const [garantias, setGarantias] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [nuevoPunto, setNuevoPunto] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);

  useEffect(() => {
    const garantia = new GarantiaService();
    garantia.findBydes(filtro.descripcion).then((response) => {
      setGarantias(response);
    });
  }, [filtro]);

  const onClick = () => {
    setNuevoPunto(true);
  };

  const hideDialog = () => {
    setNuevoPunto(false);

    setSubmitted(false);
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " cambios descartados",
      life: 4000,
    });
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _garantia = { ...garantia };
    _garantia[`${name}`] = val;
    setGarantia(_garantia);
  };

  const onEdit = (rowData) => {
    setNuevoPunto(true);
    setGarantia(rowData);
  };
  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
        />
      </>
    );
  };

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };

  const save = () => {
    setSubmitted(true);
    if (garantia.descripcion !== "") {
      const garantiaS = new GarantiaService();
      garantiaS.save(garantia).then(() => {
        if (garantia.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Garantia actualizada",
            life: 4000,
          });
        }
        setNuevoPunto(false);
        setGarantia(GarantiaEmtpy);
        setSubmitted(false);
        setFiltro(filtroEmpty);
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "No se aceptan campos vacios ",
        life: 4000,
      });
    }
  };

  return (
    <div>
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h5>Filtros</h5>
          <div className="formgrid grid">
            <div className="field col">
              <label>Tipo de garantia</label>
              <InputText
                id="nombre"
                type="text"
                autoComplete="off"
                value={filtro.descripcion}
                onChange={(e) => chengFiltro(e, "descripcion")}
              />
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nueva orientacion"
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick()}
              />
            </div>
          </div>
        </div>

        <div className="card p-fluid">
          <h4>Tipos de garantias</h4>
          <DataTable
            value={garantias}
            responsiveLayout="scroll"
            emptyMessage="No se encontro ningun puto cardinal"
            paginator
            rows={10}
          >
            <Column field="id" header="id" />
            <Column field="descripcion" header="Tipo de garantia0" />
            <Column header="Acciones" body={accciones} />
          </DataTable>
        </div>
        <Dialog
          visible={nuevoPunto}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          className="p-fluid"
          onHide={hideDialog}
          header={garantia.id === 0 ? "Nueva garantia" : `Editar garantia`}
          autoComplete="off"
        >
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="nombre">Nombre de la garantia</label>
                <InputText
                  id="nombre"
                  type="text"
                  placeholder="CON GARANTIA SOBRE CUENTA A PLAZO FIJO"
                  value={garantia.descripcion}
                  onChange={(e) => onChange(e, "descripcion")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !garantia.descripcion,
                  })}
                />
              </div>
            </div>
          </div>

          <Messages ref={message} />
          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={hideDialog}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => save()}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
