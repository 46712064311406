import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
//Servicios
import PersonaService from "./../../../../../service/AppService/PersonaService";
import EmpresaService from "./../../../../../service/AppService/EmpresaService";
import TestigoService from "./../../../../../service/AppService/TestigoService";

export const NuevaPersonaFiadora = ({
  onHide,
  setNuevaPersona,
  setfiadores,
  toast,
}) => {
  const toast1 = useRef();
  const message = useRef();

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  let fiadorEmty = {
    conId: 0,
    id: 0,
    perId: 0,
    nombre: "",
    persona: {
      apellidoCasada: "",
      deudor: "",
      emId: 0,
      empresa: {
        estado: "",
        id: 0,
        nombre: "",
        nombreAbreviado: "",
        registroInacop: [
          {
            empId: 0,
            estado: "",
            fechaIns: "",
            folio: 0,
            id: 0,
            libro: 0,
            registro: 0,
          },
        ],
      },
      estado: "",
      estadoCivil: "",
      fechaNacimiento: "",
      id: 0,
      nacionalidad: "",
      notario: "",
      numeroDpi: "",
      otroNombre: "",
      primerApellido: "",
      primerNombre: "",
      profecion: "",
      representanteLegal: "",
      segundoApellido: "",
      segundoNombre: "",
      sexo: "",
    },
    sabeFirmar: "",
  };

  let personaEmpty = {
    apellidoCasada: "",
    deudor: "",
    emId: null,

    estado: "",
    estadoCivil: "",
    fechaNacimiento: "",
    id: 0,
    nacionalidad: "",
    notario: "",
    numeroDpi: "",
    otroNombre: "",
    primerApellido: "",
    primerNombre: "",
    profecion: "",
    representanteLegal: "",
    segundoApellido: "",
    segundoNombre: "",
    sexo: "",
  };
  const [persona, setPersona] = useState(personaEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [block, setBlock] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [fiador, setfiador] = useState(fiadorEmty);
  const [editar, setEditar] = useState(false);

  const onChangeF = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _fiador = { ...fiador };
    _fiador[`${name}`] = val;
    setfiador(_fiador);
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _persona = { ...persona };
    _persona[`${name}`] = val;

    if (val === "S") {
      _persona[`estado`] = "A";
    }
    if (val === "N") {
      _persona[`estado`] = "";
      _persona[`emId`] = null;
    }

    if (val === "M") {
      _persona[`apellidoCasada`] = "";
    }

    setPersona(_persona);
  };

  const cancelar = () => {
    setNuevaPersona(false);
    setSubmitted(false);

    if (persona.id === 0) {
      setPersona(personaEmpty);
    }
    toast1.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " cambios descartados",
      life: 4000,
    });
  };

  const save = () => {
    setSubmitted(true);
    let _persona = { ...persona };

    if (
      persona.numeroDpi !== "" &&
      persona.primerNombre !== "" &&
      persona.primerApellido !== "" &&
      persona.nacionalidad !== "" &&
      persona.fechaNacimiento !== "" &&
      persona.estadoCivil !== ""
    ) {
      const personaService = new PersonaService();
      personaService.save(_persona).then((response) => {
        setPersona(response);

        let _fiador = { ...fiador };
        _fiador["perId"] = response.id;
        _fiador["nombre"] =
          response.primerNombre +
          "  " +
          response.segundoNombre +
          "  " +
          response.otroNombre +
          "  " +
          response.primerApellido +
          "  " +
          response.segundoApellido +
          "  " +
          response.apellidoCasada;
        _fiador["persona"] = response;
        setfiador(_fiador);

        if (persona.id === 0) {
          toast1.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nueva persona creada",
            life: 4000,
          });
        } else {
          toast1.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Persona actualizada",
            life: 4000,
          });
        }
        setEditar(true);

        setSubmitted(false);
        setBlock(true);
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _persona = { ...persona };
    _persona[`${name}`] = val;

    _persona[`${key}`] = value;

    setPersona(_persona);
  };

  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _persona = { ...persona };
    _persona[`${name}`] = val;

    setPersona(_persona);
  };

  const onHide1 = () => {
    onHide(false);
    setNuevaPersona(false);

    setfiador(fiadorEmty);
    setEditar(false);
  };

  const saveF = () => {
    setSubmitted(true);

    let _fiador = { ...fiador };
    if (fiador.conId === 0) {
      _fiador["conId"] = contrato1.id;
    }
    if (fiador.sabeFirmar !== "") {
      const testigoService = new TestigoService();
      testigoService.save(_fiador).then(() => {
        onHide(false);
        setNuevaPersona(false);
        setfiador(fiadorEmty);
        setSubmitted(false);
        setEditar(false);
        if (fiador.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo fiador añadido",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " fiador actualizado",
            life: 4000,
          });
        }

        testigoService.findByConId(contrato1.id).then((response) => {
          setfiadores(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  useEffect(() => {
    const empresaService = new EmpresaService();
    empresaService.findByEstado().then((response) => {
      setEmpresas(response);
    });
  }, []);

  return (
    <div autoComplete="off">
      <div className="card p-fluid" autoComplete="off">
        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Primer nombre"> Numero de DPI</label>

            <InputMask
              mask="9999 99999 9999"
              autoFocus
              value={persona.numeroDpi}
              onChange={(e) => onChange(e, "numeroDpi")}
              className={classNames({
                "p-invalid": submitted && !persona.numeroDpi,
              })}
              disabled={block === true ? "on" : ""}
            ></InputMask>
            {submitted && !persona?.numeroDpi && (
              <small className="p-invalid">DPI requerido</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor=" Genero"> Genero</label>
            <div className="formgrid grid">
              <div className="col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="M"
                    checked={persona?.sexo === "M"}
                    onChange={(e) => onChange(e, "sexo")}
                    className={classNames({
                      "p-invalid": submitted && !persona.sexo,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Masculino</label>
                </div>
              </div>
              <div className="col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="F"
                    checked={persona?.sexo === "F"}
                    onChange={(e) => onChange(e, "sexo")}
                    className={classNames({
                      "p-invalid": submitted && !persona.sexo,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Femenino</label>
                </div>
              </div>
            </div>
          </div>

          <div className="field col">
            <InputSwitch checked={block} onChange={(e) => setBlock(e.value)} />
            <label htmlFor="Editar datos" className="ml-3">
              {block == true ? "Editar" : "Bloquear"}
            </label>
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col " autoComplete="off">
            <label htmlFor="Primer nombre"> Primer nombre</label>
            <InputText
              id="PrimerNombre"
              type="text"
              value={persona?.primerNombre}
              onChange={(e) => onChange(e, "primerNombre")}
              required
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && !persona.primerNombre,
              })}
              disabled={block === true ? "on" : ""}
            />
            {submitted && !persona.primerApellido && (
              <small className="p-invalid">Primer nombre requerido</small>
            )}
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Segundo nombre</label>

            <InputText
              id="SegundoNombre"
              type="text"
              value={persona?.segundoNombre}
              onChange={(e) => onChange(e, "segundoNombre")}
              required
              autoComplete="off"
              disabled={block === true ? "on" : ""}
            />
          </div>
          <div className="field col">
            <label htmlFor="Primer nombre"> Otro nombre</label>

            <InputText
              id="OtroNombre"
              type="text"
              value={persona?.otroNombre}
              onChange={(e) => onChange(e, "otroNombre")}
              required
              autoComplete="off"
              disabled={block === true ? "on" : ""}
            />
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Primer Apellido"> Primer apellido</label>

            <InputText
              id="PrimerNombre"
              type="text"
              value={persona?.primerApellido}
              onChange={(e) => onChange(e, "primerApellido")}
              required
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && !persona.primerApellido,
              })}
              disabled={block === true ? "on" : ""}
            />
            {submitted && !persona.primerApellido && (
              <small className="p-invalid">Primer apellido requerido</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="Primer nombre"> Segundo apellido</label>

            <InputText
              id="SegundoApellido"
              type="text"
              value={persona?.segundoApellido}
              onChange={(e) => onChange(e, "segundoApellido")}
              required
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && !persona.segundoApellido,
              })}
              disabled={block === true ? "on" : ""}
            />
            {submitted && !persona.segundoApellido && (
              <small className="p-invalid">Segundo apellido requerido</small>
            )}
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Apellido de casada</label>

            <InputText
              id="ApellidoCasada"
              type="text"
              value={persona?.apellidoCasada}
              autoComplete="off"
              onChange={(e) => onChange(e, "apellidoCasada")}
              disabled={
                persona?.sexo === "M" || persona?.sexo === ""
                  ? "on"
                  : "" || block === true
                  ? "on"
                  : ""
              }
              required
            />
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Primer nombre"> Nacionalidad</label>
            <InputText
              id="nacionalidad"
              type="text"
              autoComplete="off"
              value={persona?.nacionalidad}
              onChange={(e) => onChange(e, "nacionalidad")}
              required
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !persona.nacionalidad,
              })}
            />
            {submitted && !persona.nacionalidad && (
              <small className="p-invalid">Nacionalidad requerida</small>
            )}
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Fecha de nacimiento</label>
            <InputText
              id="nacionalidad"
              type="date"
              autoComplete="off"
              value={persona?.fechaNacimiento}
              onChange={(e) => onChange(e, "fechaNacimiento")}
              required
              className={classNames({
                "p-invalid": submitted && !persona.fechaNacimiento,
              })}
              disabled={block === true ? "on" : ""}
            />
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Primer nombre"> Estado Civil</label>

            <div className="formgrid grid">
              <div className="col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="C"
                    checked={persona?.estadoCivil === "C"}
                    onChange={(e) => onChange(e, "sestadoCivilexo")}
                    className={classNames({
                      "p-invalid": submitted && !persona.estadoCivil,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">
                    {persona?.sexo === "M" || persona?.sexo === ""
                      ? "Casado"
                      : "Casada"}
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={persona?.estadoCivil === "S"}
                    onChange={(e) => onChange(e, "estadoCivil")}
                    className={classNames({
                      "p-invalid": submitted && !persona.estadoCivil,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">
                    {" "}
                    {persona?.sexo === "M" || persona?.sexo === ""
                      ? "Soltero"
                      : "Soltera"}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Profesion</label>
            <InputText
              id="nacionalidad"
              type="text"
              value={persona?.profecion}
              onChange={(e) => onChange(e, "profecion")}
              required
              autoComplete="off"
              className={classNames({
                "p-invalid": submitted && !persona.profecion,
              })}
              disabled={block === true ? "on" : ""}
            />
            {submitted && !persona?.profecion && (
              <small className="p-invalid">Profesion requerida</small>
            )}
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Es deudor</label>
            <div className="formgrid grid">
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={persona.deudor === "S"}
                    onChange={(e) => onChange(e, "deudor")}
                    className={classNames({
                      "p-invalid": submitted && !persona.deudor,
                    })}
                    disabled={block === true ? "on" : ""}
                  />

                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="N"
                    checked={persona?.deudor === "N"}
                    onChange={(e) => onChange(e, "deudor")}
                    disabled={block === true ? "on" : ""}
                    className={classNames({
                      "p-invalid": submitted && !persona.deudor,
                    })}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="Primer nombre"> Es notario</label>
            <div className="formgrid grid">
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={persona?.notario === "S"}
                    onChange={(e) => onChange(e, "notario")}
                    className={classNames({
                      "p-invalid": submitted && !persona.notario,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="N"
                    checked={persona?.notario === "N"}
                    onChange={(e) => onChange(e, "notario")}
                    className={classNames({
                      "p-invalid": submitted && !persona.notario,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="field col">
            <label htmlFor="Primer nombre"> Es representante legal</label>

            <div className="formgrid grid">
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={persona?.representanteLegal === "S"}
                    onChange={(e) => onChange(e, "representanteLegal")}
                    className={classNames({
                      "p-invalid": submitted && !persona.representanteLegal,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="N"
                    checked={persona?.representanteLegal === "N"}
                    onChange={(e) => onChange(e, "representanteLegal")}
                    className={classNames({
                      "p-invalid": submitted && !persona.representanteLegal,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* nueva fila de cliente */}
        <div className="formgrid grid">
          <div
            className="field col "
            style={{
              display: persona.representanteLegal === "S" ? "" : "none",
            }}
          >
            <label htmlFor="Primer nombre"> Estado</label>

            <div className="formgrid grid">
              <div className="col-12 md:col-4">
                <div className="field-radiobutton">
                  <Checkbox
                    inputId="checkOption1"
                    name="option"
                    //   className=" ml-2 mr-2 mb-2"
                    checked={persona.estado === "I" ? false : true}
                    onChange={(e) =>
                      onInputChangeRecCheckbox(
                        e.target.checked === true ? "A" : "I",
                        "estado"
                      )
                    }
                    className={classNames({
                      "p-invalid":
                        submitted &&
                        !persona.estado &&
                        persona.representanteLegal === "S",
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="checkOption1">
                    {persona.estado === "A" ? "Activo" : "Inactivo"}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="field col"
            style={{
              display: persona.representanteLegal === "S" ? "" : "none",
            }}
          >
            <label htmlFor="sucursal">Empresas</label>
            <Dropdown
              value={persona.empresa}
              options={empresas}
              onChange={(e) =>
                onInputChangeRegObject(e, "empresa", e.value.id, "emId")
              }
              optionLabel="nombre"
              placeholder="Seleccione una empresa"
              required
              className={classNames({
                "p-invalid":
                  submitted &&
                  !persona.emId &&
                  persona.representanteLegal === "S",
              })}
            />
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div className="card p-fluid">
        <div className="grid formgrid">
          <div className="col-6">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={cancelar}
            />
          </div>
          <div className="col-6">
            <Button
              type="button"
              label="Guardar y Agregar"
              icon="pi pi-upload "
              className="p-button-success"
              onClick={() => save()}
              disabled={block === true ? "on" : ""}
            />
          </div>
        </div>

        <Toast ref={toast1} />
      </div>

      <Dialog
        header={fiador.id === 0 ? `Nuevo Testigo:  ${fiador.nombre}` : "Editar"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        modal
        visible={editar}
        className="p-fluid"
        onHide={onHide1}
      >
        <div className="card p-fluid">
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor=" Genero"> Testigo sabe firmar</label>
              <div className="formgrid grid">
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="S"
                      checked={fiador.sabeFirmar === "S"}
                      onChange={(e) => onChangeF(e, "sabeFirmar")}
                      className={classNames({
                        "p-invalid": submitted && !fiador.sabeFirmar,
                      })}
                    />
                    <label htmlFor="option1">Si</label>
                  </div>
                </div>
                <div className="field col">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="N"
                      checked={fiador.sabeFirmar === "N"}
                      onChange={(e) => onChangeF(e, "sabeFirmar")}
                      className={classNames({
                        "p-invalid": submitted && !fiador.sabeFirmar,
                      })}
                    />
                    <label htmlFor="option1">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Messages ref={message} />

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide1}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Agregar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => saveF()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
