import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//Servicios
import RegistroInacopService from "../../../service/AppService/RegistroInacopService";
import EmpresaService from "../../../service/AppService/EmpresaService";

export const RegistroEmpresa = () => {
  const location = useLocation();
  const toast = useRef();
  const message = useRef();

  let empresaEmpty = {
    estado: "",
    id: 0,
    nombre: "",
    nombreAbreviado: "",
    registroInacop: [
      {
        empId: 0,
        estado: "",
        fechaIns: "",
        folio: 0,
        id: 0,
        libro: 0,
        registro: "",
      },
    ],
  };

  let registroInacopEmpy = {
    empId: 0,
    estado: "A",
    fechaIns: "",
    folio: 0,
    id: 0,
    libro: "",
    registro: 0,
  };
  const [empresa, setEmpresa] = useState(empresaEmpty);
  const [registroInacop, setRegistroInacop] = useState(registroInacopEmpy);

  const [modalNuevoRegistro, setModalNuevoRegistro] = useState(false);

  useEffect(() => {
    setEmpresa(location?.state?.empresa || empresaEmpty);
  }, []);

  const onInputChangeReg = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _registroInacop = { ...registroInacop };
    _registroInacop[`${name}`] = val;

    setRegistroInacop(_registroInacop);
  };

  //para checksbox
  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _registroInacop = { ...registroInacop };
    _registroInacop[`${name}`] = val;

    setRegistroInacop(_registroInacop);
  };

  const dialogFuncMap = {
    modalNuevoRegistro: setModalNuevoRegistro,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setRegistroInacop(registroInacopEmpy);
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onEdit = (rowData) => {
    setRegistroInacop(rowData);
    setModalNuevoRegistro(true);
  };

  const edit = (rowData) => {
    return (
      <Button
        className="p-button-rounded p-button-info mr-2 p-mr-2"
        icon="pi pi-pencil"
        onClick={() => onEdit(rowData)}
        tooltip={"editar"}
      />
    );
  };

  const save = () => {
    let _registroInacop = { ...registroInacop };
    if (registroInacop.empId === 0) {
      _registroInacop[`${"empId"}`] = empresa.id;
    }
    if (
      registroInacop.fechaIns !== "" &&
      registroInacop.folio !== 0 &&
      registroInacop.libro !== "" &&
      registroInacop.registro !== 0
    ) {
      const registroInacopService = new RegistroInacopService();
      registroInacopService.save(_registroInacop).then((response) => {
        setModalNuevoRegistro(false);
        if (registroInacop.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo registro creado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Registro actualizado",
            life: 4000,
          });
        }
        const empresaService = new EmpresaService();
        empresaService.findById(empresa.id).then((response) => {
          setEmpresa(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaIns);
  };


  return (
    <div className="grid">
      <div className="col-12 md:col-12">
        <div className="card p-fluid ">
          <h4>Datos de la Empresa</h4>
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Nombre de la Empresa</label>
              <InputText
                id="name2"
                type="text"
                value={empresa.nombre}
                disabled
              />
            </div>
            <div className="field col">
              <label htmlFor="email2">Nombre Abreviado</label>
              <InputText
                id="email2"
                type="text"
                value={empresa.nombreAbreviado}
                disabled
              />
            </div>
          </div>
          {/* End Row */}
        </div>
        <div className="card p-fluid">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 10,
            }}
          >
            {" "}
            <div
              style={{
                flex: 1,
              }}
            >
              {" "}
              <h4>Registros de inacop</h4>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 45,
              }}
            >
              {" "}
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nuevo Registro Inacop"
                className="p-button-success mr-2 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick("modalNuevoRegistro")}
              />
            </div>
          </div>

          {/* Start Row */}
          <DataTable
            value={empresa?.registroInacop}
            emptyMessage="No se encontraron registros de Inacop"
            paginator
            rows={10}
          >
            <Column sortable={true} field="id" header="id"></Column>
            <Column field="fechaIns" header="Fecha Inscripcion" body ={dateBodyTemplate}></Column>
            <Column field="libro" header="Numero de Libro"></Column>
            <Column field="registro" header="Numero de Registro"></Column>
            <Column field="folio" header="Numero de Folio "></Column>
            <Column header="Estado" body={teplateBodyEstado}></Column>
            <Column header="Acciones" body={edit}></Column>
          </DataTable>

          {/* End Row */}
        </div>
      </div>
      <Dialog
        visible={modalNuevoRegistro}
        header="Datos Registro INACOP"
        onHide={() => onHide("modalNuevoRegistro")}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
      >
        <div className="card p-fluid">
          {/* Start Row */}

          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="email2">Fecha de inscripcion</label>
              <InputText
                id="name2"
                type="date"
                value={registroInacop?.fechaIns}
                onChange={(e) => onInputChangeReg(e, "fechaIns")}
              />
            </div>

            <div className="field col">
              <label htmlFor="email2">Libro</label>
              <InputText
                id="email2"
                type="text"
                value={registroInacop?.libro}
                onChange={(e) => onInputChangeReg(e, "libro")}
              />
            </div>
          </div>
          {/* End Row */}
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Registro</label>
              <InputText
                id="name2"
                type="text"
                value={registroInacop?.registro}
                onChange={(e) => onInputChangeReg(e, "registro")}
              />
            </div>
            <div className="field col">
              <label htmlFor="name2">Folio</label>
              <InputText
                id="name2"
                type="number"
                value={registroInacop?.folio}
                onChange={(e) => onInputChangeReg(e, "folio")}
              />
            </div>
            <div
              className="p-field col-2"
              style={{
                margin: "auto",
                marginRight: 15,
                marginLeft: 15,
              }}
            >
              <div
                className="field-checkbox "
                style={{
                  margin: "auto",
                  textAlign: "right",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                <Checkbox
                  inputId="checkOption1"
                  name="option"
                  //   className=" ml-2 mr-2 mb-2"
                  checked={registroInacop?.estado === "I" ? false : true}
                  onChange={(e) =>
                    onInputChangeRecCheckbox(
                      e.target.checked === true ? "A" : "I",
                      "estado"
                    )
                  }
                />
                <label htmlFor="checkOption1">
                  {registroInacop?.estado === "A" ? "Activo" : "Inactivo"}
                </label>
              </div>
            </div>
          </div>
          {/* Start Row */}

          {/* End Row */}
        </div>
        <Messages ref={message} />
        {/* Start button save and delete  */}
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                // onClick={toggle}
                className="p-button-danger"
                onClick={() => onHide("modalNuevoRegistro")}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                //  onClick={toggleDataTable}
                className="p-button-success"
                onClick={() => save()}
              />
              <OverlayPanel
                // ref={op2}
                appendTo={document.body}
                showCloseIcon
                id="overlay_panel"
                style={{ width: "450px" }}
              ></OverlayPanel>
            </div>
          </div>
        </div>
        {/* finish button save and delete  */}
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
