import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
//services
import SucursalNoatarioService from "../../../service/AppService/SucursalNoatarioService";
import DepartamentoService from "../../../service/AppService/DepartamentoService";
import MunicipioService from "../../../service/AppService/MunicipioService";
export const SucursalNotario = () => {
  const toast = useRef();
  const message = useRef();
  const location = useLocation();
  let empresaNoatarioEmty = {
    estado: "",
    id: 0,
    nombre: "",
    representante: "",
    ubicacioin: "",
  };

  let sucursalNoatarioEmty = {
    enmid: 0,
    estado: "A",
    id: 0,
    munid: 0,
    municipios: {
      depId: 0,
      descripcion: "",
      id: 0,
    },
    nombre: "",
    ubicacion: "",
  };
  let filtroEmpty = {
    nombre: "",
  };
  let departamentoEmpty = {
    descripcion: "",
    id: 0,
  };
  let idDepartamentoEmpty = {
    descripcion: "",
    id: 0,
  };

  const [empresaNotario, setEmpresaNotario] = useState(empresaNoatarioEmty);
  const [sucursalNoatario, setSucursalNoatario] =
    useState(sucursalNoatarioEmty);
  const [sucursales, setSucursales] = useState([]);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevaSucursal, setNuevaSucursal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [appDepartamentos, setDepartamento] = useState();
  const [appMunicipio, setAppMunicipio] = useState([]);
  const [idDepartamento, setIdDepartamento] = useState(idDepartamentoEmpty);

  useEffect(() => {
    setEmpresaNotario(location?.state?.empresaNotario || empresaNoatarioEmty);
  }, []);

  useEffect(() => {
    const sucursalNoatario = new SucursalNoatarioService();
    sucursalNoatario
      .findByIdDes(
        empresaNotario.id || location?.state?.empresaNotario?.id,
        filtro.nombre
      )
      .then((response) => {
        setSucursales(response);
      });
    const departamentoService = new DepartamentoService();
    departamentoService.findAll().then((response) => {
      setDepartamento(response);
    });
  }, [filtro.nombre]);

  const onInputChangeRegObject = (e, name, value, key) => {
    setIdDepartamento(e.value);

    const municipioService = new MunicipioService();
    municipioService.findById(e.value.id, 0).then((response) => {
      setAppMunicipio(response);
    });
  };

  const onInputChangeRegObjectMun = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _sucursalNoatario = { ...sucursalNoatario };
    _sucursalNoatario[`${name}`] = val;

    _sucursalNoatario[`${key}`] = value;

    setSucursalNoatario(_sucursalNoatario);
  };

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;
    setFiltro(_filtro);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _sucursalNoatario = { ...sucursalNoatario };
    _sucursalNoatario[`${name}`] = val;

    setSucursalNoatario(_sucursalNoatario);
  };

  const onInputChangeRecCheckbox = (e, name) => {
    const val = e;
    let _sucursalNoatario = { ...sucursalNoatario };
    _sucursalNoatario[`${name}`] = val;

    setSucursalNoatario(_sucursalNoatario);
  };

  const dialogFuncMap = {
    nuevaSucursal: setNuevaSucursal,
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setSucursalNoatario(sucursalNoatarioEmty);
    setIdDepartamento(departamentoEmpty);
  };

  const hideDialog = () => {
    setSubmitted(false);
    onHide("nuevaSucursal");
    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " Cambios descartados",
      life: 4000,
    });
  };
  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  const teplateBodyEstado = (rowData) => (
    <>{rowData.estado === "A" ? "ACTIVO" : "INACTIVO"}</>
  );

  const onEdit = (rowData) => {
    setSucursalNoatario(rowData);
    const departamentoService = new DepartamentoService();
    departamentoService.findById(rowData.municipios.depId).then((response) => {
      setIdDepartamento(response);
    });
    const municipioService = new MunicipioService();
    municipioService.findById(rowData.munid, 0).then((response) => {
      setAppMunicipio(response);
    });
    setNuevaSucursal(true);
  };

  const accions = (rowData) => (
    <>
      <Button
        className="p-button-rounded p-button-info mr-2 p-mr-2"
        icon="pi pi-pencil"
        onClick={() => onEdit(rowData)}
        tooltip={"editar: "}
      />
    </>
  );

  const onSave = () => {
    setSubmitted(true);
    let _sucursalNoatario = { ...sucursalNoatario };
    if (sucursalNoatario.id === 0) {
      _sucursalNoatario[`${"enmid"}`] = empresaNotario.id;
    }

    if (
      sucursalNoatario.ubicacion !== "" &&
      sucursalNoatario.nombre !== "" &&
      sucursalNoatario.munid !== 0
    ) {
      const sucursalNotarioService = new SucursalNoatarioService();
      sucursalNotarioService.save(_sucursalNoatario).then((response) => {
        if (sucursalNoatario.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " sucursal  creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " sucursal  actualizada",
            life: 4000,
          });
        }
        setSubmitted(false);
        onHide("nuevaSucursal");
        sucursalNotarioService
          .findByIdDes(empresaNotario.id, filtro.nombre)
          .then((response) => {
            setSucursales(response);
          });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "No se aceptan campos vacios",
        life: 4000,
      });
    }
  };
  return (
    <div>
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <h4>Datos de la empresa</h4>
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Nombre de la empresa</label>
              <InputText
                id="name2"
                type="text"
                value={empresaNotario.nombre}
                disabled
              />
            </div>
          </div>
          {/* End Row */}
        </div>

        <div className="card p-fluid">
          <h4>Filtro</h4>
          {/* Start Row */}
          <div className="formgrid grid">
            <div className="field col">
              <label htmlFor="name2">Nombre</label>
              <InputText
                id="name2"
                autoComplete="off"
                type="text"
                value={filtro.nombre}
                onChange={(e) => chengFiltro(e, "nombre")}
              />
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                margin: "auto",
              }}
            >
              <Button
                style={{
                  width: "200px",
                  height: "35px",
                }}
                label="Nueva Sucursal"
                className="p-button-success mr-4 mb-2"
                icon="pi pi-plus"
                onClick={() => onClick("nuevaSucursal")}
              />
            </div>
          </div>
          {/* End Row */}
        </div>
        <div className="card p-fluid">
          <h5>Sucursales</h5>

          {/* Start Row */}
          <DataTable
            value={sucursales}
            emptyMessage="No se encontro ninguna sucural"
            paginator
            rows={10}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column field="nombre" header="Nombre "></Column>
            <Column field="municipios.descripcion" header="Municipio "></Column>
            <Column field="ubicacion" header="Ubicacion "></Column>
            <Column header="Estado" body={teplateBodyEstado}></Column>
            <Column header="Acciones" body={accions}></Column>
          </DataTable>
          {/* End Row */}

          {/* Start Button nuevo Usuario */}

          {/* End Button nuevo Usuario */}
        </div>

        <Dialog
          visible={nuevaSucursal}
          onHide={() => hideDialog()}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          header={
            sucursalNoatario.id === 0
              ? "Nueva sucursal"
              : `Editar sucursal ${sucursalNoatario.nombre}`
          }
          style={{ width: "50vw" }}
        >
          <div className="card p-fluid">
            {/* Start Row */}

            <div className="formgrid grid">
              <div className="field col">
                <label htmlFor="email2">Nombre</label>
                <InputText
                  id="name2"
                  type="text"
                  value={sucursalNoatario.nombre}
                  onChange={(e) => onInputChange(e, "nombre")}
                  autoComplete="off"
                  className={classNames({
                    "p-invalid": submitted && !sucursalNoatario.nombre,
                  })}
                />
                {submitted && !sucursalNoatario.nombre && (
                  <small className="p-invalid">
                    Nombre de la sucursal requirida
                  </small>
                )}
              </div>
            </div>

            <div className="form grid">
              <div className="field col">
                <label htmlFor="email2">Ubicacion</label>
                <InputText
                  id="email2"
                  type="text"
                  value={sucursalNoatario.ubicacion}
                  autoComplete="off"
                  onChange={(e) => onInputChange(e, "ubicacion")}
                  className={classNames({
                    "p-invalid": submitted && !sucursalNoatario.ubicacion,
                  })}
                />
                {submitted && !sucursalNoatario.ubicacion && (
                  <small className="p-invalid">
                    Ubicacion de la sucursal requirida
                  </small>
                )}
              </div>
              <div className="field col">
                <label htmlFor="email2">Estado</label>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="checkOption1"
                    name="option"
                    //   className=" ml-2 mr-2 mb-2"
                    checked={sucursalNoatario.estado === "I" ? false : true}
                    onChange={(e) =>
                      onInputChangeRecCheckbox(
                        e.target.checked === true ? "A" : "I",
                        "estado"
                      )
                    }
                  />
                  <label htmlFor="checkOption1">
                    {sucursalNoatario.estado === "A" ? "Activo" : "Inactivo"}
                  </label>
                </div>
              </div>
            </div>
            {/* End Row */}

            {/* Start Row */}
            <div className="formgrid grid ">
              <div className="field col">
                <label htmlFor="sucursal">Departamento</label>
                <Dropdown
                  value={idDepartamento}
                  options={appDepartamentos}
                  onChange={(e) =>
                    onInputChangeRegObject(
                      e,
                      "idDepartamento",
                      e.value.id,
                      "idDep"
                    )
                  }
                  optionLabel="descripcion"
                  placeholder="Seleccione un departamento"
                  required
                />
              </div>

              <div className="field col">
                <label htmlFor="sucursal">Municipio</label>
                <Dropdown
                  value={sucursalNoatario.municipios}
                  options={appMunicipio}
                  onChange={(e) =>
                    onInputChangeRegObjectMun(
                      e,
                      "municipios",
                      e.value.id,
                      "munid"
                    )
                  }
                  optionLabel="descripcion"
                  placeholder="Seleccione un municipio"
                  required
                />
              </div>
            </div>

            {/* End Row */}
          </div>

          {/* Start button save and delete  */}
          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="col-6">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={() => onHide("nuevaSucursal")}
                />
              </div>
              <div className="col-6">
                <Button
                  type="button"
                  label="Guardar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => onSave("nuevaSucursal")}
                />
              </div>
            </div>
          </div>

          {/* finish button save and delete  */}
          <Messages ref={message} />
        </Dialog>
        <Toast ref={toast} />
      </div>
    </div>
  );
};
