import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

//services
import BienInmubeleCoordenadaService from "./../../../../../../service/AppService/BienInmubeleCoordenadaService";
import PuntoCardinalService from "./../../../../../../service/AppService/PuntoCardinalService";
import { InputText } from "primereact/inputtext";
export const MedidaPorCoordenada = ({ bienInmueble1, toast }) => {
  const message = useRef();

  let Coordenadalet = {
    carId: 0,
    descripcion: "",
    id: 0,
    memida: "",
    inmId: 0,
    puntoCardinal: {
      descripcion: "",
      id: 0,
    },
  };

  const [coordenada, setCoordenada] = useState(Coordenadalet);
  const [coordenadas, setCoordenadas] = useState([]);
  const [puntoCardinal, setPuntoCardinal] = useState([]);
  const [eliminar, setEliminar] = useState(false);
  const [nuevaCoordenada, setNuevaCoordenada] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const coordenadaService = new BienInmubeleCoordenadaService();
    const puntoCardinal = new PuntoCardinalService();
    coordenadaService.findByInmId(bienInmueble1?.id || 0).then((response) => {
      console.log(response);
      setCoordenadas(response);
    });
    puntoCardinal.findAll().then((response) => {
      setPuntoCardinal(response);
    });
  }, []);

  const onClick = () => {
    setNuevaCoordenada(true);
  };
  const onHide = () => {
    setEliminar(false);
    setSubmitted(false);
    setNuevaCoordenada(false);
    setCoordenada(Coordenadalet);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _coordenada = { ...coordenada };
    _coordenada[`${name}`] = val;

    _coordenada[`${key}`] = value;

    setCoordenada(_coordenada);
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _coordenada = { ...coordenada };
    _coordenada[`${name}`] = val;

    setCoordenada(_coordenada);
  };

  const save = () => {
    setSubmitted(true);

    let _coordenada = { ...coordenada };
    if (coordenada.id === 0) {
      _coordenada["inmId"] = bienInmueble1.id;
    }
    if (_coordenada.inmId !== 0) {
      if (coordenada.descripcion !== "" && coordenada.carId !== 0) {
        const coordenadaService = new BienInmubeleCoordenadaService();
        coordenadaService.save(_coordenada).then(() => {
          if (coordenada.id === 0) {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Coordenada agregada",
              life: 4000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: " Coordenada  actualizada",
              life: 4000,
            });
          }
          setSubmitted(false);
          setNuevaCoordenada(false);
          setCoordenada(Coordenadalet);

          coordenadaService.findByInmId(bienInmueble1.id).then((res) => {
            setCoordenadas(res);
          });
        });
      } else {
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que tenga llenos los campos necesarios ",
          life: 4000,
        });
      }
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Ingrese un inmueble primero",
        life: 4000,
      });
    }
  };

  const onEdit = (rowData) => {
    setNuevaCoordenada(true);
    setCoordenada(rowData);
  };
  const eliminarT = (rowData) => {
    setNuevaCoordenada(true);
    setCoordenada(rowData);
    setEliminar(true);
  };
  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
        />
      </>
    );
  };

  const confirmDelete = () => {
    const coordenadaService = new BienInmubeleCoordenadaService();
    coordenadaService.dele(coordenada.id).then(() => {
      setEliminar(false);
      setNuevaCoordenada(false);
      setCoordenada(Coordenadalet);

      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Coordenada eliminada",
        life: 4000,
      });
      coordenadaService.findByInmId(bienInmueble1.id).then((res) => {
        setCoordenadas(res);
      });
    });
  };

  return (
    <div>
      <div className="gird">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                />
              </div>
            </div>

            <DataTable
              value={coordenadas}
              responsiveLayout="scroll"
              emptyMessage="No tiene ninguna medida de colidancia por punto Cardinal"
            >
              <Column
                field="puntoCardinal.descripcion"
                header="Punto cardinal"
              />
              <Column field="descripcion" header="Descripcion" />
              <Column body={accciones} header="Acciiones" />
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        header={
          coordenada.id === 0
            ? "Nueva coordenada"
            : eliminar === true
            ? "Eliminar coordenada"
            : "Editar coordenada"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={nuevaCoordenada}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="formgrid grid">
            <div className="field col">
              <label>Punto cardinal</label>
              <Dropdown
                value={coordenada.puntoCardinal}
                options={puntoCardinal}
                onChange={(e) =>
                  onInputChangeRegObject(
                    e,
                    "puntoCardinal",
                    e.value.id,
                    "carId"
                  )
                }
                disabled={eliminar === true ? "on" : ""}
                optionLabel="descripcion"
                placeholder="Seleccione un punto cardinal"
                required
                className={classNames({
                  "p-invalid": submitted && !coordenada.carId,
                })}
              />
            </div>
            <div className="field col">
              <label>Cantidad en metros</label>
              <InputText
                maxLength={250}
                rows={3}
                type="number"
                placeholder="(Opcional) estos iran dentro del parentecis"
                value={coordenada.memida}
                onChange={(e) => onChange(e, "memida")}
                disabled={eliminar === true ? "on" : ""}
                required
              />
            </div>
          </div>

          <div className="grid formgrid" autoComplete="off">
            <div className="field col">
              <label>Descripcion</label>
              <InputTextarea
                maxLength={250}
                rows={3}
                type="text"
                placeholder="ocho varas veinte pulgas que es equivalente a siete metros veinte centmietros con: Pablo"
                value={coordenada.descripcion}
                onChange={(e) => onChange(e, "descripcion")}
                disabled={eliminar === true ? "on" : ""}
                required
                className={classNames({
                  "p-invalid": submitted && !coordenada.descripcion,
                })}
              />
            </div>
          </div>
        </div>
        <Messages ref={message} />
        <div
          className="card p-fluid"
          style={{ display: eliminar === true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>

        <div
          className="card p-fluid"
          style={{ display: eliminar !== true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-trash"
                className="p-button-warning"
                onClick={() => confirmDelete()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
