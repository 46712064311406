import React, { useState, useEffect, useRef } from "react";
import { RadioButton } from "primereact/radiobutton";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { classNames } from "primereact/utils";

//Componentes
import { NuevaPersonaFiadora } from "./NuevaPersonaFiadora";

//service
import PersonaService from "./../../../../service/AppService/PersonaService";
import FiadorService from "./../../../../service/AppService/FiadorService";

export const Persona = ({ onHide, contrato, setfiadores, toast }) => {
  const message = useRef();

  let personaEmpty = {
    apellidoCasada: "",
    deudor: "",
    emId: 0,
    estado: "",
    estadoCivil: "",
    fechaNacimiento: "",
    id: 0,
    nacionalidad: "",
    notario: "",
    numeroDpi: "",
    otroNombre: "",
    primerApellido: "",
    primerNombre: "",
    profecion: "",
    representanteLegal: "",
    segundoApellido: "",
    segundoNombre: "",
    sexo: "",
  };
  let filtroEmpty = {
    numeroDpi: "",
    primerNombre: "",
    segundoNombre: "",
    otroNombre: "",
    primerApellido: "",
    segundoApellido: "",
    apellidoCasada: "",
  };

  let fiadorEmty = {
    conId: 0,
    id: 0,
    nombre: "hola",
    perId: 0,
    persona: {
      apellidoCasada: "",
      deudor: "",
      emId: 0,
      empresa: {
        estado: "",
        id: 0,
        nombre: "",
        nombreAbreviado: "",
        registroInacop: [
          {
            empId: 0,
            estado: "",
            fechaIns: "",
            folio: 0,
            id: 0,
            libro: 0,
            registro: 0,
          },
        ],
      },
      estado: "",
      estadoCivil: "",
      fechaNacimiento: "",
      id: 0,
      nacionalidad: "",
      notario: "",
      numeroDpi: "",
      otroNombre: "",
      primerApellido: "",
      primerNombre: "",
      profecion: "",
      representanteLegal: "",
      segundoApellido: "",
      segundoNombre: "",
      sexo: "",
    },
    sabeFirmar: 0,
  };
  const [personas, setPersonas] = useState([]);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevoFiador1, setNuevofiador1] = useState(false);
  const [fiador, setfiador] = useState(fiadorEmty);
  const [submitted, setSubmitted] = useState(false);
  const [persona, setPersona] = useState(personaEmpty);
  const [nuevaPersona, setNuevaPersona] = useState(false);
  //States para la paginacion
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    const personaService = new PersonaService();
    personaService
      .findByDpiNomApe(
        filtro.numeroDpi,
        filtro.primerNombre,
        filtro.segundoNombre,
        filtro.otroNombre,
        filtro.primerApellido,
        filtro.segundoApellido,
        filtro.apellidoCasada,
        pageNumber
      )
      .then((response) => {
        if (response !== undefined) {
          setPersonas(response.content);
          setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
          setFirstPage(response.first);
          setLastPage(response.last);
          if (
            filtro.numeroDpi != "" ||
            filtro.primerNombre != "" ||
            filtro.segundoNombre != "" ||
            filtro.otroNombre != "" ||
            filtro.primerApellido != "" ||
            filtro.segundoApellido != ""
          ) {
            setPageNumber(0);
          }
        } else {
        }
      });
  }, [pageNumber]);

  const chengFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;

    setFiltro(_filtro);
  };

  const fil = (evt) => {
    const personaService = new PersonaService();
    var e = event || evt;
    var charCode = e.which || e.keyCode;
    if (charCode == 9) {
      personaService
        .findByDpiNomApe(
          filtro.numeroDpi,
          filtro.primerNombre,
          filtro.segundoNombre,
          filtro.otroNombre,
          filtro.primerApellido,
          filtro.segundoApellido,
          filtro.apellidoCasada,
          pageNumber
        )
        .then((response) => {
          if (response !== undefined) {
            setPersonas(response.content);
            setTotalElements(response.totalElements);
            setTotalPages(response.totalPages);
            setFirstPage(response.first);
            setLastPage(response.last);
            if (
              filtro.numeroDpi != "" ||
              filtro.primerNombre != "" ||
              filtro.segundoNombre != "" ||
              filtro.otroNombre != "" ||
              filtro.primerApellido != "" ||
              filtro.segundoApellido != ""
            ) {
              setPageNumber(0);
            }
          } else {
          }
        });
    }
  };
  const fil2 = () => {
    const personaService = new PersonaService();

    personaService
      .findByDpiNomApe(
        filtro.numeroDpi,
        filtro.primerNombre,
        filtro.segundoNombre,
        filtro.otroNombre,
        filtro.primerApellido,
        filtro.segundoApellido,
        filtro.apellidoCasada,
        pageNumber
      )
      .then((response) => {
        if (response !== undefined) {
          setPersonas(response.content);
          setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
          setFirstPage(response.first);
          setLastPage(response.last);
          if (
            filtro.numeroDpi != "" ||
            filtro.primerNombre != "" ||
            filtro.segundoNombre != "" ||
            filtro.otroNombre != "" ||
            filtro.primerApellido != "" ||
            filtro.segundoApellido != ""
          ) {
            setPageNumber(0);
          }
        } else {
        }
      });
  };
  const renderPaginacion = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-double-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(0)}
        />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        />

        <InputText
          type="text"
          className="p-inputtext p-component"
          placeholder={pageNumber + 1 + " de " + totalPages}
          style={{
            width: "100px",
            height: "30px",
            borderColor: "#5499C7",
            textAlign: "center",
            fontSize: "15px",
            background: "transparent",
          }}
          readOnly
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-right"
          style={{
            background: "transparent; color: #5499C7",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(pageNumber + 1)}
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-double-right"
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(totalPages - 1)}
        />
      </div>
    );
  };

  const nombreA = (rowData) => {
    return (
      <>
        {rowData.primerNombre +
          "  " +
          rowData.segundoNombre +
          "  " +
          rowData.otroNombre +
          "  " +
          rowData.primerApellido +
          "  " +
          rowData.segundoApellido +
          "  " +
          rowData.apellidoCasada}
      </>
    );
  };

  const sexoD = (rowData) => {
    return <>{rowData.sexo === "M" ? "Masculino" : "Femenino"}</>;
  };

  const nuevaPersonaFiador = () => {
    setNuevaPersona(true);
  };

  const onHide1 = () => {
    setfiador(fiadorEmty);
    setNuevofiador1(false);
    setNuevaPersona(false);
    setPersona(personaEmpty);
  };
  const onEdit = (rowData) => {
    let _fiador = { ...fiador };
    _fiador["nombre"] =
      rowData.primerNombre +
      "  " +
      rowData.segundoNombre +
      "  " +
      rowData.otroNombre +
      "  " +
      rowData.primerApellido +
      "  " +
      rowData.segundoApellido +
      "  " +
      rowData.apellidoCasada;

    _fiador["persona"] = rowData;
    _fiador["perId"] = rowData.id;

    setfiador(_fiador);

    setNuevofiador1(true);
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _fiador = { ...fiador };
    _fiador[`${name}`] = val;
    setfiador(_fiador);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-success mr-2 p-mr-2"
          icon="pi pi-user-plus"
          tooltip={"Seleccionar"}
          onClick={() => onEdit(rowData)}
        />
      </>
    );
  };

  const save = () => {
    setSubmitted(true);
    let _fiador = { ...fiador };
    if (fiador.conId === 0) {
      _fiador["conId"] = contrato.id;
    }

    if (fiador.sabeFirmar !== 0) {
      const fiadorService = new FiadorService();
      fiadorService.save(_fiador).then(() => {
        setSubmitted(false);
        setNuevofiador1(false);
        onHide(false);

        if (fiador.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo fiador añadido",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " fiador actualizado",
            life: 4000,
          });
        }

        fiadorService.finByIdCon(contrato.id).then((response) => {
          setfiadores(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  return (
    <div className="grid">
      <div className="col-12 p-fluid">
        <div className="card p-fluid" onKeyDown={() => fil()}>
          {/* Start fila nuevo Usuario */}

          <div className="formgrid grid" autoComplete="off">
            <div className="field col-4">
              <h4>Filtros</h4>
            </div>
            <div className="field col-4"></div>
          </div>

          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">DPI</label>

              <InputMask
                mask="9999 99999 9999"
                value={filtro.numeroDpi}
                onChange={(e) => chengFiltro(e, "numeroDpi")}
              ></InputMask>
            </div>
            <div className="field col"></div>
            <div
              className="field col"
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
                marginRight: 10,
              }}
            >
              <Button
                style={{
                  width: "150px",
                  height: "35px",
                }}
                label="Buscar"
                icon="pi pi-search"
                className="p-button-info mr-2"
                onClick={() => fil2()}
              />
            </div>
          </div>

          {/* Start fila nuevo Usuario */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="nombre">primer nombre</label>

              <InputText
                id="filtroUsuario"
                value={filtro.primerNombre}
                autoComplete="off"
                onChange={(e) => chengFiltro(e, "primerNombre")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Segundo nombre</label>
              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.segundoNombre}
                onChange={(e) => chengFiltro(e, "segundoNombre")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Otro nombre</label>

              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.otroNombre}
                onChange={(e) => chengFiltro(e, "otroNombre")}
              />
            </div>
          </div>

          {/* Start fila nuevo filtro */}
          <div className="formgrid grid" autoComplete="off">
            <div className="field col">
              <label htmlFor="apellido">primer apellido</label>

              <InputText
                id="filtroApellido"
                autoComplete="off"
                type="text"
                value={filtro.primerApellido}
                onChange={(e) => chengFiltro(e, "primerApellido")}
              />
            </div>

            <div className="field col">
              <label htmlFor="apellido">segundo apellido</label>

              <InputText
                id="filtroApellido"
                autoComplete="off"
                type="text"
                value={filtro.segundoApellido}
                onChange={(e) => chengFiltro(e, "segundoApellido")}
              />
            </div>

            <div className="field col">
              <label htmlFor="nombre">Apellido de casada</label>

              <InputText
                id="filtroNombre"
                autoComplete="off"
                value={filtro.apellidoCasada}
                onChange={(e) => chengFiltro(e, "apellidoCasada")}
              />
            </div>
          </div>
          {/* End fila nuevo Usuario */}
          {/* Boton  nueva persona */}
        </div>

        <div className=" card p-fluid">
          <h5> Clientes </h5>
          <DataTable
            value={personas}
            emptyMessage="No se encontro ningun usuario"
            responsiveLayout="scroll"
            footer={renderPaginacion}
          >
            <Column sortable field="id" header="Id"></Column>
            <Column header="Nombre  " body={nombreA}></Column>
            <Column field="numeroDpi" header="DPI "></Column>
            <Column field="nacionalidad" header="Nacionalidad "></Column>
            <Column field="profecion" header="Profesion "></Column>
            <Column header="Genero " body={sexoD}></Column>
            <Column header="Acciones " body={accciones}></Column>
          </DataTable>
        </div>

        <Dialog
          header={
            fiador.id === 0 ? `Fiador:   ${fiador.nombre}` : "Editar Fiador"
          }
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          visible={nuevoFiador1}
          className="p-fluid"
          onHide={onHide1}
        >
          <div className="card p-fluid">
            <div className="formgrid grid" autoComplete="off">
              <div className="field col">
                <label htmlFor=" Genero"> Fiador sabe firmar</label>
                <div className="formgrid grid">
                  <div className="field col">
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="option1"
                        value="1"
                        checked={fiador.sabeFirmar === "1"}
                        onChange={(e) => onChange(e, "sabeFirmar")}
                        className={classNames({
                          "p-invalid": submitted && !fiador.sabeFirmar,
                        })}
                      />
                      <label htmlFor="option1">Si</label>
                    </div>
                  </div>
                  <div className="field col">
                    <div className="field-radiobutton">
                      <RadioButton
                        inputId="option1"
                        value="2"
                        checked={fiador.sabeFirmar === "2"}
                        onChange={(e) => onChange(e, "sabeFirmar")}
                        className={classNames({
                          "p-invalid": submitted && !fiador.sabeFirmar,
                        })}
                      />
                      <label htmlFor="option1">No</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Messages ref={message} />

          <div className="card p-fluid">
            <div className="grid formgrid">
              <div className="field col">
                <Button
                  type="button"
                  label="Cancelar"
                  icon="pi pi-times "
                  className="p-button-danger"
                  onClick={onHide1}
                />
              </div>
              <div className="field col">
                <Button
                  type="button"
                  label="Agregar"
                  icon="pi pi-upload "
                  className="p-button-success"
                  onClick={() => save()}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          header={persona.id === 0 ? `Nueva persona` : "Editar Fiador"}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "60vw" }}
          modal
          visible={nuevaPersona}
          className="p-fluid"
          onHide={onHide1}
        >
          <NuevaPersonaFiadora
            onHide={onHide}
            setNuevaPersona={setNuevaPersona}
            setfiadores={setfiadores}
            toast={toast}
          />
        </Dialog>
      </div>
    </div>
  );
};
