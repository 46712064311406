import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";

import UsuarioService from "../../../service/AppService/UsuarioService";

export const Contraseña = ({
  usuario,
  submitted,

  hideDialag,
  setSubmitted,
  setCambiarContraseña,
  toast,
}) => {
  const message = useRef();

  let passwordEmpty = {
    password: "",
    confirmPassword: "",
  };

  const [contraseña, setContraseña] = useState(passwordEmpty);

  const onSave = () => {
    setSubmitted(true);
    if (
      contraseña.password === contraseña.confirmPassword &&
      contraseña.password !== ""
    ) {
      let _usuario = { ...usuario };
      _usuario[`password`] = contraseña.password;

      const usuarioService = new UsuarioService();
      usuarioService.save(_usuario).then(() => {});
      setSubmitted(false);
      setCambiarContraseña(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: "Contraseña actualizada",
        life: 4000,
      });
    } else {
      message.current.show({
        severity: "warn",
        content: "Contraseñas no coinciden",
        life: 3000,
      });
      setContraseña(passwordEmpty);
    }
  };

  const onInputChangeCon = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _contraseña = { ...contraseña };
    _contraseña[`${name}`] = val;

    setContraseña(_contraseña);
  };

  return (
    <>
      <div className="card p-fluid">
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="cotraseña">Contraseña</label>
            <InputText
              id="password"
              type="password"
              value={contraseña.password}
              onChange={(e) => onInputChangeCon(e, "password")}
              autoComplete="off"
              autoFocus
              required
              className={classNames({
                "p-invalid": submitted && !contraseña.password,
              })}
            />
            {submitted && !contraseña.password && (
              <small className="p-invalid">Contraseña requirido</small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="confimarContraseña">Confirmar Contraseña</label>
            <InputText
              id="email2"
              type="password"
              value={contraseña.confirmPassword}
              onChange={(e) => onInputChangeCon(e, "confirmPassword")}
              autoComplete="off"
              required
              className={classNames({
                "p-invalid": submitted && !contraseña.confirmPassword,
              })}
            />
            {submitted && !contraseña.confirmPassword && (
              <small className="p-invalid">Confirme su contraseña</small>
            )}
          </div>

          {/* Start button save and delete  */}
        </div>
      </div>
      <Messages ref={message} />
      <div className="card p-fluid">
        <div className="formgrid grid">
          <div className="field col">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={hideDialag}
            />
          </div>
          <div className="field col">
            <Button
              type="button"
              label="Guardar"
              icon="pi pi-upload "
              className="p-button-success"
              onClick={() => onSave()}
            />
          </div>
        </div>
      </div>

      {/* finish button save and delete  */}
    </>
  );
};
