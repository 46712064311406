import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import { Badge } from "primereact/badge";
//Servicios
import AmortizacionesService from "./../../../service/AppService/AmortizacionService";

export const Amortizaciones = ({ setTotalA }) => {
  const toast = useRef();
  const message = useRef();
  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }
  let amortizacionEmty = {
    cantidadAmorizaciones: 0,
    conId: 0,
    id: 0,
    monto: 0.0,
  };

  let pagosEmpty = [
    { id: 1, descripcion: "Semanal" },
    { id: 2, descripcion: "Quincenal" },
    { id: 3, descripcion: "Mensual" },
    { id: 4, descripcion: "Trimestral" },
    { id: 5, descripcion: "Semestral" },
    { id: 6, descripcion: "Anual" },
  ];

  let montoEmpty = { monto1: 0 };
  const [amortizacion, setAmortizacion] = useState(amortizacionEmty);
  const [amortizaciones, setAmortizaciones] = useState([]);
  const [nuevaAmortizacion, setNuevaAmortizacion] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [montoT, setMontoT] = useState(montoEmpty);

  useEffect(() => {
    const amortizacionesService = new AmortizacionesService();
    amortizacionesService.findByConId(contrato1?.id || 0).then((res) => {
      if (res.length != 0) {
        var montoTotal = 0;

        for (var i = 0; i < res.length; i++) {
          var montoTotal1;
          montoTotal1 = res[i].monto * res[i].cantidadAmorizaciones;

          montoTotal += montoTotal1;

          let _montoT = { ...montoT };
          _montoT[`monto1`] = montoTotal;

          setMontoT(_montoT);

          setAmortizaciones(res);
        }
        setTotalA(montoTotal);
      }

      if (res.length == 0) {
        if (contrato1.frecuenciaPago === pagosEmpty[0].id) {
          var plazo = contrato1.palazMeses * 4;

          pagos(plazo);
        } else if (contrato1.frecuenciaPago === pagosEmpty[1].id) {
          var plazo = contrato1.palazMeses * 2;
          pagos(plazo);
        } else if (contrato1.frecuenciaPago === pagosEmpty[2].id) {
          var plazo = contrato1.palazMeses;
          pagos(plazo);
        } else if (contrato1.frecuenciaPago === pagosEmpty[3].id) {
          var plazo = contrato1.palazMeses / 3;
          pagos(plazo);
        } else if (contrato1.frecuenciaPago === pagosEmpty[4].id) {
          var plazo = contrato1.palazMeses / 6;
          pagos(plazo);
        } else if (contrato1.frecuenciaPago === pagosEmpty[5].id) {
          var plazo = contrato1.palazMeses / 12;
          pagos(plazo);
        }
      }
    });
  }, []);

  function plazo() {
    if (contrato1.frecuenciaPago === pagosEmpty[0].id) {
      var plazo = contrato1.palazMeses * 4;
      return plazo;
    } else if (contrato1.frecuenciaPago === pagosEmpty[1].id) {
      var plazo = contrato1.palazMeses * 2;
      return plazo;
    } else if (contrato1.frecuenciaPago === pagosEmpty[2].id) {
      var plazo = contrato1.palazMeses;
      return plazo;
    } else if (contrato1.frecuenciaPago === pagosEmpty[3].id) {
      var plazo = contrato1.palazMeses / 3;
      return plazo;
    } else if (contrato1.frecuenciaPago === pagosEmpty[4].id) {
      var plazo = contrato1.palazMeses / 6;
      return plazo;
    } else if (contrato1.frecuenciaPago === pagosEmpty[5].id) {
      var plazo = contrato1.palazMeses / 12;
      return plazo;
    }
  }

  function pagos(plazo) {
    const amortizacionesService = new AmortizacionesService();
    var monto = contrato1.monto / plazo;
    if (monto % 1 == 0) {
      let _amortizacion = { ...amortizacion };
      _amortizacion["cantidadAmorizaciones"] = plazo;
      _amortizacion["monto"] = monto;
      _amortizacion["conId"] = contrato1.id;

      amortizacionesService.save(_amortizacion).then(() => {
        amortizacionesService.findByConId(contrato1.id).then((response) => {
          setAmortizaciones(response);

          var montoTotal = 0;

          for (var i = 0; i < response.length; i++) {
            var montoTotal1;
            montoTotal1 = response[i].monto * response[i].cantidadAmorizaciones;

            montoTotal += montoTotal1;

            let _montoT = { ...montoT };
            _montoT[`monto1`] = montoTotal;

            setMontoT(_montoT);

            setAmortizaciones(response);
          }
          setTotalA(montoTotal);
        });
      });
    } else {
      // numero = alert(Math.trunc(numero));
      // var des = numero - Math.trunc(numero);
      //  alert(Math.round(monto));

      var monto = Math.round(monto);
      var montoP1 = (plazo - 1) * Math.round(monto);
      var montoP2 = contrato1.monto - montoP1;
      let _amortizacion = { ...amortizacion };
      _amortizacion["cantidadAmorizaciones"] = plazo - 1;
      _amortizacion["monto"] = monto;
      _amortizacion["conId"] = contrato1.id;
      setAmortizacion(_amortizacion);

      amortizacionesService.save(_amortizacion).then(() => {
        let _amortizacion = { ...amortizacion };
        _amortizacion["cantidadAmorizaciones"] = 1;
        _amortizacion["monto"] = montoP2;
        _amortizacion["conId"] = contrato1.id;

        amortizacionesService.save(_amortizacion).then(() => {
          setAmortizacion(amortizacionEmty);

          amortizacionesService.findByConId(contrato1.id).then((response) => {
            var montoTotal = 0;

            for (var i = 0; i < response.length; i++) {
              var montoTotal1;
              montoTotal1 =
                response[i].monto * response[i].cantidadAmorizaciones;

              montoTotal += montoTotal1;

              let _montoT = { ...montoT };
              _montoT[`monto1`] = montoTotal;

              setMontoT(_montoT);

              setAmortizaciones(response);
            }
            setTotalA(montoTotal);
          });
        });
      });
    }
  }

  const frecuencia = () => {
    var frecuenciaT;

    for (var i = 0; i < pagosEmpty.length; i++) {
      if (contrato1.frecuenciaPago === pagosEmpty[i].id) {
        frecuenciaT = pagosEmpty[i].descripcion;
      }
    }

    return <>{frecuenciaT}</>;
  };

  const onClick = () => {
    setSubmitted(false);
    setAmortizacion(amortizacionEmty);
    setNuevaAmortizacion(true);
  };

  const onHide = () => {
    setEliminar(false);

    setNuevaAmortizacion(false);

    toast.current.show({
      severity: "error",
      summary: "error",
      detail: "Cambios descartados ",
      life: 4000,
    });
  };
  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _amortizacion = { ...amortizacion };
    _amortizacion[`${name}`] = val;

    setAmortizacion(_amortizacion);
  };

  const onEdit = (rowData) => {
    setSubmitted(false);
    setAmortizacion(rowData);
    setNuevaAmortizacion(true);
  };
  const eliminarT = (rowData) => {
    setAmortizacion(rowData);
    setEliminar(true);
  };

  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"Editar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
          disabled={contrato1.imp =="S" ? "on" : ""}
        />
      </>
    );
  };

  const save = () => {
    setSubmitted(true);
    let _amortizacion = { ...amortizacion };
    if (amortizacion.conId === 0) {
      _amortizacion["conId"] = contrato1.id;
    }

    if (amortizacion.monto != 0 && amortizacion.cantidadAmorizaciones != 0) {
      const amortizacionesService = new AmortizacionesService();
      amortizacionesService.save(_amortizacion).then(() => {
        if (amortizacion.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nueva amortizacion creada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " amortizacion actualizada",
            life: 4000,
          });
        }
        setSubmitted(false);
        setNuevaAmortizacion(false);
        setAmortizacion(amortizacionEmty);
        amortizacionesService.findByConId(contrato1.id).then((response) => {
          setAmortizaciones(response);
          if (response.length != 0) {
            var montoTotal = 0;

            for (var i = 0; i < response.length; i++) {
              var montoTotal1;
              montoTotal1 =
                response[i].monto * response[i].cantidadAmorizaciones;

              montoTotal += montoTotal1;

              let _montoT = { ...montoT };
              _montoT[`monto1`] = montoTotal;

              setMontoT(_montoT);
            }
            setTotalA(montoTotal);
          }
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  const confirDelete = () => {
    const amortizacionesService = new AmortizacionesService();
    amortizacionesService.dele(amortizacion.id).then(() => {
      setNuevaAmortizacion(false);
      setEliminar(false);
      setAmortizacion(amortizacionEmty);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Amortizacion eliminada",
        life: 4000,
      });
      amortizacionesService.findByConId(contrato1?.id).then((response) => {
        setAmortizaciones(response);
        if (response.length != 0) {
          var montoTotal = 0;

          for (var i = 0; i < response.length; i++) {
            var montoTotal1;
            montoTotal1 = response[i].monto * response[i].cantidadAmorizaciones;

            montoTotal += montoTotal1;

            let _montoT = { ...montoT };
            _montoT[`monto1`] = montoTotal;

            setMontoT(_montoT);
          }
          setTotalA(montoTotal);
        } else {
          setMontoT(montoEmpty);
          setTotalA(0);
        }
      });
    });
  };

  const total = (rowData) => {
    return <>{rowData.cantidadAmorizaciones * rowData.monto}</>;
  };

  return (
    <div>
      <div className="col-12 md:col-12">
        <div className="card p-fluid">
          <div className="formgrid grid">
            {" "}
            <div className="field col-6">
              {" "}
              <Button
                icon="pi pi-plus"
                className="p-button-success mr-4 md-2"
                onClick={() => onClick()}
                disabled={contrato1.imp =="S" ? "on" : ""}
              />
            </div>
            <div className="field col">
              {/* <Badge
                value={
                  "Cantidad de Amortizaciones a realizar: " +
                  `${plazo()} en un plazo de ${contrato1.palazMeses} meses`
                }
                severity="info"
              >


                {" "}
              </Badge> */}

              <Tag
                severity="success"
                value={
                  "Se deben realizar : " +
                  `${plazo()} en un plazo de ${contrato1.palazMeses} meses`
                }
              ></Tag>
            </div>
            <div className="field col">
              <Tag
                icon="pi pi-exclamation-triangle"
                value={"Monto de contrato: Q." + `${contrato1.monto}`}
                severity="warning"
              >
                {" "}
              </Tag>
            </div>
          </div>
          <DataTable
            value={amortizaciones}
            emptyMessage="No se encontro ninguna amortizacion"
            responsiveLayout="scroll"
          >
            <Column
              header="Cantidad de amortizaciones"
              field="cantidadAmorizaciones"
            ></Column>

            <Column header="Frecuenia de pago" body={frecuencia}></Column>
            <Column header="Monto por amortizacion" field="monto"></Column>
            <Column header="Total" body={total}></Column>
            <Column header="Acciones" body={accciones}></Column>
          </DataTable>
        </div>
        <Tag
          style={{
            display: montoT.monto1 - contrato1.monto != 0 ? "" : "none",
          }}
          severity="danger"
          value={
            "Error el monto por amortizacion no coindice con el monto del contrato " +
            `: La diferencia es de Q. ${montoT.monto1 - contrato1.monto}`
          }
          icon="pi pi-times"
        />
        <Tag
          style={{
            display: montoT.monto1 - contrato1.monto == 0 ? "" : "none",
          }}
          severity="success"
          value={
            "El monto de las amortizaciones coincide con el monto del contrato " +
            `Q. ${montoT.monto1} `
          }
          icon="pi pi-check"
        />{" "}
      </div>

      <Dialog
        header={
          amortizacion.id === 0 ? "Nueva amortizacion" : "Editar amortizacion"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        visible={nuevaAmortizacion}
        className="  p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          {" "}
          <div className=" formgrid grid">
            <div className="field col">
              <label>Cantidad de amortizaciones a realizar</label>
              <InputText
                id="filtroNombre"
                type="number"
                value={amortizacion.cantidadAmorizaciones}
                onChange={(e) => onChange(e, "cantidadAmorizaciones")}
                className={classNames({
                  "p-invalid": submitted && !amortizacion.cantidadAmorizaciones,
                })}
                //disabled="on"
              />
              {submitted && !amortizacion.cantidadAmorizaciones && (
                <small className="p-invalid">
                  {" "}
                  Se necesia la cantidad de amortizacion
                </small>
              )}
            </div>

            <div className="field col">
              <label>Monto por cada amortizacion</label>
              <span className="p-input-icon-left">
                <i>Q. </i>
                <InputText
                  id="filtroNombre"
                  type="number"
                  value={amortizacion.monto}
                  autoComplete="off"
                  onChange={(e) => onChange(e, "monto")}
                  className={classNames({
                    "p-invalid": submitted && !amortizacion.monto,
                  })}
                  //  disabled="on"
                />
                {submitted && !amortizacion.monto && (
                  <small className="p-invalid"> Se necesita un monto</small>
                )}
              </span>
            </div>
          </div>
        </div>

        <Messages ref={message} />
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={
          amortizacion.id === 0 ? "Nueva amortizacion" : "Editar amortizacion"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        visible={eliminar}
        className="  p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          {" "}
          <div className="grid formgrid">
            <div className="field col">
              <label>Cantidad de amortizaciones a realizar</label>
              <InputText
                id="filtroNombre"
                type="number"
                value={amortizacion.cantidadAmorizaciones}
                disabled="on"
              />
            </div>

            <div className="field col">
              <label>Monto por cada amortizacion</label>
              <span className="p-input-icon-left">
                <i>Q. </i>
                <InputText
                  id="filtroNombre"
                  type="number"
                  value={amortizacion.monto}
                  autoComplete="off"
                  disabled="on"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="field col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-trash "
                className="p-button-warning"
                onClick={() => confirDelete()}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
