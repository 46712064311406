import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { InputTextarea } from "primereact/inputtextarea";

//services
import TestimonioService from "../../../../../../service/AppService/TestimonioService";

export const Testmonio = ({ bienInmueble1, toast }) => {
  const message = useRef();
  let TestimoonioLet = {
    aFavor: "",
    descripcion: "",
    inmId: 0,
    fechaAutorizacion: "",
    id: 0,

    notario: "",
    numDeclaracion: 0,
    numEscritura: 0,
    tipoTestimonio: "",
  };
  const [testimonio, setTestimonio] = useState(TestimoonioLet);
  const [testimonios, setTestimonios] = useState([]);
  const [eliminar, setEliminar] = useState(false);
  const [nuevoTestimonio, setNuevoTestimonio] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    const testService = new TestimonioService();
    testService.findIdInmb(bienInmueble1?.id || 0).then((response) => {
      setTestimonios(response);
    });
  }, []);

  const onClick = () => {
    setNuevoTestimonio(true);
  };

  const onHide = () => {
    setEliminar(false);
    setNuevoTestimonio(false);
    setSubmitted(false);
    setTestimonio(TestimoonioLet);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: " Cambios descartados",
      life: 4000,
    });
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _testimonio = { ...testimonio };
    _testimonio[`${name}`] = val;

    setTestimonio(_testimonio);
  };

  const save = () => {
    setSubmitted(true);
    let _testimonio = { ...testimonio };
    if (testimonio.inmId === 0) {
      _testimonio["inmId"] = bienInmueble1.id;
    }
    if (
      testimonio.aFavor !== "" &&
      testimonio.fechaAutorizacion !== "" &&
      testimonio.tipoTestimonio !== "" &&
      testimonio.notario !== "" &&
      testimonio.numEscritura !== 0 &&
      testimonio.descripcion !== ""
    ) {
      const testService = new TestimonioService();
      testService.save(_testimonio).then(() => {
        if (testimonio.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Coordenada agregada",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Coordenada  actualizada",
            life: 4000,
          });
        }

        setSubmitted(false);
        setNuevoTestimonio(false);
        setTestimonio(TestimoonioLet);
        testService.findIdInmb(bienInmueble1?.id || 0).then((response) => {
          setTestimonios(response);
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };
  const onEdit = (rowData) => {
    setNuevoTestimonio(true);
    setTestimonio(rowData);
  };
  const eliminarT = (rowData) => {
    setNuevoTestimonio(true);
    setEliminar(true);
    setTestimonio(rowData);
  };
  const accciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
          tooltip={"editar "}
        />

        <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => eliminarT(rowData)}
          tooltip={"Eliminar"}
        />
      </>
    );
  };

  const confirmDelete = () => {
    const testService = new TestimonioService();
    testService.dele(testimonio.id).then(() => {
      setEliminar(false);
      setNuevoTestimonio(false);
      setTestimonio(TestimoonioLet);

      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: " Coordenada eliminada",
        life: 4000,
      });
      testService.findIdInmb(bienInmueble1?.id || 0).then((response) => {
        setTestimonios(response);
      });
    });
  };

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaAutorizacion);
  };

  return (
    <div>
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col-6">
                {" "}
                <Button
                  icon="pi pi-plus"
                  className="p-button-success mr-4 md-2"
                  onClick={() => onClick()}
                />
              </div>
            </div>

            <DataTable
              value={testimonios}
              responsiveLayout="scroll"
              emptyMessage="No tiene ninguna testimonio"
            >
              <Column header="Tipo de testimonio" field="tipoTestimonio" />

              <Column
                header="Numero de escritura publica"
                field="numEscritura"
              />
              <Column header="Direccion de autorizacion" field="inmId" />
              <Column
                body={dateBodyTemplate}
                header="Fecha de autorizacion"
                field="fechaAutorizacion"
              />
              <Column header="Notario" field="notario" />
              <Column header="A favor" field="aFavor" />
              <Column header="Acciones" body={accciones} />
            </DataTable>
          </div>
        </div>
      </div>
      <Dialog
        header={
          testimonio.id === 0
            ? "Nueva testimonio"
            : eliminar === true
            ? "Eliminar testimonio"
            : "Editar testimonio"
        }
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        modal
        visible={nuevoTestimonio}
        className="p-fluid"
        onHide={onHide}
      >
        <div className="card p-fluid">
          <div className="grid formgrid">
            <div className="field col">
              <label>Tipo de amparacion</label>
              <InputText
                value={testimonio.tipoTestimonio}
                type="text"
                placeholder="COPIA LEGALIZADA / TESTIMONIO"
                onChange={(e) => onChange(e, "tipoTestimonio")}
                disabled={eliminar === true ? "on" : ""}
                className={classNames({
                  "p-invalid": submitted && !testimonio.tipoTestimonio,
                })}
              />
            </div>
          </div>

          <div className="grid formgrtid">
            <div className="field col">
              <label>Numero de de escritura publica</label>
              <InputText
                value={testimonio.numEscritura}
                type="number"
                placeholder="42"
                disabled={eliminar === true ? "on" : ""}
                onChange={(e) => onChange(e, "numEscritura")}
                className={classNames({
                  "p-invalid": submitted && !testimonio.numEscritura,
                })}
              />
            </div>
            <div className="field col">
              <label>Fecha de autorizacion</label>
              <InputText
                value={testimonio.fechaAutorizacion}
                type="date"
                disabled={eliminar === true ? "on" : ""}
                onChange={(e) => onChange(e, "fechaAutorizacion")}
                className={classNames({
                  "p-invalid": submitted && !testimonio.fechaAutorizacion,
                })}
              />
            </div>
          </div>

          <div className="grid formgrid">
            <div className="field col">
              <label>Autorizada en</label>
              <InputTextarea
                rows={2}
                value={testimonio.descripcion}
                type="text"
                placeholder="La cabecera departamental de Sololá"
                onChange={(e) => onChange(e, "descripcion")}
                disabled={eliminar === true ? "on" : ""}
                className={classNames({
                  "p-invalid": submitted && !testimonio.descripcion,
                })}
              />
            </div>
          </div>

          <div className="grid formgrid">
            <div className="field col">
              <label>Notario que autorizo</label>
              <InputText
                value={testimonio.notario}
                type="text"
                placeholder="RAMÓN DE JESÚS ALVARADO VÁSQUEZ"
                onChange={(e) => onChange(e, "notario")}
                disabled={eliminar === true ? "on" : ""}
                className={classNames({
                  "p-invalid": submitted && !testimonio.notario,
                })}
              />
            </div>
          </div>

          <div className="grid formgrid">
            <div className="field col">
              <label>Genero del notario</label>
              <div className="formgrid grid">
                <div className="col-12 md:col-6">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="M"
                      checked={testimonio.aFavor === "M"}
                      onChange={(e) => onChange(e, "aFavor")}
                      disabled={eliminar === true ? "on" : ""}
                      className={classNames({
                        "p-invalid": submitted && !testimonio.aFavor,
                      })}
                    />
                    <label htmlFor="option1">Masculino</label>
                  </div>
                </div>
                <div className="col-12 md:col-6">
                  <div className="field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value="F"
                      checked={testimonio.aFavor === "F"}
                      onChange={(e) => onChange(e, "aFavor")}
                      disabled={eliminar === true ? "on" : ""}
                      className={classNames({
                        "p-invalid": submitted && !testimonio.aFavor,
                      })}
                    />
                    <label htmlFor="option1">Femenino</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Messages ref={message} />
        <div
          className="card p-fluid"
          style={{ display: eliminar === true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col-6">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col-6">
              <Button
                type="button"
                label="Guardar"
                icon="pi pi-upload "
                className="p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>

        <div
          className="card p-fluid"
          style={{ display: eliminar !== true ? "none" : "" }}
        >
          <div className="grid formgrid">
            <div className="col">
              <Button
                type="button"
                label="Cancelar"
                icon="pi pi-times "
                className="p-button-danger"
                onClick={onHide}
              />
            </div>
            <div className="col">
              <Button
                type="button"
                label="Confirmar"
                icon="pi pi-trash"
                className="p-button-warning"
                onClick={() => confirmDelete()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
