import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//Services

import DepartamentoService from "./../../../service/AppService/DepartamentoService";
import MunicipioService from "./../../../service/AppService/MunicipioService";
import PersonaService from "./../../../service/AppService/PersonaService";
import TipoDocumentoService from "./../../../service/AppService/TipoDocumentoService";
import ContratoService from "./../../../service/AppService/ContratoService";
import CorrelativoService from "./../../../service/AppService/CorrelativoService";
import GarantiaService from "./../../../service/AppService/GarantiaService";

export const ContratoNuevo = ({ cliente }) => {
  try {
    var usuario;

    var storList = localStorage.getItem("user");
    usuario = JSON?.parse(storList);
  } catch (err) {
    window.location = "#/error";
  }

  try {
    var contrato1;

    var conList = localStorage.getItem("contrato");
    contrato1 = JSON?.parse(conList);
  } catch (err) {
    window.location = "#/error";
  }

  const toast = useRef();
  const message = useRef();

  let idDepartamentoEmpty = {
    descripcion: "",
    id: 0,
  };

  let municipios = {
    depId: 0,
    descripcion: "",
    id: 0,
  };

  let pagosEmpty = [
    { id: 1, descripcion: "Semanal" },
    { id: 2, descripcion: "Quincenal" },
    { id: 3, descripcion: "Mensual" },
    { id: 4, descripcion: "Trimestral" },
    { id: 5, descripcion: "Semestral" },
    { id: 6, descripcion: "Anual" },
  ];
  let correlativoEmpty = {
    correlativo: 0,
    estado: "",
    fechaFinal: "",
    fechaInicial: "",
    id: 0,
  };

  let desembolsoEmty = {
    conId: 0,
    id: 0,
    monto: 0,
    num: 0,
  };

  const [correlativo, setCorrelativo] = useState(correlativoEmpty);
  const [pagos, setPagos] = useState(pagosEmpty);
  const [contrato, setContrato] = useState(contrato1);
  const [garantias, setGarantias] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [block, setBlock] = useState(false);
  const [appDepartamentos, setDepartamento] = useState();
  const [idDepartamento, setIdDepartamento] = useState(idDepartamentoEmpty);
  const [appMunicipio, setAppMunicipio] = useState(municipios);
  const [noatario, setnoatario] = useState(undefined);
  const [notarios, setNotarios] = useState([]);
  const [representates, setRepresentantes] = useState([]);
  const [representante, setRepresentante] = useState(undefined);
  const [tipoDocumento, setTipoDocumento] = useState([]);

  useEffect(() => {
    let _contrato = { ...contrato };

    const garantiasService = new GarantiaService();
    garantiasService.findBydes().then((res) => {
      console.log(res);
      setGarantias(res);
    });
    const departamentoService = new DepartamentoService();

    const correlativoService = new CorrelativoService();
    correlativoService.findByEstado().then((response) => {
      setCorrelativo(response);
    });

    departamentoService.findAll().then((response) => {
      setDepartamento(response);
    });

    const notarioService = new PersonaService();

    if (contrato.id !== 0) {
      setBlock(true);
      const municipioService = new MunicipioService();
      departamentoService
        .findById(contrato.municipio.depId)
        .then((response) => {
          setIdDepartamento(response);

          municipioService.findById(response?.id || 0).then((mun) => {
            setAppMunicipio(mun);
            setAppMunicipio(mun);
          });
        });

      notarioService.findById(contrato.idNotario).then((responseIdN) => {
        let _noatario = { ...noatario };

        var id = responseIdN?.id || 0;
        _noatario["id"] = id;

        var nombre =
          responseIdN.primerNombre +
          " " +
          responseIdN.segundoNombre +
          " " +
          responseIdN.otroNombre +
          " " +
          responseIdN.primerApellido +
          " " +
          responseIdN.segundoApellido +
          " " +
          responseIdN.apellidoCasada;
        _noatario["descripcion"] = nombre;

        _contrato["notario"] = _noatario;

        notarioService.findById(contrato.idRepresentante).then((responseId) => {
          let _representante = { ...representante };

          var id = responseId.id;
          _representante["id"] = id;

          var nombre =
            responseId.primerNombre +
            " " +
            responseId.segundoNombre +
            " " +
            responseId.otroNombre +
            " " +
            responseId.primerApellido +
            " " +
            responseId.segundoApellido +
            " " +
            responseId.apellidoCasada;
          _representante["descripcion"] = nombre;

          _contrato["representate"] = _representante;
          setContrato(_contrato);
        });
      });

      for (var i = 0; i < pagos?.length; i++) {
        if (contrato.frecuenciaPago === pagos[i]?.id) {
          _contrato["pago"] = pagos[i];
        }
      }
    }

    if (noatario === undefined) {
      notarioService.findByEsNotario().then((response) => {
        for (var i = 0; i < response?.length; i++) {
          let _noatario = { ...noatario };
          var id = response[i].id;
          _noatario["id"] = id;

          var nombre =
            response[i].primerNombre +
            " " +
            response[i].segundoNombre +
            " " +
            response[i].otroNombre +
            " " +
            response[i].primerApellido +
            " " +
            response[i].segundoApellido +
            " " +
            response[i].apellidoCasada;
          _noatario["descripcion"] = nombre;
          setnoatario(_noatario);

          setNotarios((notarios) => [...notarios, _noatario]);
        }
      });

      const tipoDocumentoService = new TipoDocumentoService();
      tipoDocumentoService.findAll().then((response) => {
        setTipoDocumento(response);
      });
    }

    if (representante === undefined) {
      notarioService.findByEsRepresentante().then((response) => {
        for (var i = 0; i < response?.length; i++) {
          let _representante = { ...representante };
          var id = response[i].id;
          _representante["id"] = id;

          var nombre =
            response[i].primerNombre +
            " " +
            response[i].segundoNombre +
            " " +
            response[i].otroNombre +
            " " +
            response[i].primerApellido +
            " " +
            response[i].segundoApellido +
            " " +
            response[i].apellidoCasada;
          _representante["descripcion"] = nombre;

          setRepresentante(_representante);

          setRepresentantes((representates) => [
            ...representates,
            _representante,
          ]);
        }
      });
    }
  }, []);

  const onchangeDep = (e) => {
    setIdDepartamento(e.value);

    const municipioService = new MunicipioService();
    municipioService.findById(e.value.id).then((response) => {
      setAppMunicipio(response);
    });
  };

  const onInputChangeRegObjectMun = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _contrato = { ...contrato };
    _contrato[`${name}`] = val;

    _contrato[`${key}`] = value;

    setContrato(_contrato);
  };

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _contrato = { ...contrato };
    _contrato[`${name}`] = val;

    setContrato(_contrato);
  };

  const onInputChangeRegObject = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";
    let _contrato = { ...contrato };
    _contrato[`${name}`] = val;

    _contrato[`${key}`] = value;

    setContrato(_contrato);
  };

  const cancelar = () => {
    setContrato(contrato1);
    setIdDepartamento(idDepartamentoEmpty);
    setAppMunicipio(municipios);

    setnoatario();

    setRepresentante();
    setTipoDocumento([]);
    setSubmitted(false);

    toast.current.show({
      severity: "error",
      summary: "Cancelado",
      detail: " cambios descartados",
      life: 4000,
    });
  };

  const save = () => {
    setSubmitted(true);
    const contratoService = new ContratoService();

    let _contrato = { ...contrato };
    if (contrato.id === 0) {
      _contrato["usuId"] = usuario.id;
      _contrato["perId"] = cliente.id;
    }
    if (contrato.imp == "" || contrato.imp == null || contrato.imp == "null") {
      _contrato["imp"] = "N";
    }
    if (contrato.corId === 0) {
      var num = correlativo.correlativo;
      var num1 = num + 1;

      _contrato["corId"] = num1;
      let _correlativo = { ...correlativo };
      _correlativo["correlativo"] = num1;

      const correlativoService = new CorrelativoService();
      correlativoService.save(_correlativo).then((response) => {});
    }

    if (
      contrato.cantDesembolsos !== 0 &&
      contrato.destino !== "" &&
      contrato.fechaContrato !== "" &&
      contrato.fechaPrimerPago !== "" &&
      contrato.fiador !== "" &&
      contrato.frecuenciaPago !== "" &&
      contrato.idRepresentante !== 0 &&
      contrato.monto !== 0 &&
      contrato.munId !== 0 &&
      contrato.palazMeses !== 0 &&
      contrato.resolucionCredito !== "" &&
      contrato.tasaIncumplimiento !== 0 &&
      contrato.tasaInteger !== 0 &&
      contrato.tasaMora !== 0 &&
      contrato.tipoDocId !== 0 &&
      contrato.garId !== 0
    ) {
      contratoService.save(_contrato).then((response) => {
        if (response === undefined) {
        } else {
          localStorage.setItem("contrato", JSON.stringify(response));
        }

        if (contrato.id === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Nuevo contrato creado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: " Contrato actualizado",
            life: 4000,
          });
        }
        setSubmitted(false);
        setBlock(true);
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que tenga llenos los campos necesarios ",
        life: 4000,
      });
    }
  };

  return (
    <div>
      <div className="card p-fluid" autoCapitalize="off">
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="description">Municipio</label>
            <InputText
              id="Destino"
              type="text"
              rows="4"
              placeholder="OPCIONAL  "
              value={contrato.garantia}
              onChange={(e) => onChange(e, "garantia")}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
            />
          </div>
          <div className="field col">
            <label htmlFor="description">Departameto</label>

            <div className="field ">
              <Dropdown
                value={idDepartamento || contrato.municipio.departamento}
                options={appDepartamentos}
                onChange={(e) => onchangeDep(e)}
                disabled={block === true ? "on" : ""}
                optionLabel="descripcion"
                placeholder="Seleccione un departamento"
                required
              />
            </div>
          </div>

          <div className="field col">
            <label htmlFor="description">Municipio</label>

            <Dropdown
              value={contrato.municipio}
              options={appMunicipio}
              onChange={(e) =>
                onInputChangeRegObjectMun(e, "municipio", e.value.id, "munId")
              }
              disabled={block === true ? "on" : ""}
              optionLabel="descripcion"
              placeholder="Seleccione un municipio"
              required
              className={classNames({
                "p-invalid": submitted && !contrato.munId,
              })}
            />
          </div>
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label html="a"> Notario para el contrato</label>
            <Dropdown
              value={contrato.notario}
              options={notarios}
              onChange={(e) =>
                onInputChangeRegObjectMun(e, "notario", e.value.id, "idNotario")
              }
              disabled={block === true ? "on" : ""}
              optionLabel="descripcion"
              placeholder="Seleccione un Notario"
              required
              className={classNames({
                "p-invalid": submitted && !contrato.idNotario,
              })}
            />
          </div>

          <div className="field col">
            <label html="a">
              {" "}
              Representante de la cooperativa para el contrato
            </label>
            <Dropdown
              value={contrato.representate}
              options={representates}
              onChange={(e) =>
                onInputChangeRegObjectMun(
                  e,
                  "representate",
                  e.value.id,
                  "idRepresentante"
                )
              }
              disabled={block === true ? "on" : ""}
              optionLabel="descripcion"
              placeholder="Seleccione un representante de la cooperativa"
              required
              className={classNames({
                "p-invalid": submitted && !contrato.idRepresentante,
              })}
            />
          </div>
          <div className="field col">
            <InputSwitch
              checked={block}
              onChange={(e) => setBlock(e.value)}
              disabled={contrato.imp == "S" ? "on" : ""}
            />
            <label htmlFor="Editar datos" className="ml-3">
              {block == true ? "Editar" : "Bloquear"}
            </label>
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label html="Garantia">Garantia</label>
            <Dropdown
              value={contrato.tipoGarantia}
              options={garantias}
              onChange={(e) =>
                onInputChangeRegObject(e, "tipoGarantia", e.value.id, "garId")
              }
              optionLabel="descripcion"
              placeholder="Seleccione un tipo de garantia"
              required
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.garId,
              })}
            />
          </div>
          <div className="field col">
            <label>Resolucion de credito</label>
            <InputText
              id="resolucion de credito"
              type="text"
              rows="4"
              placeholder="RGG 2020004/20"
              value={contrato.resolucionCredito}
              onChange={(e) => onChange(e, "resolucionCredito")}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.resolucionCredito,
              })}
            />
            {submitted && !contrato.destino && (
              <small className="p-invalid"> Se necesita un destino</small>
            )}
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label>Destino</label>
            <InputTextarea
              id="Destino"
              type="text"
              rows="4"
              placeholder="VIVIENDA (CONSTRUCCIÓN DE VIVIENDA)"
              value={contrato.destino}
              onChange={(e) => onChange(e, "destino")}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.destino,
              })}
            />
            {submitted && !contrato.destino && (
              <small className="p-invalid"> Se necesita un destino</small>
            )}
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label html="a"> Fecha de Contrato</label>
            <InputText
              id="FechaContrato"
              type="date"
              value={contrato.fechaContrato}
              onChange={(e) => onChange(e, "fechaContrato")}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.fechaContrato,
              })}
            />
          </div>
          <div className="field col">
            <label htmlFor=" Genero"> Deudor sabe firmar</label>
            <div className="formgrid grid">
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={contrato.firma == "S"}
                    onChange={(e) => onChange(e, "firma")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.firma,
                    })}
                  />
                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="N"
                    checked={contrato.firma == "N"}
                    onChange={(e) => onChange(e, "firma")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.firma,
                    })}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col">
            <label htmlFor="monto">Monto total en Quetzales</label>
            <span className="p-input-icon-left">
              <i>Q. </i>
              <InputText
                id="FechaContrato"
                type="number"
                value={contrato.monto}
                mode="decimal"
                autoComplete="off"
                disabled={block === true ? "on" : ""}
                onChange={(e) => onChange(e, "monto")}
                className={classNames({
                  "p-invalid": submitted && !contrato.monto,
                })}
              />
            </span>
            {submitted && !contrato.monto && (
              <small className="p-invalid"> Se necesita un monto</small>
            )}
          </div>

          <div className="field col">
            <label htmlFor="monto">Cantidad de desembolsos</label>

            <InputText
              id="FechaContrato"
              type="number"
              value={contrato.cantDesembolsos}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              onChange={(e) => onChange(e, "cantDesembolsos")}
              className={classNames({
                "p-invalid": submitted && !contrato.cantDesembolsos,
              })}
            />

            {submitted && !contrato.cantDesembolsos && (
              <small className="p-invalid">
                {" "}
                Se necesita almenos un desembolso a realizar
              </small>
            )}
          </div>
          <div className="field col">
            <label htmlFor="plazo">Plazo en meses </label>
            <InputText
              id="Plazo"
              type="number"
              value={contrato.palazMeses}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              onChange={(e) => onChange(e, "palazMeses")}
              className={classNames({
                "p-invalid": submitted && !contrato.palazMeses,
              })}
            />
            {submitted && !contrato.cantDesembolsos && (
              <small className="p-invalid">
                {" "}
                Indeque para cuantos meses es el prestamo
              </small>
            )}
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col">
            <label htmlFor="Frecuencia de pago">Frecuencia de pago</label>
            <Dropdown
              value={contrato.pago}
              options={pagos}
              onChange={(e) =>
                onInputChangeRegObject(e, "pago", e.value.id, "frecuenciaPago")
              }
              optionLabel="descripcion"
              placeholder="Seleccione una frecuencia de pago"
              required
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.frecuenciaPago,
              })}
            />
          </div>

          <div className="field col">
            <label html="a"> Fecha primer pago</label>
            <InputText
              id="FechaContrato"
              type="date"
              value={contrato.fechaPrimerPago}
              onChange={(e) => onChange(e, "fechaPrimerPago")}
              autoComplete="off"
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.fechaPrimerPago,
              })}
            />
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col">
            <label htmlFor=" Genero"> Tasa de tasaIncumplimiento</label>
            <span className="p-input-icon-right">
              <i>% </i>
              <InputText
                id="FechaContrato"
                type="number"
                value={contrato.tasaIncumplimiento}
                mode="decimal"
                autoComplete="off"
                disabled={block === true ? "on" : ""}
                onChange={(e) => onChange(e, "tasaIncumplimiento")}
                className={classNames({
                  "p-invalid": submitted && !contrato.tasaIncumplimiento,
                })}
              />
            </span>
            {submitted && !contrato.tasaIncumplimiento && (
              <small className="p-invalid">
                {" "}
                Se necesita una tasa de incumpliiento
              </small>
            )}
          </div>

          <div className="field col">
            <label htmlFor=" tasa de interes"> Tasa de interes</label>
            <span className="p-input-icon-right">
              <i>% </i>
              <InputText
                id="tasa de interes"
                type="number"
                value={contrato.tasaInteger}
                mode="decimal"
                autoComplete="off"
                disabled={block === true ? "on" : ""}
                onChange={(e) => onChange(e, "tasaInteger")}
                className={classNames({
                  "p-invalid": submitted && !contrato.tasaInteger,
                })}
              />
            </span>
            {submitted && !contrato.tasaInteger && (
              <small className="p-invalid">
                {" "}
                Se necesita una tasa de interes
              </small>
            )}
          </div>

          <div className="field col">
            <label htmlFor=" tasa de mora"> Tasa de mora</label>
            <span className="p-input-icon-right">
              <i>% </i>
              <InputText
                id="tasa de mora"
                type="number"
                value={contrato.tasaMora}
                mode="decimal"
                autoComplete="off"
                disabled={block === true ? "on" : ""}
                onChange={(e) => onChange(e, "tasaMora")}
                className={classNames({
                  "p-invalid": submitted && !contrato.tasaMora,
                })}
              />
            </span>
            {submitted && !contrato.tasaMora && (
              <small className="p-invalid">
                {" "}
                Se necesita una tasa de morosidad
              </small>
            )}
          </div>
        </div>

        <div className="grid formgrid">
          <div className="field col-4">
            <label htmlFor=" Genero"> contrato con fiador</label>
            <div className="formgrid grid">
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={contrato.fiador === "S"}
                    onChange={(e) => onChange(e, "fiador")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.fiador,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="fiador"
                    value="N"
                    checked={contrato.fiador === "N"}
                    onChange={(e) => onChange(e, "fiador")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.fiador,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>

          <div className="field col-4 ">
            <label htmlFor="Frecuencia de pago">Tipo de contrato</label>
            <Dropdown
              value={contrato.tipoDocumento}
              options={tipoDocumento}
              onChange={(e) =>
                onInputChangeRegObject(
                  e,
                  "tipoDocumento",
                  e.value.id,
                  "tipoDocId"
                )
              }
              optionLabel="descripcion"
              placeholder="Seleccione un tipo de contrato"
              required
              disabled={block === true ? "on" : ""}
              className={classNames({
                "p-invalid": submitted && !contrato.tipoDocId,
              })}
            />
          </div>
          <div
            className="field col-4"
            style={{ display: contrato.fiador === "S" ? "" : "none" }}
          >
            <label htmlFor=" tasa de interes"> El fiador tiene garantias</label>

            <div className="formgrid grid">
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="option1"
                    value="S"
                    checked={contrato.fiadorGarantia === "S"}
                    onChange={(e) => onChange(e, "fiadorGarantia")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.fiadorGarantia,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">Si</label>
                </div>
              </div>
              <div className="field col">
                <div className="field-radiobutton">
                  <RadioButton
                    inputId="fiador"
                    value="N"
                    checked={contrato.fiadorGarantia === "N"}
                    onChange={(e) => onChange(e, "fiadorGarantia")}
                    className={classNames({
                      "p-invalid": submitted && !contrato.fiadorGarantia,
                    })}
                    disabled={block === true ? "on" : ""}
                  />
                  <label htmlFor="option1">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div className="card p-fluid">
        <div className="grid formgrid">
          <div className="col-6">
            <Button
              type="button"
              label="Cancelar"
              icon="pi pi-times "
              className="p-button-danger"
              onClick={cancelar}
            />
          </div>
          <div className="col-6">
            <Button
              type="button"
              label="Guardar"
              icon="pi pi-upload "
              className="p-button-success"
              onClick={() => save()}
              disabled={block === true ? "on" : ""}
            />
          </div>
        </div>

        <Toast ref={toast} />
      </div>
    </div>
  );
};
