import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
//services
import ContratoService from "./../../service/AppService/ContratoService";
import PersonaService from "./../../service/AppService/PersonaService";
import { VisualizarDoc } from "../VisualizarDoc";
import { ImprimirDoc } from "../ImprimirDoc";

import { Toast } from "primereact/toast";
export const Contrato = ({ cliente }) => {
  let contratoEmpty = {
    cantDesembolsos: 0,
    cantidadGarantia: 1,
    corId: 0,
    destino: "",
    garId: 0,
    deudorGarantia: "",
    fechaContrato: "",
    fechaPrimerPago: "",
    fiador: "",
    fiadorGarantia: "",
    frecuenciaPago: 0,
    pago: {
      descripcion: "",
      id: 0,
    },

    garantia: "",
    id: 0,
    idNotario: 0,
    notario: {
      descripcion: "",
      id: 0,
    },

    idRepresentante: 0,
    epresentate: { id: 0, descripcion: "" },

    monto: 0,
    munId: 0,
    municipio: {
      depId: 0,
      departamento: {
        descripcio: "",
        id: 0,
      },
    },
    palazMeses: 0,
    perId: 0,
    resolucionCredito: "",
    tasaIncumplimiento: 0,
    tasaInteger: 0,
    tasaMora: 0,
    tipoDocId: 0,
    tipoDocumento: { id: 0, descripcion: "" },
    usuId: 0,
  };

  const [contratos, setContratos] = useState([]);
  const [persona, setPersona] = useState([]);
  const [representante, setRepresentante] = useState([]);
  const [clienteApp, setClienteApp] = useState(cliente);
  const [imprimir, setImprimir] = useState(false);
  const [visualizar, setVisualizar] = useState(false);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const accept = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });

    onClick();
    //localStorage.setItem("contrato", JSON.stringify(rowData));
  };

  const reject = () => {
    setVisible(false);
    toast.current.show({
      severity: "warn",
      summary: "Error",
      detail: "Se canselo la impresion del contrato",
      life: 3000,
    });
  };

  useEffect(() => {
    setClienteApp(cliente);
    const personaService = new PersonaService();

    const contratoService = new ContratoService();
    contratoService.findByPerId(cliente?.id || 0).then((response) => {
      setContratos(response);
    });

    personaService.findByEsNotario().then((response) => {
      setPersona(response);
    });

    personaService.findByEsRepresentante().then((response) => {
      setRepresentante(response);
    });
  }, []);

  const frecuenciaPago = (rowData) => {
    return (
      <div>
        {rowData.frecuenciaPago === 1
          ? "Semanal"
          : rowData.frecuenciaPago === 2
          ? "Quincenal"
          : rowData.frecuenciaPago === 3
          ? "Mensual"
          : rowData.frecuenciaPago === 4
          ? "Trimestral"
          : rowData.frecuenciaPago === 5
          ? "Semestral"
          : rowData.frecuenciaPago === 6
          ? "Anual"
          : "Otro"}
      </div>
    );
  };

  const moraPorta = (rowData) => {
    return <div>{rowData.tasaMora + " %"}</div>;
  };

  const intPorta = (rowData) => {
    return <div>{rowData.tasaInteger + " %"}</div>;
  };

  const notarioD = (rowData) => {
    var nombre = "no encontrado";
    for (var i = 0; i < persona.length; i++) {
      if (rowData.idNotario === persona[i].id) {
        nombre = persona[i].primerNombre + " " + persona[i].primerApellido;
      }
    }
    return <>{nombre}</>;
  };

  const representative = (rowData) => {
    var nombre = "no encontrado";
    for (var i = 0; i < representante.length; i++) {
      if (rowData.idRepresentante === representante[i].id) {
        nombre =
          representante[i].primerNombre + " " + representante[i].primerApellido;
      }
    }
    return <>{nombre}</>;
  };

  const onClick = () => {
    setImprimir(true);
  };

  const onHide = () => {
    setImprimir(false);
    setVisualizar(false);
    setVisible(false);
  };

  const boton = (
    <div>
      <Link
        to={{
          pathname: "/contrato/menuContrato",
        }}
      >
        <Button
          icon="pi pi-plus"
          className="p-button-success mr-4 md-2"
          onClick={() =>
            localStorage.setItem("contrato", JSON.stringify(contratoEmpty))
          }
        />
      </Link>
    </div>
  );

  const accciones = (rowData) => {
    if (rowData.imp == "N") {
      return (
        <>
          <Link
            to={{
              pathname: "/contrato/menuContrato",
            }}
          >
            <Button
              className="p-button-rounded p-button-info mr-2 p-mr-2"
              icon="pi pi-pencil"
              onClick={() =>
                localStorage.setItem("contrato", JSON.stringify(rowData))
              }
              tooltip={"editar"}
            />
          </Link>

          <Button
            className="p-button-rounded p-button-warning mr-2 p-mr-2"
            icon="pi pi-eye"
            onClick={() => {
              setVisualizar(true);
              localStorage.setItem("contrato", JSON.stringify(rowData));
            }}
            tooltip={"Visualizar"}
          />

          <Button
            icon="  pi pi-print"
            className="p-button-rounded p-button-danger mr-2 p-mr-2 t-mr-2"
            onClick={() => {
              setVisible(true);
              localStorage.setItem("contrato", JSON.stringify(rowData));
            }}
            tooltip={"Imprimir"}
          />
        </>
      );
    } else {
      return (
        <>
          <Link
            to={{
              pathname: "/contrato/menuContrato",
            }}
          >
            <Button
              className="p-button-rounded p-button-info mr-2 p-mr-2"
              icon="pi pi-pencil"
              onClick={() =>
                localStorage.setItem("contrato", JSON.stringify(rowData))
              }
              tooltip={"editar"}
            />
          </Link>

          <Button
            className="p-button-rounded p-button-warning mr-2 p-mr-2"
            icon="pi pi-eye"
            onClick={() => {
              setVisualizar(true);
              localStorage.setItem("contrato", JSON.stringify(rowData));
            }}
            tooltip={"Visualizar"}
          />

          <Button
            icon="  pi pi-print"
            className="p-button-rounded p-button-danger mr-2 p-mr-2 t-mr-2"
            onClick={() => {
              setImprimir(true);
              localStorage.setItem("contrato", JSON.stringify(rowData));
            }}
            tooltip={"Imprimir"}
          />
        </>
      );
    }
  };

  const actualizar = () => {
    try {
      var contrato1;

      var conList = localStorage.getItem("contrato");
      contrato1 = JSON?.parse(conList);
    } catch (err) {
      window.location = "#/error";
    }
    const contratoService = new ContratoService();
    let _contrato = { ...contrato1 };
    if (contrato1.imp == "N") {
      _contrato["imp"] = "S";
    }
    contratoService.save(_contrato).then((response) => {
      if (response === undefined) {
      } else {
        localStorage.setItem("contrato", JSON.stringify(response));
      }
      contratoService.findByPerId(cliente?.id || 0).then((response) => {
        setContratos(response);
      });
    });
  };

  const formatDate = (value) => {
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "-" + mm + "-" + yy;

    return myDateString;
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.fechaContrato);
  };

  const formatMiles = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";
    let arr = number.toString().split(".");
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join(".") : arr[0];
  };
  const formatNumber = (value) => {
    return formatMiles(value.monto);
  };
  return (
    <div>
      <Toast ref={toast} />{" "}
      <div className="grid">
        <div className="col-12 md:col-12">
          <div className="card p-fluid">
            <div className="formgrid grid">
              <div className="field col-4">{boton}</div>
            </div>
            <DataTable
              value={contratos}
              emptyMessage="No se encontro ninguna contrato"
              responsiveLayout="scroll"
            >
              <Column
                field="corId"
                header="Numero de contrato"
                bodyClassName="text-center"
              ></Column>
              <Column
                dataType="date"
                header="Fecha del contrato"
                body={dateBodyTemplate}
              ></Column>
              <Column field="destino" header="Destino"></Column>

              <Column
                field="monto"
                body={formatNumber}
                header="Monto Q."
                bodyClassName="text-center"
              ></Column>
              <Column
                field="palazMeses"
                header="Plazo en meses"
                bodyClassName="text-center"
              ></Column>
              <Column
                body={frecuenciaPago}
                header="Fecuencia del pago"
              ></Column>

              <Column
                field="idNotario"
                body={notarioD}
                header="Notario"
              ></Column>

              <Column
                field="idRepresentante"
                body={representative}
                header="Representante"
              ></Column>

              <Column
                field="resolucionCredito"
                header="Resolucion de Credito"
                bodyClassName="text-justify"
              ></Column>
              <Column
                body={intPorta}
                header="Tasa de interes"
                bodyClassName="text-center"
              ></Column>
              <Column
                body={moraPorta}
                header="Tasa de mora"
                bodyClassName="text-center"
              ></Column>
              <Column
                body={accciones}
                header="Acciones disponibles"
                bodyClassName="text-center"
              ></Column>
            </DataTable>
          </div>
          <Dialog
            header="Imprimir contrato"
            visible={imprimir}
            onHide={() => onHide()}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            style={{ width: "90vw" }}
          >
            <ImprimirDoc />
          </Dialog>

          <Dialog
            header="Desea imprimir el contrato?"
            visible={visible}
            onHide={() => reject()}
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            style={{ width: "50vw" }}
          >
            <div className="flex align-items-center py-5 px-5">
              <i className="pi pi-exclamation-triangle mr-4 text-2xl" />
              <p className="m-0 text-lg">
                Una vez impreso no puede editar el contrato
              </p>
            </div>
            <div className="formgrid grid">
              <div className="field col"></div>
              <div className="field col">
                <Button
                  label="Cancelar"
                  className="p-button-warning p-button-text mr-2 mb-2"
                  onClick={() => {
                    reject();
                  }}
                />{" "}
                <Button
                  label="Confrimar"
                  className="p-button-raised p-button-info mr-2 mb-2"
                  onClick={() => {
                    setVisible(false);
                    setImprimir(true), actualizar();
                  }}
                />
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Vista previa"
            visible={visualizar}
            onHide={() => onHide()}
            icon="pi pi-exclamation-triangle "
            breakpoints={{ "960px": "75vw", "640px": "100vw" }}
            style={{ width: "90vw" }}
          >
            <VisualizarDoc />
          </Dialog>
        </div>
      </div>{" "}
    </div>
  );
};
